import React from "react";
import { Route } from "react-router-dom";
import CurrentBooking from "../pages/Booking/CurrentBooking/CurrentBooking";
import AddBooking from "../pages/Booking/AddBooking/AddBooking";
import NewBooking from "../pages/Booking/NewBooking/NewBooking";
import OnlineBooking from "../pages/Booking/OnlineBooking/OnlineBooking";
import RacBooking from "../pages/Booking/RacBooking/RacBooking";
import CompletedBooking from "../pages/Booking/CompletedBooking/CompletedBooking";
import CancelledBooking from "../pages/Booking/CancelledBooking/CancelledBooking";
import Enquiry from "../pages/Booking/Enquiry/Enquiry";
import TeleCancel from "../pages/Booking/TeleCancel/TeleCancel";
import Lmv from "../pages/Booking/Lmv/Lmv";


const BookingRoutes = ({ basePath }: any) => {
  return (
    <>
      <Route
        path={basePath.path + "/"}
        exact
        component={CurrentBooking}
      ></Route>
      <Route
        path={basePath.path + "/addBooking"}
        exact
        component={AddBooking}
      ></Route>
      <Route
        path={basePath.path + "/editBooking"}
        exact
        component={AddBooking}
      ></Route>
      <Route
        path={basePath.path + "/newBooking"}
        exact
        component={NewBooking}
      ></Route>
      <Route
        path={basePath.path + "/onlineBooking"}
        exact
        component={OnlineBooking}
      ></Route>
      <Route
        path={basePath.path + "/racBooking"}
        exact
        component={RacBooking}
      ></Route>
      <Route
        path={basePath.path + "/currentBooking"}
        exact
        component={CurrentBooking}
      ></Route>
      <Route
        path={basePath.path + "/completedBooking"}
        exact
        component={CompletedBooking}
      ></Route>
      <Route
        path={basePath.path + "/enquiry"}
        exact
        component={Enquiry}
      ></Route>
      <Route
        path={basePath.path + "/cancelledBooking"}
        exact
        component={CancelledBooking}
      ></Route>
      <Route
        path={basePath.path + "/teleCancel"}
        exact
        component={TeleCancel}
      ></Route>
      <Route path={basePath.path + "/lmv"} exact component={Lmv}></Route>
    </>
  );
};

export default BookingRoutes;
