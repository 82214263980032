import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select, InputNumber, Row, Col, notification, DatePicker } from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { TextArea } = Input;
// import OtpInput from 'react-otp-input';


const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const Vehicleinfo = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);



  useEffect(() => {
    setVisible(props.visible);
    console.log(props.selectedRowData);



  }, [props.visible]);




  //Vehicle Type list





  const handleCancel = (e: any, data: any = null) => {
    props.hideModal(false, data);

  };


  //Branchlist




  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={800}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      {(props.selectedRowData !== undefined) && (
        
        // <div>
          <Form
        {...formItemLayout}
        name="vehicle-info"
      >
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="vehicleid"
          label="Vehicle Id"
          >
           <Input defaultValue={props.selectedRowData.vehicleid || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="cab_id"
          label="M-Truck ID"
        >
           <Input defaultValue={props.selectedRowData.cab_id || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="car_Type"
          label="Car Type"
        >
           <Input defaultValue={props.selectedRowData.car_Type || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="vehicletype"
          label="Vehicle Type"
        >
           <Input 
              defaultValue={props.selectedRowData.vehicletype == 1 ?
                'Tata Ace' : (props.selectedRowData.vehicletype == 2) ? 
                'Bolero' : (props.selectedRowData.vehicletype == 3) ? 
                '407' : (props.selectedRowData.vehicletype == 4) ? 
                'Eicher' : ''} 
              readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="vehiclename"
          label="Vehicle Name"
        >
           <Input defaultValue={props.selectedRowData.vehiclename || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="cab_no"
          label="Reg No"
        >
           <Input defaultValue={props.selectedRowData.cab_no || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="CityID"
          label="City ID"
        >
           <Input defaultValue={props.selectedRowData.CityID || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="odameter"
          label="Odameter"
        >
           <Input defaultValue={props.selectedRowData.odameter || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="start_km"
          label="Start KM"
        >
           <Input defaultValue={props.selectedRowData.start_km || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="close_km"
          label="Close KM"
        >
           <Input defaultValue={props.selectedRowData.close_km || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row> 
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="owner_id"
          label="Owner ID"
        >
           <Input defaultValue={props.selectedRowData.owner_id || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name={"owner_name"}
          label="Owner Name"
        >
           <Input defaultValue={props.selectedRowData.owner_name || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="owner_mobno"
          label="Owner Mobile"
        >
           <Input defaultValue={props.selectedRowData.owner_mobno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="owneraltermobno"
          label="Alternate"
        >
           <Input defaultValue={props.selectedRowData.owneraltermobno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="owner_address"
          label="Owner Address"
        >
           <TextArea defaultValue={props.selectedRowData.owner_address || ""} 
              readOnly= {true} 
              autoSize
              style={{'resize':'none'}}/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="owneraadhar"
          label="Owner Aadhaar"
        >
           <Input defaultValue={props.selectedRowData.owneraadhar || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="owerlic"
          label="Owner license No"
        >
           <Input defaultValue={props.selectedRowData.owerlic || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="owerlicexp"
          label="Owner license Expiry"
        >
           <Input defaultValue={props.selectedRowData.owerlicexp || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>    
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="owerbadge"
          label="Owner Batch No"
        >
           <Input defaultValue={props.selectedRowData.owerbadge || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name={"owerbadgeexp"}
          label="Owner Batch Expiry"
        >
           <Input defaultValue={props.selectedRowData.owerbadgeexp || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="accno"
          label="Owner Bank Account"
        >
           <Input defaultValue={props.selectedRowData.accno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="bankname"
          label="Owner Bank Name"
        >
           <Input defaultValue={props.selectedRowData.accno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="branch"
          label="Owner Bank Branch"
        >
           <Input defaultValue={props.selectedRowData.branch || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="ifsc"
          label="Owner IFSC"
        >
           <Input defaultValue={props.selectedRowData.ifsc || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="pan"
          label="Owner PAN No"
        >
           <Input defaultValue={props.selectedRowData.pan || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>    
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driver_id"
          label="Driver ID"
        >
           <Input defaultValue={props.selectedRowData.driver_id || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driver_name"
          label="Driver Name"
        >
           <Input defaultValue={props.selectedRowData.driver_name || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driver_mobno"
          label="Driver Mobile"
        >
           <Input defaultValue={props.selectedRowData.driver_mobno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driver_almobno"
          label="Personal No"
        >
           <Input defaultValue={props.selectedRowData.driver_almobno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="dtype"
          label="Driver Type"
        >
           <Input 
              defaultValue={(props.selectedRowData.dtype) === 1 ?
                 'Self' : (props.selectedRowData.dtype === 2) ? 'Managed' : ""} 
              readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="isenabled"
          label="Driver Status"
        >
           <Input 
              defaultValue={props.selectedRowData.isenabled == 1 ?
                'Active' : (props.selectedRowData.isenabled == 0) ? 'Inactive' : ""} 
              readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row> 
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driver_address"
          label="Driver Address"
        >
          <TextArea defaultValue={props.selectedRowData.driver_address || ""} 
              readOnly= {true} 
              autoSize
              style={{'resize':'none'}}/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driveraadhar"
          label="Driver Aadhaar"
        >
           <Input defaultValue={props.selectedRowData.driveraadhar || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>    
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driver_licenceno"
          label="Driver license No"
        >
           <Input defaultValue={props.selectedRowData.driver_licenceno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driver_lic_val"
          label="Driver License Expiry"
        >
           <Input defaultValue={props.selectedRowData.driver_lic_val || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driverbadge"
          label="Driver Batch No"
        >
           <Input defaultValue={props.selectedRowData.driverbadge || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driverbadgeexp"
          label="Driver Batch Expiry"
        >
           <Input defaultValue={props.selectedRowData.driverbadgeexp || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driveraccno"
          label="Driver Bank Account"
        >
           <Input defaultValue={props.selectedRowData.driveraccno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driverbankname"
          label="Driver Bank Name"
        >
           <Input defaultValue={props.selectedRowData.driverbankname || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driverbranch"
          label="Driver Bank Branch"
        >
           <Input defaultValue={props.selectedRowData.driverbranch || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="driverifsc"
          label="Driver IFSC"
        >
           <Input defaultValue={props.selectedRowData.driverifsc || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="driverpan"
          label="Driver PAN No"
        >
           <Input defaultValue={props.selectedRowData.driverpan || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="Deposit"
          label="Deposit Amount"
        >
           <Input defaultValue={props.selectedRowData.Deposit || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>  
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="fc_val"
          label="FC Date"
        >
           <Input defaultValue={props.selectedRowData.fc_val || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="inexpiry"
          label="Insurance Expiry"
        >
           <Input defaultValue={props.selectedRowData.inexpiry || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="policyexpirt"
          label="Pollution Date"
        >
           <Input defaultValue={props.selectedRowData.policyexpirt || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="policyno"
          label="Ins. Policy No"
        >
           <Input defaultValue={props.selectedRowData.policyno || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>     
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="tarpaulin"
          label="Tarpaulin"
        >
           <Input 
              defaultValue={(props.selectedRowData.tarpaulin) === 1 ?
                'Yes' : (props.selectedRowData.tarpaulin === 0) ? 'No' : ""} 
                readOnly= {true} 
                size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="rope"
          label="Rope"
        >
           <Input 
              defaultValue={(props.selectedRowData.rope) === 1 ?
                'Yes' : (props.selectedRowData.rope === 0) ? 'No' : ""} 
                readOnly= {true} 
                size="small"/>  
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="keralapermit"
          label="Kerala Permit"
        >
           <Input 
              defaultValue={(props.selectedRowData.keralapermit) === 1 ?
                'Yes' : (props.selectedRowData.keralapermit === 2) ? 'No' : ""} 
                readOnly= {true} 
                size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="AttachDate"
          label="Attached Date"
        >
           <Input defaultValue={props.selectedRowData.AttachDate || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="username"
          label="Username"
        >
           <Input  readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="password"
          label="Password"
        >
           <Input defaultValue={props.selectedRowData.password || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>   
        <Row gutter={24}>
        <Col span={12}>
        <Form.Item
          name="imei"
          label="App IMEI"
        >
           <Input defaultValue={props.selectedRowData.imei || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="present_location"
          label="Login Location"
        >
           <Input defaultValue={props.selectedRowData.present_location || ""} readOnly= {true} size="small"/> 
        </Form.Item>
        </Col>
        </Row>    
          {/* <Row gutter={24}>
            <Col span={6}><span>Vehicle ID :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.vehicleid || ""}</span> </Col>
            <Col span={6}><span>M-Truck ID :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.cab_id || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Vehicle Name :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.car_Type || ""}</span> </Col>
            <Col span={6}><span>Body Type :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.car_Type || ""}</span> </Col>
          </Row> 
          <Row gutter={24}>
            <Col span={6}><span>Reg No:</span> </Col>
            <Col span={6}><span>{props.selectedRowData.cab_no || ""}  </span> </Col>
            <Col span={6}><span>Type :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.type || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner ID :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owner_id || ""}</span> </Col>
            <Col span={6}><span>Owner Name :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owner_name || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner Mobile :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owner_mobno || ""}</span> </Col>
            <Col span={6}><span>Alternate :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owneraltermobno || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner Address :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owner_address || ""}</span> </Col>
            <Col span={6}><span>Owner Aadhaar :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owneraadhar || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner license No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owerlic || ""}</span> </Col>
            <Col span={6}><span>Owner license Expiry :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owerlicexp || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner Batch No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owerbadge || ""}</span> </Col>
            <Col span={6}><span>Owner Batch Expiry :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.owerbadgeexp || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner Bank Account :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.accno || ""}</span> </Col>
            <Col span={6}><span>Owner Bank Name :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.bankname || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner Bank Branch :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.branch || ""}</span> </Col>
            <Col span={6}><span>Owner IFSC :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.ifsc || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Owner PAN No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.pan || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver ID :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_id || ""}</span> </Col>
            <Col span={6}><span>Driver Name :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_name || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver Mobile :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_mobno || ""}</span> </Col>
            <Col span={6}><span>Personal No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_almobno || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver Address :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_address || ""}</span> </Col>
            <Col span={6}><span>Driver Aadhaar:</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driveraadhar || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver license No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_licenceno || ""}</span> </Col>
            <Col span={6}><span>Driver License Expiry :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driver_lic_val || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver Batch No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverbadge || ""}</span> </Col>
            <Col span={6}><span>Driver Batch Expiry :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverbadgeexp || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver Bank Account :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driveraccno || ""}</span> </Col>
            <Col span={6}><span>Driver Bank Name :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverbankname || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver Bank Branch :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverbranch || ""}</span> </Col>
            <Col span={6}><span>Driver IFSC :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverifsc || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Driver PAN No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.driverpan || ""}</span> </Col>
            <Col span={6}><span>Deposit Amount :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.Deposit || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>FC Date :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.fc_val || ""}</span> </Col>
            <Col span={6}><span>Insurance Expiry :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.inexpiry || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Pollution Date :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.policyexpirt || ""}</span> </Col>
            <Col span={6}><span>Ins. Policy No :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.policyno || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Tarpaulin : </span> </Col>
            <Col span={6}><span>{props.selectedRowData.tarpaulin || ""}</span> </Col>
            <Col span={6}><span>Rope :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.rope || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Kerala Permit : </span> </Col>
            <Col span={6}><span>{props.selectedRowData.keralapermit || ""}</span> </Col>
            <Col span={6}><span>Attached Date  :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.AttachDate || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>Username : </span> </Col>
            <Col span={6}><span>{props.selectedRowData.username || ""}</span> </Col>
            <Col span={6}><span>Password :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.password || ""}</span> </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}><span>App IMEI : </span> </Col>
            <Col span={6}><span>{props.selectedRowData.imei || ""}</span> </Col>
            <Col span={6}><span>Login Location :</span> </Col>
            <Col span={6}><span>{props.selectedRowData.present_location || ""}</span> </Col>
          </Row>
          </div> */}
        </Form>
      )}


    </Modal>
  );
};

export default Vehicleinfo;
