import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select, InputNumber, Row, Col, notification, DatePicker, Checkbox } from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import OtpInput from 'react-otp-input';


const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const Vehicleform = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [defaultDateTime, setDefaultDateTime]: any = useState();
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [oldChecked, setoldChecked] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    console.log(props.selectedValues);
    setVisible(props.visible);
    getbrancheslist();
    getVechileTypeList();
    if (props.isedit)
      setSelectedValues(props.selectedValues);
    else
      getnewid();

  }, [props.visible]);


  const getnewid = () => {
    if (props.isold) {
      let copyselectedvalues = { ...selectedValues };

      copyselectedvalues.mtruckid = props.mtruckid;
      setSelectedValues(copyselectedvalues);
    }
    else {
      const uri = UrlConstants.getvehicleid + "?CityId=" + branchid;
      axiosGet(uri).then((resp: any) => {
        console.log(resp);
        console.log(props.mtruckid);
        let copyselectedvalues = { ...selectedValues };
        copyselectedvalues.vehicleid = resp[0].vehicleid;
        copyselectedvalues.mtruckid = props.mtruckid;
        setSelectedValues(copyselectedvalues);
      });
    }

  };


  //Vehicle Type list
  const getVechileTypeList = () => {
    const options = {
      params: {
        cityid: branchid,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + branchid;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
      let vehicleindex = resp.findIndex((obj: { id: any; }) => obj.id === 1);
      resp[vehicleindex].city = branchid;
    });
  };

  const onVehicleTypeChange = (value: any, fullInfo: any) => {
    console.log(fullInfo);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleType = value;
    copyselectedvalues.capacity = fullInfo.others.loadcapacity;
    copyselectedvalues.vehiclename = fullInfo.others.vehicletype;
    setSelectedValues(copyselectedvalues);
  };


  const handleCancel = (e: any, data: any = null) => {
    setSelectedValues({});
    props.hideModal(false, data);
  };


  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  }
  const oncabtypeChange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.cabtype = value;
    setSelectedValues(copyselectedvalues);
  }
  const ontarpaulinChange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.tarpaulin = value;
    setSelectedValues(copyselectedvalues);
  }
  const onropeChange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.rope = value;
    setSelectedValues(copyselectedvalues);
  }
  const onklpermitChange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.kerelapermit = value;
    setSelectedValues(copyselectedvalues);
  }
  const inscompanyChange = (e: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.inscompany = e.target.value;
    setSelectedValues(copyselectedvalues);
  }
  const regnoChange = (e: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.regno = e.target.value;
    setSelectedValues(copyselectedvalues);
  }

  const OnvehicleidChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleid = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const capacitychange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.capacity = value;
    setSelectedValues(copyselectedvalues);
  }
  const policynochange = (e: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.policyno = e.target.value;
    setSelectedValues(copyselectedvalues);
  }
  const vehiclemodelchange = (e: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehiclemodelname = e.target.value;
    setSelectedValues(copyselectedvalues);
  }
  const pollutionexpdateChange = (value: any) => {

    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.pollutionexpdate = value;
    setSelectedValues(copyselectedvalues);
  }



  const onfcDateChange = (value: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.fcdate = value;
    setSelectedValues(selectedValuesCopy);
  };
  const insexpdateChange = (value: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.insexpdate = value;
    setSelectedValues(selectedValuesCopy);
  };
  function zeroPad(num: any) {
    return num.toString().padStart(6, "0");
  }
  const odometerchange = (e: any) => {
    // e=e.target.value.replace(/^0+/, '');
    // e=zeroPad(e);
    // if(e.length==7)
    //   e=e.slice(0, -1);
    //   console.log(e);

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.odometer = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const addnewvehicle = () => {
    let fcdate;
    let pollutionexpdate;
    let insexpdate;
    if (selectedValues.fcdate)
      fcdate = selectedValues.fcdate
        ? selectedValues.fcdate.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");
    if (selectedValues.pollutionexpdate)
      pollutionexpdate = selectedValues.pollutionexpdate
        ? selectedValues.pollutionexpdate.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");
    if (selectedValues.insexpdate)
      insexpdate = selectedValues.insexpdate
        ? selectedValues.insexpdate.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");
    console.log(selectedValues);
    const options: any = {
      data: {
        cab_id: selectedValues.mtruckid || selectedValues.cab_id,
        vehicleid: selectedValues.vehicleid || selectedValues.vehicleid,
        cab_no: selectedValues.regno || selectedValues.cab_no,
        car_Type: selectedValues.vehiclename || selectedValues.car_Type,
        vehicletype: selectedValues.vehicleType || selectedValues.vehicletype,
        type: selectedValues.cabtype || selectedValues.type,
        inc: selectedValues.inscompany || selectedValues.inc || "",
        fc_val: fcdate || selectedValues.fc_val || "",
        policyno: selectedValues.policyno || selectedValues.policyno || "",
        policyexpirt: pollutionexpdate || selectedValues.policyexpirt || "",
        inexpiry: insexpdate || selectedValues.inexpiry || "",
        tarpaulin: selectedValues.tarpaulin || selectedValues.tarpaulin,
        rope: selectedValues.rope || selectedValues.rope,
        odameter: selectedValues.odometer || selectedValues.odameter,
        keralapermit: selectedValues.kerelapermit || selectedValues.keralapermit,
        isold: props.isold || oldChecked || false,
        vehiclename: selectedValues.vehiclemodelname || selectedValues.keralapermit,

      },
    };
    console.log(options);
    let url = UrlConstants.addnewvehicle;
    if (props.isedit)
      url = UrlConstants.editvehicle;
    axiosPost(UrlConstants.addnewvehicle, options)
      .then((resp: any) => {
        if (resp.status != 401) {
          console.log(resp);
          handleCancel("", options.data);

          let notificationInfo: any;
          if (props.isedit) {
            notificationInfo = {
              header: "Vehicle Updated Successfully",
            };
          }
          else {
            notificationInfo = {
              header: "Vehicle Added Successfully",
            };
          }
          openNotificationWithIcon("Success", notificationInfo);

        }
        else {
          let notificationInfo1: any;
          notificationInfo1 = {
            header: resp.message,
          };
          openNotificationWithIcon("Error", notificationInfo1);
        }
      })
      .catch(function (error: any) {
        console.log(error);
        if (error.response) {
          props.hideModal(true);
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "VehicleAdding Failed",
          description: error.response.status,
        };
        openNotificationWithIcon("Error", notificationInfo);

      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };


  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={700}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="nest-messages" {...formItemLayout}
      >

        <Row gutter={24}>

          <Col span={12}>


            <Form.Item name={`vehicleid`} label={`Vehicle ID `} rules={[]}>
              {
                props.isold !== true ?
                  <Input value={selectedValues.vehicleid} defaultValue={selectedValues.vehicleid} type="text" readOnly />

                  :


                  <Input value={selectedValues.vehicleid} onChange={OnvehicleidChange} type="text" />



              }

            </Form.Item>

          </Col>


          <Col span={12} >
            <Form.Item

              name={`regno`}
              label={`Reg No `}
              rules={[{ required: true }]}
            >
              <Input
                onChange={regnoChange}
                placeholder="reg no" value={selectedValues.regno} defaultValue={selectedValues.cab_no} maxLength={10} />
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["vehicle name", "vehicle name"]} label="VehicleName" rules={[]}>
              <Input type="text" onChange={vehiclemodelchange} defaultValue={selectedValues.vehiclename} value={selectedValues.vehiclemodelname} />

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["vehiclename", "vehiclename"]} label="Vehicle Type" rules={[]}>

              <Select
                placeholder="Vehicle Type"
                value={selectedValues.vehicleType}
                defaultValue={selectedValues.vehicletype}
                onChange={onVehicleTypeChange}
              >
                {vehicleTypeList.map((vehicle: any) => (
                  <Option
                    key={vehicle.id}
                    value={vehicle.id}
                    others={vehicle}
                  >
                    {vehicle.vehicletype}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["cabtype", "cabtype"]} label="Type" rules={[]}>

              <Select placeholder="Type"
                value={selectedValues.cabtype}
                defaultValue={selectedValues.type}
                onChange={oncabtypeChange}
              >
                <Option value={1}>Open Body</Option>
                <Option value={2}>Closed Body</Option>

              </Select>


            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["Capacity", "Capacity"]} label="Capacity" rules={[]}>
              <span style={{ display: "none" }}>{selectedValues.vehicleType}</span>
              <Select
                placeholder="capacity"
                defaultValue={selectedValues.capacity}
                value={selectedValues.capacity}
                onChange={capacitychange}
                disabled
              >
                {vehicleTypeList.map((vehicle: any) => (
                  <Option
                    key={vehicle.id}
                    value={vehicle.id}
                    others={vehicle}
                  >
                    {vehicle.loadcapacity}
                  </Option>
                ))}
              </Select>
              {/* <Input type="text" value={selectedValues.capacity} onChange={capacitychange} /> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["Insurance", "Insurance"]} label="Ins. Company" rules={[]}>
              <Input type="text" onChange={inscompanyChange}
                value={selectedValues.inscompany} defaultValue={selectedValues.inc} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["FC Date", "FC Date"]} label="FC Date" rules={[]}>
              <DatePicker

                value={selectedValues.fcdate}
                defaultValue={moment(selectedValues.fc_val)}
                format={dateFormat}
                onChange={onfcDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["Policy No", "Policy No"]} label="Policy No" rules={[]}>
              <Input type="text" onChange={policynochange} defaultValue={selectedValues.policyno} value={selectedValues.policyno} />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["Polution Expiry Date", "Polution Expiry Date"]} label="Polution Expiry" rules={[]}>
              <DatePicker

                value={selectedValues.pollutionexpdate}
                defaultValue={moment(selectedValues.policyexpirt)}
                format={dateFormat}
                onChange={pollutionexpdateChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["Expiry Date", "Expiry Date"]} label="Expiry Date" rules={[]}>
              <DatePicker
                // defaultValue={selectedValues.insexpdate}
                value={selectedValues.insexpdate}
                defaultValue={moment(selectedValues.inexpiry)}
                format={dateFormat}
                onChange={insexpdateChange}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["Tarpaulin", "Tarpaulin"]} label="Tarpaulin" rules={[]}>
              <Select placeholder="Tarpaulin"
                value={selectedValues.tarpaulin}

                defaultValue={selectedValues.tarpaulin}
                onChange={ontarpaulinChange}
              >
                <Option value={1}>Yes</Option>
                <Option value={2}>No</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["Rope", "Rope"]} label="Rope" rules={[]}>
              <Select placeholder="Rope"
                value={selectedValues.rope}
                defaultValue={selectedValues.rope}
                onChange={onropeChange}
              >
                <Option value={1}>Yes</Option>
                <Option value={2}>No</Option>

              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["Odometer", "Odometer"]} label="Odometer" rules={[]}>
              <Input maxLength={7} type="text" onChange={odometerchange} value={selectedValues.odometer} defaultValue={selectedValues.odameter} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["Kerala Permit", "Kerala Permit"]} label="Kerala Permit" rules={[]}>
              <Select placeholder="Kerala Permit"
                value={selectedValues.kerelapermit}
                defaultValue={selectedValues.keralapermit}
                onChange={onklpermitChange}
              >
                <Option value={1}>Yes</Option>
                <Option value={2}>No</Option>

              </Select>
            </Form.Item>
          </Col>

        </Row>


      </Form>
      <Row gutter={24}>

        <Col offset={5}>
          <Button onClick={addnewvehicle}>
            Save
      </Button>
        </Col>
        <Col offset={2}>
          <Button onClick={handleCancel}>
            Cancel
      </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Vehicleform;
