import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, DatePicker, Input, Spin, Select } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";
import "./Report.scss";
import { CSVLink } from "react-csv";

const { Option } = Select;
const { Search } = Input;

const TeleColorReport = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchEmployeeIdsList, setSearchEmployeeIdsList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment() );
  const dateFormat = "DD/MM/YYYY";
  const [flag, setFlag] = useState(false);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
        title: "Employ id",
        dataIndex: "employeeid",
        key: "Booking ID",

    },
    {
        title: "Login Time",
        dataIndex: "LoginTime",
        key: "Booking ID",
        render: (text: any) => {
            return moment(text).format("hh:mm A");
          },

    },
    {
        title: "Log out time",
        dataIndex: "Logouttime",
        key: "Name",
        render: (text: any) => {
            return moment(text).format("hh:mm A");
          },

    },
    {
        title: "City id",
        dataIndex: "Cityid",
        key: "Name",

    },
  
    {
        title: "Hours worked",
        dataIndex: "Hoursworked",
        key: "Name",
        render: (text:any) => {
          if(text != undefined){
            if(text != null && text!= undefined && text!='' && text!=0)
              return Math.round(text);
            else
              return text;
          }
        }
    },
    {
        title: "Total Booking",
        dataIndex: "Totalbooking",
        key: "Name",

    },
    {
        title: "Assigned",
        dataIndex: "Assigned",
        key: "Name",

    },
    {
        title: "Completed",
        dataIndex: "completed",
        key: "Name",

    },
    {
        title: "Cancelled",
        dataIndex: "Cancelled",
        key: "Name",

    },
    {
        title: "Enquiry",
        dataIndex: "Enquiry",
        key: "Name",

    },
   
    {
        title: "Total calls attended",
        dataIndex: "Totalcallsattended",
        key: "Name",

    },
    {
        title: "Star rating",
        dataIndex: "Starrating",
        key: "Name",

    },
    
   

]

  
const onfromdatechange = (value: any) => {
  setfromdate(value);
  try {

    var fromdate = moment(value).format("YYYY/MM/DD");
    let todate = moment().format("YYYY/MM/DD");
    //DateSearch(fromdate, todate);
    setFlag(false);
  }
  catch (ex) {
    console.log(ex);
  }

}
const ontodatechange = (value: any) => {
  settodate(value);
  try {

    var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
    let startdate = moment(fromdate).format("YYYY/MM/DD");
    //DateSearch(startdate, todate);
    setFlag(false);
  }
  catch (ex) {
    console.log(ex);
  }

}

const adayclick = (event: any = null) => {
  setIsButtonClicked('Today');
  var first = moment();
  var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
  let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
  setfromdate(moment(fromdate));
  //var new_date = moment().add('days', 1);
  var new_date = moment();
  var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
  let todate = year + '-' + month + '-' + day;
  settodate(moment(todate));
  setFlag(true);
  onTableSearch(fromdate, todate, pagination, SearchText);
};

const twodayclick = () => {
  setIsButtonClicked('Yesday');
  var first = moment().add('days', -1);
  var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
  let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
  setfromdate(moment(fromdate));
  var new_date = moment().add('days', 1);
  var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
  let todate = year + '-' + month + '-' + day;
  settodate(moment(todate));
  setFlag(true);
  onTableSearch(fromdate, todate, pagination, SearchText);
};

const monthclick = () => {
  setIsButtonClicked('Month');
  var first = moment();
  var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
  let fromdate = firstyear + '-' + firstmonth + '-' + '01';
  setfromdate(moment(fromdate));
  //var new_date = moment().add('days', 1);
  var new_date = moment();
  var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
  let todate = year + '-' + month + '-' + day;
  settodate(moment(todate));
  console.log(fromdate);
  setFlag(true);
  onTableSearch(fromdate, todate, pagination, SearchText);
};

const weekclick = () => {
  setIsButtonClicked('Week');
  var first = moment().add('days', -(moment().day()))
  var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
  let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
  setfromdate(moment(fromdate));
  console.log(fromdate);
  //var new_date = moment().add('days', 1);
  var new_date = moment();
  var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
  let todate = year + '-' + month + '-' + day;
  settodate(moment(todate));
  setFlag(true);
  onTableSearch(fromdate, todate, pagination, SearchText);
};
const disabledDate = (current: any) => {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
};
const disabledFromDate = (current: any) => {
  // Can not select days before today and today
  return (current && current > moment(todate).endOf("day"));
};

 

const DateSearch =(fromdate:any,todate:any,pagination:any,SearchText:any)=>{
  setIsLoading(true);
  const options: any = {
    params: {
      branchid:branchid,
      fromdate:fromdate,
      todate: todate,
      employeeid: selectedValues.employeeid,
      limit: pagination.pageSize,
      skip: (pagination.current-1) * (pagination.pageSize),
      filtervalue: SearchText
    },
  };
  console.log(options);
  
  axiosGet(UrlConstants.gettelecallerreport,options).then((resp: any) => {
    resp.data.forEach((e: any) => {
      e.key = e._id;
    });
    setIsLoading(false);
    setCurrentBookingGridData(resp.data);
    setcopyCurrentBookingGridData(resp.data);
    console.log(resp);
    setPagination({
      ...pagination,
      total: resp.tablelength
    });
  }).catch(function (error:any) {
    setIsLoading(false);
    if (error.response) {
      console.log(error.response.data,error.response.status);
      setCurrentBookingGridData([]);
      setcopyCurrentBookingGridData([]);
    }
  });
  };


  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  useEffect(() => {
    console.log(isRowSelected);
    adayclick();
  }, []);

  /* const onEmployeeIdSearch = (value:any) => {
   if (value) {
      // const searchedList = locationsList.filter(
      //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
      // );
      var filtered = employees.filter((x: any) => String(x.vehicleid.toLowerCase()).includes(value.toLowerCase()));
      //console.log(searchedList,filtered);
      setSearchEmployeeIdsList(filtered);
    } else {
      setSearchEmployeeIdsList([]);
    }  
  };*/
  
  const onTableSearch = (fromdate:any, todate:any, pagination:any, SearchText:any) => {
    //DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
    DateSearch(moment(fromdate).format("YYYY/MM/DD"), moment(todate).add("days", 1).format("YYYY/MM/DD"), pagination, SearchText);
  }
  const onTableClear = () => {
    selectedValues.employeeid = null;
    //setSearchEmployeeIdsList([])
    onTableSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'),pagination, SearchText);
  }

  const onEmployeeIdChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.employeeid=e.target.value;
      setSelectedValues(copyselectedvalues);
    };

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
    const globalSearch = (searchText:any) =>{ 
    let filteredData = currentBookingGridData.filter((value: any) => {
      //console.log(value.otp,value.vehicleid);
      if(value.employeeid===undefined || value.employeeid===null)
          value.employeeid='';
        if(value.Cityid===undefined || value.Cityid===null)
          value.Cityid='';
        if(value.Hoursworked===undefined || value.Hoursworked===null)
          value.Hoursworked='';
        if(value.Totalbooking===undefined || value.Totalbooking===null)
          value.Totalbooking='';
        if(value.Assigned===undefined || value.Assigned===null)
          value.Assigned='';
        if(value.completed===undefined || value.completed===null)
          value.completed='';
        if(value.Cancelled===undefined || value.Cancelled===null)
          value.Cancelled='';
        if(value.Enquiry===undefined || value.Enquiry===null)
          value.Enquiry='';
        if(value.Totalcallsattended===undefined || value.Totalcallsattended===null)
          value.Totalcallsattended='';
        if(value.Starrating===undefined || value.Starrating===null)
          value.Starrating='';
    return (
      value.employeeid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Cityid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Hoursworked.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Totalbooking.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Assigned.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.completed.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Cancelled.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Enquiry.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Totalcallsattended.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      value.Starrating.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
      setcopyCurrentBookingGridData(filteredData);
};

const headersToExport = columns.map((column:any) => 
      ((column.title =='Hours worked') ? {label:column.title, key:'Hoursworked'} 
    : {label: column.title, key: column.dataIndex}))

const dataToExport = currentBookingGridData.map((value:any) => ({
    ...value,
    Hoursworked:(value.Hoursworked != null && value.Hoursworked!= undefined && value.Hoursworked!='') ? Math.round(value.Hoursworked) : value.Hoursworked,
    LoginTime:moment(value.LoginTime).format("hh:mm A"),
    Logouttime:moment(value.Logouttime).format("hh:mm A"),
}))
const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
  setPagination(pagination);
  onTableSearch(fromdate, todate, pagination, SearchText);
  console.log(pagination); 
}
const onSearch = (value:any) => {
  console.log(value);
  setsearchText(value);
  onTableSearch(fromdate, todate, pagination, value);
}

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
      <Row  style={{ paddingBottom: 10 }}> <Col> <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}> TELE CALLER REPORT</span>
      </Col>  
        <Col offset={2}>
          <span style={{ fontSize: 'large' , fontWeight: 'bold', fontStyle:'Times New Roman' }}> From : {moment(fromdate).format('DD.MM.YYYY')}   To : {flag ? moment().format('DD.MM.YYYY') : moment(todate).format('DD.MM.YYYY')}  </span>
      
          </Col>
          </Row>
      <Row style={{paddingBottom:10}}>
      <Col>


<span style={{ fontSize: 'medium' }}> From:</span>
 
 <DatePicker
 
     value={fromdate}
     format={dateFormat}
     disabledDate={disabledFromDate}  
     onChange={onfromdatechange} />
<span style={{ fontSize: 'medium' }}> To:</span>


<DatePicker
     value={flag? moment() : todate}
     //value={todate}
     format={dateFormat}
     disabledDate={disabledDate}  
     onChange={ontodatechange} />


</Col>
<Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>
          <Col span={2} offset={1} >
            <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
</Row>
<Row style={{paddingBottom:10}}>
               
                <Col span={3}>
                {/* <Select placeholder="Employee Id"
                  value={selectedValues.employeeId}
                  style={{ width: 120 }}
                  showSearch
                  optionFilterProp="children"
                  onChange={onEmployeeIdChange}
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  showArrow={false}
                  onSearch={onEmployeeIdSearch}
                  notFoundContent={null}
                  >

                  {searchEmployeeIdsList.map((id: any) => (
                    <Option key={id.employeeid} value={id.employeeid}>
                      {id.vehicleid}
                    </Option>
                  ))}
                </Select> */}
              <Input placeholder="Employee ID"  value={selectedValues.employeeid}
                  onChange={onEmployeeIdChange}/>
                </Col>
                <Col span={2} offset={1}>
                    <Button
                        className="report-btn-blockSearch"
                        onClick={()=>onTableSearch(fromdate, todate, pagination, SearchText)}
                        disabled={moment(fromdate).isAfter(moment(todate))}>
                        Search
                    </Button>
            </Col>
            <Col span={2}>
            <Button
            htmlType="button"
              style={{marginLeft:10}}
              className="report-btn-blockClear"
              onClick={onTableClear}>
              Clear
            </Button>          
          </Col>
            
            <Col span={2}  offset={1}>
            <CSVLink
                filename={"TeleCallerReport.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              >
                <Button className="report-btn-blockExport" >Export</Button>
            </CSVLink>
          </Col>
 <Col span={4} offset={9}>
                    {/* <Input placeholder="Search Text" 
                      className="search" 
                      onChange={onSearchchange}
                      value={SearchText}/> */}
                      <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
            </Col>
            </Row>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          onchange={onPaginationchange}
          enablePagination={pagination}
         
          scroll={{ x: 1850,y: 480 }}
        ></AppTable>
        </Spin>
      </div>
    </div>
  );
};

export default TeleColorReport;
