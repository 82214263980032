
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input, Spin, Pagination } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddGoods from "../../components/AppModal/Addgoods";

const { Search } = Input;

const GoodsMaster = () => {
  let history: any = useHistory();
  const [addgoods, setaddgoodsmodal] = useState(false);
  const [editgoods, seteditgoodsmodal] = useState(false);
  const [ModalTitle, setModalTitle] = useState("Add Goods");
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [goodslist, setgoodslist] = useState([]);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [selectedValues, setselectedValues]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [availableCabData, setAvailableCabData] = useState([]);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });

  const showpreassignmodal = () => {
    setPreAssignModalVisible(true);
  };
  const hidepreassignmodal = (isVisible: any) => {
    setPreAssignModalVisible(isVisible);
  };
 
  const columns = [
    {
      title: "Goods ID",
      dataIndex: "Packageid",
      key: "Goodsid",
    
     
      
    },
    {
      title: "Name",
      dataIndex: "Package",
      key: "name",
      
     
      ellipsis: true,
    },
    
  ];

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };


  const globalSearch = (searchText: any) => {
    let filteredData = goodslist.filter((value: any) => {


      return (

        value.Packageid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          
        value.Package
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) 

      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  
  const Addgoodsmodal = () => {
    let templist:any=goodslist;
    let Newid=Number(templist[templist.length-1].Packageid)+1;
    console.log(Newid);
    let copyselectedvalues:any={};
    copyselectedvalues.Goodsid=Newid;
    setselectedValues(copyselectedvalues);
    setModalTitle("Add Goods");
    seteditgoodsmodal(false);
    setaddgoodsmodal(true);
  };
  const Editgoodsmodal = () => {
    console.log(selectedRowData);
    let copyselectedvalues:any={};
    copyselectedvalues.Goodsid=selectedRowData[0].Packageid;
    copyselectedvalues.GoodsName=selectedRowData[0].Package;
    setselectedValues(copyselectedvalues);
    setModalTitle("Edit Goods");
    seteditgoodsmodal(true);
    setaddgoodsmodal(true);
  };
  const hideModal  = (isVisible: any) => {
    setaddgoodsmodal(isVisible);
    getgoodslist(pagination, SearchText);
    refresh(pagination, SearchText);
    setsearchText('');
  };
  
  
 
  useEffect(() => {
    getgoodslist(pagination, SearchText);
   refresh(pagination, SearchText);
  }, []);


  const refresh = (pagination:any, SearchText:any) => {
    const options = {
      params: {
        branchid: branchid,
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    axiosGet(UrlConstants.getgoodslist, options).then(
      (resp: any) => {
        console.log(resp);
        resp.data.forEach((e: any) => {
          e.key = e.Packageid.toString();
        });
        setgoodslist(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setPagination({
          ...pagination,
          total: resp.tablelength
        });
      }).catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getgoodslist = (pagination:any, SearchText:any) => {
    const options: any = {
      params: {
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    axiosGet(UrlConstants.getgoodslist, options).then((resp: any) => {
      console.log(resp);
     if (resp.data!= undefined) {
      resp.data.forEach((e: any) => {
        e.key = e.Packageid.toString();
      });
      setgoodslist(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    }
    })
    .catch(function (error:any) {
      if (error.response) {
        console.log(error.response.data,error.response.status);
      }
    });
  };
  const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
    setPagination(pagination);
    getgoodslist(pagination, SearchText);
    console.log(pagination); 
  } 
  const onSearch = (value:any) => {
    setsearchText(value);
    getgoodslist(pagination, value);
  }
  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
          <Button  onClick={Addgoodsmodal} >
           
             Add
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button onClick={Editgoodsmodal}
             
              disabled={!isRowSelected}
              
            >
             Edit
            </Button>
          </Col>
          <Col span={4} offset={14}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
      </div>
      <div style={{width: "50%"}}>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          enableRadioButton={true}
          onRadioButtonChange={onGridRowSelect}
          enablePagination={pagination}
          selectionType="radio"
          onchange={onPaginationchange}
        ></AppTable>
        </Spin>
      </div>
      <AddGoods
     
         title={ModalTitle}
       visible={addgoods}
       selectedRowData={selectedValues}
       hideModal={hideModal}
       isedit={editgoods}
      ></AddGoods>
      
    </div>
  );
};

export default GoodsMaster;
