import React, { createContext, useState } from "react";

export const AuthContext = createContext({});

const AuthContextProvider = (props: any) => {
  const checkIsUserLogedIn = () => {
    const token = localStorage.getItem("token");
    return { isUserLoggedIn: token ? true : false, loggedInToken: token };
  };
  const [isUserLogedIn, setIsUserLogedIn] = useState(
    checkIsUserLogedIn().isUserLoggedIn
  );
  const [loginTokenInfo, setLoginTokenInfo] = useState(
    checkIsUserLogedIn().loggedInToken
  );

  const updateUserLoginInfo = () => {
    setIsUserLogedIn(checkIsUserLogedIn().isUserLoggedIn);
    setLoginTokenInfo(checkIsUserLogedIn().loggedInToken);
  };

  return (
    <AuthContext.Provider
      value={{
        isUserLogedIn,
        loginTokenInfo,
        updateUserLoginInfo: updateUserLoginInfo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
