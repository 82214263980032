import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
  DatePicker,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddUser = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [defaultDateTime, setDefaultDateTime]: any = useState();
  const [disableFields, setDisableFields] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [roleList, setroleList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const dateFormat = "DD/MM/YYYY";
  const [employeeid, setemployeeid] = useState("");
  const [joiningdate, setjoiningdate] = useState("");
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [cityid, setcityid] = useState("");
  const [role, setrole] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [bankname, setbankname] = useState("");
  const [bankaccountno, setbankaccountno] = useState("");
  const [bankbranchname, setbankbranchname] = useState("");
  const [ifsc, setifsc] = useState("");
  const [panno, setpanno] = useState("");
  const [aaddharno, setaaddharno] = useState("");
  const [fathername, setfathername] = useState("");
  const [husbandname, sethusbandname] = useState("");
  const [husbanddob, sethusbanddob] = useState("");
  const [employeedob, setemployeedob] = useState("");
  const [kidsname, setkidsname] = useState("");
  const [kidsdob, setkidsdob] = useState("");
  const [qualification, setqualification] = useState("");
  const [specialinterest, setspecialinterest] = useState("");
  const [edudetails, setedudetails] = useState("");
  const [skills, setskills] = useState("");
  const [weddingdate, setweddingdate] = useState("");
  const [address, setaddress] = useState("");
  const [alternateno, setalternateno] = useState("");
  const [pfno, setpfno] = useState("");
  const [insurance, setinsurance] = useState("");
  const [showValidations, setValidations] = useState(false);
  const [oldChecked, setoldChecked] = useState(true);

  useEffect(() => {
    setVisible(props.visible);
    getroleslist();
    getbrancheslist();

    if (props.isedit) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.employeeid = props.selectedRowData.LoginID;
      copyselectedvalues.name = props.selectedRowData.name;
      copyselectedvalues.date = moment(props.selectedRowData.doj, "YYYY-MM-DD");
      copyselectedvalues.Branchid = props.selectedRowData.CityID;
      copyselectedvalues.username = props.selectedRowData.UserName;
      copyselectedvalues.password = props.selectedRowData.Passwd;
      copyselectedvalues.roleid = props.selectedRowData.roledetail[0].id;
      copyselectedvalues.mobileno = props.selectedRowData.mobno;
      copyselectedvalues.bankname = props.selectedRowData.bankname;
      copyselectedvalues.bankaccountno = props.selectedRowData.bankaccountno;
      copyselectedvalues.ifsc = props.selectedRowData.ifsc;
      copyselectedvalues.bankbranchname = props.selectedRowData.branchname;
      copyselectedvalues.aadhar = props.selectedRowData.adhaarno;
      copyselectedvalues.pan = props.selectedRowData.pan;
      copyselectedvalues.fathername = props.selectedRowData.fatherhusbandname;
      copyselectedvalues.address = props.selectedRowData.address;
      copyselectedvalues.alternateno =
        props.selectedRowData.alternativemobilenumber;
      copyselectedvalues.qualification = props.selectedRowData.qualification;
      copyselectedvalues.dob = moment(props.selectedRowData.dob, "YYYY-MM-DD");
      copyselectedvalues.husbandname = props.selectedRowData.husbandname;
      copyselectedvalues.husdob = moment(
        props.selectedRowData.hdob,
        "YYYY-MM-DD"
      );
      copyselectedvalues.kidsname = props.selectedRowData.kidsname;
      copyselectedvalues.kidsdob = moment(
        props.selectedRowData.kdob,
        "YYYY-MM-DD"
      );
      copyselectedvalues.weddingdate = moment(
        props.selectedRowData.weddingdate,
        "YYYY-MM-DD"
      );
      copyselectedvalues.edudetails = props.selectedRowData.educationaldetails;
      copyselectedvalues.specialinterest =
        props.selectedRowData.specialinterest;
      copyselectedvalues.skills = props.selectedRowData.skills;
      copyselectedvalues.pfno = props.selectedRowData.pfnumber;
      copyselectedvalues.insurance = props.selectedRowData.insurancedetails;

      setSelectedValues(copyselectedvalues);
    } else {
      setDefaultDateTime(moment());
      getnewid();
    }
  }, [props.visible]);

  const getnewid = () => {
    const uri = UrlConstants.getemployeeid + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.employeeid = resp[0].LoginID;
      setSelectedValues(copyselectedvalues);
    });
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  // const onFinish = (value: any) => {

  //   props.hideModal(false);
  // };
  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }
  function getroleslist() {
    axiosGet(UrlConstants.getroletypes).then((resp: any) => {
      setroleList(resp);
    });
  }

  const validateInput = () => {
    {
    }
    if (selectedValues.mobileno != undefined) {
      if (selectedValues.mobileno.length != 10) {
        setValidations(true);

        return false;
      } else setValidations(false);
      return true;
    } else if (selectedValues.alternateno != undefined) {
      if (selectedValues.alternateno.length != 10) {
        return false;
      } else return true;
    } else if (selectedValues.aadhar != undefined) {
      if (selectedValues.aadhar.length != 12) {
        return false;
      } else setValidations(false);
      return true;
    } else {
      setValidations(true);
      return false;
    }
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    props.hideModal(false);
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };
  const onrolechange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.roleid = value;
    setSelectedValues(copyselectedvalues);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const disabledDatedob = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const onDateChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.date = value;
    setSelectedValues(selectedValuesCopy);
  };
  const ondobChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.dob = value;
    setSelectedValues(selectedValuesCopy);
  };
  const onhusdobChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.husdob = value;
    setSelectedValues(selectedValuesCopy);
  };
  const onkidsdobChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.kidsdob = value;
    setSelectedValues(selectedValuesCopy);
  };
  const onweddingdateChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.weddingdate = value;
    setSelectedValues(selectedValuesCopy);
  };
  const namechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.name = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const usernamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.username = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const passwordchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.password = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const mobilenochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mobileno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const banknamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const bankaccountnochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankaccountno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const ifscchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.ifsc = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const bankbranchnamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankbranchname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const aadharchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.aadhar = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const panchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.pan = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const fathernamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.fathername = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const addresschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.address = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const alternatenochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.alternateno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const qualificationchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.qualification = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const husbandnamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.husbandname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const kidsnamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.kidsname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const edudetailschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.edudetails = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const specialinterestchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.specialinterest = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const skillschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.skills = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const pfnochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.pfno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const insurancechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.insurance = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const adduserdetails = () => {
    if (!validateInput()) {
      return;
    }
    let doj = selectedValues.date
      ? selectedValues.date.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let dob = selectedValues.dob
      ? selectedValues.dob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let husdob = selectedValues.husdob
      ? selectedValues.husdob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let kidsdob = selectedValues.kidsdob
      ? selectedValues.kidsdob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let weddingdate = selectedValues.weddingdate
      ? selectedValues.weddingdate.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");

    const options: any = {
      data: {
        LoginID: selectedValues.employeeid,
        doj: doj,
        name: selectedValues.name,
        CityID: branchid,
        UserName: selectedValues.username,
        Passwd: selectedValues.password,
        flag: "1",

        role: Number(selectedValues.roleid),
        mobno: selectedValues.mobileno,
        bankname: selectedValues.bankname,
        bankaccountno: selectedValues.bankaccountno,
        ifsc: selectedValues.ifsc,
        branchname: selectedValues.bankbranchname,
        adhaarno: selectedValues.aadhar,
        pan: selectedValues.pan || "",
        fatherhusbandname: selectedValues.fathername,
        address: selectedValues.address,
        alternativemobilenumber: selectedValues.alternateno || "",
        qualification: selectedValues.qualification,
        dob: dob,
        husbandname: selectedValues.husbandname || "",
        hdob: husdob || "",
        kidsname: selectedValues.kidsname || "",
        kdob: kidsdob || "",
        weddingdate: weddingdate || "",
        educationaldetails: selectedValues.edudetails,
        specialinterest: selectedValues.specialinterest || "",
        skills: selectedValues.skills || "",
        pfnumber: selectedValues.pfno || "",
        insurancedetails: selectedValues.insurance || "",
        isold: oldChecked,
      },
    };
    axiosPost(UrlConstants.adduser, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header: "User Added Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "User Adding Failed",
          description: "Error Occurred while Adding User ",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const updateuserdetails = () => {
    if (!validateInput()) {
      return;
    }
    let doj = selectedValues.date
      ? selectedValues.date.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let dob = selectedValues.dob
      ? selectedValues.dob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let husdob = selectedValues.husdob
      ? selectedValues.husdob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let kidsdob = selectedValues.kidsdob
      ? selectedValues.kidsdob.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let weddingdate = selectedValues.weddingdate
      ? selectedValues.weddingdate.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");

    const options: any = {
      data: {
        LoginID: selectedValues.employeeid,
        doj: doj,
        name: selectedValues.name,
        CityID: branchid,
        UserName: selectedValues.username,
        Passwd: selectedValues.password,
        flag: "1",

        role: Number(selectedValues.roleid),
        mobno: selectedValues.mobileno,
        bankname: selectedValues.bankname,
        bankaccountno: selectedValues.bankaccountno,
        ifsc: selectedValues.ifsc,
        branchname: selectedValues.bankbranchname,
        adhaarno: selectedValues.aadhar,
        pan: selectedValues.pan,
        fatherhusbandname: selectedValues.fathername,
        address: selectedValues.address,
        alternativemobilenumber: selectedValues.alternateno,
        qualification: selectedValues.qualification,
        dob: dob,
        husbandname: selectedValues.husbandname,
        hdob: husdob,
        kidsname: selectedValues.kidsname,
        kdob: kidsdob,
        weddingdate: weddingdate,
        educationaldetails: selectedValues.edudetails,
        specialinterest: selectedValues.specialinterest,
        skills: selectedValues.skills,
        pfnumber: selectedValues.pfno,
        insurancedetails: selectedValues.insurance,
        isold: oldChecked,
      },
    };
    axiosPost(UrlConstants.edituser, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header:
            "UserDetails for   " +
            selectedValues.name +
            " updated Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "updating userdetails  Failed",
          description: "Error Occurred while updating userdetails Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={500}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="nest-messages"
        {...formItemLayout}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["EmployeeID", "EmployeeID"]}
              label="EmployeeID"
              rules={[]}
            >
              <Input
                type="text"
                defaultValue={selectedValues.employeeid}
                value={selectedValues.employeeid}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Dateofjoining", "Dateofjoining"]}
              label="Date Of Joining"
              rules={[]}
            >
              <DatePicker
                defaultValue={selectedValues.date}
                value={selectedValues.date}
                // disabled={disableFields}
                // disabledDate={disabledDate}
                format={dateFormat}
                onChange={onDateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Name", "Name"]} label="Name" rules={[]}>
              <Input
                defaultValue={selectedValues.name}
                value={selectedValues.name}
                onChange={namechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Username", "Username"]}
              label="User Name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.username}
                value={selectedValues.username}
                onChange={usernamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Password", "Password"]}
              label="Password"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.password}
                value={selectedValues.password}
                onChange={passwordchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["cityid", "cityid"]}
              label="Select City"
              rules={[]}
            >
              <Select
                placeholder="Select City"
                defaultValue={selectedValues.Branchid}
                value={selectedValues.Branchid}
                onChange={onBranchChange}
              >
                {branchList.map((branch: any) => (
                  <Option key={branch._id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Role", "Role"]} label="Role" rules={[]}>
              <Select
                placeholder="Select Role"
                defaultValue={selectedValues.roleid}
                value={selectedValues.roleid}
                onChange={onrolechange}
              >
                {roleList.map((role: any) => (
                  <Option key={role.id} value={role.id}>
                    {role.role}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              style={
                showValidations && !selectedValues.mobileno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["mobile", "Mobileno"]}
              label="Mobile"
              rules={[]}
            >
              <Input
                value={selectedValues.mobileno}
                defaultValue={selectedValues.mobileno}
                onChange={mobilenochange}
                maxLength={10}
              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Mobile Number!
                </span>
              )}
            </Form.Item>
            {/* <Form.Item name={["MobileNo", "MobileNo"]} label="Mobile No" rules={[]}>
              <Input defaultValue={selectedValues.mobileno} value={selectedValues.mobileno} onChange={mobilenochange} type="text" />
            </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["BankName", "BankName"]}
              label="BankName"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.bankname}
                value={selectedValues.bankname}
                onChange={banknamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["BankAccountNo", "BankAccountNo"]}
              label="Bank Account No"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.bankaccountno}
                value={selectedValues.bankaccountno}
                onChange={bankaccountnochange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["IFSC", "IFSC"]} label="IFSC" rules={[]}>
              <Input
                defaultValue={selectedValues.ifsc}
                value={selectedValues.ifsc}
                onChange={ifscchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Branchname", "Branchname"]}
              label="Branch Name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.bankbranchname}
                value={selectedValues.bankbranchname}
                onChange={bankbranchnamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              style={
                showValidations && !selectedValues.aadhar
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["Adhaar", "Aadhaar"]}
              label="Aadhaar"
              rules={[]}
            >
              <Input
                value={selectedValues.aadhar}
                defaultValue={selectedValues.aadhar}
                onChange={aadharchange}
                maxLength={12}
              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Aadhar Number!
                </span>
              )}
            </Form.Item>
            {/* <Form.Item name={["AadhaarNo", "AadhaarNo"]} label="Aadhaar No" rules={[]}>
              <Input defaultValue={selectedValues.aadhar} value={selectedValues.aadhar} onChange={aadharchange} type="text" />
            </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["PAN", "PAN"]} label="PAN" rules={[]}>
              <Input
                defaultValue={selectedValues.pan}
                value={selectedValues.pan}
                onChange={panchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Father name", "Father name"]}
              label="Father name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.fathername}
                value={selectedValues.fathername}
                onChange={fathernamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Address", "Address"]} label="Address" rules={[]}>
              <Input
                defaultValue={selectedValues.address}
                value={selectedValues.address}
                onChange={addresschange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              style={
                showValidations && !selectedValues.alternateno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["alternate", "Alternate"]}
              label="Alternate No"
              rules={[]}
            >
              <Input
                value={selectedValues.alternateno}
                defaultValue={selectedValues.alternateno}
                onChange={alternatenochange}
                maxLength={10}
              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Alternate Mobile Number!
                </span>
              )}
            </Form.Item>
            {/* <Form.Item name={["AlternativeMobileNo", "AlternativeMobileNo"]} label="Alternative Mobile No" rules={[]}>
              <Input defaultValue={selectedValues.alternateno} value={selectedValues.alternateno} onChange={alternatenochange} type="text" />
            </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Qualification", "Qualification"]}
              label="Qualification"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.qualification}
                value={selectedValues.qualification}
                onChange={qualificationchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["DOB", "DOB"]} label="DOB" rules={[]}>
              <DatePicker
                defaultValue={selectedValues.dob}
                value={selectedValues.dob}
                disabled={disableFields}
                disabledDate={disabledDatedob}
                format={dateFormat}
                onChange={ondobChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Husband Name", "Husband Name"]}
              label="Husband Name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.husbandname}
                value={selectedValues.husbandname}
                onChange={husbandnamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["husdob", "husdob"]} label="DOB" rules={[]}>
              <DatePicker
                defaultValue={selectedValues.husdob}
                value={selectedValues.husdob}
                disabled={disableFields}
                disabledDate={disabledDatedob}
                format={dateFormat}
                onChange={onhusdobChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Kids name", "Kids name"]}
              label="Kids name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.kidsname}
                value={selectedValues.kidsnmae}
                onChange={kidsnamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["kidsdob", "kidsdob"]} label="DOB" rules={[]}>
              <DatePicker
                defaultValue={selectedValues.kidsdob}
                value={selectedValues.kidsdob}
                disabled={disableFields}
                disabledDate={disabledDatedob}
                format={dateFormat}
                onChange={onkidsdobChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Wedding Date", "Wedding Date"]}
              label="Wedding Date"
              rules={[]}
            >
              <DatePicker
                defaultValue={selectedValues.weddingdate}
                value={selectedValues.weddingdate}
                disabled={disableFields}
                disabledDate={disabledDatedob}
                format={dateFormat}
                onChange={onweddingdateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={[" Educationaldetails", " Educationaldetails"]}
              label=" Educational details"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.edudetails}
                value={selectedValues.edudetails}
                onChange={edudetailschange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Special interest", "Special interest"]}
              label="Special interest"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.specialinterest}
                value={selectedValues.specialinterest}
                onChange={specialinterestchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Skills", "Skills"]} label="Skills" rules={[]}>
              <Input
                defaultValue={selectedValues.skills}
                value={selectedValues.skills}
                onChange={skillschange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["PF number", "PF number"]}
              label="PF number"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.pfno}
                value={selectedValues.pfno}
                onChange={pfnochange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["Insurance Details", "Insurance Details"]}
              label="Insurance Details"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.insurance}
                value={selectedValues.insurance}
                onChange={insurancechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        {props.isedit !== true && (
          <Col offset={6}>
            <Button onClick={adduserdetails}>Save</Button>
          </Col>
        )}
        {props.isedit === true && (
          <Col offset={6}>
            <Button onClick={updateuserdetails}>Update</Button>
          </Col>
        )}
        {/* <Col offset={2}>
          <Button >
            Approve
      </Button>
        </Col> */}

        <Col offset={2}>
          <Button>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddUser;
