import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
  DatePicker,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddCorporate = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [defaultDateTime, setDefaultDateTime] = useState(moment());
  const [disableFields, setDisableFields] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [roleList, setroleList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const dateFormat = "DD/MM/YYYY";
  const [showValidations, setValidations] = useState(false);

  useEffect(() => {
    setVisible(props.visible);
    if (props.isedit) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.CompanyID = props.selectedRowData.CompanyID;
      copyselectedvalues.CompanyName = props.selectedRowData.CompanyName;
      copyselectedvalues.mdName = props.selectedRowData.MD_Name;
      copyselectedvalues.mobileno = props.selectedRowData.MD_Mobno;
      copyselectedvalues.Authperson = props.selectedRowData.Authorized_Person;
      copyselectedvalues.AuthMobile = props.selectedRowData.Auth_Mobno;
      copyselectedvalues.gstno = props.selectedRowData.gst;
      if (props.selectedRowData.is_credit == "True")
        copyselectedvalues.CreditStatus = 1;
      else copyselectedvalues.CreditStatus = 0;
      if (props.selectedRowData.isdeleted == "Inactive")
        copyselectedvalues.Status = 1;
      else copyselectedvalues.Status = 0;
      copyselectedvalues.address = props.selectedRowData.Address;
      copyselectedvalues.username = props.selectedRowData.username;
      copyselectedvalues.password = props.selectedRowData.Password;
      setSelectedValues(copyselectedvalues);
    } else {
      getnewid();
    }
  }, [props.visible]);

  const getnewid = () => {
    const uri = UrlConstants.getcompanyid + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.CompanyID = resp[0].CompanyID;
      setSelectedValues(copyselectedvalues);
    });
  };

  const handleCancel = (e: any) => {
    if (e == false) props.hideModal(true);
    else props.hideModal(false);
  };

  const validateInput = () => {
    {
    }
    if (selectedValues.mobileno != undefined) {
      if (selectedValues.mobileno.length != 10) {
        return false;
      } else setValidations(false);
      return true;
    } else if (selectedValues.AuthMobile != undefined) {
      if (selectedValues.AuthMobile.length != 10) {
        return false;
      } else setValidations(false);
      return true;
    } else if (selectedValues.whatsapp != undefined) {
      if (selectedValues.whatsapp.length != 12) {
        return false;
      } else setValidations(false);
      return true;
    } else {
      return false;
    }
  };

  const onFinish = (values: any) => {
    //   console.log("Received values of form: ", values);
    props.hideModal(false);
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };

  const Authpersonchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.Authperson = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const usernamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.username = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const passwordchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.password = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const mobilenochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mobileno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const Statuschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.Status = e;
    setSelectedValues(selectedValuesCopy);
  };
  const mdNamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mdName = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const gstnochange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.gstno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const addresschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.address = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const AuthMobilechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.AuthMobile = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const CreditStatuschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.CreditStatus = e;
    setSelectedValues(selectedValuesCopy);
  };

  const CompanyNamechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.CompanyName = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const savecompany = () => {
    if (!validateInput()) {
      return;
    }
    const options: any = {
      data: {
        CompanyID: selectedValues.CompanyID,
        CompanyName: selectedValues.CompanyName,
        MD_Name: selectedValues.mdName,
        MD_Mobno: selectedValues.mobileno,
        Authorized_Person: selectedValues.Authperson,
        Auth_Mobno: selectedValues.AuthMobile,
        gst: selectedValues.gstno,
        is_credit: selectedValues.CreditStatus.toString(),
        Address: selectedValues.address,
        cityid: branchid,
        username: selectedValues.username,
        Password: selectedValues.password,
        isdeleted: selectedValues.Status.toString(),
      },
    };
    axiosPost(UrlConstants.addcompany, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header: "Company Added Successfully",
          description: "Company for " + selectedValues.CompanyName + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Company Adding Failed",
          description: "Error Occurred while Adding Company",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const updatecompany = () => {
    const options: any = {
      data: {
        ID: props.selectedRowData.ID,
        CompanyID: selectedValues.CompanyID,
        CompanyName: selectedValues.CompanyName,
        MD_Name: selectedValues.mdName,
        MD_Mobno: selectedValues.mobileno,
        Authorized_Person: selectedValues.Authperson,
        Auth_Mobno: selectedValues.AuthMobile,
        gst: selectedValues.gstno,
        is_credit: selectedValues.CreditStatus.toString(),
        Address: selectedValues.address,
        cityid: branchid,
        username: selectedValues.username,
        Password: selectedValues.password,
        isdeleted: selectedValues.Status.toString(),
      },
    };
    /*  axiosPost(UrlConstants.editcompany, options)
          .then((resp: any) => {
            console.log(resp);
            handleCancel("");
            const notificationInfo: any = {
              header: "Company updated Successfully",
              description: "Company for " + selectedValues.CompanyName + " updated",
            };

            openNotificationWithIcon("Success", notificationInfo);

          })
          .catch(function (error: any) {
            var notificationInfo = {
                header: "Company Updating Failed",
                description: "Error Occurred while Updating Company",
              };
            if (error.response) {
              console.log(error.response.data, error.response.status);
              notificationInfo = {
                header: "Company Updating Failed",
                description: error.response.data.message,
              };
            }
          
            handleCancel(false);
            openNotificationWithIcon("Error", notificationInfo);
          }); */
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={500}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Company ID", "Company ID"]}
              label="Company ID"
              rules={[]}
            >
              <Input
                type="text"
                defaultValue={selectedValues.CompanyID}
                value={selectedValues.CompanyID}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Company Name", "Company Name"]}
              label="Company Name"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.CompanyName}
                value={selectedValues.CompanyName}
                onChange={CompanyNamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["GST NO", "GST NO"]} label="GST NO" rules={[]}>
              <Input
                defaultValue={selectedValues.gstno}
                value={selectedValues.gstno}
                onChange={gstnochange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["MD Name", "MD Name"]} label="MD Name" rules={[]}>
              <Input
                defaultValue={selectedValues.mdName}
                value={selectedValues.mdName}
                onChange={mdNamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              style={
                showValidations && !selectedValues.mobileno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["mobile", "Mobileno"]}
              label="Mobile"
              rules={[]}
            >
              <Input
                value={selectedValues.mobileno}
                defaultValue={selectedValues.mobileno}
                onChange={mobilenochange}
                maxLength={10}
              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Mobile Number!
                </span>
              )}
            </Form.Item>
            {/* <Form.Item name={["MobileNo", "MobileNo"]} label="Mobile No" rules={[]}>
                            <Input defaultValue={selectedValues.mobileno} value={selectedValues.mobileno} onChange={mobilenochange} type="text" />
                        </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Auth person", "Auth person"]}
              label="Auth person"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.Authperson}
                value={selectedValues.Authperson}
                onChange={Authpersonchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              style={
                showValidations && !selectedValues.AuthMobile
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["Auth Mobile", "Auth Mobile"]}
              label="Mobile"
              rules={[]}
            >
              <Input
                value={selectedValues.AuthMobile}
                defaultValue={selectedValues.AuthMobile}
                onChange={AuthMobilechange}
                maxLength={10}
              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Mobile Number!
                </span>
              )}
            </Form.Item>
            {/*  <Form.Item name={["Auth Mobile", "Auth Mobile"]} label="Auth Mobile" rules={[]}>
                            <Input defaultValue={selectedValues.AuthMobile} value={selectedValues.AuthMobile} onChange={AuthMobilechange} type="text" />
                        </Form.Item> */}
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Address", "Address"]} label="Address" rules={[]}>
              <Input
                defaultValue={selectedValues.address}
                value={selectedValues.address}
                onChange={addresschange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Credit Status", "Credit Status"]}
              label="Credit Status"
              rules={[]}
            >
              {/* <Input defaultValue={selectedValues.CreditStatus} value={selectedValues.CreditStatus} onChange={CreditStatuschange} type="text" /> */}
              <Select
                placeholder="Credit Status"
                value={selectedValues.CreditStatus}
                defaultValue={selectedValues.CreditStatus}
                onChange={CreditStatuschange}
              >
                <Option key={1} value={1}>
                  True
                </Option>
                <Option key={0} value={0}>
                  False
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["Status", "Status"]} label="Status" rules={[]}>
              {/* <Input defaultValue={selectedValues.Status} value={selectedValues.Status} onChange={Statuschange} type="text" /> */}
              <Select
                placeholder="Status"
                value={selectedValues.Status}
                defaultValue={selectedValues.Status}
                onChange={Statuschange}
              >
                <Option key={0} value={0}>
                  Active
                </Option>
                <Option key={1} value={1}>
                  Inactive
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["Username", "Username"]}
              label="Username"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.username}
                value={selectedValues.username}
                onChange={usernamechange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={[" Password", " Password"]}
              label="Password"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.password}
                value={selectedValues.password}
                onChange={passwordchange}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit !== true && (
          <Col offset={6}>
            <Button onClick={savecompany}>Save</Button>
          </Col>
        )}
        {props.isedit === true && (
          <Col offset={6}>
            <Button onClick={updatecompany}>Update</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddCorporate;
