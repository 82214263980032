import React, { Component } from "react";
import axios from "axios";
import "./Login.scss";
import { Typography, Form, Input, Checkbox, Button, Row, Col,notification } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
const { Title } = Typography;

class Login extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      opened: false,
    };
    this.toggleBox = this.toggleBox.bind(this);
    this.employeeid = "";
  }
  public employeeid = "";

  toggleBox() {
    const { opened }: any = this.state;
    this.setState({
      opened: !opened,
    });
  }

  render() {
    let employeeid;
    let password: any;
    let otp: any;
    const { opened }: any = this.state;

    function Copyright() {
      return (
        <Title className="login-copyright-section" level={4}>
          Copyright © TruckTaxi {new Date().getFullYear()}.
        </Title>
      );
    }

    let employeeidset = (e: any) => {
      this.employeeid = e;
      employeeid = e;
    };
    let passwordset = (e: any) => {
      password = e;
    };
    let otpset = (e: any) => {
      otp = e;
    };

    const loginHandler = (e: any) => {
      e.preventDefault();      
      if (opened) {
        var OtpFormData = new FormData();
        OtpFormData.append('username',this.employeeid);
        OtpFormData.append('otp',otp);
        axios.post(UrlConstants.verifyOtp, OtpFormData).then((res: any) => {
        if (res.status == 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("cityid", res.data.cityid);
          localStorage.setItem("username", this.employeeid);
          localStorage.setItem("roleid", res.data.roleid);
          localStorage.setItem("menu", JSON.stringify(res.data.menus));
          localStorage.setItem("submenu", JSON.stringify(res.data.submenus));
          localStorage.setItem("employeeid", res.data.employeeid);
          localStorage.setItem("employeename", res.data.employeename);
          // const notificationInfo = {
          //   header: "OTP Verified",
          //   description: res.data.message,
          // };
          // openNotificationWithIcon("Success", notificationInfo);
          this.props.authContext.updateUserLoginInfo();
          }
          else
          console.log(res);
        }).catch(function (error) {
          if (error.response) {
            const notificationInfo = {
              header: "OTP Verification Failed",
              description: error.response.data.message,
            };
            openNotificationWithIcon("Error", notificationInfo);
            console.log(error.response.data,error.response.status);
          }
        });
        } else {
        var loginFormData = new FormData();
        loginFormData.append('username',this.employeeid);
        loginFormData.append('password',password);
        axios.post(UrlConstants.login, loginFormData).then((resp) => {
        if (resp.status == 200) {
            try {
              this.toggleBox();              
            } catch (e) {}
          } else {
            console.log("failed");
          }
          }).catch(function (error) {
            if (error.response) {
              const notificationInfo = {
                header: "Login Failed",
                description: error.response.data.message,
              };
              openNotificationWithIcon("Error", notificationInfo);
              console.log(error.response.data,error.response.status);
            }
          });
        }
    };

    const openNotificationWithIcon = (type: any, info: any) => {
      if (type === "Success") {
        notification.success({
          message: info.header,
          description: info.description,
        });
      } else if (type === "Error") {
        notification.error({
          message: info.header,
          description: info.description,
        });
      }
    };
    function handleEnter(e:any) {
      if (e.keyCode === 13) {
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      }
    }
    return (
      <div className="container login-container">
        <Row>
          <Col span={12} offset={9}>
            <div className="paper">
              <LoginOutlined />
              <Title>Sign in</Title>
              <Form className="form" noValidate>
                <Form.Item>
                  <Input
                    placeholder="Employee Id"
                    value={employeeid}
                    onKeyDown={handleEnter}
                    onChange={(e) => employeeidset(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                  <Input.Password
                    //type="password"
                    placeholder="Password"
                    value={password}
                    onKeyDown={handleEnter}
                    onChange={(e) => passwordset(e.target.value)}
                  />
                </Form.Item>
              {opened && (
                  <Input
                    placeholder="OTP"
                    value={otp}
                    onKeyDown={handleEnter}
                    onChange={(e) => otpset(e.target.value)}
                    autoFocus={opened}
                  />
                )}
                <Button
                  className="login-btn-primary"
                  type="primary"
                  onClick={loginHandler}>
                  Sign In
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Copyright />
      </div>
    );
  }
}

export default Login;
