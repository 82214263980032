import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import { render } from "@testing-library/react";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddIntercitytariffTataAce = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [rbasekm, setrbasekm] = useState("");
  const [rbasemin, setrbasemin] = useState("");
  const [rtotal, setrtotal] = useState("");

  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    setSelectedRowInfo(props.selectedRowData);
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
    if (props.isedit) {
      setrbasekm(props.selectedRowData.RoundtripKM);
      setrbasemin(props.selectedRowData.RBASETIME);
      setrtotal(props.selectedRowData.TOTAL);

      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.tolerancekm = props.selectedRowData.TollerenceKM;
      copyselectedvalues.basefare = props.selectedRowData.BASEAMOUNT;
      copyselectedvalues.basekm = props.selectedRowData.BASEKM;
      copyselectedvalues.basemin = props.selectedRowData.BASETIME;
      copyselectedvalues.rbasefare = props.selectedRowData.ReturnAMOUNT;
      setSelectedValues(copyselectedvalues);
    }
  }, [props.visible]);

  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }
  const handleCancel = (e: any) => {
    props.hideModal(false);
    setrbasekm("");
    setrbasemin("");
    setrtotal("");
  };

  const tolerancekmchange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.tolerancekm = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const basekmchange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.basekm = e.target.value;
    copyselectedvalues.rbasekm = Number(e.target.value) * 2;
    let rbasekm = Number(e.target.value) * 2;
    setrbasekm(rbasekm.toString());
    setSelectedValues(copyselectedvalues);
  };
  const basefarechange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.basefare = e.target.value;
    if (selectedValues.rbasefare != undefined) {
      let total = Number(copyselectedvalues.rbasefare) + Number(e.target.value);
      setrtotal(total.toString());
    }
    setSelectedValues(copyselectedvalues);
  };
  const baseminchange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.basemin = e.target.value;
    copyselectedvalues.rbasemin = Number(e.target.value) * 2 - 60;
    let rbasemin = Number(e.target.value) * 2 - 60;
    setrbasemin(rbasemin.toString());
    setSelectedValues(copyselectedvalues);
  };
  const rbasefarechange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.rbasefare = e.target.value;
    if (selectedValues.basefare != undefined) {
      let total = Number(copyselectedvalues.basefare) + Number(e.target.value);
      setrtotal(total.toString());
    }
    setSelectedValues(copyselectedvalues);
  };
  const rbasekmchange = (e: any) => {
    setrbasekm(e.target.value);
  };
  const rbaseminchange = (e: any) => {
    setrbasemin(e.target.value);
  };
  const rtotalchange = (e: any) => {
    setrtotal(e.target.value);
  };
  const saveintercitytariff = () => {
    const options: any = {
      data: {
        TollerencKM: selectedValues.tolerancekm,
        BASEKM: selectedValues.basekm,
        BASEAMOUNT: selectedValues.basefare,
        BASETIME: selectedValues.basemin,
        RoundtripKM: rbasekm,
        ReturnAMOUNT: selectedValues.rbasefare,
        TOTAL: rtotal,
        RBASETIME: rbasemin,
      },
    };
    axiosPost(UrlConstants.addintercityfare, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Intercity Added Successfully",
          description: "Intercity for " + selectedValues.GoodsName + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Adding Failed",
          description: "Error Occurred while Adding Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const editintercitytariff = () => {
    const options: any = {
      data: {
        ID: selectedRowInfo.ID,
        TollerencKM: selectedValues.tolerancekm,
        BASEKM: selectedValues.basekm,
        BASEAMOUNT: selectedValues.basefare,
        BASETIME: selectedValues.basemin,
        RoundtripKM: rbasekm,
        ReturnAMOUNT: selectedValues.rbasefare,
        TOTAL: rtotal,
        RBASETIME: rbasemin,
      },
    };
    axiosPost(UrlConstants.editintercityfare, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header: "Intercity updated Successfully",
          description: "Intercity for " + selectedRowInfo.ID + " updated",
        };

        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Updating Failed",
          description: "Error Occurred while Updating Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={400}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["tolkm", "tolkm"]}
              label="Tolerance KM"
              rules={[]}
            >
              <Input
                defaultValue={selectedRowInfo.TollerenceKM}
                onChange={tolerancekmchange}
                value={selectedValues.tolerancekm}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item name={["basekm", "basekm"]} label="Base KM" rules={[]}>
              <Input
                defaultValue={selectedRowInfo.BASEKM}
                onChange={basekmchange}
                value={selectedValues.basekm}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basefare", "basefare"]}
              label="Base Fare"
              rules={[]}
            >
              <Input
                defaultValue={selectedRowInfo.BASEAMOUNT}
                onChange={basefarechange}
                value={selectedValues.basefare}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basemin", "basemin"]}
              label="Base Min"
              rules={[]}
            >
              <Input
                defaultValue={selectedRowInfo.BASETIME}
                onChange={baseminchange}
                value={selectedValues.basemin}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {/* <Form.Item name={["rbasekm", "rbasekm"]} label="R-Base KM" rules={[]} >
                        <Input  value={rbasekm} onChange={rbasekmchange} disabled={true} />
                       
                    </Form.Item> */}
            <Form.Item label="R-Base KM">
              <span style={{ display: "none" }}>{rbasekm}</span>
              <Input value={rbasekm} onChange={rbasekmchange} readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["rbasefare", "rbasefare"]}
              label="R-Base Fare"
              rules={[]}
            >
              <Input
                defaultValue={selectedRowInfo.ReturnAMOUNT}
                onChange={rbasefarechange}
                value={selectedValues.rbasefare}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="R-Base Min">
              <span style={{ display: "none" }}>{rbasemin}</span>
              <Input
                value={rbasemin}
                onChange={rbaseminchange}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item label="R-Total Fare">
              <span style={{ display: "none" }}>
                {rtotal || selectedRowInfo.TOTAL}
              </span>
              <Input value={rtotal} onChange={rtotalchange} readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit === true && (
          <Col offset={5}>
            <Button onClick={editintercitytariff}>Update</Button>
          </Col>
        )}
        {props.isedit !== true && (
          <Col offset={5}>
            <Button onClick={saveintercitytariff}>Save</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddIntercitytariffTataAce;
