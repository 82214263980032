import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";

const Corporateaccountmoreinfo = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [corporatepayemnthistory, setcorporatepayemnthistory] = useState([]);
  const [copycorporatepayemnthistory, setcopycorporatepayemnthistory] =
    useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));

  const [isLoading, setIsLoading] = useState(false);
  const [SearchText, setsearchText]: any = useState("");

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData) {
      setSelectedRowInfo(props.selectedRowData);
      getvehiclepaymenthistory();
    }
  }, [props.visible]);

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "id",
      key: "Booking ID",
    },
    {
      title: "Cab Id",
      dataIndex: "cabid",
      key: "Booking ID",
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "Booking ID",
    },

    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "Booking ID",
      render: (text: any, record: any) => {
        return <span>{record.iscredit == false ? "No" : "Yes"}</span>;
      },
    },
  ];
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = corporatepayemnthistory.filter((value: any) => {
      setIsLoading(true);
      return (
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        // value.cabid.toLowerCase().includes(searchText.toLowerCase()) ||
        value.name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fare.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopycorporatepayemnthistory(filteredData);
    setIsLoading(false);
  };
  const getvehiclepaymenthistory = () => {
    setIsLoading(true);

    let fromdate = moment(props.selectedRowData.fromdate).format("YYYY-MM-DD");
    let todate = moment(props.selectedRowData.todate).format("YYYY-MM-DD");
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        ID: props.selectedRowData.compid,
      },
    };

    axiosGet(UrlConstants.getcorporatemoreinfo, options)
      .then((resp: any) => {
        setcorporatepayemnthistory(resp);
        setcopycorporatepayemnthistory(resp);
        setIsLoading(false);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setcorporatepayemnthistory([]);
          setcopycorporatepayemnthistory([]);
        }
      });
  };
  const handleCancel = () => {
    props.hideModal(false);
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        className="custom-modal-cancel-form"
        width={450}
        title="More Info"
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>Company Name: {selectedRowInfo.name}</Col>
        </Row>
        <Row>
          <Col span={8} offset={16} style={{ marginBottom: 10 }}>
            <Input
              placeholder="Search Text"
              value={SearchText}
              onChange={onSearchchange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AppTable
              columns={columns}
              data={copycorporatepayemnthistory}
            ></AppTable>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Corporateaccountmoreinfo;
