
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddLocation from "../../components/AppModal/AddLocation";
import DeleteConfirmModal from "../../components/AppModal/DeleteConfirmModal";

const { Search } = Input;

const LocationMaster = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [addlocationmodal, setaddlocationmodal] = useState(false);
  const [isedit, setisedit] = useState(false);
  const [modelTittile, setmodelTittile] = useState("Add Location");
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [locationslist, setlocationslistdata] = useState([]);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [selectedValues, setselectedValues]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));

  const [availableCabData, setAvailableCabData] = useState([]);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [deletelocationtitle, setDeletelocationtitle] = useState('Location');

  const showpreassignmodal = () => {
    setPreAssignModalVisible(true);
  };
  const hidepreassignmodal = (isVisible: any) => {
    setPreAssignModalVisible(isVisible);
  };

  const columns = [
    {
      title: "City",
      dataIndex: "CityID",
      key: "city",
    },
    {
      title: "Place Name",
      dataIndex: "PlaceName",
      key: "Placename",
      ellipsis: true,
    },
    {
      title: "Latitude",
      dataIndex: "Latitude",

      key: "Latitude",
      ellipsis: true,
     
    },
    {
      title: "Longitude",
      dataIndex: "Longitude",
      key: "Longitude",
      ellipsis: true,


    },

  ];

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };

  useEffect(() => {
    getlocationslist(pagination, SearchText);
    refresh(pagination, SearchText);
  }, []);


  const refresh = (pagination:any, SearchText:any) => {
    const options = {
      params: {
        branchid: branchid,
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    axiosGet(UrlConstants.getlocations, options).then((resp: any) => {
      resp.data.forEach((e: any) => {
        e.key = e.ID.toString();
      });
      setlocationslistdata(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
      }).catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getlocationslist = (pagination:any, SearchText: any) => {
    const options: any = {
      params: {
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    axiosGet(UrlConstants.getlocations, options).then((resp: any) => {
      resp.data?.forEach((e: any) => {
        e.key = e.ID.toString();
      });
      setlocationslistdata(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      console.log(resp);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    });
  };

  const Addlocation = () => {
    setisedit(false);
    setmodelTittile("Add Location");
    setaddlocationmodal(true);
  }

  const Editlocation = () => {
    setisedit(true);
    setmodelTittile("Edit Location");
    let copyselectedvalues={... selectedRowData[0]}
    setselectedValues(copyselectedvalues);
    setaddlocationmodal(true);
  }

  const Deletelocation = () => {
    const options: any = {
      data: {
        ID:selectedRowData[0].ID,
      },
    };
    console.log(options);
    
    /* axiosPost(UrlConstants.deletelocation, options)
    .then((resp: any) => {
      console.log(resp);
      refresh(pagination);
      const notificationInfo: any = {
        header: "Location Deleted Successfully",
        description: "Location Name: " + selectedRowData[0].PlaceName + " Updated",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: "Delete Location Failed",
        description: "Error Occurred while Delete Location",
      };
      openNotificationWithIcon("Error", notificationInfo);
    }); */
    refresh(pagination, SearchText);
  }

  const hideModal = (isVisible: any) => {
    setaddlocationmodal(isVisible);
    refresh(pagination, SearchText);
    setsearchText('');
  }; 


  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };


  const globalSearch = (searchText: any) => {
    let filteredData = locationslist.filter((value: any) => {
      if(value.CityID===undefined || value.CityID===null)
      value.CityID='';
      if(value.PlaceName===undefined || value.PlaceName===null)
      value.PlaceName='';
      if(value.Latitude===undefined || value.Latitude===null)
      value.Latitude='';
      if(value.Longitude===undefined || value.Longitude===null)
      value.Longitude='';

      return (
        value.CityID
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.PlaceName
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())||
        value.Latitude
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.Longitude
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) 
      );
    });
    console.log(filteredData);
    
    setcopyCurrentBookingGridData(filteredData);
  };
  const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
    setPagination(pagination);
    getlocationslist(pagination, SearchText);
    console.log(pagination); 
  } 
  const showConfirmDeleteModal = (tariffType:any) => {
    setConfirmDeleteModalVisible(true);
  };
  const hideConfirmDeleteModal = (isVisible: any) => {
    setConfirmDeleteModalVisible(isVisible);
    refresh(pagination, SearchText);
  };
  const confirmDelete = (option:any) => {
    if(option == true){
      Deletelocation();
    }
  }
  const onSearch = (value:any) => {
    setsearchText(value);
    getlocationslist(pagination, value);
  }
  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
            <Button onClick={Addlocation}>

              Add
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
onClick={Editlocation}
              disabled={!isRowSelected}

            >
              Edit
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
onClick={showConfirmDeleteModal}
              disabled={!isRowSelected}

            >
              Delete
            </Button>
          </Col>
          <Col span={4} offset={12}>
            <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} />
            {/* <Search placeholder="Search Text" onSearch={onSearch} enterButton/> */}
          </Col>
        </Row>
      </div>
      <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          enableRadioButton={true}
          onRadioButtonChange={onGridRowSelect}
          defaultPageSize={100}
          enablePagination={true}
          selectionType="radio"
          onchange={onPaginationchange}
        ></AppTable>
        </Spin>
        <AddLocation
          title={modelTittile}
          visible={addlocationmodal}
          isedit={isedit}
          selectedRowData={selectedValues}
          hideModal={hideModal}
        >
        </AddLocation>
        <DeleteConfirmModal
          title={deletelocationtitle}
          visible={confirmDeleteModalVisible}
          hideModal={hideConfirmDeleteModal}
          selectedRowData={selectedRowData}
          confirmDelete={confirmDelete}
        />
      </div>

    </div>
  );
};

export default LocationMaster;
