import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, Input, Spin, Select , DatePicker} from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";
import "./Report.scss" ;
import AppModalCabAtdncReport from "../../components/AppModal/AppModalCabAtdncReport";
import { CSVLink } from "react-csv";

const { Option } = Select;
const { Search } = Input;

const CabAtdncReport = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [cabatdmodalVisible, setcabatdmodalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment() );
  const dateFormat = "DD/MM/YYYY";
  const [selectedValues, setSelectedValues]: any = useState({});
  const [cabIdsList, setcabIdsList] = useState([]);
  const [vehicleIdsList, setvehicleIdsList] = useState([]);
  const [searchCabIdsList, setSearchCabIdsList] = useState([]);
  const [searchVehicleIdsList, setSearchVehicleIdsList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalamount, setTotalamount] = useState(0);

  const totalFareTitle = Math.round(totalamount);
  const columns = [
    {
      title: "Booking ID",
      dataIndex: "id",
      key: "Booking ID",

    },
    {
      title: "Customer name",
      dataIndex: "name",
      key: "Name",

    },
    {
      title: "Customer ID",
      dataIndex: "name",
      key: "Name",

    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "Name",

    },
    {
      title: "To location",
      dataIndex: "toloc",
      key: "Name",

    },
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Name",

    },
    {
      title: "Date",
      dataIndex: "dateandtime",
      key: "Name",
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      }

    },
    {
      title: "Time",
      dataIndex: "dateandtime",
      key: "Name",
      render: (text: any) => {
        return moment(text).format("hh:mm A");
      }

    },
    {
      title: "Booking type",
      dataIndex: "faretype",
      key: "Name",

    },
    {
      title: "Empty km",
      dataIndex: "emptyKM",
      key: "Name",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }

    },
    {
      title: "Effective km",
      dataIndex: "distance",
      key: "Name",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }

    },
    {
      title: "Fare",
      children: [{
      title: totalFareTitle,
      dataIndex: "fare",
      key: "Name",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }
      }]
    },
  ]
  const columnsToExport = [
    {
      title: "Booking ID",
      dataIndex: "id",
      key: "Booking ID",
    },
    {
      title: "Customer name",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "Customer ID",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "Name",
    },
    {
      title: "To location",
      dataIndex: "toloc",
      key: "Name",
    },
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Name",
    },
    {
      title: "Date",
      dataIndex: "dateandtime",
      key: "Name",
    },
    {
      title: "Time",
      dataIndex: "dateandtime",
      key: "Name",
    },
    {
      title: "Booking type",
      dataIndex: "faretype",
      key: "Name",
    },
    {
      title: "Empty km",
      dataIndex: "emptyKM",
      key: "Name",
    },
    {
      title: "Effective km",
      dataIndex: "distance",
      key: "Name",
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "Name",
    },
  ]
  const cabsatdnmodal = () => {
    setcabatdmodalVisible(true);
  };
  const hideModalcabatdmodalVisible = (isVisible: any) => {
    setcabatdmodalVisible(isVisible);
  };

  const onChange = (dates: any, dateStrings: any) => {
    try {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      let startdate = dateStrings[1];
      var new_date = moment(startdate, "YYYY-MM-DD").add('days', 1);
      var day = new_date.format('DD');
      var month = new_date.format('MM');
      var year = new_date.format('YYYY');
      let todate = year + '-' + month + '-' + day;
      onTableSearch(dateStrings[0], todate, pagination, SearchText);
    }
    catch (ex) {
      console.log(ex);
    }
  };



  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
  
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setFlag(false);
    }
    catch (ex) {
      console.log(ex);
    }
  
  }
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
  
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
      setFlag(false);
    }
    catch (ex) {
      console.log(ex);
    }
  
  }
  
  const adayclick = (event: any = null) => {
    setIsButtonClicked('Today');
    var first = moment();
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const twodayclick = () => {
    setIsButtonClicked('Yesday');
    var first = moment().add('days', -1);
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const monthclick = () => {
    setIsButtonClicked('Month');
    var first = moment();
    var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + '01';
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    console.log(fromdate);
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const weekclick = () => {
    setIsButtonClicked('Week');
    var first = moment().add('days', -(moment().day()))
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    console.log(fromdate);
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return (current && current > moment().endOf("day"));
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return (current && current > moment(todate).endOf("day"));
  };
  const disabledToDate = (current: any) => {
    // Can not select days before today and today
    return (current && current < moment(fromdate).endOf("day"));
  };

  const DateSearch = (fromdate: any, todate: any, pagination: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText/* ,
        cabid:selectedValues.cabid  || "ALL",       
        cusmobno:selectedValues.cusmobno,
        bookingid:selectedValues.bookingid */
      },
    };
    axiosGet(UrlConstants.getwebreport, options).then((resp: any) => {
      resp.data.forEach((e: any) => {
        e.key = e.id;
      });
      setIsLoading(false);
      setCurrentBookingGridData(resp.data);
      setcopyCurrentBookingGridData(resp.data);  
      console.log(resp);
      setTotalamount(resp.totalamount);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });    
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
        setCurrentBookingGridData([]);
        setcopyCurrentBookingGridData([]);
      }
    });
  };


  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  useEffect(() => {
    console.log(isRowSelected);
    getcabids();
    getvehicleids();
    adayclick();
  }, []);
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const onVehicleIdChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleid=value;
      setSelectedValues(copyselectedvalues);
    };

  const onBookingIdChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.bookingid = e.target.value;
      setSelectedValues(copyselectedvalues);
    };
    const onMobilenoChange = (e: any) => {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.cusmobno = e.target.value;
        setSelectedValues(copyselectedvalues);
      };
    const onCabIdChange = (value: any, fullInfo: any) => {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.cabid=value;
        setSelectedValues(copyselectedvalues);
    };
  
  
    const getcabids = () => {
      const uri = UrlConstants.getcabidlist + "?CityId=" + branchid;
      axiosGet(uri).then((resp: any) => {
        console.log(resp);
        setcabIdsList(resp);
      });
    };
     const getvehicleids = () => {
      const uri = UrlConstants.getvehicleidslist + "?CityId=" + branchid;
      axiosGet(uri).then((resp: any) => {
        console.log(resp);
        setvehicleIdsList(resp);
      });
    };

    const onCabIdSearch = (value:any) => {
      if (value) {
        var filtered = cabIdsList.filter((x: any) => String(x.cab_id?.toLowerCase()).includes(value.toLowerCase()));
        setSearchCabIdsList(filtered);
      } else {
        setSearchCabIdsList([]);
      }
    };

    const onTableSearch = (fromdate:any, todate:any, pagination:any, SearchText:any) => {
      console.log(currentBookingGridData);
      
      if (selectedValues.cabid) {
       
        var filtered = currentBookingGridData.filter((x: any) => String(x.cabid?.toLowerCase()) === (selectedValues.cabid.toLowerCase()));
        console.log(filtered);
        setcopyCurrentBookingGridData(filtered);
      }
      //DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
      DateSearch(moment(fromdate).format("YYYY/MM/DD"), moment(todate).add("days", 1).format("YYYY/MM/DD"), pagination, SearchText);
     /*  if (selectedValues.id) {
        var filtered = currentBookingGridData.filter((x: any) => String(x.id.toLowerCase()) === (selectedValues.id.toLowerCase()));
        console.log(filtered);
        setcopyCurrentBookingGridData(filtered);
      }  */
     /*  else {
        setcopyCurrentBookingGridData([]);
      } */
    }


  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      //console.log(value.otp, value.vehicleid);
      if (value.toloc === undefined || value.toloc === null)
        value.toloc = '';
      if (value.id === undefined || value.id === null)
        value.id = '';
      if (value.fromloc === undefined || value.fromloc === null)
        value.fromloc = '';
      if (value.name === undefined || value.name === null)
        value.name = '';
      if (value.cabid === undefined || value.cabid === null)
        value.cabid = '';
     /*  if (value.faretype === undefined || value.faretype === null)
        value.faretype = ''; */
      if (value.emptyKM === undefined || value.emptyKM === null)
        value.emptyKM = '';
      if (value.distance === undefined || value.distance === null)
        value.distance = '';
      if (value.fare === undefined || value.fare === null)
        value.fare = '';


      return (
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.name.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.fromloc.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.toloc.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.cabid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        // value.faretype.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.emptyKM.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.distance.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.fare.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  const onTableClear = () => {
    selectedValues.cabid = null;
    selectedValues.bookingid = null;
    selectedValues.cusmobno = null;
    setSearchCabIdsList([]);
    //setSearchVehicleIdsList([])
    //adayclick();
    onTableSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'), pagination, SearchText);
  }
  const headersToExport = columns.map((column:any) => 
      ((column.title =='Time') ? {label:column.title, key:'time'} 
    : (column.title == 'Date') ? {label:column.title, key:'date'} 
    : (column.title == 'Fare') ? {label:column.title, key:'fare'}
    : (column.title == 'Effective km') ? {label:column.title, key:'efficitiveKM'}
    : (column.title == 'Empty km') ? {label:column.title, key:'emptyKM'}
    : {label: column.title, key: column.dataIndex}))

  const dataToExport = currentBookingGridData.map((value:any) => ({
    ...value,
    fare: (value.fare != null && value.fare!= undefined && value.fare!='') ?
        Math.round(value.fare) : value.fare,
    time: moment(value.dateandtime).format("hh:mm A"),
    date: moment(value.dateandtime).format("DD/MM/YYYY"),
    efficitiveKM: (value.distance != null && value.distance!= undefined && value.distance!='') ?
        Math.round(value.distance) : value.distance,
    emptyKM: (value.emptyKM != null && value.emptyKM!= undefined && value.emptyKM!='') ?
        Math.round(value.emptyKM) : value.emptyKM
  }))
  const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
    setPagination(pagination);
    onTableSearch(fromdate, todate, pagination, SearchText);
    console.log(pagination); 
  }
  const onSearch = (value:any) => {
    console.log(value);
    setsearchText(value);
    onTableSearch(fromdate, todate, pagination, value);
  }

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
        <Row>    <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}>  WEB REPORT</span></Row>
        <Row style={{ paddingBottom: 10 }}>

        <Col>


<span style={{ fontSize: 'medium' }}> From:</span>
 
 <DatePicker
 
     value={fromdate}
     format={dateFormat}
     disabledDate={disabledFromDate}  
     onChange={onfromdatechange} />
<span style={{ fontSize: 'medium' }}> To:</span>


<DatePicker
     value={flag? moment() : todate}
     //value={todate}
     format={dateFormat}
     disabledDate={disabledDate}  
     onChange={ontodatechange} />


</Col>
          <Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>
          <Col span={2} offset={1} >
            <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
        </Row>
        <Row style={{paddingBottom:'10px'}}>
          <Col span={3}>
            <Select placeholder="Cab ID"
                style={{ width: 100 }}
                showSearch
                optionFilterProp="children"
                value={selectedValues.cabid}
                onChange={onCabIdChange}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showArrow={false}
                onSearch={onCabIdSearch}
                notFoundContent={null}
            >
                    {searchCabIdsList.map((id_: any) => (
                      <Option key={id_.cab_id} value={id_.cab_id}>
                        {id_.cab_id}
                      </Option>
                    ))}
              </Select>
          </Col>
          <Col span={4}>
              <Input placeholder="Customer Mobile"  value={selectedValues.cusmobno}
                  onChange={onMobilenoChange}/>
          </Col>
          <Col span={3} offset={1}>
            <Input placeholder="Booking ID"  value={selectedValues.bookingid}
                onChange={onBookingIdChange}/>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="report-btn-blockSearch" 
              onClick={()=>onTableSearch(fromdate, todate, pagination, SearchText)}
              disabled={moment(fromdate).isAfter(moment(todate))}>
              Search
 </Button>
          </Col>
          <Col span={2}>
            <Button
            htmlType="button"
              style={{marginLeft:10}}
              className="report-btn-blockClear"
              onClick={onTableClear}>
              Clear
            </Button>          
          </Col>
          <Col span={2}  offset={1}>
            <CSVLink
                filename={"WebReport.csv"}
                headers={headersToExport}
                data={dataToExport}
              >
      <Button className="report-btn-blockExport">Export</Button>
    </CSVLink>
            </Col>

        {/* </Row>
        <Row style={{ paddingBottom: 10 }}>
          <Col span={4} >

            <span>Total Amount: </span>
            <span> {Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.fare); }, 0) + Number.EPSILON) * 100) / 100)}</span>
          </Col>
          <Col span={4}>
            <span>Total Trip: </span>
            <span> {Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.fare); }, 0) + Number.EPSILON) * 100) / 100}</span>
          </Col>
 */}

          <Col span={4} offset={1}>
            {/* <Input placeholder="Search Text" className="search" onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>

        </Row>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            onchange={onPaginationchange}
            enablePagination={pagination}

            scroll={{ x: 1850, y: 480 }}
          ></AppTable>
        </Spin>
      </div>
      <AppModalCabAtdncReport
        title="MoreInfo"
        selectedRowData={selectedRowData}
        hideModal={hideModalcabatdmodalVisible}
        visible={cabatdmodalVisible}
        onRowClickEnabled={false}
        customClass="new-booking-assign"
      />
    </div>
  );
};

export default CabAtdncReport;
