import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddLocation = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));

  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    if (props.isedit) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.Branchid = props.selectedRowData.CityID;
      copyselectedvalues.location = props.selectedRowData.PlaceName;
      copyselectedvalues.latitude = props.selectedRowData.Latitude;
      copyselectedvalues.longitude = props.selectedRowData.Longitude;
      setSelectedValues(copyselectedvalues);
    }
  }, [props.visible]);

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const locationnamechange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.location = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onlatittudeChange = (e: any, name: any = null) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.latitude = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onlongtittudeChange = (e: any, name: any = null) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.longitude = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onFinish = (value: any) => {
    addlocation();
    props.hideModal(false);
  };
  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };

  const addlocation = () => {
    const options: any = {
      data: {
        CityID: selectedValues.Branchid,
        PlaceName: selectedValues.location,
        Latitude: selectedValues.latitude,
        Longitude: selectedValues.longitude,
      },
    };
    axiosPost(UrlConstants.addlocation, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Location Added Successfully",
          description: "Location Name: " + selectedValues.location + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Adding Loaction Failed",
          description: "Error Occurred while Adding Location",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const OnUpdate = (value: any) => {
    updatelocation();
    props.hideModal(false);
  };

  const updatelocation = () => {
    const options: any = {
      data: {
        ID: props.selectedRowData.ID,
        CityID: selectedValues.Branchid,
        PlaceName: selectedValues.location,
        Latitude: selectedValues.latitude,
        Longitude: selectedValues.longitude,
      },
    };
    axiosPost(UrlConstants.editlocation, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Location Updated Successfully",
          description: "Location Name: " + selectedValues.location + " Updated",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "updating Location Failed",
          description: "Error Occurred while Updating Location",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={400}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["user", "cabId"]} label="Select City" rules={[]}>
              <Select
                placeholder="Select City"
                value={selectedValues.Branchid}
                defaultValue={selectedValues.Branchid}
                onChange={onBranchChange}
              >
                {branchList.map((branch: any) => (
                  <Option key={branch._id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["location", "location"]}
              label="Location"
              rules={[]}
            >
              <Input
                type="text"
                defaultValue={selectedValues.location}
                value={selectedValues.location}
                onChange={locationnamechange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["latitude", "latitude"]}
              label="Latitude"
              rules={[]}
            >
              <Input
                type="text"
                defaultValue={selectedValues.latitude}
                value={selectedValues.latitude}
                onChange={onlatittudeChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["longitude", "longitude"]}
              label="Longitude"
              rules={[]}
            >
              <Input
                type="text"
                defaultValue={selectedValues.longitude}
                value={selectedValues.longitude}
                onChange={onlongtittudeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit === true && (
          <Col offset={5}>
            <Button onClick={OnUpdate}>Update</Button>
          </Col>
        )}
        {props.isedit !== true && (
          <Col offset={5}>
            <Button onClick={onFinish}>Save</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddLocation;
