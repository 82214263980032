import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import AppModalCancelbeforeAssign from "../../../components/AppModal/AppModalCancelbeforeAssign";
import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import AppModalPreAssign from "../../../components/AppModal/AppModalPreAssign";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

const { Search } = Input;

const OnlineBooking = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [tempavailableCabData, setTempAvailableCabData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(localStorage.getItem("username"));
  const [SearchText, setsearchText]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });

  let tempavialablecabs: any = [];
  const showpreassignmodal = () => {
    setPreAssignModalVisible(true);
  };
  const hidepreassignmodal = (isVisible: any) => {
    setPreAssignModalVisible(isVisible);
  };
  const PreAssignColumns = [
    {
      title: "CabId",
      dataIndex: "CabId",
      key: "CabId",
      fixed: "left",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Distance",
      dataIndex: "Distance",
      key: "Distance",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      key: "Vehicle",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Driver Name",
      dataIndex: "Driver Name",
      key: "Driver Name",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Mobile No",
      dataIndex: "Mobile No",
      key: "Mobile No",
      render: (text: any) => <a>{text?.slice(-10)}</a>,
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Total Trips",
      dataIndex: "Total Trips",
      key: "Total Trips",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Total Amount",
      dataIndex: "Total Amount",
      key: "Total Amount",
      render: (text: any) => <a>{text}</a>,
    },
  ];
  const columns = [
    {
      title: "Book ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      //sorter: (a: any, b: any) => a.id - b.id,
      width:"85px"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      // sorter: (a: any, b: any) => a.name - b.name,
      //ellipsis: true,
      width:'200px'
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      fixed: "left",
      key: "Mobile",
      ellipsis: true,
      render: (text: any, record: any) => {
        //return record.mobileno + " / " + record.alternativemobileno;
        return text?.slice(-10);
      },
      width:"111px"
    },

    {
      title: "Date",
      dataIndex: "dateandtime",
      key: "Date",
      ellipsis: true,
      render: (text: any) => {
        if (text != undefined || text != "")
          return moment(text).format("DD/MM/YYYY");
      },
      width:"108px"
    },
    {
      title: "Time",
      dataIndex: "dateandtime",
      key: "Time",
      ellipsis: true,
      render: (text: any) => {
        if (text != undefined || text != "")
          return moment(text).format("hh:mm A");
      },
      width:"109px"
    },

    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "fromloc",
      //ellipsis: true,
      //sorter: (a: any, b: any) => a.fromloc - b.fromloc,
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "toloc",
      //ellipsis: true,
      // sorter: (a: any, b: any) => a.toloc - b.toloc,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      //ellipsis: true,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "vehicletype",
      ellipsis: true,
      render: (text: any, record: any) => {
        return record.iscv ? <Tag color="green">{text}</Tag> : text;
      },
      width:"85px"
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "faretype",
      //ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.ttdetails[0].name;
      // },
    },
    {
      title: "Additional Fare",
      dataIndex: "iscredit",
      key: "iscredit",
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // marginTop: "-1rem",
              // marginBottom: "-1rem",
            }}
          >
            {/* <Checkbox defaultChecked={text} disabled /> */}
            {record.isgst && (
              <span
                style={{
                  color: "red",
                }}
              >
                GST
              </span>
            )}
            {record.isadditionalfare && (
              <span
                style={{
                  color: "red",
                }}
              >
                30%
              </span>
            )}
            {record.istollgate && (
              <span
                style={{
                  color: "red",
                }}
              >
                Toll Gate
              </span>
            )}
            {record.iscredit && (
              <span
                style={{
                  color: "red",
                }}
              >
                Credit
              </span>
            )}
          </div>
        );
      },
      width:"110px"
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "goods",
      //ellipsis: true,
    },



    {
      title: "Alternative Number",
      dataIndex: "alternativemobileno",
      key: "Alternative",
      //ellipsis: true,
      render: (text: any, record: any) => {
        //return record.mobileno + " / " + record.alternativemobileno;
        return text?.slice(-10);
      },
      width:"110px"
    },
    {
      title: "Referred by",
      dataIndex: "refferedby",
      key: "reffered",
      ellipsis: true,
      width:"110px"
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
      //sorter: (a: any, b: any) => a.cityid - b.cityid,
      width:"120px"
    },

    {
      title: "Edit",
      dataIndex: "city",
      key: "city123",
      ellipsis: true,
      render: (text: any, record: any) => {
        return (
          <div>
            <Link
              style={{ marginRight: 16 }}
              to={{
                pathname: "/booking/editBooking",
                hash: new Date().getTime().toString(),
                state: { details: record },
              }}
            >
              <EditOutlined className="edit-booking" />
            </Link>
          </div>
        );
      },
      width:"60px"
    },
  ];

  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
      render: (text: any, record: any) => (
        <a onClick={() => onAssignBooking(null, record, null)}>{text}</a>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "drivername",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Mobile No",
      dataIndex: "drivermobileno",
      key: "drivermobileno",
      //render: (text: any) => <a>{text?.slice(-10)}</a>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Trips",
      dataIndex: "totaltrip",
      key: "totaltrip",
    },
    {
      title: "Total Amount",
      dataIndex: "tripfare",
      key: "tripfare",
    },
  ];

  const showGridModal = () => {
    console.log(selectedRowData);
    setGridModalVisible(true);
    getAvailableCabList(selectedRowData);
    console.log(availableCabData);
  };

  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
    getNewBookingList(pagination, SearchText);
  };

  const showCancelFormModal = () => {
    setCancelFormModalVisible(true);
  };

  const hideCancelFormModal = (isVisible: any) => {
    getNewBookingList(pagination, SearchText);
    setCancelFormModalVisible(isVisible);
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };

  const sendtoRAC = () => {
    console.log(selectedRowData[0].id);
    const options = {
      data: {
        bookid: selectedRowData[0].id,
      },
    };
    axiosPost(UrlConstants.newbookingtoRAC, options)
      .then((resp: any) => {
        console.log(resp);
        getNewBookingList(pagination, SearchText);
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to RAC",
          description:
            "You can see all the RAC bookings by clicking below button",
          buttonText: "View RAC Bookings",
          path: "/racBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Send Booking to RAC Failed",
          description: "Error Occurred while sending Booking to RAC",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const onAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    console.log(record);

    const options = {
      data: {
        cabid: record.cabid,
        bookid: selectedRowData[0].id,
        driverid: record.driverid,
        assignedby: username,
        emptyKM: record.distance
      },
    };
    console.log(options);
    axiosPost(UrlConstants.assignBooking, options)
      .then((resp: any) => {
        console.log(resp);
        hideModal(false);
        getNewBookingList(pagination, SearchText);
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to " + record.cabid,
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        if (error.response) {
          //console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Assigning Booking Failed",
          description: "Error Occurred while Assigning New Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const onPreAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    console.log(record);
    console.log(selectedRowData);
    console.log(selectedRowData[0].cabid);
    const options = {
      params: {
        cabid: record.cabid,
        bookid: selectedRowData[0].id,
        assignedcabid: selectedRowData[0].cabid,
        driverid: record.driverid,
      },
    };
    axiosPost(UrlConstants.assignBooking, options)
      .then((resp: any) => {
        console.log(resp);
        getNewBookingList(pagination, SearchText);
        getAvailableCabListforpreAssign();
        hideModal(false);
        const notificationInfo = {
          header: "RAC Booking is Assigned to " + record.cabid,
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Assigning Booking Failed",
          description: "Error Occurred while Assigning New Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  useEffect(() => {
    getNewBookingList(pagination, SearchText);
    // getAvailableCabList();
    getAvailableCabListforpreAssign();
    console.log(branchid);
  }, []);

  const getNewBookingList = (pagination:any, SearchText:any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    //const uri = UrlConstants.getonlinebooking + "?branchid=" + branchid;
    axiosGet(UrlConstants.getonlinebooking, options).then((resp: any) => {
      resp.data.forEach((e: any) => {
        e.key = e.id.toString();
      });
      console.log(resp);
      setIsLoading(false);
      setCurrentBookingGridData(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setTotalCount(resp.tablelength);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
    });
  };

  const getAvailableCabList = (rowinfo: any) => {
    const options = {
      params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
      },
    };
    axiosGet(UrlConstants.getAvailableCabs, options).then((resp: any) => {

      var MainInfo: any = [];
      var TataAce: any = []; var Bolero: any = []; var FNS: any = [];
      var T: any = []; var B: any = []; var F: any = [];
      var cabslist:any= resp.data;
      try{
        cabslist = groupBy(resp.data, 'cabtype');

      }
      catch{}
    

      let TataacePromise = new Promise(function (myResolve, myReject) {
        try {
          if (cabslist.TataAce) {
            const copyTataAce = cabslist.TataAce.slice(0);
            //setAvailableCabData(cabslist.TataAce);
            cabslist.TataAce.forEach((item: any) => {
              // //console.log(item);
              item.key = item.cabid;
              var obj: any = {};
              if (item.latitude) {
                let uri = UrlConstants.getDistance + "?fromlat=" + rowinfo[0].fromloclat + "&fromlong=" +
                  rowinfo[0].fromloclong + "&tolat=" + item.latitude + "&tolong=" + item.longitude;
                //item.distance=0;
                axiosGet(uri)
                  .then((res: any) => {
                    item.distance = Math.round(Number(res[0].distance.replace(/[^\d.-]/g, '')));
                    TataAce.push(item);
                    ////console.log(MainInfo);
                    TataAce.sort((a: any, b: any) => {
                      return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : a.tripfare > b.tripfare ? 1 : a.tripfare < b.tripfare ? -1 : a.totaltrip > b.totaltrip ? 1 : a.totaltrip < b.totaltrip ? -1 : 0
                    })
                    //   MainInfo.sort(function(a:any, b:any) {
                    //     return a.distance - b.distance;
                    // });
                    // if (!cabslist.Bolero) {
                    if (TataAce.length === copyTataAce.length) {
                      myResolve(TataAce);
                    }
                    // }
                  });
                //console.log(cabslist);
              }
              else {
                const index = copyTataAce.indexOf(item);

                if (index > -1) {
                  copyTataAce.splice(index, 1);

                }
                if (copyTataAce.length == 0)
                  myResolve(TataAce);

              }
            })
          }
          else
            myResolve(TataAce); // when error
        } catch (error) {
          myReject(error);
        }
      });
      TataacePromise.then(
        function (value) { T = value; console.log(value); fetchavailablecabs(value, "Tataace", rowinfo[0].vehicletypeid); },
        function (error) { console.log(error) }
      );

      let BoleroPromise = new Promise(function (myResolve, myReject) {
        try {
          if (cabslist.Bolero) {
            //setAvailableCabData(cabslist.TataAce);
            const copybolero = cabslist.Bolero.slice(0);
            cabslist.Bolero.forEach((item: any) => {
              //console.log(item, cabslist.Bolero);
              item.key = item.cabid;
              var obj: any = {};
              if (item.latitude) {
                let uri = UrlConstants.getDistance + "?fromlat=" + rowinfo[0].fromloclat + "&fromlong=" +
                  rowinfo[0].fromloclong + "&tolat=" + item.latitude + "&tolong=" + item.longitude;
                //item.distance=0;
                axiosGet(uri)
                  .then((res: any) => {
                    //console.log(res);
                    item.distance = Math.round(Number(res[0].distance.replace(/[^\d.-]/g, '')));
                    Bolero.push(item);
                    ////console.log(Bolero);
                    Bolero.sort((a: any, b: any) => {
                      return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : a.tripfare > b.tripfare ? 1 : a.tripfare < b.tripfare ? -1 : a.totaltrip > b.totaltrip ? 1 : a.totaltrip < b.totaltrip ? -1 : 0
                    })
                    //   MainInfo.sort(function(a:any, b:any) {
                    //     return a.distance - b.distance;
                    // });
                    //console.log(Bolero.length, copybolero.length);
                    if (Bolero.length === copybolero.length) {
                      myResolve(Bolero);
                    }
                    //   if (rowinfo[0].vehicletypeid == 1)
                    //     MainInfo = TataAce.concat(Bolero);
                    //   else
                    //     MainInfo = Bolero.concat(TataAce);
                    //   setAvailableCabData(MainInfo);
                    //   //console.log(MainInfo);
                    // }
                  })
                  .catch((error: any) => {
                    //console.log(error)
                  });
              }
              else {
                const index = copybolero.indexOf(item);
                if (index > -1) {
                  copybolero.splice(index, 1);
                }
                if (copybolero.length == 0)
                  myResolve(Bolero);
              }
            })
          }
          else
            myResolve(Bolero); // when error
        } catch (error) {
          myReject(error);
        }
      });
      BoleroPromise.then(
        function (value) { B = value; console.log(value); fetchavailablecabs(value, "Bolero", rowinfo[0].vehicletypeid); },
        function (error) { console.log(error) }
      );

      let FNSPromise = new Promise(function (myResolve, myReject) {
        try {
          if (cabslist["407"]) {
            const copyFNS = cabslist["407"].slice(0);
            ////console.log("407 STarted");
            cabslist["407"].forEach((item: any) => {
              item.key = item.cabid;
              var obj: any = {};
              if (item.latitude) {
                let uri = UrlConstants.getDistance + "?fromlat=" + rowinfo[0].fromloclat + "&fromlong=" +
                  rowinfo[0].fromloclong + "&tolat=" + item.latitude + "&tolong=" + item.longitude;
                //item.distance=0;
                axiosGet(uri)
                  .then((res: any) => {
                    item.distance = Math.round(Number(res[0].distance.replace(/[^\d.-]/g, '')));
                    FNS.push(item);
                    ////console.log(FNS);
                    FNS.sort((a: any, b: any) => {
                      return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : a.tripfare > b.tripfare ? 1 : a.tripfare < b.tripfare ? -1 : a.totaltrip > b.totaltrip ? 1 : a.totaltrip < b.totaltrip ? -1 : 0
                    })
                    //   MainInfo.sort(function(a:any, b:any) {
                    //     return a.distance - b.distance;
                    // });
                    if (FNS.length === copyFNS.length) {
                      myResolve(FNS);
                    }
                    //   ////console.log(FNS);
                    //   if (rowinfo[0].vehicletypeid == 3)
                    //     MainInfo = FNS.concat(MainInfo);
                    //   else
                    //     MainInfo = MainInfo.concat(FNS);
                    //   ////console.log(MainInfo);
                    //   setAvailableCabData(MainInfo);
                    // }
                  });
              }
              else {
                const index = copyFNS.indexOf(item);
                if (index > -1) {
                  copyFNS.splice(index, 1);
                }
                if (copyFNS.length == 0)
                  myResolve(FNS);
              }
            })
            // myResolve(FNS);
          }
          else
            myResolve(FNS); // when error
        } catch (error) {
          myReject(error);
        }
      });
      FNSPromise.then(
        function (value) { F = value; console.log(value); fetchavailablecabs(value, "Fns", rowinfo[0].vehicletypeid); },
        function (error) { console.log(error) }
      );
      console.log(T, B, F);


    });

  };


  const fetchavailablecabs = (cabslist: any, name: any, vehicleid: any) => {
    tempavialablecabs[name] = cabslist;
    console.log(tempavialablecabs);
    if (tempavialablecabs.Tataace && tempavialablecabs.Bolero && tempavialablecabs.Fns) {
      var MainInfo = []
      if (vehicleid == 1) {
        MainInfo = tempavialablecabs.Tataace.concat(tempavialablecabs.Bolero);
        MainInfo = MainInfo.concat(tempavialablecabs.Fns);
        setAvailableCabData(MainInfo);
      }
      else if (vehicleid == 2) {
        MainInfo = tempavialablecabs.Bolero.concat(tempavialablecabs.Tataace);
        MainInfo = MainInfo.concat(tempavialablecabs.Fns);
        setAvailableCabData(MainInfo);
      }
      else if (vehicleid == 3) {
        MainInfo = tempavialablecabs.Fns.concat(tempavialablecabs.Bolero);
        MainInfo = MainInfo.concat(tempavialablecabs.Tataace);
        setAvailableCabData(MainInfo);
      }
    }

  };


  const groupBy = (items: any, key: any) => items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );
  const getAvailableCabListforpreAssign = () => {
    const uri = UrlConstants.getAvailableCabs + "?branchid=" + branchid;
    axiosGet(uri).then((resp: any) => {
      if (resp != undefined) {
        resp.forEach((e: any) => {
          e.key = e.cabid;
        });
      }
      // setAvailableCabData(resp);
    });
  };
  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };


  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      console.log(value.toloc, value.vehicletype);
  
    
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = '';
      if (value.refferedby === undefined || value.refferedby === null)
        value.refferedby = '';
      if (value.alternativemobileno === undefined || value.alternativemobileno === null)
        value.alternativemobileno = '';
      return (

        value.name.toLowerCase().includes(searchText.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.mobileno.toLowerCase().includes(searchText.toLowerCase()) ||
        value.alternativemobileno.toLowerCase().includes(searchText.toLowerCase()) ||
        value.refferedby.toLowerCase().includes(searchText.toLowerCase()) ||
        value.fromloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.toloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.remarks.toLowerCase().includes(searchText.toLowerCase()) ||
        value.vehicletype.toLowerCase().includes(searchText.toLowerCase()) ||
        value.goods.toLowerCase().includes(searchText.toLowerCase()) ||
        value.bookedby.toLowerCase().includes(searchText.toLowerCase()) ||
        value.city.toLowerCase().includes(searchText.toLowerCase()) ||
        value.faretype.toLowerCase().includes(searchText.toLowerCase())


      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
    setPagination(pagination);
    getNewBookingList(pagination, SearchText);
    console.log(pagination); 
  }
  const onSearch = (value:any) => {
    console.log(value);
    setsearchText(value);
    getNewBookingList(pagination, value);
  }

  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
            <Button className="booking-btn-preassign" disabled={!isRowSelected}
              onClick={showpreassignmodal}>
              Pre Assign
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-assign"
              disabled={!isRowSelected}
              onClick={showGridModal}
            >
              Assign
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-rac"
              disabled={!isRowSelected}
              onClick={sendtoRAC}
            >
              RAC
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
              disabled={!isRowSelected}
              onClick={showCancelFormModal}
            >
              Cancel
            </Button>
          </Col>
          <Col offset={2}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Trips : </span></Col>
          <Col style={{ paddingLeft: '10px' }}>
         
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {totalCount}</span>
            </Col>
          <Col span={4} offset={5}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
      </div>


      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={pagination}
            selectionType="radio"
            scroll={{ x: 2850, y: 480 }}
            onchange={onPaginationchange}
          ></AppTable>
        </Spin>
      </div>
      <AppModalPreAssign
        title="Pre Assign Trip"
        visible={PreAssignmodalVisible}
        hideModal={hidepreassignmodal}
        selectedRowData={selectedRowData}
        width={700}
        modalGridColumns={PreAssignColumns}
        modalGridData={[]}
      />
      <AppModalWithGrid
        title="Assign Trip"
        visible={gridModelVisible}
        hideModal={hideModal}
        width={830}
        selectedRowData={selectedRowData}
        modalGridColumns={modalReAssignColumns}
        isAddbooking={false}
        modalGridData={availableCabData}
        onRowClickEnabled={false}
        onRowClick={onAssignBooking}
        customClass="new-booking-assign"
      />

      <AppModalCancelbeforeAssign
        title="Cancel Booking"
        visible={cancelformModalVisible}
        hideModal={hideCancelFormModal}
        selectedRowData={selectedRowData}
      />
    </div>
  );
};

export default OnlineBooking;
