import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Select, Input } from "antd";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import {
  DesktopOutlined,
  DashboardOutlined,
  PieChartOutlined,
  TeamOutlined,
  EditOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import "./AppLayout.scss";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import { useHistory } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

const { Option } = Select;
const { Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;

const navigationList = [
  { key: "A", path: "/" },
  { key: "B", path: "/booking" },
  { key: "C", path: "/driverAttendance" },
  { key: "D", path: "/feedback" },
  // { key: "E", path: "/" },
  // { key: "F", path: "/" },
  // { key: "G", path: "/" },
  { key: "H", path: "/heatmap" },
  // { key: "I", path: "/" },
  { key: "J", path: "/dayreport" },
  { key: "K", path: "/webreport" },
  { key: "L", path: "/staticsreport" },
  { key: "M", path: "/cabatdncreport" },
  { key: "N", path: "/telecolorreport" },
  { key: "O", path: "/driverdeatils" },
  { key: "P", path: "/ownerdetails" },
  { key: "Q", path: "/vehicledetails" },
  { key: "3", path: "/vehiclechangesreport" },
  // { key: "R", path: "/" },
  { key: "S", path: "/goodsmaster" },
  { key: "T", path: "/tarriffmaster" },
  { key: "U", path: "/usermaster" },
  { key: "V", path: "/corporatemaster" },
  { key: "W", path: "/locationmaster" },
  { key: "X", path: "/vehiclemaster" },
  // { key: "Y", path: "/" },
  { key: "Z", path: "/vehicleaccount" },
  { key: "0", path: "/vehiclepaymenthistory" },
  { key: "1", path: "/corporateaccount" },
  { key: "2", path: "/corporatepaymenthistory" },
  // { key: "3", path: "" },
  { key: "4", path: "/creditreport" },
  { key: "5", path: "/creditinvoice" },
  { key: "6", path: "/creditpaymenthistory" },
  { key: "7", path: "/appusers" },
];

const AppLayout = (props: any) => {
  let history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedNavigation, setSelectedNavigation]: any = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [MenuList, setMenuList]: any = useState([]);
  const [SubMenuList, setSubMenuList]: any = useState([]);
  const [branchid, setbranchid]: any = useState("");
  const [roleid, setRoleid]: any = useState(localStorage.getItem("roleid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [employeename, setEmployeeName]: any = useState(
    localStorage.getItem("employeename")
  );
  const [employeeid, setEmployeeid]: any = useState(
    localStorage.getItem("employeeid")
  );

  employeeid: useEffect(() => {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
    setbranchid(localStorage.getItem("cityid"));
    setMenuList(JSON.parse(localStorage.getItem("menu") || ""));
    setSubMenuList(JSON.parse(localStorage.getItem("submenu") || ""));
  }, []);

  const onBranchChange = (value: any) => {
    setbranchid(value);
    localStorage.setItem("cityid", value);
  };
  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };

  const onSidebarNavigation = (path: any) => {
    history.push(path);
  };

  const openBooking = (e: any, path: any) => {
    history.push(path);
  };

  const openContext = (e: any, path: any) => {
    e.preventDefault();
    var base_url = window.location.origin + path;
    window.open(base_url);
  };

  const onRouteChange = (location: any) => {
    const path = location.pathname;
    navigationList.map((each: any) => {
      if (path === "/") {
        setSelectedNavigation([navigationList[0].key]);
        return;
      } else if (path.indexOf(each.path) > -1) {
        setSelectedNavigation(each.key);
        return;
      }
    });
  };

  const Logout = () => {
    const options: any = {
      data: {
        username: username,
        roleid: roleid,
        employeeid: employeeid,
      },
    };
    axiosPost(UrlConstants.userlogout, options).then((resp: any) => {
      localStorage.removeItem("token");
      props.authContext.updateUserLoginInfo();
    });
  };

  useEffect(() => {
    onRouteChange(history.location);
    const unlisten = history.listen((location) => {
      onRouteChange(location);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="style-1"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        // collapsible collapsed={collapsed} onCollapse={onCollapse}
      >
        <span style={{ color: "white", fontWeight: "bolder", fontSize: 18 }}>
          Hi, {employeename} !
        </span>
        <Select
          placeholder="Select City"
          style={{ width: "100%" }}
          value={branchid}
          onChange={onBranchChange}
        >
          {branchList.map((branch: any) => (
            <Option key={branch._id} value={branch.id}>
              {branch.name}
            </Option>
          ))}
        </Select>
        <Menu
          theme="dark"
          //defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={selectedNavigation}
        >
          <Menu.Item
            key="A"
            hidden={!MenuList.includes(1)}
            icon={<DashboardOutlined />}
            onContextMenu={(e) => openContext(e, "/")}
            onClick={() => onSidebarNavigation("/")}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key="B"
            hidden={!MenuList.includes(2)}
            icon={<EditOutlined />}
            onContextMenu={(e) => openContext(e, "/booking")}
            onClick={(e) => openBooking(e, "/booking")}
          >
            Booking
          </Menu.Item>
          <Menu.Item
            key="C"
            hidden={!MenuList.includes(3)}
            icon={<UsergroupAddOutlined />}
            onContextMenu={(e) => openContext(e, "/driverAttendance")}
            onClick={() => onSidebarNavigation("/driverAttendance")}
          >
            Driver Attendence
          </Menu.Item>

          <Menu.Item
            key="D"
            hidden={!MenuList.includes(4)}
            onContextMenu={(e) => openContext(e, "/feedback")}
            onClick={() => onSidebarNavigation("/feedback")}
          >
            Feedback
          </Menu.Item>
          <Menu.Item
            key="E"
            hidden={!MenuList.includes(5)}
            onContextMenu={(e) => openContext(e, "/attachmentenquiry")}
            onClick={() => onSidebarNavigation("/attachmentenquiry")}
          >
            Attachment Enquiry
          </Menu.Item>
          <Menu.Item
            key="F"
            hidden={!MenuList.includes(6)}
            icon={<DesktopOutlined />}
          >
            Tracking
          </Menu.Item>
          <Menu.Item
            key="G"
            hidden={!MenuList.includes(7)}
            icon={<MessageOutlined />}
          >
            SMS
          </Menu.Item>
          {/* {MenuList.includes(8) && (
            <Menu.Item
              key="H"
              onContextMenu={(e) => openContext(e, "/heatmap")}
              onClick={() => onSidebarNavigation("/heatmap")}
            >
              Heat Map
            </Menu.Item>
          )} */}

          <Menu.Item
            key="7"
            hidden={!MenuList.includes(7)}
            onContextMenu={(e) => openContext(e, "/appusers")}
            onClick={() => onSidebarNavigation("/appusers")}
          >
            App Users
          </Menu.Item>
          {MenuList.includes(8) && (
            <SubMenu key="sub1" icon={<PieChartOutlined />} title="Report">
              <Menu.Item key="I">Vehicle Accounts</Menu.Item>
              <Menu.Item
                key="J"
                hidden={!SubMenuList.includes(11)}
                onContextMenu={(e) => openContext(e, "/dayreport")}
                onClick={() => onSidebarNavigation("/dayreport")}
              >
                Day Report
              </Menu.Item>
              <Menu.Item
                key="K"
                hidden={!SubMenuList.includes(12)}
                onContextMenu={(e) => openContext(e, "/webreport")}
                onClick={() => onSidebarNavigation("/webreport")}
              >
                Web Report
              </Menu.Item>
              <Menu.Item
                key="L"
                hidden={!SubMenuList.includes(13)}
                onContextMenu={(e) => openContext(e, "/staticsreport")}
                onClick={() => onSidebarNavigation("/staticsreport")}
              >
                Statistics Report
              </Menu.Item>
              <Menu.Item
                key="M"
                hidden={!SubMenuList.includes(14)}
                onContextMenu={(e) => openContext(e, "/cabatdncreport")}
                onClick={() => onSidebarNavigation("/cabatdncreport")}
              >
                Cab Attendence
              </Menu.Item>
              <Menu.Item
                key="N"
                hidden={!SubMenuList.includes(15)}
                onContextMenu={(e) => openContext(e, "/telecolorreport")}
                onClick={() => onSidebarNavigation("/telecolorreport")}
              >
                Tele Caller Report
              </Menu.Item>
              <Menu.Item
                key="O"
                hidden={!SubMenuList.includes(18)}
                onContextMenu={(e) => openContext(e, "/driverdeatils")}
                onClick={() => onSidebarNavigation("/driverdeatils")}
              >
                Driver Details Report
              </Menu.Item>
              <Menu.Item
                key="P"
                hidden={!SubMenuList.includes(17)}
                onContextMenu={(e) => openContext(e, "/ownerdetails")}
                onClick={() => onSidebarNavigation("/ownerdetails")}
              >
                Owner Details Report
              </Menu.Item>
              <Menu.Item
                key="Q"
                hidden={!SubMenuList.includes(19)}
                onContextMenu={(e) => openContext(e, "/vehicledetails")}
                onClick={() => onSidebarNavigation("/vehicledetails")}
              >
                Vehicle Details Report
              </Menu.Item>
              {/* <Menu.Item
                key="3"
                onContextMenu={(e) => openContext(e, "/driverchangesreport")}
                onClick={() => onSidebarNavigation("/driverchangesreport")}
              >
                Driver Changes Report
              </Menu.Item> */}
            </SubMenu>
          )}
          {/* {(MenuList.includes(12)) &&( */}
          {(roleid == 1 || roleid == 2 || roleid == 8) && (
            <SubMenu key="sub4" icon={<TeamOutlined />} title="Credit">
              <Menu.Item
                key="4"
                onContextMenu={(e) => openContext(e, "/")}
                onClick={() => onSidebarNavigation("/creditreport")}
              >
                Credit Report
              </Menu.Item>
              <Menu.Item
                key="5"
                onContextMenu={(e) => openContext(e, "/")}
                onClick={() => onSidebarNavigation("/creditinvoice")}
              >
                Credit Invoice
              </Menu.Item>
              <Menu.Item
                key="6"
                onContextMenu={(e) => openContext(e, "/")}
                onClick={() => onSidebarNavigation("/creditpaymenthistory")}
              >
                Credit Payment History
              </Menu.Item>
            </SubMenu>
          )}
          {/* )} */}
          {MenuList.includes(9) && (
            <SubMenu key="sub2" icon={<TeamOutlined />} title="Master">
              <Menu.Item
                key="S"
                hidden={!SubMenuList.includes(20)}
                onContextMenu={(e) => openContext(e, "/goodsmaster")}
                onClick={() => onSidebarNavigation("/goodsmaster")}
              >
                Goods Master
              </Menu.Item>
              <Menu.Item
                key="T"
                hidden={!SubMenuList.includes(21)}
                onContextMenu={(e) => openContext(e, "/tarriffmaster")}
                onClick={() => onSidebarNavigation("/tarriffmaster")}
              >
                Tarriff Master
              </Menu.Item>
              <Menu.Item
                key="U"
                hidden={!SubMenuList.includes(22)}
                onContextMenu={(e) => openContext(e, "/usermaster")}
                onClick={() => onSidebarNavigation("/usermaster")}
              >
                User Master
              </Menu.Item>
              <Menu.Item
                key="V"
                hidden={!SubMenuList.includes(23)}
                onContextMenu={(e) => openContext(e, "/corporatemaster")}
                onClick={() => onSidebarNavigation("/corporatemaster")}
              >
                Corporate
              </Menu.Item>
              <Menu.Item
                key="W"
                hidden={!SubMenuList.includes(24)}
                onContextMenu={(e) => openContext(e, "/locationmaster")}
                onClick={() => onSidebarNavigation("/locationmaster")}
              >
                Location
              </Menu.Item>
              <Menu.Item
                key="X"
                hidden={!SubMenuList.includes(25)}
                onContextMenu={(e) => openContext(e, "/vehiclemaster")}
                onClick={() => onSidebarNavigation("/vehiclemaster")}
              >
                Vehicle
              </Menu.Item>
            </SubMenu>
          )}
          {/* <Menu.Item key="Y" icon={<DesktopOutlined />}>
              Finance 
          </Menu.Item> */}
          {MenuList.includes(10) && (
            <SubMenu key="sub3" icon={<TeamOutlined />} title="Finance">
              <Menu.Item
                key="Z"
                hidden={!SubMenuList.includes(26)}
                onContextMenu={(e) => openContext(e, "/vehicleaccount")}
                onClick={() => onSidebarNavigation("/vehicleaccount")}
              >
                Vehicle Accounts
              </Menu.Item>
              <Menu.Item
                key="0"
                hidden={!SubMenuList.includes(27)}
                onContextMenu={(e) => openContext(e, "/vehiclepaymenthistory")}
                onClick={() => onSidebarNavigation("/vehiclepaymenthistory")}
              >
                Vehicle payment History
              </Menu.Item>
              <Menu.Item
                key="1"
                onContextMenu={(e) => openContext(e, "/corporateaccount")}
                onClick={() => onSidebarNavigation("/corporateaccount")}
              >
                Corporate Account
              </Menu.Item>
              <Menu.Item
                key="2"
                hidden={!SubMenuList.includes(29)}
                onContextMenu={(e) =>
                  openContext(e, "/corporatepaymenthistory")
                }
                onClick={() => onSidebarNavigation("/corporatepaymenthistory")}
              >
                Corporate Payment History
              </Menu.Item>
            </SubMenu>
          )}
          {/* <Menu.Item key="3"  onClick={() => {
          localStorage.removeItem("token");
          props.authContext.updateUserLoginInfo(); }}>
            Log Out
          </Menu.Item> */}
          <Menu.Item key="3" onClick={Logout}>
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        {/* <AppHeader authContext={props.authContext} /> */}
        <Content style={{ margin: "0.5rem" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <AppRoutes></AppRoutes>
        </Content>
        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
