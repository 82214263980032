import React, { useState } from "react";
import { Button, Card, Col, DatePicker, Input, Row, Select, Spin } from "antd";
import "../../pages/Booking/Booking.scss";
import "./Credit.scss";
import moment from "moment";
import AppTable from "../../components/AppTable/AppTable";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const { Option } = Select;
const { Search } = Input;
const CreditPaymentHistory = () => {
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [selectedValues, setSelectedValues]: any = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [SearchText, setsearchText]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));

  const columns = [
    {
      title: "Against Invoice",
      dataIndex: "vehicleid",
      key: "Vehicle ID",
    },
    {
      title: "Against Invoice",
      dataIndex: "cabid",
      key: "Cab ID",
    },
    {
      title: "Company",
      dataIndex: "cabid",
      key: "Cab ID",
    },
    {
      title: "Comments",
      dataIndex: "comissionamt",
      key: "comissionamt",
    },
    {
      title: "Amount(Including GST)",
      dataIndex: "cabid",
      key: "Cab ID",
    },
    {
      title: "Amount(Excluding GST)",
      dataIndex: "cabid",
      key: "Cab ID",
    },
    {
      title: "Book ID",
      dataIndex: "hours",
      key: "hours",
    },
  ];

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
    } catch (ex) {
      console.log(ex);
    }
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const DateSearch = (fromdate: any, todate: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        companyid: selectedValues.companyid || "All",
        filtervalue: SearchText
      },
    };
    console.log(options);

    axiosGet(UrlConstants.getCreditInvoice, options)
      .then((resp: any) => {
        resp.forEach((e: any) => {
          e.key = e._id;
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp);
        setcopyCurrentBookingGridData(resp);
        console.log(resp);
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      if (value.vehicleid === undefined || value.vehicleid === null)
        value.vehicleid = "";
      if (value.cabid === undefined || value.cabid === null) value.cabid = "";
      if (value.comissionamt === undefined || value.comissionamt === null)
        value.comissionamt = "";
      if (value.fare === undefined || value.fare === null) value.fare = "";
      if (value.trip === undefined || value.trip === null) value.trip = "";
      if (value.days === undefined || value.days === null) value.days = "";
      if (value.hours === undefined || value.hours === null) value.hours = "";
      if (value.totalkm === undefined || value.totalkm === null)
        value.totalkm = "";
      if (value.efficitiveKM === undefined || value.efficitiveKM === null)
        value.efficitiveKM = "";
      if (value.diffKM === undefined || value.diffKM === null)
        value.diffKM = "";

      return (
        value.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.cabid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.comissionamt
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fare
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.trip
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.days
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.hours
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.totalkm
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.efficitiveKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.diffKM.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onSearch = (value:any) => {
    console.log(value);
    setsearchText(value);
    DateSearch(fromdate, todate, value);
  }

  return (
    <div>
      <div className="booking-child-routes-button-section">
        <Row gutter={8}>
          <Col>
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              CREDIT PAYMENT HISTORY
            </span>
          </Col>
        </Row>
      </div>
      <div style={{ background: "white", padding: "20px" }}>
        <Row gutter={8}>
          <Col span={10}>
            <span style={{ fontSize: "medium", marginRight: "10px" }}>
              {" "}
              From:
            </span>

            <DatePicker
              value={fromdate}
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={onfromdatechange}
            />
            <span
              style={{
                fontSize: "medium",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              {" "}
              To:
            </span>

            <DatePicker
              value={todate}
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={ontodatechange}
            />
          </Col>
          <Col span={3} offset={1}>
            <Select
              placeholder="Cab Id"
              style={{ width: "100%" }}
              /* showSearch
              optionFilterProp="children"
              value={selectedValues.cab_id}
              onChange={onCabIdChange}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showArrow={false}
              onSearch={onCabIdSearch}
              notFoundContent={null} */
            >
              {/*  {searchCabIdsList.map((id_: any) => (
                <Option key={id_.cab_id} value={id_.cab_id}>
                  {id_.cab_id}
                </Option>
              ))} */}
            </Select>
          </Col>
          <Col span={2} offset={1}>
            <Button
              //onClick={onTableSearch}
              className="credit-btn-blockSearch"
            >
              Search
            </Button>
          </Col>
          <Col span={2}>
            <Button
              //onClick={onTableSearch}
              className="credit-btn-blockExport"
            >
              Export
            </Button>
          </Col>
        </Row>

        <Row style={{ margin: "20px 0px" }}>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col span={8}>
            <span>
              From :{" "}
              <span className="credit-blueText">
                {moment(fromdate).format("DD/MM/YYYY")}{" "}
              </span>
              To :{" "}
              <span className="credit-blueText">
                {moment(todate).format("DD/MM/YYYY")}{" "}
              </span>
              Total Amount : <span className="credit-blueText">{8000} </span>
            </span>
          </Col>
          <Col span={4} offset={12}>
           {/*  <Input
              placeholder="Search Text"
              className="search"
              value={SearchText}
              onChange={onSearchchange}
            /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
        <Row className="credit-table-row" style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Spin tip="Loading..." spinning={false}>
              <AppTable
                columns={columns}
                data={copycurrentBookingGridData}
                enablePagination={true}
                //scroll={{ x: 1850, y: 480 }}
              ></AppTable>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreditPaymentHistory;
