import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

const AppFooter = (props: any) => {
  return (
    <Footer style={{ textAlign: "center", backgroundColor: "#FFFFFF", paddingTop: "5px", paddingBottom: "5px" }}>
      Copyright ©2023 Truck Taxi
    </Footer>
  );
};

export default AppFooter;
