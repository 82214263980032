import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, DatePicker, Input, Spin } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";

const { Search } = Input;

const Ownerdetails = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
 
  const RangePicker = DatePicker.RangePicker;

  const columns = [
    {
        title: "Owner Id",
        dataIndex: "owner_id",
        key: "Booking ID",

    },
    {
        title: "Name",
        dataIndex: "owner_name",
        key: "Booking ID",

    },
    {
        title: "Mobile Number",
        dataIndex: "owner_mobno",
        key: "Name",

    },
    {
        title: "Adhar Number",
        dataIndex: "owneraadhar",
        key: "Name",

    },

    {
        title: "Driving License No",
        dataIndex: "owerlic",
        key: "Name",

    },
    {
        title: "Badge No",
        dataIndex: "owerbadge",
        key: "Name",

    },
    {
        title: "Address",
        dataIndex: "owner_address",
        key: "Name",

    },
    {
        title: "D of joining",
        dataIndex: "name",
        key: "Name",

    },
    {
        title: "Truck Id",
        dataIndex: "cab_id",
        key: "Name",

    },
    {
        title: "Vehicle ID",
        dataIndex: "name",
        key: "Name",

    },
  



]


  const adayclick =(event: any=null)=>{
    setIsButtonClicked('Today');
    var first = moment();
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate=firstyear + '-' + firstmonth + '-' + firstday;
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate=year + '-' + month + '-' + day;
    DateSearch(/* fromdate,todate, */pagination, SearchText);
  };

 



const DateSearch =(/* fromdate:any,todate:any, */pagination:any, SearchText:any)=>{
  setIsLoading(true);
  const options: any = {
    params: {
      branchid:branchid,
      limit: pagination.pageSize,
      skip: (pagination.current-1) * (pagination.pageSize),
      filtervalue: SearchText
    },
  };
  axiosGet(UrlConstants.getownerdetails,options).then((resp: any) => {
    resp.data.forEach((e: any) => {
      e.key = e._id;
    });
    setIsLoading(false);
    setCurrentBookingGridData(resp.data);
    setcopyCurrentBookingGridData(resp.data);
    console.log(resp);
    setPagination({
      ...pagination,
      total: resp.tablelength
    });

  }).catch(function (error:any) {
    setIsLoading(false);
    if (error.response) {
      console.log(error.response.data,error.response.status);
      setCurrentBookingGridData([]);
      setcopyCurrentBookingGridData([]);
    }
  });
  };



  useEffect(() => {
    console.log(isRowSelected);
    adayclick();
  }, []);
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
    const globalSearch = (searchText:any) =>{ 

    let filteredData = currentBookingGridData.filter((value: any) => {
      if(value.owner_id===undefined || value.owner_id===null)
        value.owner_id='';
      if(value.owner_name===undefined || value.owner_name===null)
        value.owner_name='';
      if(value.owner_mobno===undefined || value.owner_mobno===null)
        value.owner_mobno='';
      if(value.owneraadhar===undefined || value.owneraadhar===null)
        value.owneraadhar='';
      if(value.owerlic===undefined || value.owerlic===null)
        value.owerlic='';
      if(value.owerbadge===undefined || value.owerbadge===null)
        value.owerbadge='';
      if(value.owner_address===undefined || value.owner_address===null)
        value.owner_address='';
      if(value.cab_id===undefined || value.cab_id===null)
        value.cab_id='';
    
    return (
     
        value.owner_id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.owner_name.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.owner_mobno
        .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())||
          value.owneraadhar
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.owerlic
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) || 
           value.owerbadge
           .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.owner_address
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.cab_id
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) 
      );
    });
      setcopyCurrentBookingGridData(filteredData);
};

function disabledDate(current:any) {
  // Can not select days before today and today
  return current > moment().endOf('day');
}
const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
  setPagination(pagination);
  DateSearch(/* fromdate, todate,  */pagination, SearchText);
  console.log(pagination); 
}
const onSearch = (value:any) => {
  console.log(value);
  setsearchText(value);
  DateSearch(/* fromdate, todate,  */pagination, value);
}

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
      <Row style={{paddingBottom:10}}>
        
              <Col span={4} offset={20}>
        {/* <Input  placeholder="Search Text" value={SearchText} onChange={onSearchchange}/> */}
        <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
        </Col>
        </Row>
      </div>
      
      <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          onchange={onPaginationchange}
          enablePagination={pagination}
          selectionType="radio"
          scroll={{ x:1800,y: 480 }}
        ></AppTable>
        </Spin>
      </div>
    </div>
  );
};

export default Ownerdetails;
