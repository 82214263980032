import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddGoods = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [goodsid, setGoodsid]: any = useState(props.selectedRowData.Goodsid);
  const initialState = { selectedRowData: null };

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData) {
      setGoodsid(props.selectedRowData.Goodsid);
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.Goodsid = props.selectedRowData.Goodsid;
      if (props.isedit)
        copyselectedvalues.GoodsName = props.selectedRowData.GoodsName;
      else copyselectedvalues.GoodsName = "";
      setSelectedValues(copyselectedvalues);
      // goodsnamechange('',props.selectedRowData.GoodsName);
    }
  }, [props.visible, props.Goodsid]);

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const goodsnamechange = (e: any, name: any = null) => {
    let selectedValuesCopy = { ...selectedValues };
    if (e != "") selectedValuesCopy.GoodsName = e.target.value;
    else selectedValuesCopy.GoodsName = name;
    setSelectedValues(selectedValuesCopy);
  };
  const onFinish = (value: any) => {
    addgoods();
    props.hideModal(false);
  };

  const addgoods = () => {
    const options: any = {
      data: {
        Package: selectedValues.GoodsName,
        isactive: 1,
      },
    };
    axiosPost(UrlConstants.addgoods, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Goods Added Successfully",
          description: "Goods Name: " + selectedValues.GoodsName + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Adding Goods Failed",
          description: "Error Occurred while Adding Goods",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const OnUpdate = (value: any) => {
    updategoods();
    props.hideModal(false);
  };

  const updategoods = () => {
    const options: any = {
      data: {
        Package_id: selectedValues.Goodsid,
        Package: selectedValues.GoodsName,
        isactive: 1,
      },
    };
    axiosPost(UrlConstants.updategoods, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Goods Updated Successfully",
          description: "Goods Name: " + selectedValues.GoodsName + " Updated",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "updating Goods Failed",
          description: "Error Occurred while Updating Goods",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={400}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item name={["goodsid", "goodsId"]} label="GoodsId" rules={[]}>
              <Input
                defaultValue={selectedValues.Goodsid}
                value={selectedValues.Goodsid}
                type="text"
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["goodsname", "goodsname"]}
              label="GoodsName"
              rules={[]}
            >
              <Input
                defaultValue={selectedValues.GoodsName}
                value={selectedValues.GoodsName}
                type="text"
                onChange={goodsnamechange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit === true && (
          <Col offset={5}>
            <Button onClick={OnUpdate}>Update</Button>
          </Col>
        )}
        {props.isedit !== true && (
          <Col offset={5}>
            <Button onClick={onFinish}>Save</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddGoods;
