import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select, InputNumber, Row, Col, notification, DatePicker, Checkbox } from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import OtpInput from 'react-otp-input';


const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const Ownerform = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [defaultDateTime, setDefaultDateTime]: any = useState();
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const [showValidations, setValidations] = useState(false);
  const [oldChecked, setoldChecked] = useState(false);
  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    if (props.isedit)
      setSelectedValues(props.selectedValues);
    else{
      getnewid();
      setSelectedValues({}); 
    }

  }, [props.visible]);

  const getnewid = () => {
    const uri = UrlConstants.getownerid + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.ownerid = resp[0].owner_id;
      copyselectedvalues.mtruckid = props.mtruckid;
      setSelectedValues(copyselectedvalues);
    });
  };


  const validateInput = () => {
    { }
    if (selectedValues.mobno != undefined) {
      if (selectedValues.mobno.length != 10) {
        console.log(showValidations);
        setValidations(true);
        console.log(selectedValues.mobno.length)
        return false;
      }
      else
        setValidations(false);
      return true;

    }

    else if (selectedValues.altno != undefined) {
      if (selectedValues.altno.length != 10) {
        console.log(showValidations);
        setValidations(true);
        console.log(selectedValues.altno.length)
        return false;
      }
      else
        setValidations(false);
      return true;

    }
    else if (selectedValues.aadhar != undefined) {
      if (selectedValues.aadhar.length != 12) {
        console.log(showValidations);
        setValidations(true);
        console.log(selectedValues.aadhar.length)
        return false;
      }
      else
        setValidations(false);
      return true;

    }
    else {
      setValidations(true);
      return false;
    }

  }

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };



  const handleCancel = (e: any, data: any = null) => {
    setSelectedValues({});
    props.hideModal(false, data);
  };


  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  }

  const ownernamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.ownername = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const mobnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mobno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const altnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.altno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const addresschange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.address = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const aadharchange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.aadhar = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const licensenochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.licenseno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const licexpdateChange = (value: any) => {
    console.log(value);
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.licexpdate = value;
    setSelectedValues(selectedValuesCopy);
  };

  const badgenochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.badgeno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const badgeexpChange = (value: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.badgeexpdate = value;
    setSelectedValues(selectedValuesCopy);
  };
  const accountnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.accountno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const ifscchange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.ifsc = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const banknamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const bankbranchnamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankbranchname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const pannochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.panno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const OnowneridChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.ownerid = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const addnewowner = () => {
    if (!validateInput()) {
      return;
    }
    console.log(selectedValues.badgeexpdate)
    let licexpdate;
    let badgeexpdate;
    if (selectedValues.licexpdate)
      licexpdate = selectedValues.licexpdate
        ? selectedValues.licexpdate.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");
    if (selectedValues.badgeexpdate)
      badgeexpdate = selectedValues.badgeexpdate
        ? selectedValues.badgeexpdate.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");

    const options: any = {
      data: {
        cab_id: selectedValues.mtruckid || selectedValues.cab_id,
        owner_id: selectedValues.ownerid || selectedValues.owner_id,
        owner_name: selectedValues.ownername || selectedValues.owner_name,
        owner_mobno: selectedValues.mobno || selectedValues.owner_mobno,
        owneraltermobno: selectedValues.altno || selectedValues.owneraltermobno,
        owner_address: selectedValues.address || selectedValues.owner_address,
        owneraadhar: selectedValues.aadhar || selectedValues.owneraadhar,
        owerlic: selectedValues.licenseno || selectedValues.owerlic,
        owerbadge: selectedValues.badgeno || selectedValues.owerbadge || "",
        accno: selectedValues.accountno || selectedValues.accno || "",
        ifsc: selectedValues.ifsc || selectedValues.ifsc || "",
        bankname: selectedValues.bankname || selectedValues || "",
        branch: selectedValues.bankbranchname || selectedValues.branch || "",
        pan: selectedValues.panno || selectedValues.pan || "",
        owerlicexp: licexpdate || selectedValues.owerlicexp|| "",
        owerbadgeexp: badgeexpdate || selectedValues.owerbadgeexp || "",
        isold: props.isold || oldChecked || false,
      },
    };
    console.log(options);
    let url = UrlConstants.addnewowner;
    if (props.isedit)
      url = UrlConstants.editowner;
    axiosPost(url, options)
      .then((resp: any) => {
        if (resp.status != 401) {
          console.log(resp);
          handleCancel("", options.data);
          let notificationInfo: any = {
            header: "Owner Added Successfully",
          }
          if (props.isedit)
            notificationInfo = {
              header: "Owner Updated Successfully",

            };
          openNotificationWithIcon("Success", notificationInfo);

        }
        else {
          let notificationInfo1: any;
          notificationInfo1 = {
            header: resp.message,
          };
          openNotificationWithIcon("Error", notificationInfo1);
        }
      })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        let notificationInfo = {
          header: "Owner Adding Failed",
          description: "Error Occurred while Adding Owner",
        };
        if (props.isedit)
          notificationInfo = {
            header: "Owner Update Failed",
            description: "Error Occurred while Updating Owner",
          };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };





  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={700}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="nest-messages" {...formItemLayout}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row gutter={24}>

          <Col span={12}>
            <Form.Item name={["ownerid", "Ownerid"]} label="OwnerId" rules={[]}>

              {
                props.isold !== true ?
                  <Input type="text" value={selectedValues.ownerid} defaultValue={selectedValues.owner_id || selectedValues.ownerid} readOnly />

                  :


                  <Input value={selectedValues.ownerid} onChange={OnowneridChange} type="text" />
              }

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["ownername", "Ownername"]} label="Ownername" rules={[]}>
              <Input onChange={ownernamechange} value={selectedValues.ownername} defaultValue={selectedValues.owner_name} type="text" />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>

            <Form.Item
              style={
                showValidations && !selectedValues.mobno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["mobile", "Mobileno"]} label="Mobile" rules={[]}>

              <Input
                value={selectedValues.mobno}
                defaultValue={selectedValues.owner_mobno}
                onChange={mobnochange}

                maxLength={10}

              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Mobile Number!
                </span>
              )}

            </Form.Item>

          </Col>
          <Col span={12}>

            <Form.Item
              style={
                showValidations && !selectedValues.altno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["alternate", "Alternate"]} label="Alternate No" rules={[]}>

              <Input
                value={selectedValues.altno}
                defaultValue={selectedValues.owneraltermobno}
                onChange={altnochange}

                maxLength={10}

              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Alternate Mobile Number!
                </span>
              )}



            </Form.Item>
            {/* <Form.Item name={["alternate", "Alternate"]} label="Alternate" rules={[]}>
              <Input onChange={altnochange} value={selectedValues.altno} type="text" />

            </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>

            <Form.Item name={["address", "Address"]} label="Adddress" rules={[]}>
              <Input onChange={addresschange} value={selectedValues.address} defaultValue={selectedValues.owner_address} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>

            <Form.Item
              style={
                showValidations && !selectedValues.aadhar
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["Adhaar", "Aadhaar"]} label="Aadhaar" rules={[]}>

              <Input
                value={selectedValues.aadhar}
                defaultValue={selectedValues.owneraadhar}
                onChange={aadharchange}

                maxLength={12}

              ></Input>
              {showValidations && (
                <span className="form-error-validation">
                  Please input Aadhar Number!
                </span>
              )}



            </Form.Item>
            {/* <Form.Item name={["Adhaar", "Aadhaar"]} label="Aadhaar" rules={[]}>
              <Input onChange={aadharchange} value={selectedValues.aadhar} type="text" /> */}

            {/* </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["license", "License"]} label="License" rules={[]}>
              <Input onChange={licensenochange} value={selectedValues.licenseno} defaultValue={selectedValues.owerlic} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["license", "LicenseExpiry Date"]} label="LicenseExpiry" rules={[]}>
              <DatePicker

                value={selectedValues.licexpdate}
                defaultValue={moment(selectedValues.owerlicexp)}
                format={dateFormat}
                onChange={licexpdateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["badge", "Badge"]} label="Batch" rules={[]}>
              <Input onChange={badgenochange} value={selectedValues.badgeno} defaultValue={selectedValues.owerbadge} type="text" />

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["badge", "BadgeExpiry Date"]} label="BatchExpiry" rules={[]}>
              <DatePicker

                value={selectedValues.badgeexpdate}
                defaultValue={moment(selectedValues.owerbadgeexp)}
                format={dateFormat}
                onChange={badgeexpChange}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["account", "Account No"]} label="A/C No" rules={[]}>
              <Input onChange={accountnochange} defaultValue={selectedValues.accno} value={selectedValues.accountno} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["ifsc", "IFSC"]} label="IFSC" rules={[]}>
              <Input onChange={ifscchange} value={selectedValues.ifsc} defaultValue={selectedValues.ifsc} type="text" />

            </Form.Item>
          </Col>
        </Row >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["bank", "BankName"]} label="Bank Name" rules={[]}>
              <Input onChange={banknamechange} value={selectedValues.bankname} defaultValue={selectedValues.bankname} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["branch", "Branch"]} label="Branch" rules={[]}>
              <Input onChange={bankbranchnamechange} value={selectedValues.bankbranchname} defaultValue={selectedValues.branch} type="text" />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["PAN", "PAN No"]} label="PAN" rules={[]}>
              <Input onChange={pannochange} value={selectedValues.panno} defaultValue={selectedValues.pan} type="text" />
            </Form.Item>
          </Col>

        </Row>


      </Form>
      <Row gutter={24}>

        <Col offset={5}>
          <Button onClick={addnewowner}>
            Save
      </Button>
        </Col>
        <Col offset={2}>
          <Button onClick={handleCancel}>
            Cancel
      </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Ownerform;
