import React, { useState, useEffect } from "react";
import {
  Tag,
  Space,
  Row,
  Col,
  Button,
  Checkbox,
  Input,
  Spin,
  Select,
  DatePicker,
} from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";
import "./Report.scss";
import AppModalCabAtdncReport from "../../components/AppModal/AppModalCabAtdncReport";
import AppModalCabAtdncEditReport from "../../components/AppModal/AppModalCabAtdncEditReport";
import { CSVLink } from "react-csv";
import { EditOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Search } = Input;

const CabAtdncReport = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [cabatdmodalVisible, setcabatdmodalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [selectedValues, setSelectedValues]: any = useState({});
  const [cabIdsList, setcabIdsList] = useState([]);
  const [vehicleIdsList, setvehicleIdsList] = useState([]);
  const [searchVehicleIdsList, setSearchVehicleIdsList] = useState([]);
  const [cabatdeditmodalVisible, setcabatdeditmodalVisible] = useState(false);
   const [filteredData, setFilteredData] = useState([]);
  //const [cabatdeditmodal, setcabatdeditmodal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 100,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "LoginTime",
      key: "Booking ID",
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "Vehicle ID",
    },
    {
      title: "Cab id",
      dataIndex: "Cab_id",
      key: "Booking ID",
    },
    {
      title: "Login Time",
      dataIndex: "LoginTime",
      key: "Name",
      render: (text: any) => {
        return moment(text).format("hh:mm A");
      },
    },
    {
      title: "Logout Tme",
      dataIndex: "LogoutTime",
      key: "Name",
      render: (text: any) => {
        return moment(text).format("hh:mm A");
      },
    },

    {
      title: "Total hours",
      dataIndex: "Hours",
      key: "Name",
      render: (text: any, record: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Starting KM",
      dataIndex: "startkm",
      key: "Name",
    },
    {
      title: "Closing KM",
      dataIndex: "close_km",
      key: "close_km",
    },
    {
      title: "Differential km",
      dataIndex: "DifferentialKM",
      key: "DifferentialKM",
      render: (text: any) => {
        if (text !== undefined) {
          if (text != null && text !== undefined && text !== "" && text !== 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Login location",
      dataIndex: "loginloc",
      key: "loginloc",
    },
    {
      title: "Login by",
      dataIndex: "loginby",
      key: "username",
    },
    //   {
    //     title: "Logout by",
    //     dataIndex: "logoutby",
    //     key: "username",

    // },

    {
      title: "MoreInfo",
      dataIndex: "bookedby",
      key: "Booked by1",
      render: (text: any, record: any) => {
        return (
          <div>
            <span style={{ width: 1, color: "black" }}>
              {/* <EditOutlined className="edit-booking" /> */}
              <Button
                style={{
                  backgroundColor: "#B0E0E6",
                  color: "black",
                  borderColor: "black",
                }}
                // onClick={cabsatdnmodal}
              >
                Moreinfo
              </Button>
            </span>
          </div>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "bookedby",
      key: "Cab Id1",
      ellipsis: true,
      render: (text: any, record: any) => {
        return (
          <div>
            <a onClick={() => editCabattReport(record)}>
              <EditOutlined className="edit-report" />
            </a>
          </div>
        );
      },
    },
  ];
  const cabsatdnmodal = () => {
    setcabatdmodalVisible(true);
  };
  const hideModalcabatdmodalVisible = (isVisible: any) => {
    setcabatdmodalVisible(isVisible);
    //adayclick();
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    setsearchText("");
  };
  const editCabattReport = (selectedRow: any) => {
    setcabatdeditmodalVisible(true);
    setSelectedRowData(selectedRow);
  };
  const hideModalEditCabattmodalVisible = (isVisible: any) => {
    setcabatdeditmodalVisible(isVisible);
    //adayclick();
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    setsearchText("");
  };

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      /*  var fromdate = moment(value).format("YYYY-MM-DD");
      let todate = moment().format("YYYY-MM-DD"); */
      //DateSearch(fromdate, todate);
      setFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      /* var todate = moment(value).add("days", 1).format("YYYY-MM-DD");
      let startdate = moment(fromdate).format("YYYY-MM-DD"); */
      //DateSearch(startdate, todate);
      setFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const adayclick = (event: any = null) => {
    setIsButtonClicked("Today");
    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    console.log(todate);
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const twodayclick = () => {
    setIsButtonClicked("Yesday");
    var first = moment().add("days", -1);
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add("days", 1);
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setFlag(false);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const monthclick = () => {
    setIsButtonClicked("Month");
    var first = moment();
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + "01";
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    console.log(fromdate);
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const weekclick = () => {
    setIsButtonClicked("Week");
    var first = moment().add("days", -moment().day());
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    console.log(fromdate);
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    console.log(todate);
    settodate(moment(todate));
    setFlag(false);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };

  const DateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        FromDate: fromdate,
        ToDate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        //filtervalue: SearchText,
        //CabID_or_VechicleID
        //VechicleID: selectedValues.vehicleid || 'ALL'
      },
    };
    const filterOptions: any = {
      params: {
        cityid: branchid,
        FromDate: fromdate,
        ToDate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        CabID_or_VechicleID: SearchText,
      },
    };
    console.log( typeof(SearchText))
    const filterParams = (SearchText !== "" && SearchText !== null && SearchText !== undefined) ? filterOptions : options
    axiosGet(UrlConstants.getcabattendancereport, filterParams)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.AID;
        });
        setIsLoading(false);
        resp.data.sort((a: any, b: any) => a.Cab_id.localeCompare(b.Cab_id));
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        console.log(resp);
        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };

/*   const onVehicleIdChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleid = value;
    setSelectedValues(copyselectedvalues);
  }; */
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    console.log(rowInfo);
  };
  useEffect(() => {
    console.log(isRowSelected);
    getvehicleids();
    adayclick();
  }, []);
  
  const getvehicleids = () => {
    const uri = UrlConstants.getvehicleidslist + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      setvehicleIdsList(resp);
    });
  };

 /*  const onVehicleIdSearch = (value: any) => {
    if (value) {
      // const searchedList = locationsList.filter(
      //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
      // );
      var filtered = vehicleIdsList.filter((x: any) =>
        String(x.vehicleid?.toLowerCase()).includes(value.toLowerCase())
      );
      //console.log(searchedList,filtered);
      setSearchVehicleIdsList(filtered);
    } else {
      setSearchVehicleIdsList([]);
    }
  }; */

  const onTableSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    //DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const onTableClear = () => {
    selectedValues.vehicleid = null;
    setSearchVehicleIdsList([]);
    //setSearchVehicleIdsList([])
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    //adayclick();
  };

  const headersToExport = columns.map((column: any) =>
    column.title == "Date"
      ? { label: column.title, key: "date" }
      : column.title == "Total hours"
      ? { label: column.title, key: "totalhours" }
      : column.title == "Differential km"
      ? { label: column.title, key: "DifferentialKM" }
      : column.title == "MoreInfo"
      ? { label: "", key: "" }
      : column.title == "Edit"
      ? { label: "", key: "" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = currentBookingGridData.map((value: any) => ({
    ...value,
    date: moment(value.LoginTime).format("DD/MM/YYYY"),
    LoginTime: moment(value.LoginTime).format("hh:mm A"),
    LogoutTime: moment(value.LogoutTime).format("hh:mm A"),
    totalhours:
      value.Hours != null && value.Hours != undefined && value.Hours != ""
        ? Math.round(value.Hours)
        : value.Hours,
    DifferentialKM:
      value.DifferentialKM != null &&
      value.DifferentialKM != undefined &&
      value.DifferentialKM != ""
        ? Math.round(value.DifferentialKM)
        : value.DifferentialKM,
  }));
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    onTableSearch(fromdate, todate, pagination, SearchText);
    console.log(pagination);
  };
  /*  const Editgoodsmodal = () => {
    console.log(selectedRowData);
    let copyselectedvalues:any={};
    copyselectedvalues.Goodsid=selectedRowData[0].Packageid;
    copyselectedvalues.GoodsName=selectedRowData[0].Package;
    setselectedValues(copyselectedvalues);
    setModalTitle("Edit Goods");
    seteditgoodsmodal(true);
    setaddgoodsmodal(true);
  }; */
  const onSearch = (value: any) => {
    console.log(value);
    setsearchText(value);
    onTableSearch(fromdate, todate, pagination, value);
  };
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
    
      return (
        value.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())

      );
    });
    setcopyCurrentBookingGridData(filteredData);
    setFilteredData(filteredData);
  };

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section" style={{padding: "0 0.5rem"}}>
        <Row style={{ paddingBottom: 10 }}>
          {" "}
          <Col>
            {" "}
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              {" "}
              CAB ATTENDANCE REPORT
            </span>
          </Col>
          <Col offset={2}>
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              {" "}
              From : {moment(fromdate).format("DD.MM.YYYY")} To :{" "}
              {flag
                ? moment().format("DD.MM.YYYY")
                : moment(todate).format("DD.MM.YYYY")}{" "}
            </span>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 10 }}>
          <Col>
            <span style={{ fontSize: "medium" }}> From:</span>

            <DatePicker
              value={fromdate}
              format={dateFormat}
              disabledDate={disabledFromDate}
              onChange={onfromdatechange}
            />
            <span style={{ fontSize: "medium" }}> To:</span>

            <DatePicker
              value={flag ? moment() : todate}
              //value={todate}
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={ontodatechange}
            />
          </Col>
          <Col span={2} offset={1}>
            <Button
              className={
                ButtonClicked === "Today"
                  ? "booking-btn-buttonTrue"
                  : "booking-btn-buttonFalse"
              }
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className={
                ButtonClicked === "Week"
                  ? "booking-btn-buttonTrue"
                  : "booking-btn-buttonFalse"
              }
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className={
                ButtonClicked === "Month"
                  ? "booking-btn-buttonTrue"
                  : "booking-btn-buttonFalse"
              }
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className="report-btn-blockSearch"
              onClick={() =>
                onTableSearch(fromdate, todate, pagination, SearchText)
              }
              disabled={moment(fromdate).isAfter(moment(todate))}
            >
              Search
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <CSVLink
              filename={"CabAttendanceReport.csv"}
              /* headers={columns.map((column:any)=> (column.title!='MoreInfo' ? 
                    {label:column.title, key:column.dataIndex} : {label:'', key:''}))} */
              headers={headersToExport}
              data={dataToExport}
              className="btn btn-primary"
            >
              <Button className="report-btn-blockExport">Export</Button>
            </CSVLink>
          </Col>
          <Col span={4}  offset={1}>
            <Input 
                      placeholder="Search Text" 
                      className="search" 
                      value={SearchText}
                      onChange={onSearchchange}/>
            {/* <Search placeholder="Search Text" onSearch={onSearch} enterButton /> */}
          </Col>
        </Row>

        <Row>
          <Col span={3}>
            {/* <Select
              placeholder="Vehicle Id"
              value={selectedValues.vehicleid}
              style={{ width: 100 }}
              showSearch
              optionFilterProp="children"
              onChange={onVehicleIdChange}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showArrow={false}
              onSearch={onVehicleIdSearch}
              notFoundContent={null}
            >
              {searchVehicleIdsList.map((id: any) => (
                <Option key={id.vehicleid} value={id.vehicleid}>
                  {id.vehicleid}
                </Option>
              ))}
            </Select> */}
          </Col>
           
        {/*   <Col span={2}>
            <Button
              htmlType="button"
              style={{ marginLeft: 10 }}
              className="report-btn-blockClear"
              onClick={onTableClear}
            >
              Clear
            </Button>
          </Col>
           */}
          {/*  </Row>
            <Row style={{ paddingBottom: 10 }}> */}
         
        </Row>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            onchange={onPaginationchange}
            enablePagination={pagination}
            scroll={{ x: 1850, y: 480 }}
          ></AppTable>
        </Spin>
      </div>
      <AppModalCabAtdncReport
        title="More Info"
        selectedRowData={selectedRowData}
        hideModal={hideModalcabatdmodalVisible}
        visible={cabatdmodalVisible}
        onRowClickEnabled={false}
        customClass="new-booking-assign"
      />
      <AppModalCabAtdncEditReport
        title="Edit Odometer Readings"
        selectedRowData={selectedRowData}
        hideModal={hideModalEditCabattmodalVisible}
        visible={cabatdeditmodalVisible}
        onRowClickEnabled={false}
        customClass="new-booking-assign"
      />
    </div>
  );
};

export default CabAtdncReport;
