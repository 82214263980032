import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, notification, Spin, DatePicker } from "antd";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";

import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "../Booking.scss";
import { CSVLink } from "react-csv";

const { Search } = Input;

const CancelledBooking = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  let tempavialablecabs: any = [];
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  const [todayFlag, setTodayFlag] = useState(false);
  const [prevFlag, setPrevFlag] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const dateFormat = "DD/MM/YYYY";
  const columns = [
    {
      title: "Book ID",
      dataIndex: "id",
      key: "Booking ID",
      fixed: "left",
      //sorter: (a: any, b: any) => a.id - b.id,
      width: "85px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      fixed: "left",
      // sorter: (a: any, b: any) => a.name - b.name,
      width: "200px",
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      key: "Mobile",
      fixed: "left",
      width: "110px",
      render: (text: any) => text?.slice(-10),
    },

    {
      title: "Date",
      dataIndex: "bookedtime",
      key: "Date",

      render: (text: any) => {
        if (text !== undefined || text !== "")
          return moment(text).format("DD/MM/YYYY");
      },
      width: "110px",
    },
    {
      title: "Time",
      dataIndex: ["bookedtime", "assignedtime", "cancelledtime"],
      key: "Time",
      render: (text: any, record: any) => {
        return (
          <div>
            <Row>
              B -{" "}
              {record.bookedtime !== undefined
                ? moment(record.bookedtime).format("hh:mm A")
                : ""}
            </Row>
            <Row>
              A -{" "}
              {record.assignedtime !== undefined
                ? moment(record.assignedtime).format("hh:mm A")
                : ""}
            </Row>
            <Row>
              C -{" "}
              {record.cancelledtime !== undefined
                ? moment(record.cancelledtime).format("hh:mm A")
                : ""}
            </Row>
          </div>
        );
      },
      width: "120px",
    },
    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "From Location",
      // sorter: (a: any, b: any) => a.fromloc - b.fromloc,
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "To Location",
      // sorter: (a: any, b: any) => a.toloc - b.toloc,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "Remarks",
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "Reason",
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "Vehicle",
      // render: (text: any, record: any) => {
      //   return record.vechicledetail[0].Vechicle;
      // },
      width: "85px",
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "Fare Type",
      // render: (text: any, record: any) => {
      //   return record.ttdetails[0].name;
      // },
    },
    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "Credit",
      render: (text: any) => <a>{text}</a>,
      width: "85px",
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "Goods",
    },

    {
      title: "Booked by",
      dataIndex: ["bookedby", "cancelledby", "updatedby"],
      key: "Booked by",

      render: (text: any, record: any) => {
        return (
          <div>
            <Row>B - {record.bookedby} </Row>
            {record.reassignby && <Row>A - {record.assignedby} </Row>}
            <Row>C- {record.cancelledby} </Row>
            {record.updatedby && <Row>U - {record.updatedby} </Row>}
            {record.reassignby && <Row>R - {record.reassignby} </Row>}
            {record.enquiryby && <Row>E - {record.enquiryby} </Row>}
          </div>
        );
      },
      width: "130px",
    },
    {
      title: "Cancellation Charges",
      dataIndex: "cancelcharge",
      key: "Cancellation Charges",
      render: (text: any) => {
        if (text !== undefined) {
          if (text !== null || text !== undefined || text !== "")
            return Math.round(text);
          else return "";
        }
      },
      width: "120px",
    },
    {
      title: "Alternative",
      dataIndex: "alternativemobileno",
      key: "Alternative",
      width: "110px",
      render: (text: any) => text?.slice(-10),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "City",
      // sorter: (a: any, b: any) => a.cityid - b.cityid,
      width: "120px",
    },
    {
      title: "Cab Id",
      dataIndex: "cabid",
      key: "Vehicle Id",
      width: "85px",
    },
  ];

  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
      render: (text: any, record: any) => (
        <a onClick={() => onAssignBooking(null, record, null)}>{text}</a>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "drivername",
    },
    {
      title: "Mobile No",
      dataIndex: "drivermobileno",
      key: "drivermobileno",
      render: (text: any) => text?.slice(-10),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Trips",
      dataIndex: "totaltrip",
      key: "totaltrip",
    },
    {
      title: "Total Amount",
      dataIndex: "tripfare",
      key: "tripfare",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.tripfare === 0
                  ? "#f5d7c6"
                  : record.tripfare >= 1 && record.tripfare <= 499
                  ? "#f2ee9d"
                  : record.tripfare >= 499 && record.tripfare <= 1000
                  ? "##b0d4a0"
                  : "#fff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Home",
      dataIndex: "startlocation",
      key: "Home",
    },
  ];

  const showGridModal = () => {
    getAvailableCabList(selectedRowData);
    setGridModalVisible(true);
  };

  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
    onDateSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  const onAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    const options = {
      data: {
        cabid: record.cabid,
        bookid: selectedRowData[0].id,
        driverid: record.driverid,
        assignedby: username,
        emptyKM: record.distance,
      },
    };
    axiosPost(UrlConstants.assignBooking, options)
      .then((resp: any) => {
        //getCancelledBookingList();
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to " + record.cabid,
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Assigning Booking Failed",
          description: "Error Occurred while Assigning New Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const booknow = () => {
    const options = {
      data: {
        bookid: selectedRowData[0].id,
      },
    };
    axiosPost(UrlConstants.UpdatetoNewBooking, options)
      .then((resp: any) => {
        //getCancelledBookingList();
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );
      })
      .catch((error: any) => {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  useEffect(() => {
    adayclick();
  }, []);

  const getCancelledBookingList = () => {
    const uri = UrlConstants.getcancelledbookinglist + "?branchid=" + branchid;
    axiosGet(uri)
      .then((resp: any) => {
        resp.forEach((e: any) => {
          e.key = e.id.toString();
        });
        setCurrentBookingGridData(resp);
        setcopyCurrentBookingGridData(resp);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getAvailableCabList = (rowinfo: any) => {
     const options = {
       params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
        roleid: Role,
        fromlat: rowinfo[0].fromloclat,
        fromlong: rowinfo[0].fromloclong
      },
    };
 
    axiosGet(UrlConstants.getAvailableCabsNew, options).then((resp: any) => {
      setAvailableCabData(resp.data);
     
    });
  };


  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      if (value.cancelcharge === undefined || value.cancelcharge === null)
        value.cancelcharge = "";
      if (value.vehicletype === undefined || value.vehicletype === null)
        value.vehicletype = "";
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = "";
      if (
        value.alternativemobileno === undefined ||
        value.alternativemobileno === null
      )
        value.alternativemobileno = "";
      return (
        value.name.toLowerCase().includes(searchText.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.mobileno.toLowerCase().includes(searchText.toLowerCase()) ||
        value.alternativemobileno
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fromloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.toloc.toLowerCase().includes(searchText.toLowerCase()) ||
        value.remarks.toLowerCase().includes(searchText.toLowerCase()) ||
        value.vehicletype.toLowerCase().includes(searchText.toLowerCase()) ||
        value.goods.toLowerCase().includes(searchText.toLowerCase()) ||
        value.bookedby.toLowerCase().includes(searchText.toLowerCase()) ||
        value.city.toLowerCase().includes(searchText.toLowerCase()) ||
        value.faretype.toLowerCase().includes(searchText.toLowerCase()) ||
        value.reason.toLowerCase().includes(searchText.toLowerCase()) ||
        value.cancelcharge.toLowerCase().includes(searchText.toLowerCase()) ||
        value.cancelledby.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  const sendtoRAC = () => {
    const options = {
      data: {
        bookid: selectedRowData[0].id,
      },
    };
    axiosPost(UrlConstants.newbookingtoRAC, options)
      .then((resp: any) => {
        //getCancelledBookingList();
        onDateSearch(
          fromdate.format("YYYY/MM/DD"),
          todate.format("YYYY/MM/DD"),
          pagination,
          SearchText
        );
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to RAC",
          description:
            "You can see all the RAC bookings by clicking below button",
          buttonText: "View RAC Bookings",
          path: "/racBooking",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Send Booking to RAC Failed",
          description: "Error Occurred while sending Booking to RAC",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const adayclick = (event: any = null) => {
    setIsButtonClicked("Today");
    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const twodayclick = () => {
    setIsButtonClicked("Yesday");
    var first = moment().add("days", -1);
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment().add("days", -1);
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(false);
    setPrevFlag(true);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const monthclick = () => {
    setIsButtonClicked("Month");
    var first = moment();
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + "01";
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };

  const weekclick = () => {
    setIsButtonClicked("Week");
    var first = moment().add("days", -moment().day());
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };
  const onDateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const DateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        fromdate: fromdate,
        todate: todate,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    axiosGet(UrlConstants.getcancelbydate, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.id;
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setTotalCount(resp.tablelength);

        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  };
  const columnsForAll = columns.filter((col: any) => col.title !== "Mobile");

  const headersToExport = columnsForAll.map((column: any) =>
    column.title === "Time"
      ? { label: column.title, key: "time" }
      : column.title === "Booked by"
      ? { label: column.title, key: "by" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = currentBookingGridData.map((value: any) => ({
    ...value,
    bookedtime: moment(value.bookedtime).format("DD/MM/YYYY"),
    iscredit: value.iscredit === false ? "No" : "Yes",
    time: "B-".concat(
      moment(value.bookedtime).format("hh:mm A"),
      "\n",
      "A-",
      value.assignedtime ? moment(value.assignedtime).format("hh:mm A") : "",
      "\n",
      "C-",
      moment(value.cancelledtime).format("hh:mm A")
    ),
    by: "B-".concat(value.bookedby, "\n", "C-", value.cancelledby),
  }));

  const tripsCount = (trips: any) => {
    history.push({ cancelledTripscount: trips });
  };
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    onDateSearch(fromdate, todate, pagination, SearchText);
  };
  const onSearch = (value: any) => {
    setsearchText(value);
    onDateSearch(fromdate, todate, pagination, value);
  };

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
        {(Role == 1 || Role == 2) && (
          <Row style={{ paddingBottom: 7 }}>
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              {" "}
              From : {moment(fromdate).format("DD.MM.YYYY")} To :{" "}
              {todayFlag
                ? moment().format("DD.MM.YYYY")
                : moment(todate).format("DD.MM.YYYY")}{" "}
            </span>
          </Row>
        )}
        {(Role == 1 || Role == 2) && (
          <Row style={{ paddingBottom: 7 }}>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Today"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={adayclick}
              >
                Today
              </Button>
            </Col>

            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Yesday"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={twodayclick}
              >
                Prev
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Week"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={weekclick}
              >
                Week
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={
                  ButtonClicked === "Month"
                    ? "booking-btn-buttonTrue"
                    : "booking-btn-buttonFalse"
                }
                onClick={monthclick}
              >
                Month
              </Button>
            </Col>
            <Col>
              <span style={{ fontSize: "medium" }}> From:</span>

              <DatePicker
                value={fromdate}
                format={dateFormat}
                disabledDate={disabledFromDate}
                onChange={onfromdatechange}
              />
              <span style={{ fontSize: "medium" }}> To:</span>

              <DatePicker
                value={
                  todayFlag
                    ? moment()
                    : prevFlag
                    ? moment().add("days", -1)
                    : todate
                }
                //value={todate}
                format={dateFormat}
                disabledDate={disabledDate}
                onChange={ontodatechange}
              />
            </Col>
            <Col span={2} offset={1}>
              <Button
                className="booking-btn-blockSearch"
                onClick={() =>
                  onDateSearch(fromdate, todate, pagination, SearchText)
                }
                disabled={moment(fromdate).isAfter(moment(todate))}
              >
                Search
              </Button>
            </Col>

            <Col offset={1}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                Trips :{" "}
              </span>
            </Col>
            <Col style={{ paddingLeft: "10px" }}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                {totalCount}
              </span>
            </Col>
          </Row>
        )}

        <Row>
        {(Role == 1 || Role == 2 || Role == 4 || Role == 7) && (
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-assign"
              disabled={!isRowSelected}
              onClick={showGridModal}
            >
              Assign
            </Button>
          </Col>)}
          {(Role == 1 || Role == 2 || Role == 4 || Role == 7) && (
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
              disabled={!isRowSelected}
              onClick={booknow}
            >
              Book Now
            </Button>
          </Col>)}
          {(Role == 4 || Role == 5 || Role == 7 || Role == 7) && (
            <Col offset={1}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                Trips :{" "}
              </span>
            </Col>
          )}
          {(Role == 4 || Role == 5 || Role == 7 || Role == 7) && (
            <Col style={{ paddingLeft: "10px" }}>
              <span style={{ fontSize: "large", fontWeight: "bold" }}>
                {totalCount}
              </span>
            </Col>
          )}
          {(Role == 1 || Role == 2) && (
            <Col span={2} offset={1}>
              <CSVLink
                filename={"CancelledBooking.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              >
                <Button className="booking-btn-blockExport">Export</Button>
              </CSVLink>
            </Col>
          )}
         {(Role == 1 || Role == 2) && (
          <Col span={4} offset={6}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton />
          </Col>)}
          {(Role != 1 || Role != 2) && (
          <Col span={4} offset={17}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton />
          </Col>)}
        </Row>
        
        {/* {(Role!==1 && Role!==2) && (
         <Row>
           <Col offset={15}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Trips : </span></Col>
           <Col style={{ paddingLeft: '10px' }}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {totalCount}</span></Col>
          <Col span={4} offset={2}>
            <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} />
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row> 
     )}*/}
      </div>
      {/* <div>
       
      </div> */}

      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={pagination}
            selectionType="radio"
            scroll={{ x: 2850, y: 480 }}
            onchange={onPaginationchange}
          ></AppTable>
        </Spin>
      </div>
      <AppModalWithGrid
        title="Assign Trip"
        visible={gridModelVisible}
        hideModal={hideModal}
        width={'fit-content'}
        selectedRowData={selectedRowData}
        modalGridColumns={modalReAssignColumns.filter(
          (item) =>
            moment().isSameOrAfter(moment("18:00:00", "HH:mm:ss")) ||
            item.dataIndex !== "startlocation"
        )}
        isAddbooking={false}
        modalGridData={availableCabData}
        onRowClickEnabled={true}
        onRowClick={onAssignBooking}
        customClass="new-booking-assign"
      />
    </div>
  );
};

export default CancelledBooking;
