import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import "./Booking.scss";
import { useHistory } from "react-router-dom";

const Booking = (props: any) => {
  const [roleid, setRoleid]: any = useState(localStorage.getItem("roleid"));
  let history = useHistory();
  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };
  const getBookingsHeader = () => {
    return (
      <Row gutter={16} className="main-row">
        <Col
          span={2}
          className={
            history.location.pathname === "/booking/addBooking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-add"
            onClick={() => onBookingNavigation("/addBooking")}
          >
            Add
          </Button>
        </Col>
        <Col
          span={2}
          className={
            history.location.pathname === "/booking/newBooking"
              ? "selectedTab"
              : ""
          }
        >
          <div>
            <Button
              className="booking-btn-new"
              onClick={() => onBookingNavigation("/newBooking")}
            >
              New
            </Button>
          </div>
        </Col>
        {/* <Col
          span={2}
          className={
            history.location.pathname === "/booking/onlineBooking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-online"
            onClick={() => onBookingNavigation("/onlineBooking")}
          >
            Online
          </Button>
        </Col>
        <Col
          span={2}
          className={
            history.location.pathname === "/booking/racBooking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-rac"
            onClick={() => onBookingNavigation("/racBooking")}
          >
            RAC
          </Button>
        </Col> */}
        <Col
          span={2}
          className={
            history.location.pathname === "/booking/currentBooking" ||
            history.location.pathname === "/booking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-live"
            onClick={() => onBookingNavigation("/currentBooking")}
          >
            Live
          </Button>
        </Col>
        <Col
          span={3}
          className={
            history.location.pathname === "/booking/completedBooking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-completed"
            onClick={() => onBookingNavigation("/completedBooking")}
          >
            Completed
          </Button>
        </Col>
        <Col
          span={2}
          className={
            history.location.pathname === "/booking/enquiry"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-enquiry"
            onClick={() => onBookingNavigation("/enquiry")}
          >
            Enquiry
          </Button>
        </Col>
        <Col
          span={3}
          className={
            history.location.pathname === "/booking/cancelledBooking"
              ? "selectedTab"
              : ""
          }
        >
          <Button
            className="booking-btn-cancelled"
            onClick={() => onBookingNavigation("/cancelledBooking")}
          >
            Cancel
          </Button>
        </Col>
        <Col
          span={3}
          className={
            history.location.pathname === "/booking/teleCancel"
              ? "selectedTab"
              : ""
          }
        >
          {(roleid == 1 || roleid == 2) && (
            <Button
              className="booking-btn-telecancel"
              onClick={() => onBookingNavigation("/teleCancel")}
            >
              Tele Cancel
            </Button>
          )}
        </Col>
        {/* <Col
          span={2}
          className={
            history.location.pathname === "/booking/lmv" ? "selectedTab" : ""
          }
        >
           {(roleid == 1 || roleid == 2 ) && (
          <Button
            className="booking-btn-lmv"
            onClick={() => onBookingNavigation("/lmv")}
          >
            LMV
          </Button>
           )}
        </Col>  */}
      </Row>
    );
  };
  return (
    <div className="booking">
      {getBookingsHeader()}
      {props.children}
    </div>
  );
};

export default Booking;
