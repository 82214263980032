import axios from "axios";
let localContext: any;

export default {
  setupInterceptors: (context: any) => {
    localContext = context;
    axios.interceptors.request.use(
      // perform a task before the request is sent
      (config) => {
        //const storeInfo = store.getState();
        // console.log("interceptor " + context.loginTokenInfo);
        // console.log(context);
        // console.log("interceptor local context" + localContext.loginTokenInfo);
        if (localContext.loginTokenInfo) {
          config.headers["x-access-token"] = localContext.loginTokenInfo;
        }

        if (window.location.origin.indexOf("http://3.7.196.215:1212") === -1 && config.url?.indexOf("http://3.7.196.215:1212") === -1) {
       //   config.url = "http://103.26.206.219" + config.url;
          config.url = "http://3.7.196.215:1212" + config.url;
        }


        return config;
      },
      (error) => {
        // handle the error
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response != undefined) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            context.updateUserLoginInfo();
          }
        }
        return Promise.reject(error);
      }
    );
  },
};
