import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  notification,
  InputNumber,
} from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";
import { on } from "cluster";
import { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddPaymentsForCorporate = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [selectedValues, setSelectedValues]: any = useState({});

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData) {
      setSelectedRowInfo(props.selectedRowData);
    }
  }, [props.visible]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };
  const onamountchange = (e: any, name: any = null) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.amount = e;
    setSelectedValues(selectedValuesCopy);
  };
  const referencenochange = (e: any, name: any = null) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.referenceno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const onpaymentmodechange = (value: any, fullInfo: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mop = Number(value);
    setSelectedValues(selectedValuesCopy);
  };
  const onFinish = (values: any) => {
    selectedValues.cabid = props.data.cabid;
    setSelectedValues(selectedValues);
    //makepayment();
  };
  const makepayment = () => {
    const formattedDate = moment().format("YYYY-MM-YY HH:mm:ss");
    const options: any = {
      data: {
        ID: selectedRowInfo.id,
        amount: selectedValues.amount || selectedRowInfo.amount,
        mop: selectedValues.mop || 3,
        refference: selectedValues.referenceno,
        date: formattedDate,
        balance: selectedRowInfo.amount - selectedValues.amount || 0,
      },
    };
    axiosPost(UrlConstants.makecorporatepayment, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Payment added Successfully",
          //description: "Booking Id: "+ selectedValues.bookid +" Booking cancelled",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleOk("close");
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Add Payment Failed",
          description: "Error Occurred while making payment",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  return (
    <div>
      <Modal
        destroyOnClose={true}
        className="custom-modal-cancel-form"
        width={450}
        title="Add Corporate Payment"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form name="nest-messages" {...formItemLayout}>
          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Form.Item
                name={["compid", "compid"]}
                label="Company ID"
                rules={[]}
              >
                <Input
                  defaultValue={selectedRowInfo.compid}
                  value={selectedValues.compid}
                  type="text"
                  readOnly={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Form.Item name={["amount", "amount"]} label="Amount" rules={[]}>
                <InputNumber
                  defaultValue={selectedRowInfo.amount}
                  value={selectedValues.amount}
                  style={{ width: "100%" }}
                  onChange={onamountchange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name={["modepayment", "modepayment"]}
                label="Mode of Payment"
              >
                <Select
                  placeholder="Payment Mode"
                  value={selectedValues.mop}
                  defaultValue={selectedValues.mop}
                  onChange={onpaymentmodechange}
                >
                  <Option value="1">NEFT</Option>
                  <Option value="2">Prepaid</Option>
                  <Option value="3">Cash</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Form.Item
                name={["reference", "reference"]}
                label="Reference No"
                rules={[]}
              >
                <Input
                  value={selectedValues.referenceno}
                  type="text"
                  onChange={referencenochange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row gutter={10} style={{ marginTop: "10px" }}>
          <Col span={6}></Col>
          <Col span={6}>
            <Button className="finance-btn-blockpaid" onClick={makepayment}>
              Save
            </Button>
          </Col>
          <Col span={6}>
            <Button className="finance-btn-blockUnpaid" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddPaymentsForCorporate;
