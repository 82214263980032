import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spin, Select, DatePicker, Input } from "antd";

import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import AddAttachmentenquiry from "../../components/AppModal/AddAttachmentenquiry";
import moment from "moment";

const { Option } = Select
const { Search } = Input;

const Attachmentenquiry = () => {
  const [approveDriverAttendanceVisible, setApproveDriverAttendanceVisible,] = useState(false);

  const [driverAttendanceList, setDriverAttendanceList] = useState([]);
  const [logoutdetails, setlogoutdetails] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isblocked, setisblocked] = useState(true);
  const [nologout, setnologout] = useState(true);
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [isLoading, setIsLoading] = useState(false);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [fromdate, setfromdate]: any = useState(moment());
  const [todate, settodate]: any = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [SearchText, setsearchText]: any = useState("");
  const [editAttachmentEnquiry, setEditAttachmentEnquiry] = useState(false);
  const [approveDriverAttendanceTitle, setApproveDriverAttendanceTitle] = useState("Add Enquiry");
  const [selectedValues, setselectedValues]: any = useState([]);
  const [roleid, setroleid]: any = useState(localStorage.getItem("roleid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [branchList, setBranchList] = useState([]);                                                                                                                                  

  const columns = [
    {
      title: "ID ",
      dataIndex: "enquiry_id",
      key: "license",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "created",
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {

          children: <div>{moment(text).format("DD/MM/YYYY")}</div>
        };
      },
      
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

    },
    {
      title: " Number",
      dataIndex: "number",
      key: "mobile",
      render: (text:any) => (roleid.toString() == '1' || roleid.toString() == '2') ? text : 'xxxxxxxxxx'
    },
    {
      title: "Area",
      dataIndex: "address",
      key: "area",

    },
    {
      title: "City",
      dataIndex: "cityid",
      key: "city",
      render:(text: any, record: any) => {
        let selectedBranch:any = branchList.filter((branch:any) => branch.id == text )
        return (<div>{selectedBranch[0]?.name}</div>);
      }
    },

    {
      title: "Enquiry Type",
      dataIndex: "enquiry_type",
      key: "enquiry",
      ellipsis: true,
      render:(text: any, record: any) => {
        let selectedVehicle:any = vehicleTypeList.filter((vehicle:any) => vehicle.id == text )
        return (<div>{selectedVehicle[0]?.vehicletype}</div>);
      }
    },
    {
      title: "License ",
      dataIndex: "license",
      key: "license",
      ellipsis: true,
    },
    {
      title: "Registration",
      dataIndex: "registration",
      key: "registration",

    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",

    },
    {
      title: "Make year",
      dataIndex: "year",
      key: "year",

    },
    {
      title: "Status",
      dataIndex: "action",
      key: "status",

    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",

    },
    {
      title: "Attend by",
      dataIndex: "attended_by",
      key: "attendby",
      render: (text: any, record: any) => {
        return {

          children: <div>{text}</div>
        };
      },
    },
  ];
  
  const getVechileTypeList = (cityId: any) => {
    const options = {
        params: {
            cityid: cityId,
        },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + cityId;
    axiosGet(uri).then((resp: any) => {
        setVehicleTypeList(resp);
    });
};
const getBranchDetails = (id: any = null) => {
  setIsLoading(true);
  axiosGet(UrlConstants.getBranchList).then((resp: any) => {
    setBranchList(resp);
  }).catch(function (error: any) {
    if (error.response) {
      console.log(error.response.data, error.response.status);
    }
  });
};


  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {

      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      DateSearch(fromdate, todate);
    }
    catch (ex) {
      console.log(ex);
    }

  }
  const ontodatechange = (value: any) => {
    settodate(value);
    try {

      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      DateSearch(startdate, todate);
    }
    catch (ex) {
      console.log(ex);
    }

  }


  const DateSearch = (fromdate: any, todate: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        type: "All",
        status: "All"
      },
    };
    axiosGet(UrlConstants.getattachment, options).then((resp: any) => {
      resp.data.forEach((e: any) => {
        e.key = e.enquiry_id;
      });
      setIsLoading(false);
      setDriverAttendanceList(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setTotalRecordCount(resp.data.length);
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
        setDriverAttendanceList([]);
        setcopyCurrentBookingGridData([]);
      }
    });
  };

  const showaddattachment = () => {
    setApproveDriverAttendanceTitle("Add Enquiry");
    setApproveDriverAttendanceVisible(true);
    setEditAttachmentEnquiry(false);
  };
  const showeditattachment = () => {
    setApproveDriverAttendanceTitle("Edit Enquiry");
    setEditAttachmentEnquiry(true);
    setApproveDriverAttendanceVisible(true);
  };

  const hideApproveDriverAttendanceFormModal = (isVisible: any) => {
    setApproveDriverAttendanceVisible(isVisible);
    adayclick();
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    if (rowInfo.length > 0) {
      if (rowInfo[0].statuscode == 16 || rowInfo[0].statuscode == 2 || rowInfo[0].statuscode == 3 || rowInfo[0].statuscode == 4 || rowInfo[0].statuscode == 11) {

        setisblocked(true);
      }
      else
        setisblocked(false);
      if (rowInfo[0].statuscode == 16 || rowInfo[0].statuscode == 2 || rowInfo[0].statuscode == 3 || rowInfo[0].statuscode == 4 || rowInfo[0].statuscode == 16 || rowInfo[0].statuscode == 10) {

        setnologout(true);
      }
      else
        setnologout(false);
      setSelectedRowData(rowInfo[0]);
    }
  };
  const adayclick = (event: any = null) => {
    setIsButtonClicked('Today');
    var first = moment();
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    DateSearch(fromdate, todate);
  };

  const twodayclick = () => {
    setIsButtonClicked('Yesday');
    var first = moment().add('days', -1);
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    DateSearch(fromdate, todate);
  };

  const monthclick = () => {
    setIsButtonClicked('Month');
    var first = moment();
    var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + '01';
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    DateSearch(fromdate, todate);
  };

  const weekclick = () => {
    setIsButtonClicked('Week');
    var first = moment().add('days', -(moment().day()))
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));

    DateSearch(fromdate, todate);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = driverAttendanceList.filter((value: any) => {
      if (value.name === undefined || value.name === null)
        value.name = '';
      if (value.number === undefined || value.number === null)
        value.number = '';
      if (value.area === undefined || value.area === null)
        value.area = '';
      if (value.city === undefined || value.city === null)
        value.city = '';
      if (value.license === undefined || value.license === null)
        value.license = '';
      if (value.registration === undefined || value.registration === null)
        value.registration = '';
      if (value.model === undefined || value.model === null)
        value.model = '';
      if (value.year === undefined || value.year === null)
        value.year = '';
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = '';
      if (value.attended_by === undefined || value.attended_by === null)
        value.attended_by = '';

      return (
         value.name.toString().toLowerCase().includes(searchText.toLowerCase())||
         ((roleid.toString() == '1' || roleid.toString() == '2') ?
        value.number.toString().toLowerCase().includes(searchText.toLowerCase()): null) ||
         value.area.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.city.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.license.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.registration.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.model.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.year.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.remarks.toString().toLowerCase().includes(searchText.toLowerCase()) ||
         value.attended_by.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  useEffect(() => {
    adayclick();
    getBranchDetails();
    getVechileTypeList(branchid);
  }, []);

  const onSearch = (value:any) => {
    setsearchText(value);
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        type: "All",
        status: "All",
        searchtext: value
      },
    };
    axiosGet(UrlConstants.getattachment, options).then((resp: any) => {
      resp.forEach((e: any) => {
        e.key = e.enquiry_id;
      });
      setIsLoading(false);
      setDriverAttendanceList(resp);
      setcopyCurrentBookingGridData(resp);
      setTotalRecordCount(resp.length);
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
        setDriverAttendanceList([]);
        setcopyCurrentBookingGridData([]);
      }
    });
  }

  return (
    <div >
      <Row style={{ display: "flex" }}>
        <Col span={2}>
          <Button
            className="driver-attendance-btn-approve"

            onClick={showaddattachment}
          >
            Add
          </Button>
        </Col>
        {(roleid == '1' || roleid == '2') &&
        <>
        <Col span={2} offset={1}>
        <Button
        className="driver-attendance-btn-block"
          disabled={!isRowSelected}
            onClick={showeditattachment}
          >
            Edit
          </Button>
</Col>
        <Col span={2} offset={1}>
          <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
            onClick={adayclick}
          >
            Today
          </Button>
        </Col>

        <Col span={2} >
          <Button className={ButtonClicked === 'Yesday' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
            onClick={twodayclick}
          >
            Prev
          </Button>
        </Col>
        <Col span={2} >
          <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
            onClick={weekclick}
          >
            Week
          </Button>
        </Col>
        <Col span={2} >
          <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
            onClick={monthclick}
          >
            Month
          </Button>
        </Col>


        <Col>


          <span style={{ fontSize: 'medium' }}> From:</span>

          <DatePicker

            value={fromdate}
            format={dateFormat}

            disabledDate={disabledDate}
            onChange={onfromdatechange} />
          <span style={{ fontSize: 'medium' }}> To:</span>


          <DatePicker 
            value={todate}
            format={dateFormat}
            disabledDate={disabledDate}
            onChange={ontodatechange} />

        </Col>
        </>
}
  </Row>
  <Row style={{ paddingBottom: 7 }}>
        <Col span={4} className="driver-attendance-total-count">
          <h2>Total Enquiry: {totalRecordCount}</h2>
        </Col>
        
        <Col span={4} offset={16} >
          {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
          <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
        </Col>
      </Row>
      <Row className="driver-attendance-table-row" style={{ display: "unset" }}>

        <Spin tip="Loading..." spinning={isLoading}>

          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={true}
            selectionType="radio"

          ></AppTable>

        </Spin>
        <AddAttachmentenquiry
          title={approveDriverAttendanceTitle}
          visible={approveDriverAttendanceVisible}
          hideModal={hideApproveDriverAttendanceFormModal}
          width={700}
          isedit={editAttachmentEnquiry}
         selectedRowData={selectedRowData}

        />


      </Row>
    </div>
  );
};



export default Attachmentenquiry;
