
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, DatePicker, Input , Spin} from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddUser from "../../components/AppModal/Adduser";
import DeleteConfirmModal from "../../components/AppModal/DeleteConfirmModal";

const { Search } = Input;

const UserMaster = () => {
  let history: any = useHistory();

  const [userlist, setuserlist] = useState([]);
  const [status, setstatus] = useState("Block");
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [addusermodal, setaddusermodal]: any =useState(false);
  const [editusermodal, seteditusermodal]: any =useState(false);
  const [adduserTittle, setadduserTittle] = useState("Add User");
  const [selectedValues, setselectedValues]: any = useState([]);
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [deleteusertitle, setDeleteusertitle] = useState('User');


  const columns = [
    {
      title: "Joining Date",
      dataIndex: "doj",
      key: "Date",
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
    
    },
    {
      title: "Name",
      dataIndex: "name",
      
      key: "Name",
      ellipsis: true,
      
    },
    {
      title: "Employee ID",
      dataIndex: "LoginID",
      key: "Employee ID",
     
    ellipsis: true,
      },
    {
      title: "Username",
      dataIndex: "UserName",
      key: "Name",
      ellipsis: true,
      
    },
    {
      title: "Password",
      dataIndex: "Passwd",
      key: "Password",
      ellipsis: true,
     
    },
    {
        title: "Role",
        dataIndex: "role",
        key: "Role",
       ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "flag",
        key: "Status",
         ellipsis: true,
      },
     

  ];

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    if(rowInfo.length>0)
    {
      if(rowInfo[0].flag=="Active")
      setstatus("Block");
      else
      setstatus("Unblock");
    }
  };
  const Blockuser = () => {
    let statusb;
    if(selectedRowData[0].flag=="Active")
      statusb="0";
      else
      statusb="1";
    const options: any = {
      data: {
        LoginID:selectedRowData[0].LoginID,
        flag:statusb
      },
    };
    console.log(options);
    axiosPost(UrlConstants.blockuser, options)
    .then((resp: any) => {
      console.log(resp);
      const notificationInfo: any = {
        header: "User "+status+"ed Successfully",
        description: "User : " + selectedRowData[0].name + " " +status+"ed",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: status+" User Failed",
        description: "Error Occurred while "+status+" User",
      };
      openNotificationWithIcon("Error", notificationInfo);
    });
  }
  
  const showaddusermodal=()=>{
    setaddusermodal(true);
    setadduserTittle("Add User");
    seteditusermodal(false);
  }
  const showeditusermodal=()=>{
    setaddusermodal(true);
    setadduserTittle("Edit User");
    let copyselectedvalues={... selectedRowData[0]}
    setselectedValues(copyselectedvalues);
    seteditusermodal(true);
  }
  const hideModal=(isVisible: any)=>{
    //setsearchText('');
    setaddusermodal(isVisible);
    refresh(pagination, SearchText);
  }
 
  useEffect(() => {
    getuserlist(pagination, SearchText);
   refresh(pagination, SearchText);
  }, []);


  const refresh = (pagination:any, SearchText:any) => {
    const options = {
      params: {
        branchid: branchid,
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    axiosGet(UrlConstants.getuserslist, options).then((resp: any) => {
      resp.data?.forEach((e: any) => {
        e.key = e.LoginID.toString();
        e.role=e.roledetail[0].role;
       if( e.flag=="1")
       e.flag="Active";
       else
       e.flag="InActive";
      });
      setuserlist(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
      }).catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getuserlist = (pagination:any, SearchText:any) => {
    const options: any = {
      params: {
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    axiosGet(UrlConstants.getuserslist, options).then((resp: any) => {
        resp.data?.forEach((e: any) => {
        e.key = e.LoginID.toString();
        e.role=e.roledetail[0].role;
       if( e.flag=="1")
       e.flag="Active";
       else
       e.flag="InActive";
      });
      setuserlist(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      console.log(resp.data);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    });
  };

  const deleteuser = () => {
    const options: any = {
      data: {
        LoginID:selectedRowData[0].LoginID,
      },
    };
    console.log(options);
    
    /* axiosPost(UrlConstants.deleteuser, options)
    .then((resp: any) => {
      console.log(resp);
      refresh(pagination);
      const notificationInfo: any = {
        header: "User Deleted Successfully",
        description: "User " + selectedRowData[0].name + " Deleted",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: "Deleting User Failed",
        description: "Error Occurred while Deleting User",
      };
      openNotificationWithIcon("Error", notificationInfo);
    }); */
    
  }
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
    refresh(pagination, SearchText);
  };

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };


  const globalSearch = (searchText: any) => {
    let filteredData = userlist.filter((value: any) => {
    if (value.name === undefined || value.name === null)
      value.name = '';
    if (value.LoginID === undefined || value.LoginID === null)
      value.LoginID = '';
    if (value.UserName === undefined || value.UserName === null)
      value.UserName = '';
    if (value.Passwd === undefined || value.Passwd === null)
      value.Passwd = '';
    if (value.role === undefined || value.role === null)
      value.role = '';
  /*   if (value.flag === undefined || value.flag === null)
      value.flag = '';
    if (value.totalkm === undefined || value.totalkm === null)
      value.totalkm = '';
    if (value.efficitiveKM === undefined || value.efficitiveKM === null)
      value.efficitiveKM = '';
    if (value.diffKM === undefined || value.diffKM === null)
      value.diffKM = ''; */

      return (

        value.name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.LoginID
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.UserName
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.Passwd
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.role
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) /* ||
        value.flag
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) */


      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
    setPagination(pagination);
    getuserlist(pagination, SearchText);
    console.log(pagination); 
  } 
  const showConfirmDeleteModal = (tariffType:any) => {
    setConfirmDeleteModalVisible(true);
  };
  const hideConfirmDeleteModal = (isVisible: any) => {
    setConfirmDeleteModalVisible(isVisible);
    refresh(pagination, SearchText);
  };
  const confirmDelete = (option:any) => {
    if(option == true){
      deleteuser();
    }
  }
  const onSearch = (value:any) => {
    setsearchText(value);
    getuserlist(pagination, value);
  }

  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
          <Button onClick={showaddusermodal}  >
           
             Add
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
             
              disabled={!isRowSelected}
              onClick={showeditusermodal} 
            >
             Edit
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
            disabled={!isRowSelected}
            onClick={showConfirmDeleteModal}
            >
         Delete
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button onClick={Blockuser}
            disabled={!isRowSelected}
            >
        {status}
            </Button>
          </Col>
          <Col span={4} offset={9}>
            {/* <Input placeholder="Search Text" value={SearchText} onChange={onSearchchange} /> */}
            <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
      </div>
      <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          enableRadioButton={true}
          onRadioButtonChange={onGridRowSelect}
          enablePagination={pagination}
          selectionType="radio"
          onchange={onPaginationchange}
        ></AppTable>
        </Spin>
        <AddUser
          title={adduserTittle}
          visible={addusermodal}
          selectedRowData={selectedValues}
          hideModal={hideModal}
          isedit={editusermodal}
        >
        </AddUser>
        <DeleteConfirmModal
          title={deleteusertitle}
          visible={confirmDeleteModalVisible}
          hideModal={hideConfirmDeleteModal}
          selectedRowData={selectedRowData}
          confirmDelete={confirmDelete}
        />
      </div>
      
    </div>
  );
};

export default UserMaster;

