import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, notification } from "antd";
import "./AppModal.scss";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const DeleteConfirmModal = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData) 
      setSelectedRowData(props.selectedRowData);
  });

  const handleCancel = () => {
    props.confirmDelete(false);
    props.hideModal(false);
  };
  const handleDelete = () => {
    props.confirmDelete(true);
    props.hideModal(false);
  }

  return (
    <>
      <Modal
        className="custom-modal-form confirm-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={[]}
        width={360}
        centered
      >
        <p>Are you sure you want to delete the selected {props.title}?</p>
          <Button
            className="driver-attendance-btn-onduty modal-button-width"
            style={{ marginRight: "10px" }}
            htmlType="submit"
            onClick={handleDelete}
          >
            Yes
          </Button>
          <Button
            className="driver-attendance-btn-blocked modal-button-width"
            htmlType="submit"
            onClick={handleCancel}
          >
            No
          </Button>
      </Modal>
    </>
  );
};

export default DeleteConfirmModal;
