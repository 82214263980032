import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const { Search } = Input;

const Feedback = () => {
  let history: any = useHistory();

  const [isRowSelected, setIsRowSelected] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  

  
  const columns = [
    {
      title: "Complaint Id",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      sorter: (a: any, b: any) => a.id - b.id,
    //   render: (text: any, record: any) => {
    //     return (
    //       <div>
    //         <Link
    //           style={{ marginRight: 16 }}
    //           to={{
    //             pathname: "/booking/editBooking",
    //             hash: new Date().getTime().toString(),
    //             state: { details: record },
    //           }}
    //         >
    //           <EditOutlined className="edit-booking" />
    //         </Link>
    //         <span>{text}</span>
    //       </div>
    //     );
    //   },
    },
    {
      title: "Booking Id",
      dataIndex: "Booking Id",
      key: "Booking Id",
      fixed: "left",
      sorter: (a: any, b: any) => a.name - b.name,
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
     
      sorter: (a: any, b: any) => a.name - b.name,
      ellipsis: true,
    },
    {
      title: "Mobile/Alternative Number",
      dataIndex: "mobileno",
     
      key: "Mobile",
      ellipsis: true,
     
    },
    {
        title: "Cab Id",
        dataIndex: "Cab Id",
       
        key: "Cab Id",
        ellipsis: true,
        
      },
      {
        title: "Distance",
        dataIndex: "Distance",
        
        key: "Distance",
        ellipsis: true,
        
      },
      {
        title: "Duration",
        dataIndex: "Duration",
       
        key: "Duration",
        ellipsis: true,
        
      },
      {
        title: "Fare",
        dataIndex: "Fare",
      
        key: "Fare",
        ellipsis: true,
        
      },
      {
        title: "City",
        dataIndex: "City",
       
        key: "City",
        ellipsis: true,
        
      },
      


    {
      title: "Date/Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      ellipsis: true,
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      title: "From",
      dataIndex: "fromloc",
      key: "fromloc",
      ellipsis: true,
      sorter: (a: any, b: any) => a.fromloc - b.fromloc,
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: "To ",
      dataIndex: "toloc",
      key: "toloc",
      ellipsis: true,
      sorter: (a: any, b: any) => a.toloc - b.toloc,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      ellipsis: true,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "vehicletype",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.vechicledetail[0].Vechicle;
      // }
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "faretype",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.ttdetails[0].name;
      // },
    },
    {
      title: "Credit",
      dataIndex: "iscredit",
      key: "iscredit",
      render: (text: any, record: any) => {
        return (
          <div>
            <Checkbox defaultChecked={text} disabled />
          </div>
        );
      },
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "goods",
      ellipsis: true,
    },
    {
        title: "OTP",
        dataIndex: "otp",
        key: "otp",
        ellipsis: true,
      },
  
    {
      title: "Booked by",
      dataIndex: "bookedby",
      key: "bookedby",
      ellipsis: true,
    },
    {
      title: "Feedback",
      dataIndex: "Feedback",
      key: "feedback",
      ellipsis: true,
     // sorter: (a: any, b: any) => a.cityid - b.cityid,
    },
    {
        title: "Solution",
        dataIndex: "Solution",
        key: "Solution",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        ellipsis: true,
      },
  ];

 
  

 

 


  
  
 
  useEffect(() => {
   
  }, []);

  const onSearch = (value:any) => {
    console.log(value);
    setsearchText(value);
    setIsLoading(true);
    const options: any = {
      params: {
        searchtext: value
      },
    };
    console.log(options);

    axiosGet(UrlConstants.getCreditReport, options)
      .then((resp: any) => {
        resp.forEach((e: any) => {
          e.key = e.id;
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp);
        setcopyCurrentBookingGridData(resp);
        console.log(resp);
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      });
  }

 
  



  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
          <Button className="booking-btn-preassign"
            >
              Add Feedback
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-assign"
              
             
            >
             Pending
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-rac"
             
            
            >
              Completed
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
             
             
            >
              New
            </Button>
          </Col>
          <Col span={4} offset={9}>
          <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
        </Col>
        </Row>
      </div>
      <div>
        <AppTable
          columns={columns}
         
          enableRadioButton={true}
         
          enablePagination={true}
          selectionType="radio"
        ></AppTable>
      </div>
     
     

      
    </div>
  );
};

export default Feedback;
