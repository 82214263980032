import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";

const AppModalPreAssign = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});

  useEffect(() => {
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
  }, [props.selectedRowData]);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  return (
    <div>
     
      <Modal
        destroyOnClose={true}
        className="custom-modal-grid"
        width={props.width || 520}
        title={props.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row className="custom-modal-grid-booking-info">
          <Col span={8}>Booking Id: {selectedRowInfo.id}</Col>
          <Col span={8}>From: {selectedRowInfo.fromloc}</Col>
          <Col span={8}>To: {selectedRowInfo.toloc}</Col>
        </Row>
        <Row className="custom-modal-grid-booking-grid">
          <AppTable
            columns={props.modalGridColumns}
            data={props.modalGridData}
          ></AppTable>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModalPreAssign;
