import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Select } from "antd";
import AppTable from "../AppTable/AppTable";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
const { Option } = Select;
const AppModalDriverAttendanceAppManager = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [availableCabList, setAvailableList] = useState([]);

  useEffect(() => {
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
  }, [props.selectedRowData]);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    axiosGet(UrlConstants.getAvailableCabs).then((resp: any) => {
      setAvailableList(resp);
    });
  }, []);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const onGridRowSelect = (rowInfo: any) => {};
  const avaiableCabGridColumns = [
    {
      title: "Cab ID",
      dataIndex: "_id",
      key: "_id",
      ellipsis: true,
      //render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Driver User Id",
      dataIndex: "driverId",
      key: "driverId",
      ellipsis: true,
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.mobileno[0];
      // },
      //render: (text: any) => <a>{text}</a>,
    },
    {
      title: "IMEI No",
      dataIndex: "imeino",
      key: "imeino",
      ellipsis: true,
      // render: (text: any, record: any) => (
      //   <>
      //     <Select
      //       defaultValue="Pending payment"
      //       style={{ width: 120 }}
      //       onChange={() => {}}
      //     >
      //       <Option value={1}>Pending payment</Option>
      //       <Option value={2}>Uniform</Option>
      //       <Option value={3}>Sticker</Option>
      //       <Option value={4}>Not taking trip</Option>
      //       <Option value={5}>Misbehaving</Option>
      //       <Option value={6}>Time managment</Option>
      //       <Option value={7}>Taking other trips</Option>
      //       <Option value={8}>Other reason</Option>
      //       <Option value={9}>Leave without information</Option>
      //       <Option value={10}>Trip cancel driver issues</Option>
      //     </Select>
      //   </>
      // ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return new Date(record.pickup).toLocaleDateString();
      // },
      //render: (text: any) => <a>{text}</a>,
    },
  ];

  return (
    <div>
      {/* <Button type="primary" onClick={() => showModal()}>
          Open Modal
        </Button> */}
      <Modal
        destroyOnClose={true}
        className="custom-modal-grid"
        width={props.width || 520}
        title={props.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Row className="custom-modal-grid-booking-info">
          <Col span={4}>
            <div className="driver-attendance-appmanager-active">
              <span>Active</span>
            </div>
          </Col>
          <Col span={4} offset={2}>
            <div className="driver-attendance-appmanager-inactive">
              <span>Inactive</span>
            </div>
          </Col>
        </Row>
        <Row className="custom-modal-grid-booking-grid block-driver-attendance">
          <AppTable
            columns={avaiableCabGridColumns}
            data={availableCabList}
            enablePagination={true}
          ></AppTable>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModalDriverAttendanceAppManager;
