import axios from "axios";
export default function axiosGet(
  url: any,
  params: any = null
): Promise<any> | any {
  return axios.get(url, params).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    } else {
      return [];
    }
  });
}

export const axiosPost = (url: any, params: any): Promise<any> | any => {
  return axios.post(url,  params.data).then((res) => {
    if (res.status === 200) {
      return res.data.data;
    } else {
      return [];
    }
  });
};
