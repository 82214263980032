import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, Input, notification } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";
import { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const AppModuleCabAtdncEditReport = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [selectedValues, setSelectedValues]: any = useState({});

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    if (props.selectedRowData) {
      setSelectedRowInfo(props.selectedRowData);
    }
    setVisible(props.visible);
  }, [props.selectedRowData, props.visible]);

  const OnUpdate = (e: any) => {
    updateOdometerReadings();
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const onStartkmChange = (e: any) => {
    let selectedValuesCopy = { ...selectedRowInfo };
    selectedValuesCopy.startkm = e.target.value;
    setSelectedRowInfo(selectedValuesCopy);
  };
  const onClosekmChange = (e: any) => {
    let selectedValuesCopy = { ...selectedRowInfo };
    selectedValuesCopy.close_km = e.target.value;
    setSelectedRowInfo(selectedValuesCopy);
  };
  const updateOdometerReadings = () => {
    const options: any = {
      data: {
        cabid: selectedRowInfo.Cab_id,
        closingKM: selectedRowInfo.close_km || "",
        AID: selectedRowInfo.AID,
      },
    };
    axiosPost(UrlConstants.updateodometer, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Odometer Readings Updated Successfully",
          //description: "MetreFare for " + selectedValues.GoodsName + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Odometer Readings Updating Failed",
          description: "Error Occurred while Updating Odometer Readings",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        className="custom-modal-cancel-form"
        width={550}
        title="Edit Odometer Readings"
        visible={visible}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form name="nest-messages" {...formItemLayout}>
          <Row>
            <Col span={24}>
              {/* Cab Id: {selectedRowInfo.Cab_id} */}
              <Form.Item name={["Cab_id", "Cab_id"]} label="Cab ID" rules={[]}>
                <span>{selectedRowInfo.Cab_id}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Form.Item
                name={["startkm", "startkm"]}
                label="Previous Opening KM"
                rules={[]}
              >
                <Input
                  defaultValue={selectedRowInfo.startkm}
                  value={selectedRowInfo.startkm}
                  type="text"
                  onChange={onStartkmChange}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Form.Item
                name={["close_km", "close_km"]}
                label="Previous Closing KM"
                rules={[]}
              >
                <Input
                  defaultValue={selectedRowInfo.close_km}
                  value={selectedRowInfo.close_km}
                  type="text"
                  onChange={onClosekmChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col offset={7}>
            <Button onClick={OnUpdate}>Update</Button>
          </Col>

          <Col offset={2}>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModuleCabAtdncEditReport;
