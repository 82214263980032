import React, { useState, useEffect } from "react";
import {
  Tag,
  Space,
  Row,
  Col,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Select,
  Spin,
  Table,
} from "antd";
import AppTable from "../../components/AppTable/AppTable";
import AddPaymentsForVehicle from "../../components/AppModal/AddPaymentsForVehicle";
import Vehicleaccountmoreinfo from "../../components/AppModal/Vehiclepaymentmoreinfo";
import moment from "moment";
import "./Finance.scss";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { CSVLink } from "react-csv";

const { Option } = Select;
const { Search } = Input;

const Vehicleaccount = () => {
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [vehicleaccountmodalVisible, setvehicleaccountmodalVisible] =
    useState(false);
  const [vehiclemoremodalVisible, setvehiclemoreinfomodalVisible] =
    useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [vehicleaccounts, setvehicleaccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [selectedValues, setSelectedValues]: any = useState({});
  const [cabIdsList, setcabIdsList] = useState([]);
  const [searchCabIdsList, setSearchCabIdsList] = useState([]);
  const [SearchText, setsearchText]: any = useState("");
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [noofaccountspaid, setNoofAccountsPaid] = useState(0);
  const [noofaccountsunpaid, setNoofAccountsUnpaid] = useState(0);
  //const [limit, setLimit] = useState(10);
  //const [skip, setSkip] = useState(0);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });
  const [flag, setFlag] = useState(false);
  const [comissionamount, setComissionamount] = useState(0);
  const [totalamount, setTotalamount] = useState(0);
  const [tripscount, setTripscount] = useState(0);

  const totalAmountTitle = Math.round(
    Math.round(
      (copycurrentBookingGridData.reduce(function (cnt, o: any) {
        return cnt + Number(o.totalamount);
      }, 0) +
        Number.EPSILON) *
        100
    ) / 100
  );
  const totalComissionamountTitle = Math.round(comissionamount);

  const columns = [
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Booking ID",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Commission",
      children: [
        {
          title: totalComissionamountTitle,
          dataIndex: "commision",
          key: "Booking ID",
          render: (text: any) => {
            if (text != undefined) {
              if (text != null && text != undefined && text != "" && text != 0)
                return Math.round(text);
              else return text;
            }
          },
        },
      ],
    },
    {
      title: "Services Charges",
      dataIndex: "servicecharge",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Total Amount",
      children: [
        {
          title: totalAmountTitle,
          dataIndex: "totalamount",
          key: "Booking ID",
          render: (text: any) => {
            if (text != undefined) {
              if (text != null && text != undefined && text != "" && text != 0)
                return Math.round(text);
              else return text;
            }
          },
        },
      ],
    },
    {
      title: "Credit Amount",
      dataIndex: "creditamount",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },

    {
      title: "Add payments",
      dataIndex: "bookedby",
      key: "Booked by1",
      render: (text: any, record: any) => {
        return (
          <div>
            <span style={{ width: 1, color: "black" }}>
              {/* <EditOutlined className="edit-booking" /> */}
              <Button
                style={{
                  backgroundColor: "#B0E0E6",
                  color: "black",
                  borderColor: "black",
                }}
                onClick={(e) => vehicleaccountmodal(e, record)}
              >
                ADD
              </Button>
            </span>
          </div>
        );
      },
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Amount Balance",
      dataIndex: "amountbalance",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Booking ID",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "Booking ID",
    },
    {
      title: "More Info",
      dataIndex: "bookedby",
      key: "Booked by1",
      render: (text: any, record: any) => {
        return (
          <div>
            <span style={{ width: 1, color: "black" }}>
              {/* <EditOutlined className="edit-booking" /> */}
              <Button
                style={{
                  backgroundColor: "#B0E0E6",
                  color: "black",
                  borderColor: "black",
                }}
                onClick={(e) => vehiclemoreinfomodal(e, record)}
              >
                More Info
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate, pagination);
      setFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate, pagination);
      setFlag(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const adayclick = (event: any = null) => {
    setIsButtonClicked("Today");
    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const twodayclick = () => {
    setIsButtonClicked("Yesday");
    var first = moment().add("days", -1);
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const monthclick = () => {
    setIsButtonClicked("Month");
    var first = moment();
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + "01";
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };

  const weekclick = () => {
    setIsButtonClicked("Week");
    var first = moment().add("days", -moment().day());
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    console.log(fromdate);
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };
  const onTableSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
  };
  const DateSearch = (
    fromdate: any,
    todate: any,
    pagination: any,
    SearchText: any
  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        filtervalue: SearchText,
      },
    };
    console.log(options);

    axiosGet(UrlConstants.getvehicleaccountnew, options)
      .then((resp: any) => {
        console.log(resp);
        /*  resp.data.forEach((e: any) => {
          e.key = e.cabid;
        });
        setIsLoading(false);
        statusCount(resp.data);
        setTotalCount(resp.tablelength);
        setvehicleaccounts(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setPagination({
          ...pagination,
          total: resp.tablelength
        }); */
        resp.data.forEach((e: any) => {
          e.key = e.cabid;
        });
        setIsLoading(false);
        statusCount(resp.data);
        setTotalCount(resp.tablelength);
        setvehicleaccounts(resp.data);
        setComissionamount(resp.comissionamount);
        setTotalamount(resp.totalamount);
        setTripscount(resp.tripscount);
        setcopyCurrentBookingGridData(resp.data);

        console.log(resp.tablelength);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const statusCount = (resp: any) => {
    let noofaccountspaid = 0;
    let noofaccountsunpaid = 0;
    resp.map((value: any) => {
      if (value.status == "Paid") {
        noofaccountspaid++;
      } else if (value.status == "Unpaid") noofaccountsunpaid++;
    });
    setNoofAccountsPaid(noofaccountspaid);
    setNoofAccountsUnpaid(noofaccountsunpaid);
  };

  const showPaid = () => {
    let filteredData = vehicleaccounts.filter(
      (value: any) => value.status == "Paid"
    );
    setcopyCurrentBookingGridData(filteredData);
  };
  const showUnpaid = () => {
    let filteredData = vehicleaccounts.filter(
      (value: any) => value.status == "Unpaid"
    );
    setcopyCurrentBookingGridData(filteredData);
  };
  const showAll = () => {
    setcopyCurrentBookingGridData(vehicleaccounts);
  };
  useEffect(() => {
    getcabids();
    adayclick();
  }, []);
  const vehicleaccountmodal = (e: any, data: any) => {
    setvehicleaccountmodalVisible(true);
    setSelectedRowData(data);
  };
  const hideModalvehicleaccountVisible = (isVisible: any) => {
    setvehicleaccountmodalVisible(isVisible);
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    setsearchText("");
  };
  const vehiclemoreinfomodal = (e: any, data: any) => {
    setvehiclemoreinfomodalVisible(true);
    let dates = {
      fromdate: fromdate,
      todate: todate,
    };
    let copyvalues = {
      ...data,
      ...dates,
    };
    setSelectedRowData(copyvalues);
  };
  const hidevehiclemoreinfoVisible = (isVisible: any) => {
    setvehiclemoreinfomodalVisible(isVisible);
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      SearchText
    );
    setsearchText("");
  };

  const onCabIdChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.cabid = value;
    setSelectedValues(copyselectedvalues);
  };

  const getcabids = () => {
    const uri = UrlConstants.getcabidlist + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      setcabIdsList(resp);
    });
  };
  const onCabIdSearch = (value: any) => {
    if (value) {
      var filtered = cabIdsList.filter((x: any) =>
        String(x.cab_id.toLowerCase()).includes(value.toLowerCase())
      );
      //console.log(searchedList,filtered);
      setSearchCabIdsList(filtered);
    } else {
      setSearchCabIdsList([]);
    }
  };
  /* const onSearch = () => {
    DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'), pagination);
  } */
  const onClear = () => {
    selectedValues.cabid = null;
    setSearchCabIdsList([]);
    //DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'), pagination);
    adayclick();
  };
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };

  const globalSearch = (searchText: any) => {
    let filteredData = vehicleaccounts.filter((value: any) => {
      if (value.cabid === undefined || value.cabid === null) value.cabid = "";
      if (value.amount === undefined || value.amount === null)
        value.amount = "";
      if (value.commision === undefined || value.commision === null)
        value.commision = "";
      if (value.servicecharge === undefined || value.servicecharge === null)
        value.servicecharge = "";
      if (value.totalamount === undefined || value.totalamount === null)
        value.totalamount = "";
      if (value.paid === undefined || value.paid === null) value.paid = "";
      if (value.amountbalance === undefined || value.amountbalance === null)
        value.amountbalance = "";
      /*  if (value.status === undefined || value.status === null)
        value.status = ''; */
      if (value.remarks === undefined || value.remarks === null)
        value.remarks = "";
      /*  if (value.bookedby === undefined || value.bookedby === null)
        value.bookedby = ''; */

      return (
        value.cabid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.amount
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.commision
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.servicecharge
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.totalamount
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.paid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.amountbalance
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        // value.status.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.remarks
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) /*||
        value.bookedby.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.distance.toString().toLowerCase().includes(searchText.toLowerCase()) */
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };

  const columnsToExport = columns.filter(
    (col: any) => col.title != "More Info" && col.title != "Add payments"
  );
  const headersToExport = columnsToExport.map((column: any) =>
    column.title == "Amount"
      ? { label: column.title, key: "amount" }
      : column.title == "Commission"
      ? { label: column.title, key: "commision" }
      : column.title == "Services Charges"
      ? { label: column.title, key: "servicecharge" }
      : column.title == "Total Amount"
      ? { label: column.title, key: "totalamount" }
      : column.title == "Credit Amount"
      ? { label: column.title, key: "creditamount" }
      : column.title == "Paid"
      ? { label: column.title, key: "paid" }
      : column.title == "Amount Balance"
      ? { label: column.title, key: "amountbalance" }
      : { label: column.title, key: column.dataIndex }
  );

  const dataToExport = vehicleaccounts.map((value: any) => ({
    ...value,
    amount:
      value.amount != null && value.amount != undefined && value.amount != ""
        ? Math.round(value.amount)
        : value.amount,
    commision:
      value.commision != null &&
      value.commision != undefined &&
      value.commision != ""
        ? Math.round(value.commision)
        : value.commision,
    servicecharge:
      value.servicecharge != null &&
      value.servicecharge != undefined &&
      value.servicecharge != ""
        ? Math.round(value.servicecharge)
        : value.servicecharge,
    totalamount:
      value.totalamount != null &&
      value.totalamount != undefined &&
      value.totalamount != ""
        ? Math.round(value.totalamount)
        : value.totalamount,
    creditamount:
      value.creditamount != null &&
      value.creditamount != undefined &&
      value.creditamount != ""
        ? Math.round(value.creditamount)
        : value.creditamount,
    paid:
      value.paid != null && value.paid != undefined && value.paid != ""
        ? Math.round(value.paid)
        : value.paid,
    amountbalance:
      value.amountbalance != null &&
      value.amountbalance != undefined &&
      value.amountbalance != ""
        ? Math.round(value.amountbalance)
        : value.amountbalance,
  }));

  // const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
  //   /* setLimit(pagination.pageSize);
  //   setSkip(pagination.current * 10);
  //   setTableLength(pagination.total); */
  //   setPagination(pagination);
  //   onTableSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'), pagination, SearchText);
  //   console.log(pagination);
  // }
  const onSearch = (value: any) => {
    console.log(value);
    setsearchText(value);
    onTableSearch(
      fromdate.format("YYYY/MM/DD"),
      todate.format("YYYY/MM/DD"),
      pagination,
      value
    );
  };

  return (
    <div>
      <Row style={{ paddingBottom: 10 }}>
        {" "}
        <Col>
          {" "}
          <span
            style={{
              fontSize: "large",
              fontWeight: "bold",
              fontStyle: "Times New Roman",
            }}
          >
            {" "}
            VEHICLE ACCOUNTS
          </span>
        </Col>
        <Col offset={2}>
          <span
            style={{
              fontSize: "large",
              fontWeight: "bold",
              fontStyle: "Times New Roman",
            }}
          >
            {" "}
            From : {moment(fromdate).format("DD.MM.YYYY")} To :{" "}
            {flag
              ? moment().format("DD.MM.YYYY")
              : moment(todate).format("DD.MM.YYYY")}{" "}
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <Button
            className={
              ButtonClicked === "Today"
                ? "booking-btn-buttonTrue"
                : "booking-btn-buttonFalse"
            }
            onClick={adayclick}
          >
            Today
          </Button>
        </Col>
        <Col>
          <span style={{ fontSize: "medium" }}> From:</span>

          <DatePicker
            value={fromdate}
            format={dateFormat}
            disabledDate={disabledFromDate}
            onChange={onfromdatechange}
          />
          <span style={{ fontSize: "medium" }}> To:</span>

          <DatePicker
            value={flag ? moment() : todate}
            //value={todate}
            format={dateFormat}
            disabledDate={disabledDate}
            onChange={ontodatechange}
          />
        </Col>

        <Col span={2} offset={1}>
          <Select
            placeholder="Cab Id"
            style={{ width: 100 }}
            showSearch
            optionFilterProp="children"
            value={selectedValues.cabid}
            onChange={onCabIdChange}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showArrow={false}
            onSearch={onCabIdSearch}
            notFoundContent={null}
          >
            {searchCabIdsList.map((id_: any) => (
              <Option key={id_.cab_id} value={id_.cab_id}>
                {id_.cab_id}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={2} offset={1}>
          <Button
            className="finance-btn-blockSearch"
            onClick={() =>
              onTableSearch(fromdate, todate, pagination, SearchText)
            }
            disabled={moment(fromdate).isAfter(moment(todate))}
          >
            Search
          </Button>
        </Col>
        <Col span={2}>
          <Button
            htmlType="button"
            style={{ marginLeft: 10 }}
            className="finance-btn-blockClear"
            onClick={onClear}
          >
            Clear
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button className="finance-btn-blocksms">Send sms</Button>
        </Col>
        <Col span={2}>
          <CSVLink
            filename={"VehicleAccount.csv"}
            headers={headersToExport}
            data={dataToExport}
          >
            <Button
              style={{ marginLeft: 10 }}
              className="finance-btn-blockExport"
            >
              Export
            </Button>
          </CSVLink>
        </Col>
      </Row>

      <Row>
        <Col span={2}>
          <Button className="finance-btn-blocksms" onClick={showAll}>
            All - {totalCount}
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button className="finance-btn-blockpaid" onClick={showPaid}>
            Paid - {noofaccountspaid}
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button className="finance-btn-blockUnpaid" onClick={showUnpaid}>
            UnPaid - {noofaccountsunpaid}
          </Button>
        </Col>

        {/* <Col span={5} offset={2}>

          <span>Total Amount:{Math.round(Math.round((vehicleaccounts.reduce(function (cnt, o: any) { return cnt + Number(o.totalamount); }, 0) + Number.EPSILON) * 100) / 100)}</span>
        </Col> */}

        <Col span={4} offset={12}>
          {/* <Input placeholder="Search Text" className="search" onChange={onSearchchange} value={SearchText}/> */}
          <Search placeholder="Search Text" onSearch={onSearch} enterButton />
        </Col>
      </Row>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
        
          bordered
          columns={columns}
          data={copycurrentBookingGridData}
          enablePagination={true}
          defaultPageSize={50}
        ></AppTable>

        <AddPaymentsForVehicle
          title="Add Vehicle Payment"
          selectedRowData={selectedRowData}
          hideModal={hideModalvehicleaccountVisible}
          visible={vehicleaccountmodalVisible}
          customClass="new-booking-assign"
        />
        <Vehicleaccountmoreinfo
          title="MoreInfo"
          selectedRowData={selectedRowData}
          hideModal={hidevehiclemoreinfoVisible}
          visible={vehiclemoremodalVisible}
        />
      </Spin>
    </div>
  );
};

export default Vehicleaccount;
