import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddTariff = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [basefare, setbasefare] = useState("");
  const [basekm, setbasekm] = useState("");
  const [basemin, setbasemin] = useState("");
  const [addkmcharge, setaddkmcharge] = useState("");
  const [addmincharge, setaddmincharge] = useState("");
  const [feet, setfeet] = useState(0);
  const [loadcapacity, setloadcapacity] = useState("");
  const [vehicle, setvehicle] = useState("");

  useEffect(() => {
    setVisible(props.visible);
    if (props.isedit) {
      setbasefare(props.selectedRowData.BaseFare);
      setbasekm(props.selectedRowData.BaseKM);
      setbasemin(props.selectedRowData.BaseMinute);
      setaddkmcharge(props.selectedRowData.AddKMCharge);
      setaddmincharge(props.selectedRowData.AddMinuteCharge);
      setfeet(Number(props.selectedRowData.feet.replace(/\D/g, "")));
      setloadcapacity(props.selectedRowData.LoadCapacity.replace(/\D/g, ""));
      setvehicle(props.selectedRowData.Vechicle);
    }
    getbrancheslist();
  }, [props.visible]);

  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }
  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };
  const basefarechange = (e: any) => {
    setbasefare(e.target.value);
  };
  const basekmchange = (e: any) => {
    setbasekm(e.target.value);
  };
  const baseminschange = (e: any) => {
    setbasemin(e.target.value);
  };
  const addkmchange = (e: any) => {
    setaddkmcharge(e.target.value);
  };
  const addminchange = (e: any) => {
    setaddmincharge(e.target.value);
  };
  const vehiclechange = (e: any) => {
    setvehicle(e.target.value);
  };
  const loadcapacitychange = (e: any) => {
    setloadcapacity(e.target.value);
  };
  const feetchange = (e: any) => {
    setfeet(e);
  };

  //updatetariff
  const updatetariff = () => {
    const options: any = {
      data: {
        id: props.selectedRowData.id,
        LoadCapacity: loadcapacity + " Kgs",
        Vechicle: vehicle,
        CityId: branchid,
        IsActive: "1",
        BaseFare: basefare,
        BaseKM: basekm,
        BaseMinute: basemin,
        AddKMCharge: addkmcharge,
        AddMinuteCharge: addmincharge,
        feet: feet + " feet",
      },
    };
    axiosPost(UrlConstants.editmetrefare, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "MetreFare Added Successfully",
          //description: "MetreFare for " + selectedValues.GoodsName + " added",
        };
        handleCancel("");
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Adding Failed",
          description: "Error Occurred while Adding Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const savetariff = () => {
    const options: any = {
      data: {
        LoadCapacity: loadcapacity + " Kgs",
        Vechicle: vehicle,
        CityId: branchid,
        IsActive: "1",
        BaseFare: basefare,
        BaseKM: basekm,
        BaseMinute: basemin,
        AddKMCharge: addkmcharge,
        AddMinuteCharge: addmincharge,
        feet: feet + " feet",
      },
    };
    axiosPost(UrlConstants.addmetrefare, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header: "MetreFare Added Successfully",
          description: "MetreFare for " + selectedValues.GoodsName + " added",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Adding Failed",
          description: "Error Occurred while Adding Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={400}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["vehiclename", "vehiclename"]}
              label="Vehicle Name"
              rules={[]}
            >
              <Input
                onChange={vehiclechange}
                defaultValue={vehicle}
                value={vehicle}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name={["loadcapacity", "loadcapacity"]}
              label="Load Capacity"
              rules={[]}
            >
              <Input
                onChange={loadcapacitychange}
                defaultValue={loadcapacity}
                value={loadcapacity}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name={["feet", "feet"]} label="Feet" rules={[]}>
              <InputNumber
                onChange={feetchange}
                defaultValue={feet}
                value={feet}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basefare", "basefare"]}
              label="BaseFare"
              rules={[]}
            >
              <Input
                onChange={basefarechange}
                defaultValue={basefare}
                value={basefare}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item name={["basekm", "basekm"]} label="BaseKm" rules={[]}>
              <Input
                onChange={basekmchange}
                defaultValue={basekm}
                value={basekm}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basemins", "basemins"]}
              label="BaseMins"
              rules={[]}
            >
              <Input
                onChange={baseminschange}
                defaultValue={basemin}
                value={basemin}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["addkm", "addkm"]}
              label="AdditionalKm"
              rules={[]}
            >
              <Input
                onChange={addkmchange}
                defaultValue={addkmcharge}
                value={addkmcharge}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["addmin", "addmin"]}
              label="Additional Min"
              rules={[]}
            >
              <Input
                onChange={addminchange}
                defaultValue={addmincharge}
                value={addmincharge}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit === true && (
          <Col offset={5}>
            <Button onClick={updatetariff}>Update</Button>
          </Col>
        )}
        {props.isedit !== true && (
          <Col offset={5}>
            <Button onClick={savetariff}>Save</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddTariff;
