import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";

const AppModalWIthStaticsReport = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});


  useEffect(() => {
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
  }, [props.selectedRowData]);
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };
  const columns = [
    {
      title: "Time",
      dataIndex: "id",
      key: "Booking ID",

    },
    {
      title: "No Booking",
      dataIndex: "id",
      key: "Booking ID",

    },
    {
      title: "Vehicle available",
      dataIndex: "id",
      key: "Booking ID",

    },
    {
      title: "Cancellation",
      dataIndex: "id",
      key: "Booking ID",

    },


  ]
  const data = [
    {
      key: "1",
      id: "Time 6-7",
      name: 32,

    },


  ];


  return (
    <div>

      <Modal
        destroyOnClose={true}
        className="custom-modal-grid"
        width={400}
        title="Hour"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Row className="custom-modal-grid-booking-grid">
          <AppTable
            columns={columns}
            data={data}

          ></AppTable>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModalWIthStaticsReport;
