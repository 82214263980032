import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, DatePicker, Input, Select, Spin } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import moment from "moment";
import "./Finance.scss";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import axiosGet from "../../helpers/AxiosRequest";

const { Search } = Input;

const CorporatePayment = () => {
   
  
    const [corporatepayemnthistory, setcorporatepayemnthistory] = useState([]);
    const [copycorporatepayemnthistory, setcopycorporatepayemnthistory] = useState([]);
    const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
    const [isLoading, setIsLoading] = useState(false);
    const [SearchText, setsearchText]: any = useState("");
    const [pagination, setPagination]:any = useState({
      current: 1,
      pageSize: 10,
    });

    const columns = [
       
        {
            title: "Date",
            dataIndex: "date",
            key: "Date",
            ellipsis: true,
            render: (text: any) => {
              return moment(text).format("DD/MM/YYYY");
            },
          },

        {
            title: "Company ID",
            dataIndex: "ID",
            key: "Booking ID",

        },
        {
            title: "Company Name",
            dataIndex: "companyname",
            key: "Booking ID",

        },
        {
            title: "Ref. No",
            dataIndex: "refference",
            key: "Booking ID",

        },
        {
            title: "Mode of Payment",
            dataIndex: "mop",
            key: "Booking ID",
            render: (text: any, record: any) => {
                return {
                  
                  children: <div>{text == 1 ? "NEFT" : text == 2 ? "Prepaid" :text == 3 ? "Cash" : ""}</div>
                };
              },

        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "Booking ID",
            render: (text:any) => {
              if(text != undefined){
                if(text != null && text!= undefined && text!='' && text!=0)
                  return Math.round(text);
                else
                  return text;
              }
            }
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "Booking ID",
            render: (text:any) => {
              if(text != undefined){
                if(text != null && text!= undefined && text!='' && text!=0)
                  return Math.round(text);
                else
                  return text;
              }
            }
        },



    ]
    useEffect(() => {
        getcorporatepaymenthistory(pagination, SearchText);
       
      }, []);
    
      const onSearchchange = (e: any) => {
        console.log(e.target.value);
        setsearchText(e.target.value);
        globalSearch(e.target.value);
      };
      const globalSearch = (searchText:any) =>{ 
        let filteredData = corporatepayemnthistory.filter((value: any) => {
            if (value.ID === undefined || value.ID === null)
          value.ID = '';
        /* if (value.name === undefined || value.name === null)
          value.name = ''; */
        if (value.refference === undefined || value.refference === null)
          value.refference = '';
        if (value.amount === undefined || value.amount === null)
          value.amount = '';
        if (value.balance === undefined || value.balance === null)
          value.balance = '';
        
        return (
         
            value.ID.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            // value.companyname.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.refference
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())||
              value.amount.toString()
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
              value.balance.toString()
              .toLowerCase()
              .includes(searchText.toLowerCase()) 
             
           
          );
        });
          setcopycorporatepayemnthistory(filteredData);
    };
    const getcorporatepaymenthistory = (pagination:any, SearchText:any) => {
        setIsLoading(true);
        const options: any = {
            params: {
              cityid: branchid,
              fromdate: new Date("2000-01-01"),
              todate: new Date().setDate(new Date().getDate() + 1) ,
              limit: pagination.pageSize,
              skip: (pagination.current-1) * (pagination.pageSize),
              filtervalue: SearchText
            },
        };
        
        axiosGet(UrlConstants.getcorporatepaymenthistory, options).then((resp: any) => {
            console.log(resp);
            setIsLoading(false);
            resp.data.forEach((e: any) => {
                e.key = e.ID;
              });
                setcorporatepayemnthistory(resp.data);
                setcopycorporatepayemnthistory(resp.data);
                setPagination({
                  ...pagination,
                  total: resp.tablelength
                });
        })
            .catch(function (error: any) {
                if (error.response) {
                    console.log(error.response.data, error.response.status);
                }
            });
    };
    const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
      setPagination(pagination);
      getcorporatepaymenthistory(pagination, SearchText);
      console.log(pagination); 
    } 
    const onSearchText = (value:any) => {
      console.log(value);
      setsearchText(value);
      getcorporatepaymenthistory(pagination, value);
    }

    return (
        <div className="booking-child-routes new-booking">
            <div className="booking-child-routes-button-section">
            <Row style={{ paddingBottom: 10 }}> <Col>  <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}> CORPORATE PAYMENT HISTORY</span> 
      </Col> 
       </Row>
                <Row style={{ paddingBottom: 10 }}>
                    <Col span={4} offset={20}>
                    {/* <Input  placeholder="Search Text" value={SearchText} onChange={onSearchchange}/> */}
                    <Search placeholder="Search Text" onSearch={onSearchText} enterButton/>
                    </Col>
                </Row>


                <div>
                <Spin tip="Loading..." spinning={isLoading}>
                    <AppTable className="finance-table-row"
                        columns={columns}
                        data={copycorporatepayemnthistory}
                        enablePagination={pagination}
                        onchange={onPaginationchange}
                    ></AppTable>
                    </Spin>
                </div>

            </div>
        </div>

    )

}

export default CorporatePayment;


