import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Select, Input, notification } from "antd";
import AppTable from "../AppTable/AppTable";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import axios from "axios";
const { Option } = Select;
const AppModalDriverAttendanceBlock = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [availableCabList, setAvailableList] = useState([]);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [driverAttendanceList, setDriverAttendanceList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [status, setstatus] = useState("Block");
  const [selectedRowReason, setSelectedRowreason] = useState<any[]>([]);
  const [disableblock, setdisableblock] = useState(false);

  // useEffect(() => {

  // }, [props.selectedRowData]);

  useEffect(() => {
    if (props.selectedRowData.cabid != undefined) {
      onSearchchange(props.selectedRowData.cabid);
    }
    setVisible(props.visible);
    const options = {
      params: {
        branchid: branchid,
        //blockedby: username,
      },
    };
    axiosGet(UrlConstants.getDriverAttendanceList, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.cabid;
        });
        resp.data.sort((a: any, b: any) => {
          return (
            (a.blocked === b.blocked ? 0 : a.blocked ? -1 : 1) ||
            a.cabid.localeCompare(b.cabid)
          );
        });
        setDriverAttendanceList(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        if (props.selectedRowData.cabid != undefined) {
          onSearchchange(props.selectedRowData.cabid);
        }
        setdisableblock(true);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        if (props.selectedRowData.cabid != undefined) {
          onSearchchange(props.selectedRowData.cabid);
        }
      });
  }, [props.visible]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
    setSelectedRowreason([]);
    setsearchText("");
    globalSearch("");
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    var res: any = {};
    let disabledrowcount = 0;
    rowInfo.forEach(function (v: any) {
      res[v.blocked] = (res[v.blocked] || 0) + 1;
      if (v.statuscode == 2) {
        disabledrowcount++;
      }
      /* else
        setdisableblock(false); */
    });
    if (disabledrowcount > 0) {
      setdisableblock(true);
    } else {
      setdisableblock(false);
    }
    if (res.true == rowInfo.length) setstatus("Unblock");
    else setstatus("Block");
  };

  const blockdriver = () => {
    var res: any = {};
    selectedRowData.forEach(function (v: any) {
      res[v.blocked] = (res[v.blocked] || 0) + 1;
    });
    if (res.false == selectedRowData.length) {
      selectedRowData.forEach(function (data: any) {
        let index = selectedRowReason.findIndex((x) => x.cabid === data.cabid);
        let reason = "Pending payment";
        if (index != -1) reason = selectedRowReason[index].reason;
        var blockfd = new FormData();

        blockfd.append("cabid", data.cabid);
        blockfd.append("reason", reason);
        blockfd.append("name", selectedRowData[0].drivername);
        blockfd.append("ownername", selectedRowData[0].ownername);
        blockfd.append("ownermobileno", selectedRowData[0].mobileno);
        blockfd.append("mobileno", selectedRowData[0].mobileno);
        blockfd.append("blockedby", username);
        axios
          .post(UrlConstants.blockcab, blockfd)
          .then((resp) => {
            if (resp.status == 200) {
              const notificationInfo = {
                header: "Blocked successfully",
                description: "Cabs blocked successfully",
              };
              openNotificationWithIcon("Success", notificationInfo);
              handleCancel("");
            }
          })
          .catch(function (error) {
            if (error.response) {
              const notificationInfo = {
                header: "Block Failed",
                description: error.response.data.message,
              };
              openNotificationWithIcon("Error", notificationInfo);
              console.log(error.response.data, error.response.status);
            }
          });
      });
    } else if (res.true == selectedRowData.length) {
      selectedRowData.forEach(function (data: any) {
        let index = selectedRowReason.findIndex((x) => x.cabid === data.cabid);
        let reason = "Pending payment";
        if (index != -1) reason = selectedRowReason[index].reason;
        var blockfd = new FormData();
        blockfd.append("cabid", data.cabid);
        axios
          .post(UrlConstants.unblockcab, blockfd)
          .then((resp) => {
            if (resp.status == 200) {
              const notificationInfo = {
                header: "Unblocked successfully",
                description: "Cabs unblocked successfully",
              };
              openNotificationWithIcon("Success", notificationInfo);
              handleCancel("");
            }
          })
          .catch(function (error) {
            if (error.response) {
              const notificationInfo = {
                header: "Unblock Failed",
                description: error.response.data.message,
              };
              openNotificationWithIcon("Error", notificationInfo);
              console.log(error.response.data, error.response.status);
            }
          });
      });
    } else {
      const notificationInfo = {
        header: "Selection Failed",
        description: "Select either block or unblock",
      };
      openNotificationWithIcon("Error", notificationInfo);
    }
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const resonvalue = (cabid: any, blocked: boolean, reasontxt: any) => {
    let index = selectedRowReason.findIndex((x) => x.cabid === cabid);
    let reason = "Pending payment";
    if (index != -1) reason = selectedRowReason[index].reason;
    if (blocked) reason = reasontxt;
    return reason;
  };

  const resonOnChange = (e: any, cabid: any) => {
    let obj: any = {};
    obj.cabid = cabid;
    obj.reason = e;
    let main: any = [];
    main.push(obj);
    let index = selectedRowReason.findIndex((x) => x.cabid === cabid);
    setSelectedRowreason((old) => {
      if (index != -1) old.splice(index, 1);
      return [...old, ...main];
    });
  };

  const onSearchchange = (e: any) => {
    if (e.target != undefined) {
      setsearchText(e.target.value);
      globalSearch(e.target.value);
    } else {
      setsearchText(e);
      globalSearch(e);
    }
  };
  const globalSearch = (searchText: any) => {
    let filteredData = driverAttendanceList.filter((value: any) => {
      return value.cabid.toLowerCase().includes(searchText.toLowerCase());
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  const avaiableCabGridColumns = [
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "_id",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.blocked == true
                  ? "#ea4335"
                  : record.blocked == false
                  ? "#8ed071"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Owner Name",
      dataIndex: "ownername",
      key: "name",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.blocked == true
                  ? "#ea4335"
                  : record.blocked == false
                  ? "#8ed071"
                  : "",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    //   key: "mobile",
    //   ellipsis: true,
    //   render: (text: any, record: any) => {
    //     return record.mobileno[0];
    //   },
    //   //render: (text: any) => <a>{text}</a>,
    // },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <Select
            defaultValue="Pending payment"
            style={{ width: "100%" }}
            onChange={(e) => resonOnChange(e, record.cabid)}
            disabled={record.blocked}
            value={resonvalue(record.cabid, record.blocked, record.blockreason)}
          >
            <Option value={"Pending payment"}>Pending payment</Option>
            <Option value={"Uniform"}>Uniform</Option>
            <Option value={"Sticker"}>Sticker</Option>
            <Option value={"Not taking trip"}>Not taking trip</Option>
            <Option value={"Misbehaving"}>Misbehaving</Option>
            <Option value={"Time managment"}>Time managment</Option>
            <Option value={"Taking other trips"}>Taking other trips</Option>
            <Option value={"Other reason"}>Other reason</Option>
            <Option value={"Leave without information"}>
              Leave without information
            </Option>
            <Option value={"Trip cancel driver issues"}>
              Trip cancel driver issues
            </Option>
          </Select>
        </>
      ),
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   ellipsis: true,
    //   render: (text: any, record: any) => {
    //     return new Date(record.pickup).toLocaleDateString();
    //   },
    //   //render: (text: any) => <a>{text}</a>,
    // },
  ];

  return (
    <div>
      {/* <Button type="primary" onClick={() => showModal()}>
          Open Modal
        </Button> */}
      {/* <>{JSON.stringify(copycurrentBookingGridData.filter((data:any)=>data.statuscode==2))}</> */}

      <Modal
        destroyOnClose={true}
        className="custom-modal-grid"
        width={props.width || 520}
        title={props.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Row className="custom-modal-grid-booking-info">
          <Col span={8}>
            <Button
              onClick={blockdriver}
              disabled={!isRowSelected || disableblock}
            >
              {status}
            </Button>
          </Col>
          <Col span={4} offset={12}>
            <Input
              placeholder="Search Text"
              value={SearchText}
              onChange={onSearchchange}
            />
          </Col>
        </Row>
        <Row className="custom-modal-grid-booking-grid block-driver-attendance">
          <AppTable
            columns={avaiableCabGridColumns}
            data={copycurrentBookingGridData}
            enableCheckBox={true}
            onCheckboxChange={onGridRowSelect}
            enablePagination={true}
            selectionType="checkbox"
          ></AppTable>
        </Row>
      </Modal>
    </div>
  );
};

export default AppModalDriverAttendanceBlock;
