// import React, { useState, useEffect } from "react";
// import { Tag, Space, Row, Col, Button, Checkbox, Input, Spin, Select, DatePicker, Form } from "antd";
// import AppTable from "../../components/AppTable/AppTable";

// import axiosGet from "../../helpers/AxiosRequest";
// import { UrlConstants } from "../../helpers/UrlConstantsHelper";
// import moment from "moment";
// import "../../pages/Booking/Booking.scss";
// import "./Report.scss";
// import AppModalCabAtdncReport from "../../components/AppModal/AppModalCabAtdncReport";
// import { CSVLink } from "react-csv";

// const { Option } = Select;
// const DayReport = () => {
//   const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
//   const [cabatdmodalVisible, setcabatdmodalVisible] = useState(false);
//   const [isRowSelected, setIsRowSelected] = useState(false);
//   const [selectedRowData, setSelectedRowData] = useState([]);
//   const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
//   const [SearchText, setsearchText]: any = useState("");
//   const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [ButtonClicked, setIsButtonClicked] = useState("Today");
//   const [fromdate, setfromdate] = useState(moment());
//   const [todate, settodate] = useState(moment());
//   const dateFormat = "DD/MM/YYYY";
//   const [selectedValues, setSelectedValues]: any = useState({});
//   const [cabIdsList, setcabIdsList] = useState([]);
//   const [vehicleIdsList, setvehicleIdsList] = useState([]);
//   const [searchCabIdsList, setSearchCabIdsList] = useState([]);
//   const [searchVehicleIdsList, setSearchVehicleIdsList] = useState([]);
//   const [flag, setFlag] = useState(false);

// const totalFareTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.fare); }, 0) + Number.EPSILON) * 100) / 100);
// const totaltotalcomission = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.comissionamt); }, 0) + Number.EPSILON) * 100) / 100);
// const totalTripTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.trip); }, 0) + Number.EPSILON) * 100) / 100);
// const totalKMTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.totalkm); }, 0) + Number.EPSILON) * 100) / 100);
// const totalEmptyKMTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.diffKM); }, 0) + Number.EPSILON) * 100) / 100);

// const columns = [
//     {
//       title: " Vehicle ID",
//       dataIndex: "vehicleid",
//       fixed: "left",
//       key: "Vehicle ID",

//     },
//     {
//       title: " Cab ID",
//       dataIndex: "cabid",
//       fixed: "left",
//       key: "Cab ID",
//     },
//     {
//       title: "Fare",
//       children:[
//         {
//           title: totalFareTitle,
//           dataIndex: "fare",
//           fixed: "left",
//           key: "fare",
//           className:"day-report-table",
//           render: (text:any) => {
//             if(text != undefined){
//               if(text != null && text!= undefined && text!='' && text!=0)
//                 return Math.round(text);
//               else
//                 return text;
//             }
//           },
//         }
//       ],
//     },
//     {
//     title: "Comission Amount",
//     children:[
//       {
//         title: totaltotalcomission,
//         dataIndex: "comissionamt",
//         fixed: "left",
//         key: "comissionamt",
//         className:"day-report-table",
//         render: (text:any) => {
//           if(text != undefined){
//             if(text != null && text!= undefined && text!='' && text!=0)
//               return Math.round(text);
//             else
//               return text;
//           }
//         },
//       }
//     ],
//   },
    
//     {
//       title: "Trip",
//       children:[
//         {
//           title: totalTripTitle,
//           dataIndex: "trip",
//           key: "trip",
//           className:"day-report-table",
//         }
//       ]
//     },
//     {
//       title: "Days",
//       dataIndex: "days",
//       key: "days",
//       render: (text:any) => {
//         if(text != undefined){
//           if(text != null && text!= undefined && text!='' && text!=0)
//             return Math.round(text);
//           else
//             return text;
//         }
//       }
//     },
//     {
//       title: "Hours",
//       dataIndex: "hours",
//       key: "hours",
//       render: (text:any) => {
//         if(text != undefined){
//           if(text != null && text!= undefined && text!='' && text!=0)
//             return Math.round(text);
//           else
//             return text;
//         }
//       }
//     },
//     {
//       title: "Total KM",
//       children:[
//         {
//           title: totalKMTitle,
//           dataIndex: "totalkm",
//           key: "totalkm",
//           className:"day-report-table",
//           render: (text:any) => {
//             if(text != undefined){
//               if(text != null && text!= undefined && text!='' && text!=0)
//                 return Math.round(text);
//               else
//                 return text;
//             }
//           }
//         }
//       ]
//     },
//     {
//       title: "Effective Km",
//       dataIndex: "efficitiveKM",
//       key: "efficitiveKM",
//       render: (text:any) => {
//         if(text != undefined){
//           if(text != null && text!= undefined && text!='' && text!=0)
//             return Math.round(text);
//           else
//             return text;
//         }
//       }
//     },
//     {
//       title: "Differential km",
//       dataIndex: "diffKM",
//       key: "diffKM",
//       render: (text:any) => {
//         if(text != undefined){
//           if(text != null && text!= undefined && text!='' && text!=0)
//             return Math.round(text);
//           else
//             return text;
//         }
//       }
//     },
//     {
//       title: "Empty %",
//       children:[
//         {
//           title: totalEmptyKMTitle,
//           dataIndex: "diffKM",
//           key: "differKM",
//           className:"day-report-table",
//           render: (text:any) => {
//             if(text != undefined){
//               if(text != null && text!= undefined && text!='' && text!=0)
//                 return Math.round(text);
//               else
//                 return text;
//             }
//           }
//         }
//       ]
//     },

//   ]
//   const columnsToExport = [
//     {
//       title: " Vehicle ID",
//       dataIndex: "vehicleid",
//       fixed: "left",
//       key: "Vehicle ID",

//     },
//     {
//       title: " Cab ID",
//       dataIndex: "cabid",
//       fixed: "left",
//       key: "Cab ID",

//     },
//     {
//       title: "Fare",
//       dataIndex: "fare",
//       fixed: "left",
//       key: "fare",
//     },
//     {
//       title: "Comission Amount",
//       dataIndex: "comissionamt",
//       key: "comissionamt",
//     },
//     {
//       title: "Trip",
//       dataIndex: "trip",
//       key: "trip",
//     },
//     {
//       title: "Days",
//       dataIndex: "days",
//       key: "days",
//     },
//     {
//       title: "Hours",
//       dataIndex: "hours",
//       key: "hours",
//     },
//     {
//       title: "Total KM",
//       dataIndex: "totalkm",
//       key: "totalkm",
//     },
//     {
//       title: "Effective Km",
//       dataIndex: "efficitiveKM",
//       key: "efficitiveKM",
//     },
//     {
//       title: "Differential km",
//       dataIndex: "diffKM",
//       key: "diffKM",
//     },
//     {
//       title: "Empty %",
//       dataIndex: "diffKM",
//       key: "differKM",
//     },

//   ]
//   const cabsatdnmodal = () => {
//     setcabatdmodalVisible(true);
//   };
//   const hideModalcabatdmodalVisible = (isVisible: any) => {
//     setcabatdmodalVisible(isVisible);
//     DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
//   };

//   const onfromdatechange = (value: any) => {
//     setfromdate(value);
//     try {
  
//       var fromdate = moment(value).format("YYYY/MM/DD");
//       let todate = moment().format("YYYY/MM/DD");
//       DateSearch(fromdate, todate);
//       setFlag(false);
//     }
//     catch (ex) {
//       console.log(ex);
//     }
  
//   }
//   const ontodatechange = (value: any) => {
//     settodate(value);
//     try {
  
//       var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
//       let startdate = moment(fromdate).format("YYYY/MM/DD");
//       DateSearch(startdate, todate);
//       setFlag(false);
//     }
//     catch (ex) {
//       console.log(ex);
//     }
  
//   }
  
//   const adayclick = (event: any = null) => {
//     setIsButtonClicked('Today');
//     var first = moment();
//     var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
//     let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
//     setfromdate(moment(fromdate));
//     var new_date = moment().add('days', 1);
//     var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
//     let todate = year + '-' + month + '-' + day;
//     settodate(moment(todate));
//     setFlag(true);
//     DateSearch(fromdate, todate);
//   };
  
//   const twodayclick = () => {
//     setIsButtonClicked('Yesday');
//     var first = moment().add('days', -1);
//     var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
//     let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
//     setfromdate(moment(fromdate));
//     var new_date = moment().add('days', 1);
//     var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
//     let todate = year + '-' + month + '-' + day;
//     settodate(moment(todate));
//     setFlag(false);
//     DateSearch(fromdate, todate);
//   };
  
//   const monthclick = () => {
//     setIsButtonClicked('Month');
//     var first = moment();
//     var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
//     let fromdate = firstyear + '-' + firstmonth + '-' + '01';
//     setfromdate(moment(fromdate));
//     var new_date = moment().add('days', 1);
//     var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
//     let todate = year + '-' + month + '-' + day;
//     settodate(moment(todate));
//     console.log(fromdate);
//     setFlag(false);
//     DateSearch(fromdate, todate);
//   };
  
//   const weekclick = () => {
//     setIsButtonClicked('Week');
//     var first = moment().add('days', -(moment().day()))
//     var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
//     let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
//     setfromdate(moment(fromdate));
//     console.log(fromdate);
//     var new_date = moment().add('days', 1);
//     var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
//     let todate = year + '-' + month + '-' + day;
//     settodate(moment(todate));
//     setFlag(false);
//     DateSearch(fromdate, todate);
//   };
//   const disabledDate = (current: any) => {
//     // Can not select days before today and today
//     return current && current > moment().endOf("day");
//   };
  
//   const onTableSearch = () => {
//     DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
//     //onTableReset();
//   }

//   const onTableClear = () => {
//     selectedValues.cab_id = null;
//     selectedValues.vehicleid = null;
//     setSearchCabIdsList([]);
//     setSearchVehicleIdsList([])
//     /* let copyselectedvalues = { ...selectedValues };
//     copyselectedvalues.cab_id=null;
//   copyselectedvalues.vehicleid=null;
//     setSelectedValues(copyselectedvalues); */
//     //adayclick();
//     DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
//   }



//   const DateSearch = (fromdate: any, todate: any) => {
//     setIsLoading(true);
//     const options: any = {
//       params: {
//         cityid: branchid,
//         fromdate: fromdate,
//         todate: todate,
//         cabid:selectedValues.cab_id  || "ALL",
//       vehicleid:selectedValues.vehicleid || "ALL"
//       },
//     };
//     console.log(options);
    
//     axiosGet(UrlConstants.getdayreport, options).then((resp: any) => {
//       resp.forEach((e: any) => {
//         e.key = e.cabid;
//       });
//       setIsLoading(false);
//       resp.sort((a:any,b:any) => a.cabid.localeCompare(b.cabid));
//       setCurrentBookingGridData(resp);
//       setcopyCurrentBookingGridData(resp);
//       console.log(resp);
      
//     }).catch(function (error: any) {
//       setIsLoading(false);
//       if (error.response) {
//         console.log(error.response.data, error.response.status);
//         setCurrentBookingGridData([]);
//         setcopyCurrentBookingGridData([]);
//       }
//     });
//   };


//   const onGridRowSelect = (rowInfo: any) => {
//     setIsRowSelected(rowInfo.length > 0);
//     setSelectedRowData(rowInfo);
//   };
//   useEffect(() => {
//     console.log(isRowSelected);
//     adayclick();
//     getcabids();
//     getvehicleids();
//   }, []);
//   const onSearchchange = (e: any) => {
//     console.log(e.target.value);
//     setsearchText(e.target.value);
//     globalSearch(e.target.value);
//   };

//   const globalSearch = (searchText: any) => {
//     let filteredData = currentBookingGridData.filter((value: any) => {
//       //console.log(value.otp, value.vehicleid);
//       if (value.vehicleid === undefined || value.vehicleid === null)
//         value.vehicleid = '';
//       if (value.cabid === undefined || value.cabid === null)
//         value.cabid = '';
//       if (value.comissionamt === undefined || value.comissionamt === null)
//         value.comissionamt = '';
//       if (value.fare === undefined || value.fare === null)
//         value.fare = '';
//       if (value.trip === undefined || value.trip === null)
//         value.trip = '';
//       if (value.days === undefined || value.days === null)
//         value.days = '';
//       if (value.hours === undefined || value.hours === null)
//         value.hours = '';
//       if (value.totalkm === undefined || value.totalkm === null)
//         value.totalkm = '';
//       if (value.efficitiveKM === undefined || value.efficitiveKM === null)
//         value.efficitiveKM = '';
//       if (value.diffKM === undefined || value.diffKM === null)
//         value.diffKM = '';
//       /* if (value.diffKM === undefined)
//         value.distance = ''; */



//       return (
//         value.vehicleid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.cabid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.comissionamt.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.fare.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.trip.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.days.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.hours.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.totalkm.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.efficitiveKM.toString().toLowerCase().includes(searchText.toLowerCase()) ||
//         value.diffKM.toString().toLowerCase().includes(searchText.toLowerCase()) 
//         /* ||
//         value.distance.toString().toLowerCase().includes(searchText.toLowerCase()) */
//       );
//     });
//     setcopyCurrentBookingGridData(filteredData);
//   };
//   const onVehicleIdChange = (value: any, fullInfo: any) => {
//   let copyselectedvalues = { ...selectedValues };
//   copyselectedvalues.vehicleid=value;
//     setSelectedValues(copyselectedvalues);
//   };
//   const onCabIdChange = (value: any, fullInfo: any) => {
//     let copyselectedvalues = { ...selectedValues };
//     copyselectedvalues.cab_id=value;
//       setSelectedValues(copyselectedvalues);
//   };


//   const getcabids = () => {
//     const uri = UrlConstants.getcabidlist + "?CityId=" + branchid;
//     axiosGet(uri).then((resp: any) => {
//       console.log(resp);
//       setcabIdsList(resp);
//     });
//   };
//   const getvehicleids = () => {
//     const uri = UrlConstants.getvehicleidslist + "?CityId=" + branchid;
//     axiosGet(uri).then((resp: any) => {
//       console.log(resp);
//       setvehicleIdsList(resp);
//     });
//   };

//   const onCabIdSearch = (value:any) => {
//       if (value) {
//         // const searchedList = locationsList.filter(
//         //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
//         // );
//         var filtered = cabIdsList.filter((x: any) => String(x.cab_id.toLowerCase()).includes(value.toLowerCase()));
//         console.log(filtered);
//         setSearchCabIdsList(filtered);
//        /*  selectedValues.cab_id = '';
//         selectedValues.vehicleid = ''; */
//       } else {
//         setSearchCabIdsList([]);
//       }
//     };

//     const onVehicleIdSearch = (value:any) => {
//       if (value) {
//         // const searchedList = locationsList.filter(
//         //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
//         // );
//         var filtered = vehicleIdsList.filter((x: any) => String(x.vehicleid.toLowerCase()).includes(value.toLowerCase()));
//         //console.log(searchedList,filtered);
//         setSearchVehicleIdsList(filtered);
//       } else {
//         setSearchVehicleIdsList([]);
//       }
//     };
//     const headersToExport = columns.map((column:any) => 
//         ((column.title =='Fare') ? {label:column.title, key:'fare'} 
//       : (column.title == 'Trip') ? {label:column.title, key:'trip'} 
//       : (column.title == 'Total KM') ? {label:column.title, key:'totalkm'}
//       : (column.title == 'Days') ? {label:column.title, key:'days'}
//       : (column.title == 'Hours') ? {label:column.title, key:'hours'}
//       : (column.title == 'Effective Km') ? {label:column.title, key:'efficitiveKM'}
//       : (column.title == 'Differential km') ? {label:column.title, key:'diffKM'}
//       : (column.title == 'Comission Amount') ? {label:column.title, key:'comissionamt'}
//       : (column.title == 'Empty %') ? {label:column.title, key:'diffKM'}
//       : {label: column.title, key: column.dataIndex}))

//     const dataToExport = currentBookingGridData.map((value:any) => ({
//       ...value,
//       comissionamt: (value.comissionamt != null && value.comissionamt!= undefined && value.comissionamt!='') ?
//           Math.round(value.comissionamt) : value.comissionamt,
//       fare: (value.fare != null && value.fare!= undefined && value.fare!='') ?
//           Math.round(value.fare) : value.fare,
//       trip: (value.trip != null && value.trip!= undefined && value.trip!='') ?
//           Math.round(value.trip) : value.trip,
//       totalkm: (value.totalkm != null && value.totalkm!= undefined && value.totalkm!='') ?
//           Math.round(value.totalkm) : value.totalkm,
//       days: (value.days != null && value.days!= undefined && value.days!='') ?
//           Math.round(value.days) : value.days,
//       hours: (value.hours != null && value.hours!= undefined && value.hours!='') ?
//           Math.round(value.hours) : value.hours,
//       efficitiveKM: (value.efficitiveKM != null && value.efficitiveKM!= undefined && value.efficitiveKM!='') ?
//           Math.round(value.efficitiveKM) : value.efficitiveKM,
//       diffKM: (value.diffKM != null && value.diffKM!= undefined && value.diffKM!='') ?
//           Math.round(value.diffKM) : value.diffKM,
//     }))

//   return (
//     <div className="booking-child-routes">
//       <div className="booking-child-routes-button-section">
//       <Row style={{ paddingBottom: 10 }}> <Col>  <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}> DAY REPORT</span> 
//       </Col> 
//        <Col offset={2}>
//           <span style={{ fontSize: 'large' , fontWeight: 'bold', fontStyle:'Times New Roman' }}> From : {moment(fromdate).format('DD.MM.YYYY')}   To : {flag ? moment().format('DD.MM.YYYY') : moment(todate).format('DD.MM.YYYY')}  </span>
      
//           </Col></Row>
//         <Row style={{ paddingBottom: 10 }}>
         
//           <Col >

//           <span style={{ fontSize: 'medium' }}> From:</span>
 
//  <DatePicker
 
//      value={fromdate}
//      format={dateFormat}
//      disabledDate={disabledDate}  
//      onChange={onfromdatechange} />
// <span style={{ fontSize: 'medium' }}> To:</span>


// <DatePicker
//      value={flag? moment() : todate}
//      //value={todate}
//      format={dateFormat}
//      disabledDate={disabledDate}  
//      onChange={ontodatechange} />

//           </Col>
//           <Col span={2} offset={1}>
//             <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
//               onClick={adayclick}
//             >
//               Today
//             </Button>
//           </Col>
//           <Col span={2} offset={1} >
//             <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
//               onClick={weekclick}
//             >
//               Week
//             </Button>
//           </Col>
//           <Col span={2} offset={1}>
//             <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
//               onClick={monthclick}
//             >
//               Month
//             </Button>
//           </Col>
//         </Row>
//         <Row style={{paddingBottom:'10px'}}>
//         <Col span={3}>
//           <Select placeholder="Cab Id"
//               style={{ width: 100 }}
//               showSearch
//               optionFilterProp="children"
//               value={selectedValues.cab_id}
//               onChange={onCabIdChange}
//               filterOption={(input: any, option: any) =>
//                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//               }
//               showArrow={false}
//               onSearch={onCabIdSearch}
//               notFoundContent={null}>

//               {searchCabIdsList.map((id_: any) => (
//                 <Option key={id_.cab_id} value={id_.cab_id}>
//                   {id_.cab_id}
//                 </Option>
//               ))}
//           </Select>
//           </Col>
//           <Col span={3}>
//             <Select placeholder="Vehicle Id"
//                   value={selectedValues.vehicleid}
//                   style={{ width: 100 }}
//                   showSearch
//                   optionFilterProp="children"
//                   onChange={onVehicleIdChange}
//                   filterOption={(input: any, option: any) =>
//                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                   }
//                   showArrow={false}
//                   onSearch={onVehicleIdSearch}
//                   notFoundContent={null}
//                   >

//                   {searchVehicleIdsList.map((id: any) => (
//                     <Option key={id.vehicleid} value={id.vehicleid}>
//                       {id.vehicleid}
//                     </Option>
//                   ))}
//             </Select>
//           </Col>
//           <Col span={2}>
//             <Button
//               onClick={onTableSearch}
//               className="report-btn-blockSearch">
//               Search
//             </Button>
//           </Col>
//           <Col span={2}>
//             <Button
//             htmlType="button"
//               style={{marginLeft:10}}
//               className="report-btn-blockClear"
//               onClick={onTableClear}>
//               Clear
//             </Button>          
//           </Col>
//           <Col span={2} offset={2}>
//             <Button
//               className="report-btn-blocksms">
//               Send sms
//   </Button>
//           </Col>
//           <Col span={2} offset={1}>
            
//  <CSVLink
//       filename={"DayReport.csv"}
//       /*  headers={columns.map((column:any)=> (column.title!='Empty %' ? 
//             {label:column.title, key:column.dataIndex} : {label:'', key:''}))} */
//             headers={headersToExport}
//       data={dataToExport}
//     >
//       <Button className="report-btn-blockExport">Export</Button>
//     </CSVLink>
//           </Col>
//           <Col span={4} offset={3}>
//             <Input placeholder="Search Text" className="search" 
//               value={SearchText}
//               onChange={onSearchchange} />
//           </Col>
//         </Row>
        
//         <Spin tip="Loading..." spinning={isLoading}>
//           <AppTable
//             columns={columns}
//             pagination={{defaultPageSize:100}}
//             data={copycurrentBookingGridData}
//             enablePagination={true}

//             scroll={{ x: 1850, y: 480 }}
//           ></AppTable>
//         </Spin>
//       </div>
//       <AppModalCabAtdncReport
//         title="MoreInfo"
//         selectedRowData={selectedRowData}
//         hideModal={hideModalcabatdmodalVisible}
//         visible={cabatdmodalVisible}
//         onRowClickEnabled={false}
//         customClass="new-booking-assign"
//       />
//     </div>
//   );
// };

// export default DayReport;
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, Input, Spin, Select, DatePicker, Form } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";
import "./Report.scss";
import AppModalCabAtdncReport from "../../components/AppModal/AppModalCabAtdncReport";
import { CSVLink } from "react-csv";

const { Option } = Select;
const { Search } = Input;

const DayReport = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [cabatdmodalVisible, setcabatdmodalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [fromdate, setfromdate] = useState(moment());
  const [todate, settodate] = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [selectedValues, setSelectedValues]: any = useState({});
  const [cabIdsList, setcabIdsList] = useState([]);
  const [vehicleIdsList, setvehicleIdsList] = useState([]);
  const [searchCabIdsList, setSearchCabIdsList] = useState([]);
  const [searchVehicleIdsList, setSearchVehicleIdsList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [comissionamount, setComissionamount] = useState(0);
  const [emptykm, setEmptykm] = useState(0);
  const [tablelength, setTablelength] = useState(0);
  const [totalamount, setTotalamount] = useState(0);
  const [totalkm, setTotalkm] = useState(0);
  const [tripscount, setTripscount] = useState(0);
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 100,
  });
/* 
const totalFareTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.fare); }, 0) + Number.EPSILON) * 100) / 100);
const totalTripTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.trip); }, 0) + Number.EPSILON) * 100) / 100);
const totalKMTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.totalkm); }, 0) + Number.EPSILON) * 100) / 100);
const totalEmptyKMTitle = Math.round(Math.round((copycurrentBookingGridData.reduce(function (cnt, o: any) { return cnt + Number(o.diffKM); }, 0) + Number.EPSILON) * 100) / 100);
 */


const totalFareTitle = Math.round(totalamount);
const totalTripTitle = Math.round(tripscount);
const totalKMTitle = Math.round(totalkm);
const totalEmptyKMTitle = Math.round(emptykm);
const totalComissionamountTitle = Math.round(comissionamount);


const columns = [
    {
      title: " Vehicle ID",
      dataIndex: "vehicleid",
      fixed: "left",
      key: "Vehicle ID",

    },
    {
      title: " Cab ID",
      dataIndex: "cabid",
      fixed: "left",
      key: "Cab ID",
    },
    {
      title: "Fare",
      children:[
        {
          title: totalFareTitle,
          dataIndex: "fare",
          fixed: "left",
          key: "fare",
          className:"day-report-table",
          render: (text:any) => {
            if(text != undefined){
              if(text != null && text!= undefined && text!='' && text!=0)
                return Math.round(text);
              else
                return text;
            }
          },
        }
      ],
    },
    {
      title: "Comission Amount",
      /* dataIndex: "comissionamt",
      key: "comissionamt",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      } */
      children:[
        {
          title: totalComissionamountTitle,
          dataIndex: "comissionamt",
          fixed: "left",
          key: "fare",
          className:"day-report-table",
          render: (text:any) => {
            if(text != undefined){
              if(text != null && text!= undefined && text!='' && text!=0)
                return Math.round(text);
              else
                return text;
            }
          },
        }
      ],
    },
    {
      title: "Trip",
      children:[
        {
          title: totalTripTitle,
          dataIndex: "trip",
          key: "trip",
          className:"day-report-table",
        }
      ]
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }
    },
    {
      title: "Total KM",
      children:[
        {
          title: totalKMTitle,
          dataIndex: "totalkm",
          key: "totalkm",
          className:"day-report-table",
          render: (text:any) => {
            if(text != undefined){
              if(text != null && text!= undefined && text!='' && text!=0)
                return Math.round(text);
              else
                return text;
            }
          }
        }
      ]
    },
    {
      title: "Effective Km",
      dataIndex: "efficitiveKM",
      key: "efficitiveKM",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }
    },
    {
      title: "Differential km",
      dataIndex: "diffKM",
      key: "diffKM",
      render: (text:any) => {
        if(text != undefined){
          if(text != null && text!= undefined && text!='' && text!=0)
            return Math.round(text);
          else
            return text;
        }
      }
    },
    {
      title: "Empty Km",
      children:[
        {
          title: totalEmptyKMTitle,
          dataIndex: "diffKM",
          key: "differKM",
          className:"day-report-table",
          render: (text:any) => {
            if(text != undefined){
              if(text != null && text!= undefined && text!='' && text!=0)
                return Math.round(text);
              else
                return text;
            }
          }
        }
      ]
    },

  ]
  const columnsToExport = [
    {
      title: " Vehicle ID",
      dataIndex: "vehicleid",
      fixed: "left",
      key: "Vehicle ID",

    },
    {
      title: " Cab ID",
      dataIndex: "cabid",
      fixed: "left",
      key: "Cab ID",

    },
    {
      title: "Fare",
      dataIndex: "fare",
      fixed: "left",
      key: "fare",
    },
    {
      title: "Comission Amount",
      dataIndex: "comissionamt",
      key: "comissionamt",
    },
    {
      title: "Trip",
      dataIndex: "trip",
      key: "trip",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: "Total KM",
      dataIndex: "totalkm",
      key: "totalkm",
    },
    {
      title: "Effective Km",
      dataIndex: "efficitiveKM",
      key: "efficitiveKM",
    },
    {
      title: "Differential km",
      dataIndex: "diffKM",
      key: "diffKM",
    },
    {
      title: "Empty %",
      dataIndex: "diffKM",
      key: "differKM",
    },

  ]
  const cabsatdnmodal = () => {
    setcabatdmodalVisible(true);
  };
  const hideModalcabatdmodalVisible = (isVisible: any) => {
    setcabatdmodalVisible(isVisible);
    onTableSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'),pagination, SearchText);
  };

  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
  
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setFlag(false);
    }
    catch (ex) {
      console.log(ex);
    }
  
  }
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
  
      var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
      setFlag(false);
    }
    catch (ex) {
      console.log(ex);
    }
  
  }
  
  const adayclick = (event: any = null) => {
    setIsButtonClicked('Today');
    var first = moment();
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const twodayclick = () => {
    setIsButtonClicked('Yesday');
    var first = moment().add('days', -1);
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(false);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const monthclick = () => {
    setIsButtonClicked('Month');
    var first = moment();
    var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + '01';
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    console.log(fromdate);
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  
  const weekclick = () => {
    setIsButtonClicked('Week');
    var first = moment().add('days', -(moment().day()))
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
    setfromdate(moment(fromdate));
    console.log(fromdate);
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate = year + '-' + month + '-' + day;
    settodate(moment(todate));
    setFlag(true);
    onTableSearch(fromdate, todate, pagination, SearchText);
  };
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return (current && current > moment(todate).endOf("day"));
  };
  const onTableSearch = (fromdate:any, todate:any, pagination:any, SearchText: any) => {
    DateSearch(moment(fromdate).format("YYYY/MM/DD"), moment(todate).add("days", 1).format("YYYY/MM/DD"), pagination, SearchText);
    //DateSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'));
    //onTableReset();
  }

  const onTableClear = () => {
    selectedValues.cab_id = null;
    selectedValues.vehicleid = null;
    setSearchCabIdsList([]);
    setSearchVehicleIdsList([])
    /* let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.cab_id=null;
  copyselectedvalues.vehicleid=null;
    setSelectedValues(copyselectedvalues); */
    //adayclick();
    onTableSearch(fromdate.format('YYYY/MM/DD'),todate.format('YYYY/MM/DD'), pagination, SearchText);
  }



  const DateSearch = (fromdate: any, todate: any, pagination: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: fromdate,
        todate: todate,
        cabid:selectedValues.cab_id  || "ALL",
        vehicleid:selectedValues.vehicleid || "ALL",
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    
    axiosGet(UrlConstants.getdayreport, options).then((resp: any) => {
      resp.data.forEach((e: any) => {
        e.key = e.cabid;
      });
      setIsLoading(false);
      resp.data.sort((a:any,b:any) => a.cabid.localeCompare(b.cabid));
      setCurrentBookingGridData(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setComissionamount(resp.comissionamount);
      setEmptykm(resp.emptykm);
      setTotalamount(resp.totalamount);
      setTablelength(resp.tablelength);
      setTripscount(resp.tripscount);
      setTotalkm(resp.totalkm);
      console.log(resp);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
        setCurrentBookingGridData([]);
        setcopyCurrentBookingGridData([]);
      }
    });
  };


  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };
  useEffect(() => {
    console.log(isRowSelected);
    getcabids();
    getvehicleids();
    adayclick();
  }, []);
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };

  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      //console.log(value.otp, value.vehicleid);
      if (value.vehicleid === undefined || value.vehicleid === null)
        value.vehicleid = '';
      if (value.cabid === undefined || value.cabid === null)
        value.cabid = '';
      if (value.comissionamt === undefined || value.comissionamt === null)
        value.comissionamt = '';
      if (value.fare === undefined || value.fare === null)
        value.fare = '';
      if (value.trip === undefined || value.trip === null)
        value.trip = '';
      if (value.days === undefined || value.days === null)
        value.days = '';
      if (value.hours === undefined || value.hours === null)
        value.hours = '';
      if (value.totalkm === undefined || value.totalkm === null)
        value.totalkm = '';
      if (value.efficitiveKM === undefined || value.efficitiveKM === null)
        value.efficitiveKM = '';
      if (value.diffKM === undefined || value.diffKM === null)
        value.diffKM = '';
      /* if (value.diffKM === undefined)
        value.distance = ''; */



      return (
        value.vehicleid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.cabid.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.comissionamt.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.fare.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.trip.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.days.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.hours.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.totalkm.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.efficitiveKM.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.diffKM.toString().toLowerCase().includes(searchText.toLowerCase()) 
        /* ||
        value.distance.toString().toLowerCase().includes(searchText.toLowerCase()) */
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onVehicleIdChange = (value: any, fullInfo: any) => {
  let copyselectedvalues = { ...selectedValues };
  copyselectedvalues.vehicleid=value;
    setSelectedValues(copyselectedvalues);
  };
  const onCabIdChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.cab_id=value;
      setSelectedValues(copyselectedvalues);
  };


  const getcabids = () => {
    const uri = UrlConstants.getcabidlist + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      setcabIdsList(resp);
    });
  };
  const getvehicleids = () => {
    const uri = UrlConstants.getvehicleidslist + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      setvehicleIdsList(resp);
    });
  };

  const onCabIdSearch = (value:any) => {
      if (value) {
        // const searchedList = locationsList.filter(
        //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
        // );
        console.log(cabIdsList);
        
        var filtered = cabIdsList.filter((x: any) => String(x.cab_id?.toLowerCase()).includes(value.toLowerCase()));
        console.log(filtered);
        setSearchCabIdsList(filtered);
       /*  selectedValues.cab_id = '';
        selectedValues.vehicleid = ''; */
      } else {
        setSearchCabIdsList([]);
      }
    };

    const onVehicleIdSearch = (value:any) => {
      if (value) {
        // const searchedList = locationsList.filter(
        //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
        // );
        var filtered = vehicleIdsList.filter((x: any) => String(x.vehicleid?.toLowerCase()).includes(value.toLowerCase()));
        //console.log(searchedList,filtered);
        setSearchVehicleIdsList(filtered);
      } else {
        setSearchVehicleIdsList([]);
      }
    };
    const headersToExport = columns.map((column:any) => 
        ((column.title =='Fare') ? {label:column.title, key:'fare'} 
      : (column.title == 'Trip') ? {label:column.title, key:'trip'} 
      : (column.title == 'Total KM') ? {label:column.title, key:'totalkm'}
      : (column.title == 'Days') ? {label:column.title, key:'days'}
      : (column.title == 'Hours') ? {label:column.title, key:'hours'}
      : (column.title == 'Effective Km') ? {label:column.title, key:'efficitiveKM'}
      : (column.title == 'Differential km') ? {label:column.title, key:'diffKM'}
      : (column.title == 'Comission Amount') ? {label:column.title, key:'comissionamt'}
      : (column.title == 'Empty Km') ? {label:column.title, key:'diffKM'}
      : {label: column.title, key: column.dataIndex}))

    const dataToExport = currentBookingGridData.map((value:any) => ({
      ...value,
      comissionamt: (value.comissionamt != null && value.comissionamt!= undefined && value.comissionamt!='') ?
          Math.round(value.comissionamt) : value.comissionamt,
      fare: (value.fare != null && value.fare!= undefined && value.fare!='') ?
          Math.round(value.fare) : value.fare,
      trip: (value.trip != null && value.trip!= undefined && value.trip!='') ?
          Math.round(value.trip) : value.trip,
      totalkm: (value.totalkm != null && value.totalkm!= undefined && value.totalkm!='') ?
          Math.round(value.totalkm) : value.totalkm,
      days: (value.days != null && value.days!= undefined && value.days!='') ?
          Math.round(value.days) : value.days,
      hours: (value.hours != null && value.hours!= undefined && value.hours!='') ?
          Math.round(value.hours) : value.hours,
      efficitiveKM: (value.efficitiveKM != null && value.efficitiveKM!= undefined && value.efficitiveKM!='') ?
          Math.round(value.efficitiveKM) : value.efficitiveKM,
      diffKM: (value.diffKM != null && value.diffKM!= undefined && value.diffKM!='') ?
          Math.round(value.diffKM) : value.diffKM,
    }))
    const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
      setPagination(pagination);
      onTableSearch(fromdate, todate, pagination, SearchText);
      console.log(pagination); 
    }
    const onSearch = (value:any) => {
      console.log(value);
      setsearchText(value);
      onTableSearch(fromdate, todate, pagination, value);
    }

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
      <Row style={{ paddingBottom: 10 }}> <Col>  <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}> DAY REPORT</span> 
      </Col> 
       <Col offset={2}>
          <span style={{ fontSize: 'large' , fontWeight: 'bold', fontStyle:'Times New Roman' }}> From : {moment(fromdate).format('DD.MM.YYYY')}   To : {flag ? moment().format('DD.MM.YYYY') : moment(todate).format('DD.MM.YYYY')}  </span>
      
          </Col></Row>
        <Row style={{ paddingBottom: 10 }}>
         
          <Col >

          <span style={{ fontSize: 'medium' }}> From:</span>
 
 <DatePicker
 
     value={fromdate}
     format={dateFormat}
     disabledDate={disabledFromDate}  
     onChange={onfromdatechange} />
<span style={{ fontSize: 'medium' }}> To:</span>


<DatePicker
     value={flag? moment() : todate}
     //value={todate}
     format={dateFormat}
     disabledDate={disabledDate}  
     onChange={ontodatechange} />

          </Col>
          <Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>
          <Col span={2} offset={1} >
            <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
        </Row>
        <Row style={{paddingBottom:'10px'}}>
        <Col span={3}>
          <Select placeholder="Cab Id"
              style={{ width: 100 }}
              showSearch
              optionFilterProp="children"
              value={selectedValues.cab_id}
              onChange={onCabIdChange}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showArrow={false}
              onSearch={onCabIdSearch}
              notFoundContent={null}>

              {searchCabIdsList.map((id_: any) => (
                <Option key={id_.cab_id} value={id_.cab_id}>
                  {id_.cab_id}
                </Option>
              ))}
          </Select>
          </Col>
          <Col span={3}>
            <Select placeholder="Vehicle Id"
                  value={selectedValues.vehicleid}
                  style={{ width: 100 }}
                  showSearch
                  optionFilterProp="children"
                  onChange={onVehicleIdChange}
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  onSearch={onVehicleIdSearch}
                  notFoundContent={null}
                  >

                  {searchVehicleIdsList.map((id: any) => (
                    <Option key={id.vehicleid} value={id.vehicleid}>
                      {id.vehicleid}
                    </Option>
                  ))}
            </Select>
          </Col>
          <Col span={2}>
            <Button
              onClick={()=>onTableSearch(fromdate, todate, pagination, SearchText)}
              className="report-btn-blockSearch"
              disabled={moment(fromdate).isAfter(moment(todate))}>
              Search
            </Button>
          </Col>
          <Col span={2}>
            <Button
            htmlType="button"
              style={{marginLeft:10}}
              className="report-btn-blockClear"
              onClick={onTableClear}>
              Clear
            </Button>          
          </Col>
          <Col span={2} offset={2}>
            <Button
              className="report-btn-blocksms">
              Send sms
  </Button>
          </Col>
          <Col span={2} offset={1}>
            
 <CSVLink
      filename={"DayReport.csv"}
      /*  headers={columns.map((column:any)=> (column.title!='Empty %' ? 
            {label:column.title, key:column.dataIndex} : {label:'', key:''}))} */
            headers={headersToExport}
      data={dataToExport}
    >
      <Button className="report-btn-blockExport">Export</Button>
    </CSVLink>
          </Col>
          <Col span={4} offset={3}>
           {/*  <Input placeholder="Search Text" className="search" 
              value={SearchText}
              onChange={onSearchchange} /> */}
              <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
        
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enablePagination={pagination}
            onchange={onPaginationchange}
            scroll={{ x: 1850, y: 480 }}
          ></AppTable>
        </Spin>
      </div>
      <AppModalCabAtdncReport
        title="MoreInfo"
        selectedRowData={selectedRowData}
        hideModal={hideModalcabatdmodalVisible}
        visible={cabatdmodalVisible}
        onRowClickEnabled={false}
        customClass="new-booking-assign"
      />
    </div>
  );
};

export default DayReport;
