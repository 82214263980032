
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddTariff from "../../components/AppModal/AddTarriff";
import AddIntercitytariffTataAce from "../../components/AppModal/AddIntercityTarifTataAce";
import AddPackageTariff from "../../components/AppModal/AddPackageTariff";
import AddIntercitytariffBolero from "../../components/AppModal/AddIntercityTarifBolero";


const TariffMaster = () => {
  let history: any = useHistory();
  const [addtariff, setaddtariffmodal] = useState(false);
  const [packagetariffmodal, setpackagetariffmodal] = useState(false);
  const [addtariffTittle, setaddtariffTittle] = useState("Add Metre Tariff");
  const [addpackagetariffTittle, setaddpackagetariffTittle] = useState("Add Package Tariff");//addtariffTittle
  const [addintercitytarifftataace, setaddintercitytarifftataace] = useState(false);
  const [editintercityfaretataace, seteditintercityfaretataace] = useState(false);
  const [ModalTitle, setModalTitle] = useState("Add Intercity Tariff");
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [metrefarelist, setmetrefarelist] = useState([]);
  const [packagefarelist, setpackagefarelist] = useState([]);
  const [intercitytataacefarelist, setintercitytataacefarelist] = useState([]);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [dittariff, setedittariff] = useState(false);
  const [editpackagetariff, seteditpackagetariff] = useState(false);//setedittariff
  const [selectedValues, setselectedValues]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copymetrefarelist, setCopymetrefarelist] = useState([]);
  const [copypackagefarelist, setCopypackagefarelist] = useState([]);
  const [copyintercitytataacefarelist, setCopyintercitytataacefarelist] = useState([]);

  const [addintercitytariffbolero, setaddintercitytariffbolero] = useState(false);
  const [editintercityfarebolero, seteditintercityfarebolero] = useState(false);
  const [intercitybolerofarelist, setintercitybolerofarelist] = useState([]);
  const [copyintercitybolerofarelist, setCopyintercitybolerofarelist] = useState([]);

  const [availableCabData, setAvailableCabData] = useState([]);


  const metrefarecolumns = [
    {
      title: "Vehicle",
      dataIndex: "Vechicle",
      key: "Vehicle",



    },
    {
      title: "Capacity",
      dataIndex: "LoadCapacity",
      key: "Capacity",

    },
    {
      title: "Base Fare",
      dataIndex: "BaseFare",
      key: "Base Fare",

    },
    {
      title: "Base KM",
      dataIndex: "BaseKM",
      key: "Base KM",

    },
    {
      title: "Base Minute",
      dataIndex: "BaseMinute",
      key: "Base Minute",

    },

    {
      title: " Add.Km Charges",
      dataIndex: "AddKMCharge",
      key: " Add.Km Charges",

    },
    {
      title: " Add Minute charges",
      dataIndex: "AddMinuteCharge",
      key: "Add Minute charges",

    },

  ];
  const packagefarecolumns = [
    {
      title: "Vehicle",
      dataIndex: "Vechicle",
      key: "Vehicle",



    },
    {
      title: "Capacity",
      dataIndex: "LoadCapacity",
      key: "Capacity",

    },
    {
      title: "Base Fare",
      dataIndex: "Rupees",
      key: "Base Fare",

    },
    {
      title: "Base KM",
      dataIndex: "KM",
      key: "Base KM",

    },
    {
      title: "Hours",
      dataIndex: "Hours",
      key: "Base Minute",

    },

    {
      title: " Add.Km Charges",
      dataIndex: "AddKMCharge",
      key: " Add.Km Charges",

    },
    {
      title: " Add Minute charges",
      dataIndex: "AddMinuteCharge",
      key: "Add Minute charges",

    },

  ];
  const intercitytataacefarecolumns = [

    {
      title: "Base KM",
      dataIndex: "BASEKM",
      key: "BASEKM",

    },
    {
      title: "Base Fare",
      dataIndex: "BASEAMOUNT",
      key: "BASEAMOUNT",

    },
    {
      title: "Base Time",
      dataIndex: "BASETIME",
      key: "BASETIME",

    },
    {
      title: "R-Base KM",
      dataIndex: "RoundtripKM",
      key: "RoundtripKM",

    },

    {
      title: " R-Base Fare",
      dataIndex: "ReturnAMOUNT",
      key: " ReturnAMOUNT",

    },
    {
      title: " R-Base Time",
      dataIndex: "RBASETIME",
      key: "RBASETIME",

    },
    {
      title: " Total",
      dataIndex: "TOTAL",
      key: "TOTAL",

    },
    {
      title: "Tolerance Km",
      dataIndex: "TollerenceKM",
      key: "TollerencKM",



    },


  ];
  const intercitybolerofarecolumns = [

    {
      title: "Base KM",
      dataIndex: "BASEKM",
      key: "BASEKM",

    },
    {
      title: "Base Fare",
      dataIndex: "BASEAMOUNT",
      key: "BASEAMOUNT",

    },
    {
      title: "Base Time",
      dataIndex: "BASETIME",
      key: "BASETIME",

    },
    {
      title: "R-Base KM",
      dataIndex: "RoundtripKM",
      key: "RoundtripKM",

    },

    {
      title: " R-Base Fare",
      dataIndex: "ReturnAMOUNT",
      key: " ReturnAMOUNT",

    },
    {
      title: " R-Base Time",
      dataIndex: "RBASETIME",
      key: "RBASETIME",

    },
    {
      title: " Total",
      dataIndex: "TOTAL",
      key: "TOTAL",

    },
    {
      title: "Tolerance Km",
      dataIndex: "TollerenceKM",
      key: "TollerencKM",



    },


  ];


  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };





  useEffect(() => {

    getmetrefarelist();
    //getpackagefarelist();
    getintercitytataacefarelist();
    getintercitybolerofarelist();
    refresh();
  }, []);

  const refresh = () => {
    console.log("refresh");
    let metrefarelistresp: any;
    const uripackage = UrlConstants.getpackagefarelist + "?CityId=" + branchid;
    const uritataace = UrlConstants.getIntercityFareList + "?CityId=" + branchid;
    const uribolero = UrlConstants.getIntercityFareList + "?CityId=" + branchid;

    axiosGet(UrlConstants.getmetrefarelist).then((resp: any) => {

      if (resp != undefined) {
        resp.data.forEach((e: any) => {
          e.key = e.Vechicle.toString();
        });
        setmetrefarelist(resp.data);
        setCopymetrefarelist(resp.data);
        metrefarelistresp = resp.data;
        //getpackagefarelist(resp);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });

    axiosGet(uripackage).then((resp: any) => {

      if (resp != undefined) {
        resp.data.forEach((e: any) => {
          console.log(metrefarelistresp, Number(e.VehicleID));
          let vehicleindex = metrefarelistresp.findIndex((obj: { VechicleTypeId: any; }) => obj.VechicleTypeId === Number(e.VehicleID));
          console.log(vehicleindex);
          e.LoadCapacity = metrefarelistresp[vehicleindex].LoadCapacity;
          e.Vechicle = metrefarelistresp[vehicleindex].Vechicle;
          e.key = e.ID.toString();
        });
        setpackagefarelist(resp.data);
        setCopypackagefarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });

    axiosGet(uritataace).then((resp: any) => {

      if (resp != undefined) {
        resp.data.forEach((e: any) => {

          e.key = e.ID.toString();
        });
        setintercitytataacefarelist(resp.data);
        setCopyintercitytataacefarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });


    axiosGet(uribolero).then((resp: any) => {

      if (resp != undefined) {
        resp.data.forEach((e: any) => {

          e.key = e.ID.toString();
        });
        setintercitybolerofarelist(resp.data);
        setCopyintercitybolerofarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });

  }

  const showaddintercitytariffTataAce = () => {
    setModalTitle("Add Intercity Tariff - TataAce");
    setaddintercitytarifftataace(true);
    seteditintercityfaretataace(false);

  };
  const showeditintercitytariffTataAce = () => {

    setModalTitle("Edit Intercity Tariff - TataAce");
    let copyselectedvalues: any = { ...selectedRowData[0] }
    console.log(copyselectedvalues);
    setselectedValues(copyselectedvalues);
    setaddintercitytarifftataace(true);
    seteditintercityfaretataace(true);
    console.log(selectedRowData);

  }
  const showaddintercitytariffBolero = () => {
    setModalTitle("Add Intercity Tariff - Bolero");
    setaddintercitytariffbolero(true);
    seteditintercityfarebolero(false);

  };
  const showeditintercitytariffBolero = () => {

    setModalTitle("Edit Intercity Tariff - Bolero");
    let copyselectedvalues: any = { ...selectedRowData[0] }
    console.log(copyselectedvalues);
    setselectedValues(copyselectedvalues);
    setaddintercitytariffbolero(true);
    seteditintercityfarebolero(true);
    console.log(selectedRowData);

  }


  const showaddtariff = () => {
    setaddtariffTittle("Add Metre Tariff");
    setedittariff(false);
    setaddtariffmodal(true);
  };
  const showedittariff = () => {
    setaddtariffTittle("Edit Metre Tariff");
    let copyselectedvalues: any = { ...selectedRowData[0] }
    console.log(copyselectedvalues);
    setselectedValues(copyselectedvalues);
    setedittariff(true);
    setaddtariffmodal(true);
  };
  const showpackagetariff = () => {
    setaddpackagetariffTittle("Add Package Tariff");
    seteditpackagetariff(false);
    setpackagetariffmodal(true);
  };
  const showeditpackagetariff = () => {
    setaddpackagetariffTittle("Edit Package Tariff");
    let copyselectedvalues: any = { ...selectedRowData[0] }
    console.log(copyselectedvalues);
    setselectedValues(copyselectedvalues);
    seteditpackagetariff(true);
    setpackagetariffmodal(true);
  };



  const deleteintercitytarifftataace = () => {

    const options: any = {
      data: {
        ID: selectedRowData[0].ID,

      },
    };
    console.log(options);
    axiosPost(UrlConstants.deleteintercityfare, options)
      .then((resp: any) => {
        console.log(resp);
        refresh();

        const notificationInfo: any = {
          header: "Intercity deleted Successfully",
          description: "Intercity for " + selectedRowData[0].ID + " deleted",
        };

        openNotificationWithIcon("Success", notificationInfo);

      })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Deleting Failed",
          description: "Error Occurred while Deleting Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
    const openNotificationWithIcon = (type: any, info: any) => {
      if (type === "Success") {
        notification.success({
          message: info.header,
          description: info.description,
        });
      } else if (type === "Error") {
        notification.error({
          message: info.header,
          description: info.description,
        });
      }
    };

  };
  const deleteintercitytariffbolero = () => {

    const options: any = {
      data: {
        ID: selectedRowData[0].ID,

      },
    };
    console.log(options);
    axiosPost(UrlConstants.deleteintercityfare, options)
      .then((resp: any) => {
        console.log(resp);
        refresh();
        const notificationInfo: any = {
          header: "Intercity deleted Successfully",
          description: "Intercity for " + selectedRowData[0].ID + " deleted",
        };

        openNotificationWithIcon("Success", notificationInfo);

      })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Intercity Tariff Deleting Failed",
          description: "Error Occurred while Deleting Intercity Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
    const openNotificationWithIcon = (type: any, info: any) => {
      if (type === "Success") {
        notification.success({
          message: info.header,
          description: info.description,
        });
      } else if (type === "Error") {
        notification.error({
          message: info.header,
          description: info.description,
        });
      }
    };

  };

  const getmetrefarelist = () => {

    axiosGet(UrlConstants.getmetrefarelist).then((resp: any) => {
      console.log(resp);
      if (resp != undefined) {
        resp.data.forEach((e: any) => {
          e.key = e.Vechicle.toString();
        });
        setmetrefarelist(resp.data);
        setCopymetrefarelist(resp.data);
        getpackagefarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const deletemetretariff = () => {

    const options: any = {
      data: {
        id: selectedRowData[0].id,

      },
    };
    console.log(options);
    axiosPost(UrlConstants.deletemetrefare, options)
      .then((resp: any) => {
        console.log(resp);
        refresh();
        const notificationInfo: any = {
          header: "MetreFare deleted Successfully",
          // description: "Intercity for " + selectedRowData[0].ID + " deleted",
        };

        openNotificationWithIcon("Success", notificationInfo);

      })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Metre Tariff Deleting Failed",
          description: "Error Occurred while Deleting Metre Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
    const openNotificationWithIcon = (type: any, info: any) => {
      if (type === "Success") {
        notification.success({
          message: info.header,
          description: info.description,
        });
      } else if (type === "Error") {
        notification.error({
          message: info.header,
          description: info.description,
        });
      }
    };

  };
  const deletepackagetariff = () => {

    const options: any = {
      data: {
        ID: selectedRowData[0].ID,

      },
    };
    console.log(options);
    axiosPost(UrlConstants.deletepackagefare, options)
      .then((resp: any) => {
        console.log(resp);
        refresh();
        const notificationInfo: any = {
          header: "PackageFare deleted Successfully",
          // description: "Intercity for " + selectedRowData[0].ID + " deleted",
        };

        openNotificationWithIcon("Success", notificationInfo);

      })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Package Tariff Deleting Failed",
          description: "Error Occurred while Deleting Package Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
    const openNotificationWithIcon = (type: any, info: any) => {
      if (type === "Success") {
        notification.success({
          message: info.header,
          description: info.description,
        });
      } else if (type === "Error") {
        notification.error({
          message: info.header,
          description: info.description,
        });
      }
    };

  };
  const getpackagefarelist = (metrefarelist: any) => {
    const uri = UrlConstants.getpackagefarelist + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      if (resp != undefined) {
        resp.data.forEach((e: any) => {
          console.log(metrefarelist, Number(e.VehicleID));
          let vehicleindex = metrefarelist.findIndex((obj: { VechicleTypeId: any; }) => obj.VechicleTypeId === Number(e.VehicleID));
          console.log(vehicleindex);
          e.LoadCapacity = metrefarelist[vehicleindex].LoadCapacity;
          e.Vechicle = metrefarelist[vehicleindex].Vechicle;
          e.key = e.ID.toString();
        });
        setpackagefarelist(resp.data);
        setCopypackagefarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getintercitytataacefarelist = () => {
    const uri = UrlConstants.getIntercityFareList + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      if (resp != undefined) {
        resp.data.forEach((e: any) => {

          e.key = e.ID.toString();
        });
        setintercitytataacefarelist(resp.data);
        setCopyintercitytataacefarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const getintercitybolerofarelist = () => {
    const uri = UrlConstants.getIntercityFareList + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {

      if (resp != undefined) {
        resp.data.forEach((e: any) => {

          e.key = e.ID.toString();
        });
        setintercitybolerofarelist(resp.data);
        setCopyintercitybolerofarelist(resp.data);
      }
    })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const hideModal = (isVisible: any) => {
    setaddtariffmodal(isVisible);
    setaddintercitytarifftataace(isVisible);
    setaddintercitytariffbolero(isVisible);
    setpackagetariffmodal(isVisible);
    refresh();
    setsearchText('');
  };

  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearchMeterFare(e.target.value);
    globalSearchPackageFare(e.target.value);
    globalSearchIntercityFareTataace(e.target.value);
    globalSearchIntercityFareBolero(e.target.value);
  };


  const globalSearchMeterFare = (searchText: any) => {
    let filteredData = metrefarelist.filter((value: any) => {
      if (value.Vechicle === undefined || value.Vechicle === null)
        value.Vechicle = '';
      if (value.LoadCapacity === undefined || value.LoadCapacity === null)
        value.LoadCapacity = '';
      if (value.BaseFare === undefined || value.BaseFare === null)
        value.BaseFare = '';
      if (value.BaseKM === undefined || value.BaseKM === null)
        value.BaseKM = '';
      if (value.BaseMinute === undefined || value.BaseMinute === null)
        value.BaseMinute = '';
      if (value.AddKMCharge === undefined || value.AddKMCharge === null)
        value.AddKMCharge = '';
      if (value.AddMinuteCharge === undefined || value.AddMinuteCharge === null)
        value.AddMinuteCharge = '';

      return (

        value.Vechicle
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.LoadCapacity
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BaseFare
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BaseKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BaseMinute
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.AddKMCharge
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.AddMinuteCharge
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setCopymetrefarelist(filteredData);
  };
  const globalSearchPackageFare = (searchText: any) => {
    let filteredData = packagefarelist.filter((value: any) => {
      if (value.Vechicle === undefined || value.Vechicle === null)
        value.Vechicle = '';
      if (value.LoadCapacity === undefined || value.LoadCapacity === null)
        value.LoadCapacity = '';
      if (value.Rupees === undefined || value.Rupees === null)
        value.Rupees = '';
      if (value.KM === undefined || value.KM === null)
        value.KM = '';
      if (value.Hours === undefined || value.Hours === null)
        value.Hours = '';
      if (value.AddKMCharge === undefined || value.AddKMCharge === null)
        value.AddKMCharge = '';
      if (value.AddMinuteCharge === undefined || value.AddMinuteCharge === null)
        value.AddMinuteCharge = '';

      return (

        value.Vechicle
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.LoadCapacity
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.Rupees
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.KM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.Hours
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.AddKMCharge
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.AddMinuteCharge
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setCopypackagefarelist(filteredData);
  };
  const globalSearchIntercityFareTataace = (searchText: any) => {
    let filteredData = intercitytataacefarelist.filter((value: any) => {
      if (value.BASEKM === undefined || value.BASEKM === null)
        value.BASEKM = '';
      if (value.BASEAMOUNT === undefined || value.BASEAMOUNT === null)
        value.BASEAMOUNT = '';
      if (value.BASETIME === undefined || value.BASETIME === null)
        value.BASETIME = '';
      if (value.RoundtripKM === undefined || value.RoundtripKM === null)
        value.RoundtripKM = '';
      if (value.ReturnAMOUNT === undefined || value.ReturnAMOUNT === null)
        value.ReturnAMOUNT = '';
      if (value.RBASETIME === undefined || value.RBASETIME === null)
        value.RBASETIME = '';
      if (value.TollerenceKM === undefined || value.TollerenceKM === null)
        value.TollerenceKM = '';
      if (value.TOTAL === undefined || value.TOTAL === null)
        value.TOTAL = '';

      return (

        value.BASEKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BASEAMOUNT
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BASETIME
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.RoundtripKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.ReturnAMOUNT
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.RBASETIME
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.TollerenceKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.TOTAL
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setCopyintercitytataacefarelist(filteredData);
  };
  const globalSearchIntercityFareBolero = (searchText: any) => {
    let filteredData = intercitybolerofarelist.filter((value: any) => {
      if (value.BASEKM === undefined || value.BASEKM === null)
        value.BASEKM = '';
      if (value.BASEAMOUNT === undefined || value.BASEAMOUNT === null)
        value.BASEAMOUNT = '';
      if (value.BASETIME === undefined || value.BASETIME === null)
        value.BASETIME = '';
      if (value.RoundtripKM === undefined || value.RoundtripKM === null)
        value.RoundtripKM = '';
      if (value.ReturnAMOUNT === undefined || value.ReturnAMOUNT === null)
        value.ReturnAMOUNT = '';
      if (value.RBASETIME === undefined || value.RBASETIME === null)
        value.RBASETIME = '';
      if (value.TollerenceKM === undefined || value.TollerenceKM === null)
        value.TollerenceKM = '';
      if (value.TOTAL === undefined || value.TOTAL === null)
        value.TOTAL = '';

      return (

        value.BASEKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BASEAMOUNT
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.BASETIME
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.RoundtripKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.ReturnAMOUNT
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.RBASETIME
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.TollerenceKM
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.TOTAL
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setCopyintercitybolerofarelist(filteredData);
  };
  return (
    <div className="booking-child-routes new-booking">

      <h4>Meter Fare</h4>
      <Row gutter={24} style={{ padding: '10px 0' }}>
        <Col span={2}>
          <Button onClick={showaddtariff}>

            Add
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={showedittariff}
            disabled={!isRowSelected}

          >
            Edit
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={deletemetretariff}
            disabled={!isRowSelected}

          >
            Delete
          </Button>
        </Col>
        <Col span={4} offset={12}>
          <Input placeholder="Search Text"
            value={SearchText}
            onChange={onSearchchange}
          />
        </Col>
      </Row>
      <AppTable

        columns={metrefarecolumns}
        data={copymetrefarelist}
        enableRadioButton={true}
        onRadioButtonChange={onGridRowSelect}
        enablePagination={true}
        selectionType="radio"
        size="small"
      ></AppTable>
      <AddTariff
        title={addtariffTittle}
        visible={addtariff}
        isedit={dittariff}
        selectedRowData={selectedValues}
        hideModal={hideModal}
      ></AddTariff>

      <h4>Package Fare</h4>
      <Row style={{ padding: '10px 0' }}>
        <Col span={2}>
          <Button onClick={showpackagetariff} >

            Add
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            disabled={!isRowSelected}
            onClick={showeditpackagetariff}
          >
            Edit
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={deletepackagetariff}
            disabled={!isRowSelected}

          >
            Delete
          </Button>
        </Col>
      </Row>
      <AppTable

        columns={packagefarecolumns}
        data={copypackagefarelist}
        enableRadioButton={true}
        onRadioButtonChange={onGridRowSelect}
        enablePagination={true}
        selectionType="radio"
        size="small"
      ></AppTable>
      <AddPackageTariff
        title={addpackagetariffTittle}
        visible={packagetariffmodal}
        isedit={editpackagetariff}
        selectedRowData={selectedValues}
        hideModal={hideModal}>

      </AddPackageTariff>
      <h4>Intercity Fare - TataAce</h4>
      <Row style={{ padding: '10px 0' }}>
        <Col span={2}>
          <Button onClick={showaddintercitytariffTataAce} >

            Add
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={showeditintercitytariffTataAce}
            disabled={!isRowSelected}
          >
            Edit
          </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={deleteintercitytarifftataace}
            disabled={!isRowSelected}
          >
            Delete
          </Button>
        </Col>
      </Row>

      <AppTable

        columns={intercitytataacefarecolumns}
        data={copyintercitytataacefarelist}
        enableRadioButton={true}
        onRadioButtonChange={onGridRowSelect}
        enablePagination={true}
        selectionType="radio"
        size="small"
      ></AppTable>
      <AddIntercitytariffTataAce title={ModalTitle}
        visible={addintercitytarifftataace}
        selectedRowData={selectedValues}
        hideModal={hideModal}
        isedit={editintercityfaretataace}
      ></AddIntercitytariffTataAce>
      <br />
      {/* <h4>Intercity Fare - Bolero</h4>
      <Row style={{padding:'10px 0'}}>
        <Col span={2}>
          <Button onClick={showaddintercitytariffBolero} >

            Add
            </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={showeditintercitytariffBolero}
            disabled={!isRowSelected}
          >
            Edit
            </Button>
        </Col>
        <Col span={2} offset={1}>
          <Button
            onClick={deleteintercitytariffbolero}
            disabled={!isRowSelected}
          >
            Delete
            </Button>
        </Col>
      </Row>

      <AppTable

        columns={intercitybolerofarecolumns}
        data={copyintercitybolerofarelist}
        enableRadioButton={true}
        onRadioButtonChange={onGridRowSelect}
        enablePagination={true}
        selectionType="radio"
        size="small"
      ></AppTable>
      <AddIntercitytariffBolero title={ModalTitle}
        visible={addintercitytariffbolero}
        selectedRowData={selectedValues}
        hideModal={hideModal}
        isedit={editintercityfarebolero}
      ></AddIntercitytariffBolero> */}
    </div>




  );
};

export default TariffMaster;
