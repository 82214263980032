import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, notification } from "antd";
import "./AppModal.scss";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";


const DriverStatusUpdateModal = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);

  useEffect(()=>{
    setVisible(props.visible);
    if(props.selectedRowData)
      setSelectedRowData(props.selectedRowData);
  })

  const updateDriverStatus = () => {
    const options: any = {
      data: {
        cabid: selectedRowData.cabid
      },
    };
    console.log(options);
    axiosPost(UrlConstants.updateDriverStatus, options)
      .then((resp: any) => {
        handleCancel();
        console.log(resp);
        const notificationInfo: any = {
          header: "Driver status updated Successfully",
          description: "Cabid " + selectedRowData.cabid + " status updated",
        };
        openNotificationWithIcon("Success", notificationInfo);
  
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Driver status update Failed",
          description: "Error Occurred while updating Driver status",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  }
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  
const revertlogin = () => {
  const options: any = {
    data: {
      cabid: selectedRowData.cabid
    },
  };
  console.log(options);
  axiosPost(UrlConstants.revertlogout, options)
    .then((resp: any) => {
      console.log(resp);
      handleCancel();
      const notificationInfo: any = {
        header: "Reverted Login Successfully",
        description: "Cabid " + selectedRowData.cabid + " login reverted",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: "Revert Login Failed",
        description: "Error Occurred while Reverting Login",
      };
      openNotificationWithIcon("Error", notificationInfo);
    });

};
const revertlogout = () => {
  const options: any = {
    data: {
      cabid: selectedRowData.cabid
    },
  };
  console.log(options);
  axiosPost(UrlConstants.revertlogin, options)
    .then((resp: any) => {
      console.log(resp);
      handleCancel();
      const notificationInfo: any = {
        header: "Reverted Logout Successfully",
        description: "Cabid " + selectedRowData.cabid + " logout reverted",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: "Revert Logout Failed",
        description: "Error Occurred while Reverting Logout",
      };
      openNotificationWithIcon("Error", notificationInfo);
    });

};
  const handleCancel = () => {
    props.hideModal(false);
  }


  return (<>
     <Modal 
        className='custom-modal-form confirm-modal'
        visible={visible} 
        onCancel={handleCancel}
        footer={[]}
        width={360}
        centered>
            {
              (props.revertType == "login") && 
            <p>Are you sure you want to revert login for <b>{selectedRowData.cabid}</b>?</p>
            }  
            {
              (props.revertType == "logout") && 
            <p>Are you sure you want to revert logout for {selectedRowData.cabid}?</p>
            } 
            {
              (props.revertType == "ontrip") && 
            <p>Are you sure you want to change {selectedRowData.cabid} from Ontrip to Available?</p>
            }    
            {
              (props.revertType == "login") && 
              <Button className="driver-attendance-btn-onduty modal-button-width" 
                style={{marginRight: '10px'}} htmlType="submit" 
                onClick={revertlogin}>
                Yes
            </Button>
            }
            {
              (props.revertType == "logout") && 
              <Button className="driver-attendance-btn-onduty modal-button-width" 
                style={{marginRight: '10px'}} htmlType="submit" 
                onClick={revertlogout}>
                Yes
            </Button>
            }
            {
              (props.revertType == "ontrip") && 
              <Button className="driver-attendance-btn-onduty modal-button-width" 
                style={{marginRight: '10px'}} htmlType="submit" 
                onClick={updateDriverStatus}>
                Yes
            </Button>
            }
            <Button className="driver-attendance-btn-blocked modal-button-width" htmlType="submit" onClick={handleCancel}>
                No
            </Button>
    </Modal>
  </>);
}

export default DriverStatusUpdateModal;