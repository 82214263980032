import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
  DatePicker,
  Checkbox,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
// import OtpInput from 'react-otp-input';
import Ownerform from "../AppModal/Ownerform";
import Vehicleform from "../AppModal/Vehicleform";
import Driverform from "../AppModal/Driverform";
import {
  PlusOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddVehicleMaster = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [Addvehiclevisible, setAddVehicleVisible] = useState(false);
  const [Addownervisible, setAddownerVisible] = useState(false);
  const [AddDrivervisible, setAddDriverVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [IdsList, setIdsList] = useState([]);
  const [ownersList, setownersList] = useState([]);
  const [defaultDateTime, setDefaultDateTime]: any = useState();
  const [editvehicle, seteditvehicle] = useState(false);
  const [vehicleformtittle, setvehicleformtittle]: any =
    useState("Add Vehicle");
  const [editowner, seteditowner] = useState(false);
  const [ownerformtittle, setownerformtittle]: any = useState("Add Owner");
  const [driverformtittle, setdriverformtittle]: any = useState("Add Driver");
  const [editdriver, seteditdriver] = useState(false);
  const [oldChecked, setoldChecked] = useState(false);
  const [cabavailablity, setcabavailability] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    getVechileTypeList();
    getLocations(branchid);
    //getnewid();
    getavalableids();
    getowerslist();
    if (props.isedit) {
      let copyvalue = { ...props.selectedRowData };
      copyvalue.MtruckId = props.selectedRowData.cab_id;
      copyvalue.Branchid = props.selectedRowData.CityID;
      copyvalue.vehicleid = props.selectedRowData.vehicleid;
      copyvalue.car_Type = props.selectedRowData.car_Type;
      copyvalue.vehicleType = props.selectedRowData.vehicletype;
      copyvalue.Bodytype = props.selectedRowData.type;
      copyvalue.vehiclename = props.selectedRowData.vehiclename;
      copyvalue.cabtype = props.selectedRowData.dtype;
      copyvalue.ownerid = props.selectedRowData.owner_id;
      copyvalue.ownername = props.selectedRowData.owner_name;
      copyvalue.ownerno = props.selectedRowData.owner_mobno;
      copyvalue.alterno = props.selectedRowData.owneraltermobno;
      copyvalue.address = props.selectedRowData.owner_address;
      copyvalue.aadhaarno = props.selectedRowData.owneraadhar;
      copyvalue.owerbadge = props.selectedRowData.owerbadge;
      copyvalue.owerbadgeexp =
        props.selectedRowData.owerbadgeexp != null &&
        props.selectedRowData.owerbadgeexp != ""
          ? moment(props.selectedRowData.owerbadgeexp, "YYYY-MM-DD")
          : "";
      copyvalue.owerlic = props.selectedRowData.owerlic;
      copyvalue.owerlicexp =
        props.selectedRowData.owerlicexp != null &&
        props.selectedRowData.owerlicexp != ""
          ? moment(props.selectedRowData.owerlicexp, "YYYY-MM-DD")
          : "";
      copyvalue.accno = props.selectedRowData.accno;
      copyvalue.ifsc = props.selectedRowData.ifsc;
      //copyvalue.bankname = props.selectedRowData.bankname;
      copyvalue.branch = props.selectedRowData.branch;
      copyvalue.pan = props.selectedRowData.pan;
      copyvalue.driverid = props.selectedRowData.driver_id;
      copyvalue.drivername = props.selectedRowData.driver_name;
      copyvalue.personalno = props.selectedRowData.driver_mobno;
      copyvalue.driverno = props.selectedRowData.driver_almobno;
      copyvalue.daddress = props.selectedRowData.driver_address;
      copyvalue.dlicno = props.selectedRowData.driver_licenceno;
      copyvalue.driver_lic_val =
        props.selectedRowData.driver_lic_val != null &&
        props.selectedRowData.driver_lic_val != ""
          ? moment(props.selectedRowData.driver_lic_val, "YYYY-MM-DD")
          : "";
      copyvalue.batchno = props.selectedRowData.driverbadge;
      copyvalue.driverbadgeexp =
        props.selectedRowData.driverbadgeexp != null &&
        props.selectedRowData.driverbadgeexp != ""
          ? moment(props.selectedRowData.driverbadgeexp, "YYYY-MM-DD")
          : "";
      copyvalue.driveraccno = props.selectedRowData.driveraccno;
      copyvalue.driverifsc = props.selectedRowData.driverifsc;
      copyvalue.driverbankname = props.selectedRowData.driverbankname;
      copyvalue.driverbranch = props.selectedRowData.driverbranch;
      copyvalue.driverpan = props.selectedRowData.driverpan;
      copyvalue.daadharno = props.selectedRowData.driveraadhar;
      copyvalue.fc =
        props.selectedRowData.fc_val != null &&
        props.selectedRowData.fc_val != ""
          ? moment(props.selectedRowData.fc_val, "YYYY-MM-DD")
          : "";
      copyvalue.insurance =
        props.selectedRowData.inexpiry != null &&
        props.selectedRowData.inexpiry != ""
          ? moment(props.selectedRowData.inexpiry, "YYYY-MM-DD")
          : "";
      copyvalue.username = props.selectedRowData.username;
      copyvalue.password = props.selectedRowData.password;
      copyvalue.pollution =
        props.selectedRowData.policyexpirt != null &&
        props.selectedRowData.policyexpirt != ""
          ? moment(props.selectedRowData.policyexpirt, "YYYY-MM-DD")
          : "";
      copyvalue.loginloc = props.selectedRowData.Startlocation;
      copyvalue.present_location = props.selectedRowData.present_location;
      copyvalue.piclat = props.selectedRowData.piclat;
      copyvalue.piclon = props.selectedRowData.piclon;
      copyvalue.attdate =
        props.selectedRowData.AttachDate != null &&
        props.selectedRowData.AttachDate != ""
          ? moment(props.selectedRowData.AttachDate, "YYYY-MM-DD")
          : "";
      copyvalue.depamt = props.selectedRowData.Deposit;
      copyvalue.odameter = props.selectedRowData.odameter;
      copyvalue.appimei = props.selectedRowData.imei;
      copyvalue.tarpaulin = props.selectedRowData.tarpaulin;
      copyvalue.rope = props.selectedRowData.rope;
      copyvalue.keralapermit = props.selectedRowData.keralapermit;
      setSelectedValues(copyvalue);
    } else {
      getnewid();
      setSelectedValues({});
      setoldChecked(false);
      setcabavailability(false);
      setSearchFromLocationsList([]);
      setSelectedRowInfo({});
    }
  }, [props.visible]);

  const getnewid = () => {
    const uri = UrlConstants.getmtruckid + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      //console.log(resp);
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.MtruckId = resp[0].MtruckId;
      copyselectedvalues.Branchid = branchid;
      setSelectedValues(copyselectedvalues);
    });
  };

  const getavalableids = () => {
    const uri = UrlConstants.getavailablemtruckids + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      //console.log(resp);
      if (resp) {
        setIdsList(resp);
      } else {
        setIdsList([]);
      }
    });
  };

  const getowerslist = () => {
    const uri = UrlConstants.getownerslist + "?CityID=" + branchid;
    axiosGet(uri).then((resp: any) => {
      //console.log(resp);
      if (resp) {
        setownersList(resp);
      } else {
        setownersList([]);
      }
    });
  };

  const handleCancel = (e: any) => {
    setSelectedValues({});
    setSelectedRowInfo({});
    setSelectedFromLocation({});
    props.hideModal(false);
  };

  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  //Vehicle Type list
  const getVechileTypeList = () => {
    const options = {
      params: {
        cityid: branchid,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + branchid;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
      let vehicleindex = resp.findIndex((obj: { id: any }) => obj.id === 1);
      resp[vehicleindex].city = branchid;
    });
  };
  const Checkcabid = () => {
    const options = {
      params: {
        cab_id: selectedValues.MtruckId || selectedValues.cab_id,
      },
    };
    axiosGet(UrlConstants.checkcabavaialbility, options).then((resp: any) => {
      if (resp.isExist == false) {
        setcabavailability(true);
      } else {
        setcabavailability(false);

        const notificationInfo: any = {
          header: "Cab ID Already Exists",
        };
        openNotificationWithIcon("Error", notificationInfo);
      }
    });
  };
  const Addvehicle = () => {
    if (cabavailablity) {
      setAddVehicleVisible(true);
      if (props.isedit) {
        seteditvehicle(true);
        setvehicleformtittle("Edit Vehicle");
      } else setvehicleformtittle("Add Vehicle");
    }
  };
  const hidevehicleModal = (isVisible: any, AddedData: any) => {
    setAddVehicleVisible(isVisible);
    if (AddedData != null) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.vehicleid = AddedData.vehicleid;
      copyselectedvalues.vehicleType = AddedData.vehicletype;
      copyselectedvalues.Bodytype = AddedData.type;
      copyselectedvalues.fc = AddedData.fc_val ? moment(AddedData.fc_val) : "";
      copyselectedvalues.pollution = AddedData.policyexpirt
        ? moment(AddedData.policyexpirt)
        : "";
      copyselectedvalues.insurance = AddedData.inexpiry
        ? moment(AddedData.inexpiry)
        : "";

      copyselectedvalues.odameter = AddedData.odameter;

      copyselectedvalues.driverid = AddedData.driver_id;
      copyselectedvalues.drivername = AddedData.driver_name;
      copyselectedvalues.personalno = AddedData.driver_almobno;
      copyselectedvalues.driverno = AddedData.driver_mobno;
      copyselectedvalues.daddress = AddedData.driver_address;
      copyselectedvalues.daadharno = AddedData.driveraadhar;
      copyselectedvalues.dlicno = AddedData.driver_licenceno;
      copyselectedvalues.batchno = AddedData.driverbadge;

      setSelectedValues(copyselectedvalues);
    }
  };
  const Addowner = () => {
    if (cabavailablity) {
      setAddownerVisible(true);
      if (props.isedit) {
        seteditowner(true);
        setownerformtittle("Edit Owner");
      } else setownerformtittle("Add Owner");
    }
  };
  const hideownerModal = (isVisible: any, AddedData: any) => {
    setAddownerVisible(isVisible);
    if (AddedData != null) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.ownerid = AddedData.owner_id;
      copyselectedvalues.ownername = AddedData.owner_name;
      copyselectedvalues.ownerno = AddedData.owner_mobno;
      copyselectedvalues.alterno = AddedData.owneraltermobno;
      copyselectedvalues.address = AddedData.owner_address;
      copyselectedvalues.aadhaarno = AddedData.owneraadhar;
      setSelectedValues(copyselectedvalues);
    }
  };

  const AddDriver = () => {
    if (cabavailablity) {
      setAddDriverVisible(true);
      if (props.isedit) {
        seteditdriver(true);
        setdriverformtittle("Edit Driver");
      } else setdriverformtittle("Add Driver");
    }
  };
  const hidedriverModal = (isVisible: any, AddedData: any) => {
    setAddDriverVisible(isVisible);
    if (AddedData != null) {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.driverid = AddedData.driver_id;
      copyselectedvalues.drivername = AddedData.driver_name;
      copyselectedvalues.personalno = AddedData.driver_almobno;
      copyselectedvalues.driverno = AddedData.driver_mobno;
      copyselectedvalues.daddress = AddedData.driver_address;
      copyselectedvalues.daadharno = AddedData.driveraadhar;
      copyselectedvalues.dlicno = AddedData.driver_licenceno;
      copyselectedvalues.batchno = AddedData.driverbadge;
      setSelectedValues(copyselectedvalues);
    }
  };

  const OnmtrcukChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.MtruckId = value;
    setSelectedValues(copyselectedvalues);
  };
  const OnoldmtrcukChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.MtruckId = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };

  const onVehicleIdChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleid = value;
    setSelectedValues(copyselectedvalues);
  };

  const onBodytypeChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Bodytype = value;
    setSelectedValues(copyselectedvalues);
  };

  const onVehicleTypeChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleType = value;
    setSelectedValues(copyselectedvalues);
  };

  const oncabtypeChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.cabtype = value;
    setSelectedValues(copyselectedvalues);
  };

  const onOwnerIdChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.ownerid = value;
    setSelectedValues(copyselectedvalues);
  };

  const onOwnerNameChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.ownername = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onOwnerNoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.ownerno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onOwnerAlternoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.alterno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onOwnerAddressChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.address = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onAadhaarnoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.aadhaarno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerLicenseChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.owerlic = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerLicenseExpiryChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.owerlicexp = e;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerBatchChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.owerbadge = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerBatchExpChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.owerbadgeexp = e;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerAccountChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.accno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerIFSCChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.ifsc = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerBanknameChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.bankname = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerBranchChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.branch = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onOwnerPanChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.pan = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onDriveridChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverid = value;
    setSelectedValues(copyselectedvalues);
  };

  const onDrivernameChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.drivername = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onPersonalnoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.personalno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onDrivernoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onDaddressChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.daddress = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onDlicnoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.dlicno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onBatchnoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.batchno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onDaadharnoChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.daadharno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverLicenseExpiryChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driver_lic_val = e;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverBatchExpiryChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverbadgeexp = e;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverAccountChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driveraccno = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverIFSCChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverifsc = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverBanknameChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverbankname = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverBranchChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverbranch = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onDriverPanChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverpan = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onFcChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.fc = e;
    setSelectedValues(copyselectedvalues);
  };

  const onInsuranceChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.insurance = e;
    setSelectedValues(copyselectedvalues);
  };

  const onUsernameChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.username = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onPasswordChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.password = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onPollutionChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.pollution = e;
    setSelectedValues(copyselectedvalues);
  };

  const onCapacityChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.capacity = value;
    setSelectedValues(copyselectedvalues);
  };

  const onLlocChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.lloc = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onAttdateChange = (value: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.attdate = value;
    setSelectedValues(copyselectedvalues);
  };

  const ondepamtChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.depamt = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onodameterChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.odameter = e.target.value;
    setSelectedValues(copyselectedvalues);
  };

  const onappimeiChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.appimei = e.target.value;
    setSelectedValues(copyselectedvalues);
  };
  const onTarpaulinChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.tarpaulin = e;
    setSelectedValues(copyselectedvalues);
  };

  const onRopeChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.rope = e;
    setSelectedValues(copyselectedvalues);
  };

  const onKeralapermitChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.keralapermit = e;
    setSelectedValues(copyselectedvalues);
  };
  const onFromLocationChange = (value: any, options: any) => {
    setSelectedFromLocation(options.others);
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.fromloc = value;
    selectedValuesCopy.fromlocName = options.others.PlaceName;
    selectedValuesCopy.fromloclat = options.others.Latitude;
    selectedValuesCopy.fromloclong = options.others.Longitude;
    setSelectedValues(selectedValuesCopy);
    let obj = { ...selectedRowInfo };
    obj.toloclat = options.others.Latitude;
    obj.toloclong = options.others.Longitude;
    setSelectedRowInfo(obj);
  };
  const onFromLocationSearch = (value: any) => {
    if (value) {
      // const searchedList = locationsList.filter((x:any) => {
      //   x.PlaceName.startsWith(value)
      // });
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };
  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      setLocationsList(resp);
    });
  };

  const addnewvehiclemaster = () => {
    let attdate;
    if (selectedValues.attdate)
      attdate = selectedValues.attdate
        ? selectedValues.attdate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");

    const options: any = {
      data: {
        cab_id: selectedValues.MtruckId || selectedValues.cab_id,
        username: selectedValues.username || selectedValues.username,
        password: selectedValues.password || selectedValues.password,
        // Startlocation: selectedValues.fromlocName || selectedValues.Startlocation,
        Startlocation:
          selectedValues.fromlocName || selectedValues.present_location,
        present_location:
          selectedValues.fromlocName || selectedValues.present_location,
        AttachDate: attdate || moment().format("YYYY-MM-DD"),
        Deposit: selectedValues.depamt || selectedValues.Deposit,
        imei: selectedValues.appimei || selectedValues.imei,
        CityId: selectedValues.Branchid || branchid,
        dtype: selectedValues.cabtype || selectedValues.dtype,
        piclat: selectedRowInfo.toloclat || selectedValues.piclat,
        piclon: selectedRowInfo.toloclong || selectedValues.piclon,
        isold: oldChecked,
      },
    };
    let url = UrlConstants.addvehiclemaster;
    // if(props.isedit)
    //   url=UrlConstants.editvehiclemaster;
    axiosPost(url, options)
      .then((resp: any) => {
        if (resp.status != 401) {
          handleCancel("");
          let notificationInfo: any = {
            header: "Vehicle Created Successfully",
          };
          if (props.isedit)
            notificationInfo = {
              header: "Vehicle Updated Successfully",
            };
          openNotificationWithIcon("Success", notificationInfo);
        } else {
          let notificationInfo1: any;
          notificationInfo1 = {
            header: resp.message,
          };
          openNotificationWithIcon("Error", notificationInfo1);
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        let notificationInfo = {
          header: "Owner Adding Failed",
          description: "Error Occurred while Adding Owner",
        };
        if (props.isedit)
          notificationInfo = {
            header: "Owner Update Failed",
            description: "Error Occurred while Upadating Owner",
          };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const editvehiclemaster = () => {
    let attdate;
    let pollution;
    let insurance;
    let fc;
    let owerlicexp;
    let owerbadgeexp;
    let driver_lic_val;
    let driverbadgeexp;
    if (
      selectedValues.attdate ||
      selectedValues.pollution ||
      selectedValues.insurance ||
      selectedValues.fc ||
      selectedValues.owerlicexp ||
      selectedValues.owerbadgeexp ||
      selectedValues.driver_lic_val ||
      selectedValues.driverbadgeexp
    ) {
      attdate =
        selectedValues.attdate != null && selectedValues.attdate != ""
          ? selectedValues.attdate.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      pollution =
        selectedValues.pollution != null && selectedValues.pollution != ""
          ? selectedValues.pollution.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      insurance =
        selectedValues.insurance != null && selectedValues.insurance != ""
          ? selectedValues.insurance.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      fc =
        selectedValues.fc != null && selectedValues.fc != ""
          ? selectedValues.fc.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      owerlicexp =
        selectedValues.owerlicexp != null && selectedValues.owerlicexp != ""
          ? selectedValues.owerlicexp.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      owerbadgeexp =
        selectedValues.owerbadgeexp != null && selectedValues.owerbadgeexp != ""
          ? selectedValues.owerbadgeexp.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      driver_lic_val =
        selectedValues.driver_lic_val != null &&
        selectedValues.driver_lic_val != ""
          ? selectedValues.driver_lic_val.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
      driverbadgeexp =
        selectedValues.driverbadgeexp != null &&
        selectedValues.driverbadgeexp != ""
          ? selectedValues.driverbadgeexp.format("YYYY-MM-DD")
          : "" /* defaultDateTime.format("YYYY-MM-DD") */;
    }

    const options: any = {
      /*   data: {
        cab_no: selectedValues.MtruckId,
        CityID: selectedValues.Branchid,
        vehicleid: selectedValues.vehicleid,
        car_Type: selectedValues.car_Type,
        vehicletype: selectedValues.vehicleType,
        type: selectedValues.type,
        owner_id: selectedValues.owner_id,
        owner_name: selectedValues.owner_name,
        owner_mobno: selectedValues.owner_mobno,
        owneraltermobno: selectedValues.owneraltermobno,
        owner_address: selectedValues.owner_address,
        owneraadhar: selectedValues.owneraadhar,
        driver_id: selectedValues.driver_id,
        driver_name: selectedValues.driver_name,
        driver_mobno: selectedValues.driver_mobno,
        driver_almobno: selectedValues.driver_almobno,
        driver_address: selectedValues.driver_address,
        driveraadhar: selectedValues.driveraadhar,
        driver_licenceno: selectedValues.driver_licenceno,
        owerbadge: selectedValues.owerbadge,
        inexpiry: selectedValues.inexpiry,
        fc_val: selectedValues.fc_val,
        username: selectedValues.username,
        password: selectedValues.password,
        policyno: selectedValues.policyno,
        present_location: selectedValues.lloc,
        AttachDate: selectedValues.attdate,
        Deposit: selectedValues.depamt,
        odameter: selectedValues.odameter,
        imei: selectedValues.appimei,
        id: Number(selectedValues.cab_id.replace(/\D/g, "")),
        isenabled: "1",
      }, */
      data: {
        cab_no: selectedValues.MtruckId,
        car_color: "",
        CityID: selectedValues.Branchid,
        vehicleid: selectedValues.vehicleid,
        car_Type: selectedValues.car_Type,
        vehicletype: selectedValues.vehicleType,
        vehiclename: selectedValues.vehiclename,
        type: selectedValues.type,
        owner_id: selectedValues.ownerid,
        owner_name: selectedValues.ownername,
        owner_mobno: selectedValues.ownerno,
        owneraltermobno: selectedValues.alterno,
        owner_address: selectedValues.address,
        owneraadhar: selectedValues.aadhaarno,
        owerbadge: selectedValues.owerbadge,
        owerbadgeexp: owerbadgeexp || "",
        owerlic: selectedValues.owerlic,
        owerlicexp: owerlicexp || "",
        accno: selectedValues.accno,
        ifsc: selectedValues.ifsc,
        //bankname: selectedValues.bankname,
        branch: selectedValues.branch,
        pan: selectedValues.pan,
        driver_id: selectedValues.driverid,
        driver_name: selectedValues.drivername,
        driver_mobno: selectedValues.personalno,
        driver_almobno: selectedValues.driverno,
        driver_address: selectedValues.daddress,
        driveraadhar: selectedValues.daadharno,
        driver_licenceno: selectedValues.dlicno,
        driver_lic_val: driver_lic_val || selectedValues.driver_lic_val || "",
        driverbadge: selectedValues.batchno,
        driverbadgeexp: driverbadgeexp || selectedValues.driverbadgeexp || "",
        driveraccno: selectedValues.driveraccno,
        driverifsc: selectedValues.driverifsc,
        driverbankname: selectedValues.driverbankname,
        driverbranch: selectedValues.driverbranch,
        driverpan: selectedValues.driverpan,
        inexpiry: insurance || selectedValues.insurance || "",
        fc_val: fc || selectedValues.fc || "",
        username: selectedValues.username,
        password: selectedValues.password,
        policyexpirt: pollution || selectedValues.pollution || "",
        /*  Startlocation: selectedValues.fromlocName || selectedValues.loginloc,
        piclat: selectedValues.fromloclat || selectedValues.piclat,
        piclon: selectedValues.fromloclong || selectedValues.piclon, */
        //present_location: selectedValues.loginloc,
        Startlocation:
          selectedValues.fromlocName || selectedValues.present_location,
        present_location:
          selectedValues.fromlocName || selectedValues.present_location,
        piclat: selectedRowInfo.toloclat || selectedValues.piclat,
        piclon: selectedRowInfo.toloclong || selectedValues.piclon,
        StartLat: selectedRowInfo.toloclat || selectedValues.piclat,
        StartLon: selectedRowInfo.toloclong || selectedValues.piclon,
        AttachDate: attdate || selectedValues.attdate || "",
        Deposit: selectedValues.depamt,
        odameter: selectedValues.odameter,
        imei: selectedValues.appimei,
        tarpaulin: selectedValues.tarpaulin,
        rope: selectedValues.rope,
        keralapermit: selectedValues.keralapermit,
        id: Number(selectedValues.MtruckId.replace(/\D/g, "")),
        isenabled: "1",
        dtype: selectedValues.cabtype || selectedValues.dtype,
        //isold: oldChecked
      },
    };
    axiosPost(UrlConstants.editvehiclemaster, options)
      .then((resp: any) => {
        handleCancel("");
        //setSelectedValues({});
        const notificationInfo: any = {
          header: "Vehicle Updated Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Updating  Vehicle Failed",
          description: "Error Occurred while Updating Vehicle",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  return (
    <div>
      <Modal
        destroyOnClose={true}
        className="custom-modal-cancel-form"
        title={props.title}
        width={900}
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        afterClose={() => setSelectedValues({})}
      >
        <Form name="nest-messages" {...formItemLayout} preserve={false}>
          <Row gutter={24}>
            <Col span={3}>
              <Checkbox
                checked={oldChecked}
                onChange={() => {
                  setoldChecked(!oldChecked);
                }}
                disabled={props.isedit}
              >
                {"OLD"}
              </Checkbox>
            </Col>
            {oldChecked !== true ? (
              <Col span={9}>
                <Form.Item
                  name={["MtruckId", "MtruckId"]}
                  label="M-Truck ID"
                  rules={[]}
                >
                  <Select
                    value={selectedValues.MtruckId || selectedValues.cab_id}
                    defaultValue={
                      selectedValues.MtruckId || selectedValues.cab_id
                    }
                    placeholder="M-Truck ID"
                    onChange={OnmtrcukChange}
                    disabled={props.isedit}
                  >
                    {IdsList.map((id: any) => (
                      <Option key={id.cab_id} value={id.cab_id}>
                        {id.cab_id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col span={8}>
                <Form.Item
                  name={["MtruckId", "MtruckId"]}
                  label="M-Truck ID"
                  rules={[]}
                >
                  <Input
                    type="text"
                    value={selectedValues.MtruckId}
                    disabled={props.isedit}
                    onChange={OnoldmtrcukChange}
                  />
                </Form.Item>
              </Col>
            )}
            {oldChecked == true && (
              <Col span={1}>
                {cabavailablity == true ? (
                  <CheckCircleOutlined />
                ) : (
                  <QuestionCircleOutlined onClick={Checkcabid} />
                )}
              </Col>
            )}

            <Col span={12}>
              <Form.Item
                name={["branch", "branch"]}
                label="Select City"
                rules={[]}
              >
                <Select
                  placeholder="Select City"
                  value={selectedValues.Branchid}
                  defaultValue={selectedValues.Branchid}
                  // disabled={props.isedit}
                  onChange={onBranchChange}
                >
                  {branchList.map((branch: any) => (
                    <Option
                      disabled={cabavailablity == false && !props.isedit}
                      key={branch._id}
                      value={branch.id}
                    >
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Vehicle Id" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="Vehicle"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(95% - 8px)" }}
                >
                  <span style={{ display: "none" }}>
                    {selectedValues.vehicleid}
                  </span>
                  <Select
                    placeholder="VehicleId"
                    value={selectedValues.vehicleid}
                    defaultValue={selectedValues.vehicleid}
                    disabled={props.isedit}
                    onChange={onVehicleIdChange}
                  >
                    {IdsList.map((id: any) => (
                      <Option key={id.vehicleid} value={id.vehicleid}>
                        {id.vehicleid}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="addvehicle"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(5% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <PlusOutlined hidden={props.isedit} onClick={Addvehicle} />
                  {/* <EditOutlined hidden={!props.isedit} onClick={Addvehicle} /> */}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["bodytype", "bodytype"]}
                label="Body Type"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.Bodytype}
                </span>
                <Select
                  placeholder="BodyType"
                  defaultValue={selectedValues.Bodytype}
                  value={selectedValues.Bodytype}
                  disabled={props.isedit}
                  onChange={onBodytypeChange}
                >
                  <Option disabled={cabavailablity == false} value={1}>
                    Open Body
                  </Option>
                  <Option disabled={cabavailablity == false} value={2}>
                    Closed Body
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["vehicletype", "vehicletype"]}
                label="Vehicle Type"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.vehicleType}
                </span>
                <Select
                  placeholder="Vehicle Name"
                  value={selectedValues.vehicleType}
                  defaultValue={selectedValues.vehicleType}
                  disabled={props.isedit}
                  onChange={onVehicleTypeChange}
                >
                  {vehicleTypeList.map((vehicle: any) => (
                    <Option
                      disabled={cabavailablity == false}
                      key={vehicle.id}
                      value={vehicle.id}
                      others={vehicle}
                    >
                      {vehicle.vehicletype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["cabtype", "cabtype"]} label="Type" rules={[]}>
                <Select
                  placeholder="Type"
                  value={selectedValues.cabtype}
                  defaultValue={selectedValues.cabtype}
                  disabled={cabavailablity == false && !props.isedit}
                  onChange={oncabtypeChange}
                >
                  <Option value={1}>Self</Option>
                  <Option value={2}>Managed</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Owner ID" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="OwnerID"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(95% - 8px)" }}
                >
                  <span style={{ display: "none" }}>
                    {selectedValues.ownerid}
                  </span>
                  <Select
                    placeholder="OwnerID"
                    value={selectedValues.ownerid}
                    disabled={props.isedit}
                    defaultValue={selectedValues.ownerid}
                    onChange={onOwnerIdChange}
                  >
                    {ownersList.map((owner: any) => (
                      <Option key={owner.owner_id} value={owner.owner_id}>
                        {owner.owner_id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="addowner"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(5% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <PlusOutlined hidden={props.isedit} onClick={Addowner} />
                  {/* <EditOutlined hidden={!props.isedit} onClick={Addowner} /> */}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["ownername", "ownername"]}
                label="Owner Name"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.ownername}
                </span>
                <Input
                  type="text"
                  value={selectedValues.ownername}
                  defaultValue={selectedValues.ownername}
                  //disabled={props.isedit}
                  onChange={onOwnerNameChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["ownernumber", "ownernumber"]}
                label="Owner Number"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.ownerno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.ownerno}
                  defaultValue={selectedValues.ownerno}
                  //disabled={props.isedit}
                  onChange={onOwnerNoChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["alterno", "alterno"]}
                label="Alternative Number"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.alterno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.alterno}
                  defaultValue={selectedValues.alterno}
                  //disabled={props.isedit}
                  onChange={onOwnerAlternoChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["address", "address"]}
                label="Address"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.address}
                </span>
                <Input
                  type="text"
                  value={selectedValues.address}
                  defaultValue={selectedValues.address}
                  // disabled={props.isedit}
                  onChange={onOwnerAddressChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["aadhaarno", "aadhaarno"]}
                label="Aadhaar Number"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.aadhaarno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.aadhaarno}
                  defaultValue={selectedValues.aadhaarno}
                  //disabled={props.isedit}
                  onChange={onAadhaarnoChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {props.isedit && (
            <div>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["owerlic", "owerlic"]}
                    label="Owner License"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.owerlic}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.owerlic}
                      defaultValue={selectedValues.owerlic}
                      //disabled={props.isedit}
                      onChange={onOwnerLicenseChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    //name={["owerlicexp", "owerlicexp"]}
                    label="Owner License Expiry"
                    rules={[]}
                  >
                    {/* <span style={{ display: "none" }}>{selectedValues.aadhaarno}</span> */}
                    {/* <Input type="text"
                  value={selectedValues.aadhaarno}
                  defaultValue={selectedValues.aadhaarno}
                  disabled={props.isedit}
                  onChange={onAadhaarnoChange} /> */}
                    <DatePicker
                      value={selectedValues.owerlicexp}
                      defaultValue={selectedValues.owerlicexp}
                      //disabled={props.isedit}
                      onChange={onOwnerLicenseExpiryChange}
                    ></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["owerbadge", "owerbadge"]}
                    label="Owner Batch"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.owerbadge}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.owerbadge}
                      defaultValue={selectedValues.owerbadge}
                      //disabled={props.isedit}
                      onChange={onOwnerBatchChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    //name={["owerbadgeexp", "owerbadgeexp"]}
                    label="Owner Batch Expiry"
                    rules={[]}
                  >
                    {/* <span style={{ display: "none" }}>{selectedValues.owerbadgeexp}</span> */}
                    {/*  <Input type="text"
                  value={selectedValues.owerbadgeexp}
                  defaultValue={selectedValues.owerbadgeexp}
                  disabled={props.isedit}
                  onChange={onOwnerBatchExpChange} /> */}
                    <DatePicker
                      value={selectedValues.owerbadgeexp}
                      defaultValue={selectedValues.owerbadgeexp}
                      //disabled={props.isedit}
                      onChange={onOwnerBatchExpChange}
                    ></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["accno", "accno"]}
                    label="Owner Account No"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.accno}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.accno}
                      defaultValue={selectedValues.accno}
                      //disabled={props.isedit}
                      onChange={onOwnerAccountChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["ifsc", "ifsc"]}
                    label="Owner IFSC"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.ifsc}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.ifsc}
                      defaultValue={selectedValues.ifsc}
                      //disabled={props.isedit}
                      onChange={onOwnerIFSCChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  {/* <Form.Item name={["bankname", "bankname"]} label="Owner Bankname" rules={[]}>
                <span style={{ display: "none" }}>{selectedValues.bankname}</span>
                <Input type="text"
                  value={selectedValues.bankname}
                  defaultValue={selectedValues.bankname}
                  disabled={props.isedit}
                  onChange={onOwnerBanknameChange} />

              </Form.Item> */}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["branch", "branch"]}
                    label="Owner Branch"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.branch}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.branch}
                      defaultValue={selectedValues.branch}
                      //disabled={props.isedit}
                      onChange={onOwnerBranchChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name={["pan", "pan"]} label="Owner PAN" rules={[]}>
                    <span style={{ display: "none" }}>
                      {selectedValues.pan}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.pan}
                      defaultValue={selectedValues.pan}
                      //disabled={props.isedit}
                      onChange={onOwnerPanChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Driver Id" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="DriverId"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: "calc(95% - 8px)" }}
                >
                  <span style={{ display: "none" }}>
                    {selectedValues.driverid}
                  </span>
                  <Select
                    placeholder="DriverID"
                    value={selectedValues.driverid}
                    defaultValue={selectedValues.driverid}
                    disabled={props.isedit}
                    onChange={onDriveridChange}
                  >
                    {IdsList.map((id: any) => (
                      <Option key={id.driver_id} value={id.driver_id}>
                        {id.driver_id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="adddriver"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(5% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <PlusOutlined hidden={props.isedit} onClick={AddDriver} />
                  {/* <EditOutlined hidden={!props.isedit} onClick={AddDriver} /> */}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["drivername", "drivername"]}
                label="Driver Name"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.drivername}
                </span>
                <Input
                  type="text"
                  value={selectedValues.drivername}
                  defaultValue={selectedValues.drivername}
                  //disabled={props.isedit}
                  onChange={onDrivernameChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["personalno", "personalno"]}
                label="Personal Number"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.personalno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.personalno}
                  defaultValue={selectedValues.personalno}
                  //disabled={props.isedit}
                  onChange={onPersonalnoChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["driverno", "driverno"]}
                label="Driver Number"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.driverno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.driverno}
                  defaultValue={selectedValues.driverno}
                  //disabled={props.isedit}
                  onChange={onDrivernoChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["daddress", "daddress"]}
                label="Address"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.daddress}
                </span>
                <Input
                  type="text"
                  value={selectedValues.daddress}
                  defaultValue={selectedValues.daddress}
                  //disabled={props.isedit}
                  onChange={onDaddressChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["daadharno", "daadharno"]}
                label="Driver Aadhaar"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.daadharno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.daadharno}
                  defaultValue={selectedValues.daadharno}
                  //disabled={props.isedit}
                  onChange={onDaadharnoChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["dlicno", "dlicno"]}
                label="Driver license No"
                rules={[]}
              >
                <span style={{ display: "none" }}>{selectedValues.dlicno}</span>
                <Input
                  type="text"
                  value={selectedValues.dlicno}
                  defaultValue={selectedValues.dlicno}
                  //disabled={props.isedit}
                  onChange={onDlicnoChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["batchno", "batchno"]}
                label="Batch No"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.batchno}
                </span>
                <Input
                  type="text"
                  value={selectedValues.batchno}
                  defaultValue={selectedValues.batchno}
                  //disabled={props.isedit}
                  onChange={onBatchnoChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {props.isedit && (
            <div>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    //name={["driver_lic_val", "driver_lic_val"]}
                    label="Driver license Expiry"
                    rules={[]}
                  >
                    {/* <span style={{ display: "none" }}>{selectedValues.driver_lic_val}</span> */}
                    {/* <Input type="text"
                  value={selectedValues.driver_lic_val}
                  defaultValue={selectedValues.driver_lic_val}
                  disabled={props.isedit}
                  onChange={onDriverLicenseExpiryChange} /> */}
                    <DatePicker
                      value={selectedValues.driver_lic_val}
                      defaultValue={selectedValues.driver_lic_val}
                      //disabled={props.isedit}
                      onChange={onDriverLicenseExpiryChange}
                    ></DatePicker>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    //name={["driverbadgeexp", "driverbadgeexp"]}
                    label="Batch Expiry"
                    rules={[]}
                  >
                    {/* <span style={{ display: "none" }}>{selectedValues.driverbadgeexp}</span> */}
                    {/* <Input type="text"
                  value={selectedValues.driverbadgeexp}
                  defaultValue={selectedValues.driverbadgeexp}
                  disabled={props.isedit}
                  onChange={onBatchExpiryChange} /> */}
                    <DatePicker
                      value={selectedValues.driverbadgeexp}
                      defaultValue={selectedValues.driverbadgeexp}
                      //disabled={props.isedit}
                      onChange={onDriverBatchExpiryChange}
                    ></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["driveraccno", "driveraccno"]}
                    label="Driver Account No"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.driveraccno}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.driveraccno}
                      defaultValue={selectedValues.driveraccno}
                      //disabled={props.isedit}
                      onChange={onDriverAccountChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["driverifsc", "driverifsc"]}
                    label="Driver IFSC"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.driverifsc}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.driverifsc}
                      defaultValue={selectedValues.driverifsc}
                      //disabled={props.isedit}
                      onChange={onDriverIFSCChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["driverbankname", "driverbankname"]}
                    label="Driver Bankname"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.driverbankname}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.driverbankname}
                      defaultValue={selectedValues.driverbankname}
                      //disabled={props.isedit}
                      onChange={onDriverBanknameChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["driverbranch", "driverbranch"]}
                    label="Driver Branch"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.driverbranch}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.driverbranch}
                      defaultValue={selectedValues.driverbranch}
                      //disabled={props.isedit}
                      onChange={onDriverBranchChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["driverpan", "driverpan"]}
                    label="Driver PAN"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.driverpan}
                    </span>
                    <Input
                      type="text"
                      value={selectedValues.driverpan}
                      defaultValue={selectedValues.driverpan}
                      //disabled={props.isedit}
                      onChange={onDriverPanChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="FC Date">
                {/* <span style={{ display: "none" }}>{selectedValues.fc}</span> */}
                {/* <Input
                  value={selectedValues.fc}
                  defaultValue={selectedValues.fc}
                  disabled={props.isedit}
                  placeholder="FC Date"
                  onChange={onFcChange}></Input> */}
                <DatePicker
                  value={selectedValues.fc}
                  defaultValue={selectedValues.fc}
                  //disabled={props.isedit}
                  onChange={onFcChange}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                //name={["insurance", "insurance"]}
                label="Insurance"
                rules={[]}
              >
                {/* <span style={{ display: "none" }}>{selectedValues.insurance}</span> */}
                {/* <Input type="text"
                  value={selectedValues.insurance}
                  defaultValue={selectedValues.insurance}
                  disabled={props.isedit}
                  onChange={onInsuranceChange} /> */}
                <DatePicker
                  value={selectedValues.insurance}
                  defaultValue={selectedValues.insurance}
                  //disabled={props.isedit}
                  onChange={onInsuranceChange}
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["username", "username"]}
                label="UserName"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.username}
                </span>
                <Input
                  type="text"
                  value={selectedValues.username}
                  defaultValue={selectedValues.username}
                  // disabled={props.isedit}
                  onChange={onUsernameChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["password", "password"]}
                label="Password"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.password}
                </span>
                <Input
                  type="text"
                  value={selectedValues.password}
                  defaultValue={selectedValues.password}
                  // disabled={props.isedit}
                  onChange={onPasswordChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                //name={["pollution", "pollution"]}
                label="Pollution"
                rules={[]}
              >
                {/* <span style={{ display: "none" }}>{selectedValues.pollution}</span> */}
                {/*  <Input type="text"
                  value={selectedValues.pollution}
                  defaultValue={selectedValues.pollution}
                  disabled={props.isedit}
                  onChange={onPollutionChange} /> */}
                <DatePicker
                  value={selectedValues.pollution}
                  defaultValue={selectedValues.pollution}
                  //disabled={props.isedit}
                  onChange={onPollutionChange}
                ></DatePicker>
              </Form.Item>
            </Col>

            {/* </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={["capacity", "capacity"]} label="Capacity" rules={[]}>
              <span style={{ display: "none" }}>{selectedValues.vehicleType}</span>
            <Select
                placeholder="capacity"
                defaultValue={selectedValues.capacity}
                value={selectedValues.capacity}
                onChange={onCapacityChange}
                disabled
              >
                {vehicleTypeList.map((vehicle: any) => (
                  <Option
                    key={vehicle.id}
                    value={vehicle.id}
                    others={vehicle}
                  >
                    {vehicle.loadcapacity}
                  </Option>
                ))}
              </Select>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name={["lloc", "lloc"]}
                label="Login Location"
                rules={[]}
              >
                {/* <Input type="text"
                  value={selectedValues.lloc}
                  defaultValue={selectedValues.driver_address}
                  // disabled={props.isedit}
                  onChange={onLlocChange} /> */}
                <span style={{ display: "none" }}>
                  {selectedValues.fromlocName}
                </span>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onFromLocationChange}
                  disabled={props.isedit ? false : cabavailablity == false}
                  defaultValue={selectedValues.loginloc}
                  value={selectedValues.fromloc}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  onSearch={onFromLocationSearch}
                  placeholder="Search  Location"
                  notFoundContent={null}
                >
                  {searchFromLocationsList.map((item: any) => (
                    <Option key={item._id} value={item.ID} others={item}>
                      {item.PlaceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                //name={["attdate", "attdate"]}
                label="Attachment date"
                rules={[]}
              >
                {/* <span style={{ display: "none" }}>{selectedValues.attdate}</span> */}

                <DatePicker
                  //disabled={true}
                  value={selectedValues.attdate}
                  defaultValue={moment(selectedValues.attdate)}
                  //disabled={props.isedit}
                  onChange={onAttdateChange}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["depamt", "depamt"]}
                label="Deposit Amount"
                rules={[]}
              >
                <span style={{ display: "none" }}>{selectedValues.depamt}</span>
                <Input
                  type="text"
                  value={selectedValues.depamt}
                  defaultValue={selectedValues.depamt}
                  disabled={cabavailablity == false && !props.isedit}
                  onChange={ondepamtChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name={["odameter", "odameter"]}
                label="Odometer"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.odameter}
                </span>
                <Input
                  type="text"
                  value={selectedValues.odameter}
                  defaultValue={selectedValues.odameter}
                  //disabled={props.isedit}
                  onChange={onodameterChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["appimei", "appimei"]}
                label="App IMEI No"
                rules={[]}
              >
                <span style={{ display: "none" }}>
                  {selectedValues.appimei}
                </span>
                <Input
                  type="text"
                  value={selectedValues.appimei}
                  defaultValue={selectedValues.appimei}
                  disabled={cabavailablity == false && !props.isedit}
                  onChange={onappimeiChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {props.isedit && (
            <div>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["tarpaulin", "tarpaulin"]}
                    label="Tarpaulin"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.tarpaulin}
                    </span>
                    <Select
                      placeholder="Tarpaulin"
                      value={selectedValues.tarpaulin}
                      defaultValue={selectedValues.tarpaulin}
                      onChange={onTarpaulinChange}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={["rope", "rope"]} label="Rope" rules={[]}>
                    <span style={{ display: "none" }}>
                      {selectedValues.rope}
                    </span>
                    <Select
                      placeholder="Rope"
                      value={selectedValues.rope}
                      defaultValue={selectedValues.rope}
                      onChange={onRopeChange}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={["keralapermit", "keralapermit"]}
                    label="Kerala Permit"
                    rules={[]}
                  >
                    <span style={{ display: "none" }}>
                      {selectedValues.keralapermit}
                    </span>
                    <Select
                      placeholder="Kerala Permit"
                      value={selectedValues.keralapermit}
                      defaultValue={selectedValues.keralapermit}
                      onChange={onKeralapermitChange}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
        </Form>
        <Row gutter={24}>
          {props.isedit === true && (
            <Col offset={5}>
              <Button
              // onClick={exitvehicle}
              >
                Exit
              </Button>
            </Col>
          )}
          {props.isedit !== true && (
            <Col offset={2}>
              <Button
                onClick={addnewvehiclemaster}
                disabled={cabavailablity == false}
              >
                Save
              </Button>
            </Col>
          )}
          {props.isedit === true && (
            <Col offset={2}>
              <Button
                //onClick={addnewvehiclemaster}
                onClick={editvehiclemaster}
              >
                Update
              </Button>
            </Col>
          )}
          <Col offset={2}>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Modal>
      <Vehicleform
        title={vehicleformtittle}
        visible={Addvehiclevisible}
        isedit={editvehicle}
        selectedValues={selectedValues}
        mtruckid={selectedValues.MtruckId}
        hideModal={hidevehicleModal}
        isold={oldChecked}
      ></Vehicleform>
      <Ownerform
        title={ownerformtittle}
        visible={Addownervisible}
        isedit={editowner}
        isold={oldChecked}
        selectedValues={selectedValues}
        mtruckid={selectedValues.MtruckId}
        hideModal={hideownerModal}
      ></Ownerform>
      <Driverform
        title={driverformtittle}
        visible={AddDrivervisible}
        isedit={editdriver}
        selectedValues={selectedValues}
        mtruckid={selectedValues.MtruckId}
        hideModal={hidedriverModal}
        isold={oldChecked}
      ></Driverform>
    </div>
  );
};

export default AddVehicleMaster;
