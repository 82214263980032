import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, DatePicker, Input, Spin } from "antd";
import "./Report.scss";
import AppTable from "../../components/AppTable/AppTable";
import moment from "moment";

import AppModalWIthStaticsReport from "../../components/AppModal/AppModalWithStaticsReport";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { CSVLink } from "react-csv";

const { Search } = Input;

const StaticsReport = () => {
    const [fromdate, setfromdate] = useState(moment());
    const [todate, settodate] = useState(moment() );
    const dateFormat = "DD/MM/YYYY";
    const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
    const [selectedRowData, setSelectedRowData]: any = useState([]);
    const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
    const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
    const [hoursmodalVisible, sethoursmodalVisible] = useState(false);
    const RangePicker = DatePicker.RangePicker;
    const [flag, setFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ButtonClicked, setIsButtonClicked] = useState("Today");
    const [SearchText, setsearchText]: any = useState("");
    const [pagination, setPagination]:any = useState({
      current: 1,
      pageSize: 10,
    });
  
    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "Booking ID",

        },
        {
            title: "Day",
            dataIndex: "day",
            key: "Name",

        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "Name",
            render: (text:any) => Math.round(text)
        },
        {
            title: "Trip",
            dataIndex: "trips",
            key: "Name",

        },
        {
            title: "Vehicle ran",
            dataIndex: "vehicleran",
            key: "Name",

        },
        {
            title: "New Customer",
            dataIndex: "newcustomer",
            key: "Name",

        },
        {
          title: "Customer Cancel",
          dataIndex: "customercancel",
          key: "Name",

      },
      {
        title: "Tele Cancel",
        dataIndex: "telecancel",
        key: "Name",

    },
    ]
    const columns1 = [
        {
            title: "Grand total",
            dataIndex: "grandtotal",
            key: "Booking ID",
            render: (text:any) => Math.round(text)
        },
        {
            title: "Avg Amount",
            dataIndex: "avgamount",
            key: "Booking ID",
            render: (text:any) => Math.round(text)
        },
       
       
    ]
    const data1 = [
        {
            key: "1",
            id: "1000000",
            id1:"4000",
            name: 32,

        },
       

    ];
    const data = [
        {
            key: "1",
            id: "14229",
            name: 32,

        },
        {
            key: "2",
            id: "Saran",
            name: 42,

        },
        {
            key: "2",
            id: "TTC-001",
            name: 42,

        },
        {
            key: "2",
            id: "Townhall",
            name: 42,

        },
        {
            key: "2",
            id: "Townhall",
            name: 42,

        },
        {
            key: "2",
            id: "Jim Green",
            name: 42,

        },
        {
            key: "2",
            id: "Jim Green",
            name: 42,

        },
        {
            key: "2",
            id: "Jim Green",
            name: 42,

        },
        {
            key: "2",
            id: "Jim Green",
            name: 42,

        },
        {
            key: "2",
            id: "Jim Green",
            name: 42,

        },

    ];
   

    const onChange = (dates: any, dateStrings: any) => {
        try {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            let startdate = dateStrings[1];
            var new_date = moment(startdate, "YYYY-MM-DD").add('days', 1);
            var day = new_date.format('DD');
            var month = new_date.format('MM');
            var year = new_date.format('YYYY');
            let todate = year + '-' + month + '-' + day;
           
        }
        catch (ex) {
            console.log(ex);
        }
    };
    const showhoursmodal = () => {
        sethoursmodalVisible(true);
      };
      const hideModalshowhoursmodal = (isVisible: any) => {
        sethoursmodalVisible(isVisible);
      };
 
      const onfromdatechange = (value: any) => {
        setfromdate(value);
        try {
      
          var fromdate = moment(value).format("YYYY/MM/DD");
          let todate = moment().format("YYYY/MM/DD");
        //  DateSearch(fromdate, todate);
        setFlag(false);
        }
        catch (ex) {
          console.log(ex);
        }
      
      }
      const ontodatechange = (value: any) => {
        settodate(value);
        try {
      
          var todate = moment(value).add("days", 1).format("YYYY/MM/DD");
          let startdate = moment(fromdate).format("YYYY/MM/DD");
         // DateSearch(startdate, todate);
         setFlag(false);
        }
        catch (ex) {
          console.log(ex);
        }
      
      }
      useEffect(() => {
        adayclick();
      }, []);
      const adayclick = (event: any = null) => {
        setIsButtonClicked('Today');
        var first = moment();
        var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
        let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
        setfromdate(moment(fromdate));
        //var new_date = moment().add('days', 1);
        var new_date = moment();
        var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
        let todate = year + '-' + month + '-' + day;
        settodate(moment(todate));
        setFlag(true);
        onDateSearch(fromdate, todate, pagination, SearchText);
      };
      
      const twodayclick = () => {
        setIsButtonClicked('Yesday');
        var first = moment().add('days', -1);
        var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
        let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
        setfromdate(moment(fromdate));
        var new_date = moment().add('days', 1);
        var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
        let todate = year + '-' + month + '-' + day;
        settodate(moment(todate));
        setFlag(true);
        onDateSearch(fromdate, todate, pagination, SearchText);
      };
      
      const monthclick = () => {
        setIsButtonClicked('Month');
        var first = moment();
        var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
        let fromdate = firstyear + '-' + firstmonth + '-' + '01';
        setfromdate(moment(fromdate));
        //var new_date = moment().add('days', 1);
        var new_date = moment();
        var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
        let todate = year + '-' + month + '-' + day;
        settodate(moment(todate));
        console.log(fromdate);
        setFlag(true);
        onDateSearch(fromdate, todate, pagination, SearchText);
      };
      
      const weekclick = () => {
        setIsButtonClicked('Week');
        var first = moment().add('days', -(moment().day()))
        var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
        let fromdate = firstyear + '-' + firstmonth + '-' + firstday;
        setfromdate(moment(fromdate));
        console.log(fromdate);
        //var new_date = moment().add('days', 1);
        var new_date = moment();
        var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
        let todate = year + '-' + month + '-' + day;
        settodate(moment(todate));
        setFlag(true);
        onDateSearch(fromdate, todate, pagination, SearchText);
      };
      const disabledDate = (current: any) => {
        // Can not select days before today and today
        return current && current > moment().endOf("day");
      };
      const disabledFromDate = (current: any) => {
        // Can not select days before today and today
        return (current && current > moment(todate).endOf("day"));
      };
      const onDateSearch = (fromdate:any, todate:any, pagination:any, SearchText: any) => {
        DateSearch(moment(fromdate).format("YYYY/MM/DD"), moment(todate).add("days", 1).format("YYYY/MM/DD"), pagination, SearchText);
        /* (!todatechanged)? moment(todate).format("YYYY/MM/DD") : moment(todate).add("days", 1).format("YYYY/MM/DD"));*/
      }
    
      const DateSearch = (fromdate: any, todate: any, pagination:any, SearchText: any) => {
        setIsLoading(true);
        const options: any = {
          params: {
            cityid: branchid,
            fromdate: fromdate,
            todate: todate,
            limit: pagination.pageSize,
            skip: (pagination.current-1) * (pagination.pageSize),
            filtervalue: SearchText
          },
        };
        console.log(options);
        axiosGet(UrlConstants.getStatisticsReportbydate, options).then((resp: any) => {
          console.log(resp);
          resp.data.forEach((e: any, index:any) => {
            e.key = index;
            e.amount = isNaN(e.amount) ?  e.amount.replace(/[^0-9]/g, "") : e.amount;
          });
          setIsLoading(false);
          setCurrentBookingGridData(resp.data);
          setcopyCurrentBookingGridData(resp.data);
          setPagination({
            ...pagination,
            total: resp.tablelength
          });
        }).catch(function (error: any) {
          setIsLoading(false);
          if (error.response) {
            console.log(error.response.data, error.response.status);
            setCurrentBookingGridData([]);
            setcopyCurrentBookingGridData([]);
          }
        });
      };
      const onSearchchange = (e: any) => {
        console.log(e.target.value);
        setsearchText(e.target.value);
        globalSearch(e.target.value);
      };
    
      const globalSearch = (searchText: any) => {
        let filteredData = currentBookingGridData.filter((value: any) => {
          if (value.day === undefined || value.day === null)
            value.cabid = '';
          if (value.amount === undefined || value.amount === null)
            value.amount = '';
          if (value.trips === undefined || value.trips === null)
            value.trips = '';
          if (value.vehicleran === undefined || value.vehicleran === null)
            value.vehicleran = '';
          if (value.newcustomer === undefined || value.newcustomer === null)
            value.newcustomer = '';
          if (value.customercancel === undefined || value.customercancel === null)
            value.customercancel = '';
          if (value.telecancel === undefined || value.telecancel === null)
            value.telecancel = '';
         
          return (
            value.day.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.amount.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.trips.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.vehicleran.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.newcustomer.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.customercancel.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            value.telecancel.toString().toLowerCase().includes(searchText.toLowerCase()) 
          );
        });
        setcopyCurrentBookingGridData(filteredData);
      };
      const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
        setPagination(pagination);
        onDateSearch(fromdate, todate, pagination, SearchText);
        console.log(pagination); 
      }
      const onSearch = (value:any) => {
        console.log(value);
        setsearchText(value);
        onDateSearch(fromdate, todate, pagination, value);
      }
    
    return (
      <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
           <Row style={{ paddingBottom: 10 }}> <Col>  <span style={{ fontSize: 'large' ,fontWeight:'bold', fontStyle:'Times New Roman' }}> STATISTICS REPORT</span> 
      </Col> 
       <Col offset={2}>
          <span style={{ fontSize: 'large' , fontWeight: 'bold', fontStyle:'Times New Roman' }}> From : {moment(fromdate).format('DD.MM.YYYY')}   To : {flag ? moment().format('DD.MM.YYYY') : moment(todate).format('DD.MM.YYYY')}  </span>
      
          </Col></Row>
        <Row style={{ paddingBottom: 10 }}>
         
          <Col >

          <span style={{ fontSize: 'medium' }}> From:</span>
 
 <DatePicker
 
     value={fromdate}
     format={dateFormat}
     disabledDate={disabledFromDate}  
     onChange={onfromdatechange} />
<span style={{ fontSize: 'medium' }}> To:</span>


<DatePicker
     value={flag? moment() : todate}
     //value={todate}
     format={dateFormat}
     disabledDate={disabledDate}  
     onChange={ontodatechange} />

          </Col>
          <Col span={1} offset={1}>
            <Button className={ButtonClicked === 'Today' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={adayclick}
            >
              Today
            </Button>
          </Col>
          <Col span={1} offset={1} >
            <Button className={ButtonClicked === 'Week' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={weekclick}
            >
              Week
            </Button>
          </Col>
          <Col span={1} offset={1}>
            <Button className={ButtonClicked === 'Month' ? "booking-btn-buttonTrue" : "booking-btn-buttonFalse"}
              onClick={monthclick}
            >
              Month
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
              onClick={()=>onDateSearch(fromdate, todate, pagination, SearchText)}
              className="report-btn-blockSearch"
              disabled={moment(fromdate).isAfter(moment(todate))}>
              Search
            </Button>
          </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
          <Col span={2} offset={14}>
            <Button
              className="report-btn-blocksms">
              Send sms
            </Button>
          </Col>
          <Col span={2} offset={1}>
            {/* <CSVLink
              filename={"StatisticsReport.csv"}
              headers={headersToExport}
              data={dataToExport}
            > */}
              <Button className="report-btn-blockExport">Export</Button>
            {/* </CSVLink> */}
          </Col>
          <Col span={4} offset={1}>
            {/* <Input placeholder="Search Text" className="search" 
              value={SearchText}
              onChange={onSearchchange} /> */}
              <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
          </Col>
        </Row>
        <Row gutter={[0,8]}>
          <Col span={8} offset={8}>
          <Spin tip="Loading..." spinning={isLoading}>
            <AppTable className="report-table-row1"
                columns={columns1}
                data={data1}
            ></AppTable>
          </Spin>
          </Col>
        </Row>
            <br/>
          <Spin tip="Loading..." spinning={isLoading}>
            <AppTable className="report-table-row"
                columns={columns}
                data={copycurrentBookingGridData}
                //enableRadioButton={true}
                enablePagination={pagination}
                onchange={onPaginationchange}
                //selectionType="radio"
            ></AppTable>
          </Spin>
        <AppModalWIthStaticsReport
        title="Hours"
        selectedRowData={selectedRowData}
        hideModal={hideModalshowhoursmodal}
        visible={hoursmodalVisible}
        onRowClickEnabled={false}
        customClass="new-booking-assign"
    /> 
        </div>
</div>
    )

};


export default StaticsReport;


