import React, { Component } from "react";
import "antd/dist/antd.css";
import AppLayout from "./common/AppLayout/AppLayout";
import Login from "./pages/Login/Login";
import AuthContextProvider, { AuthContext } from "./context/AuthContext";
import HttpInterceptor from "./helpers/HttpInterceptor";

const App = (props: any) => {
  const renderActiveApp = (authContext: any) => {
    HttpInterceptor.setupInterceptors(authContext);
    if (authContext.isUserLogedIn) {
      return <AppLayout authContext={authContext} />;
    } else {
      return <Login key={"app-1"} authContext={authContext} />;
    }
  };
  const preventDefaultAction = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  //return <Login />;
  return (
    // <div onCut={preventDefaultAction} onCopy={preventDefaultAction} onPaste={preventDefaultAction}>
    <AuthContextProvider>
      <AuthContext.Consumer>
        {(authContext: any) => renderActiveApp(authContext)}
      </AuthContext.Consumer>
    </AuthContextProvider>
    // </div>
  );
};

export default App;
