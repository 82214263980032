import React, { useState, useEffect } from "react";
import {
  Tag,
  Space,
  Row,
  Col,
  Button,
  Checkbox,
  notification,
  Input,
  Spin,
  Pagination,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddVehicleMaster from "../../components/AppModal/AddvehicleMaster";
import Vehicleinfo from "../../components/AppModal/Vehicleinfo";

const { Search } = Input;

const VehicleMaster = () => {
  let history: any = useHistory();
  const [Addvehiclevisible, setAddVehicleVisible] = useState(false);
  const [moreinfovisible, setmoreinfovisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [selectedValues, setselectedValues]: any = useState([]);
  const [editvehicle, seteditvehicle] = useState(false);
  const [ModalTitle, setModalTitle] = useState("Add Goods");
  const [isLoading, setIsLoading] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [availableCabData, setAvailableCabData] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const showpreassignmodal = () => {
    setPreAssignModalVisible(true);
  };
  const hidepreassignmodal = (isVisible: any) => {
    setPreAssignModalVisible(isVisible);
  };

  const columns = [
    {
      title: "Truck ID",
      dataIndex: "cab_id",
      key: "truckid",
      fixed: "left",
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "vehicleid",

      ellipsis: true,
    },
    {
      title: "Owner Id",
      dataIndex: "owner_id",

      key: "ownerid",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.mobileno + " / " + record.alternativemobileno;
      // },
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "drivername",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.mobileno + " / " + record.alternativemobileno;
      // },
    },

    {
      title: "Vehicletype",
      dataIndex: "car_Type",
      key: "Vehicletype",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      ellipsis: true,
      // sorter: (a: any, b: any) => a.fromloc - b.fromloc,
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: "MoreInfo",
      dataIndex: "status",
      key: "moreinfo",
      render: (text: any, record: any) => {
        return (
          <div>
            <Button
              style={{
                backgroundColor: "#B0E0E6",
                color: "black",
                borderColor: "black",
              }}
              onClick={() => Moreinfo(record)}
            >
              Moreinfo
            </Button>
          </div>
        );
      },
    },
  ];

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    console.log(rowInfo);
  };

  useEffect(() => {
    getNewBookingList(pagination, SearchText);
    getLocations(branchid);
    refresh(pagination, SearchText);
  }, []);
  const refresh = (pagination: any, SearchText: any) => {
    const options = {
      params: {
        branchid: branchid,
        // limit: pagination.pageSize,
        // skip: (pagination.current-1) * (pagination.pageSize),
        // filtervalue: SearchText
      },
    };
    axiosGet(UrlConstants.getvehiclemasterlist, options)
      .then((resp: any) => {
        console.log(resp);
        if (resp.data) {
          resp.data.forEach((e: any) => {
            e.key = e.id.toString();
            if (e.isenabled == "0") e.status = "Inactive";
            else e.status = "Active";
          });
          setCurrentBookingGridData(resp.data);
          setcopyCurrentBookingGridData(resp.data);
          setPagination({
            ...pagination,
            total: resp.tablelength,
          });
        } else {
          setCurrentBookingGridData([]);
          setcopyCurrentBookingGridData([]);
        }
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

  const getNewBookingList = (pagination: any, SearchText: any) => {
    //console.log(UrlConstants.getvehiclemasterlist);
    const options: any = {
      params: {
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    console.log(options);
    axiosGet(UrlConstants.getvehiclemasterlist, options).then((resp: any) => {
      //console.log(resp);
      if (resp.data != undefined) {
        resp.data.forEach((e: any) => {
          e.key = e.id.toString();
          if (e.isenabled == "0") e.status = "Inactive";
          else e.status = "Active";
        });
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      } else {
        setCurrentBookingGridData([]);
        setcopyCurrentBookingGridData([]);
      }
    });
  };
  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      //console.log("getloc");

      //console.log(resp);
      setLocationsList(resp);
    });
  };

  const Moreinfo = (selectedRow: any) => {
    setmoreinfovisible(true);
    setSelectedRowData(selectedRow);
  };
  const hidemoreinfo = (isVisible: any) => {
    setmoreinfovisible(isVisible);
    setsearchText("");
  };
  const Addvehicle = () => {
    console.log(selectedRowData[0]);
    let copyselectedvalues: any = {};
    setselectedValues(copyselectedvalues);
    setAddVehicleVisible(true);
    setModalTitle("Add Vehicle");
    seteditvehicle(false);
  };
  const Editvehicle = () => {
    console.log(selectedRowData[0]);
    let copyselectedvalues: any = { ...selectedRowData[0] };
    setselectedValues(copyselectedvalues);
    setAddVehicleVisible(true);
    seteditvehicle(true);
    setModalTitle("Edit Vehicle");
  };
  const hideModal = (isVisible: any) => {
    setAddVehicleVisible(isVisible);
    refresh(pagination, SearchText);
    setsearchText("");
  };
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };

  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      if (value.cab_id === undefined || value.cab_id === null)
        value.cab_id = "";
      if (value.vehicleid === undefined || value.vehicleid === null)
        value.vehicleid = "";
      if (value.owner_id === undefined || value.owner_id === null)
        value.owner_id = "";
      if (value.driver_name === undefined || value.driver_name === null)
        value.driver_name = "";
      if (value.car_Type === undefined || value.car_Type === null)
        value.car_Type = "";
      /*  if(value.status===undefined || value.status===null)
      value.status='';
 */
      return (
        value.cab_id
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.vehicleid
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.owner_id
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.driver_name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.car_Type
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) /* ||
        value.status
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) */
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    getNewBookingList(pagination, SearchText);
    console.log(pagination);
  };
  const onSearch = (value: any) => {
    setsearchText(value);
    getNewBookingList(pagination, value);
  };

  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
            <Button onClick={Addvehicle}>Add</Button>
          </Col>
          <Col span={2} offset={1}>
            <Button disabled={!isRowSelected} onClick={Editvehicle}>
              Edit
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button disabled={!isRowSelected}>ReAttach</Button>
          </Col>
          {/*  <Col span={2} offset={1}>
            <Button
              

            disabled={!isRowSelected}
            onClick={Moreinfo}
            >
              MoreInfo
            </Button>
          </Col> */}
          <Col span={4} offset={12}>
            <Input
              placeholder="Search Text"
              value={SearchText}
              onChange={onSearchchange}
            />
            {/* <Search placeholder="Search Text" onSearch={onSearch} enterButton/> */}
          </Col>
        </Row>
      </div>
      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          {/* <div>{copycurrentBookingGridData.map((val:any)=>val.type)}</div> */}
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={true}
            defaultPageSize={100}
            selectionType="radio"
            onchange={onPaginationchange}
          ></AppTable>
        </Spin>
      </div>
      <AddVehicleMaster
        selectedRowData={selectedValues}
        title={ModalTitle}
        visible={Addvehiclevisible}
        hideModal={hideModal}
        isedit={editvehicle}
      ></AddVehicleMaster>
      <Vehicleinfo
        title="Vehicle Info"
        visible={moreinfovisible}
        hideModal={hidemoreinfo}
        selectedRowData={selectedRowData}
      ></Vehicleinfo>
    </div>
  );
};

export default VehicleMaster;
