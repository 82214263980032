import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddPackageTariff = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [basefare, setbasefare] = useState("");
  const [basekm, setbasekm] = useState("");
  const [basehours, setbasehours] = useState("");
  const [addkmcharge, setaddkmcharge] = useState("");
  const [addmincharge, setaddmincharge] = useState("");
  const [feet, setfeet] = useState(0);
  const [loadcapacity, setloadcapacity] = useState("");
  const [vehicle, setvehicle] = useState("");

  useEffect(() => {
    setVisible(props.visible);
    getVechileTypeList();
    if (props.isedit) {
      setbasefare(props.selectedRowData.Rupees);
      setbasekm(props.selectedRowData.KM);
      setbasehours(props.selectedRowData.Hours);
      setaddkmcharge(props.selectedRowData.AddKMCharge);
      setaddmincharge(props.selectedRowData.AddMinuteCharge);
      setvehicle(props.selectedRowData.Vechicle);
    } else {
      setbasefare("");
      setbasekm("");
      setbasehours("");
      setaddkmcharge("");
      setaddmincharge("");
      setvehicle("");
    }
  }, [props.visible]);

  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const handleCancel = (e: any) => {
    props.hideModal(false);
    setbasefare("");
    setbasekm("");
    setbasehours("");
    setaddkmcharge("");
    setaddmincharge("");
    setvehicle("");
  };

  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  };
  const vehicletypechange = (e: any) => {
    setvehicle(e.target.value);
  };
  const basefarechange = (e: any) => {
    setbasefare(e.target.value);
  };
  const basekmchange = (e: any) => {
    setbasekm(e.target.value);
  };
  const basehourschange = (e: any) => {
    setbasehours(e.target.value);
  };

  //Vehicle Type list
  const getVechileTypeList = () => {
    const options = {
      params: {
        cityid: branchid,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + branchid;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
      let vehicleindex = resp.findIndex((obj: { id: any }) => obj.id === 1);
      resp[vehicleindex].city = branchid;
    });
  };

  const onVehicleTypeChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.vehicleType = fullInfo.others.id;
    copyselectedvalues.addkmcharge = fullInfo.others.addkmcharge;
    copyselectedvalues.addminutecharge = fullInfo.others.addminutecharge;

    setSelectedValues(copyselectedvalues);
  };
  //updatetariff
  const updatetariff = () => {
    const options: any = {
      data: {
        ID: props.selectedRowData.ID,
        VehicleID: props.selectedRowData.VehicleID,
        CityId: branchid,
        IsActive: "1",
        Rupees: basefare,
        KM: basekm,
        Hours: basehours,
        AddKMCharge: addkmcharge,
        AddMinuteCharge: addmincharge,
      },
    };
    axiosPost(UrlConstants.editpackagefare, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "PackageFare Updated Successfully",
          //description: "MetreFare for " + selectedValues.GoodsName + " added",
        };
        handleCancel("");
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Package Fare Updating Failed",
          description: "Error Occurred while Updating Package Tariff",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const savetariff = () => {
    const options: any = {
      data: {
        VehicleID: selectedValues.vehicleType,
        CityId: branchid,
        IsActive: "1",
        Rupees: basefare,
        KM: basekm,
        Hours: basehours,
        AddKMCharge: selectedValues.addkmcharge,
        AddMinuteCharge: selectedValues.addminutecharge,
      },
    };
    axiosPost(UrlConstants.addpackagefare, options)
      .then((resp: any) => {
        handleCancel("");
        const notificationInfo: any = {
          header: "PackageFare Added Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        handleCancel("");
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "PackageFare Adding Failed",
          description: "Error Occurred while Adding PackageFare",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={400}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form name="nest-messages" {...formItemLayout}>
        {props.isedit !== true && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name={["vehiclename", "vehiclename"]}
                label="Vehicle Name"
                rules={[]}
              >
                <Select
                  placeholder="Vehicle Name"
                  value={selectedValues.vehicleType}
                  onChange={onVehicleTypeChange}
                >
                  {vehicleTypeList.map((vehicle: any) => (
                    <Option
                      key={vehicle.id}
                      value={vehicle.id}
                      others={vehicle}
                    >
                      {vehicle.vehicletype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {props.isedit === true && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name={["vehiclename", "vehiclename"]}
                label="Vehicle Name"
                rules={[]}
              >
                <Input
                  onChange={vehicletypechange}
                  defaultValue={vehicle}
                  value={vehicle}
                  type="text"
                  readOnly={true}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basefare", "basefare"]}
              label="Base Fare"
              rules={[]}
            >
              <Input
                onChange={basefarechange}
                defaultValue={basefare}
                value={basefare}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item name={["basekm", "basekm"]} label="Base Km" rules={[]}>
              <Input
                onChange={basekmchange}
                defaultValue={basekm}
                value={basekm}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Form.Item
              name={["basehours", "basehours"]}
              label="Base Hours"
              rules={[]}
            >
              <Input
                onChange={basehourschange}
                defaultValue={basehours}
                value={basehours}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        {props.isedit === true && (
          <Col offset={5}>
            <Button onClick={updatetariff}>Update</Button>
          </Col>
        )}
        {props.isedit !== true && (
          <Col offset={5}>
            <Button onClick={savetariff}>Save</Button>
          </Col>
        )}

        <Col offset={2}>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddPackageTariff;
