import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select, InputNumber, Row, Col, notification, DatePicker, Checkbox } from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import OtpInput from 'react-otp-input';


const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const Driverform = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [defaultDateTime, setDefaultDateTime]: any = useState();
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [branchList, setBranchList] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [showValidations, setValidations] = useState(false);
  const [oldChecked, setoldChecked] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    if (props.isedit)
      setSelectedValues(props.selectedValues);
    else{
        getnewid();
        setSelectedValues({}); 
    }
  }, [props.visible]);


  const getnewid = () => {
    const uri = UrlConstants.getdriverid + "?CityId=" + branchid;
    axiosGet(uri).then((resp: any) => {
      console.log(resp);
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.driverid = resp[0].driver_id;
      copyselectedvalues.mtruckid = props.mtruckid;
      setSelectedValues(copyselectedvalues);
    });
  };


  const handleCancel = (e: any, data: any = null) => {
    setSelectedValues({});
    props.hideModal(false, data);
  };



  //Branchlist

  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }


  const validateInput = () => {
    {}
    if (selectedValues.mobno != undefined) {
      if (selectedValues.mobno.length != 10) {
        console.log(showValidations);
        setValidations(true);
        console.log(selectedValues.mobno.length)
        return false;
      }
        else 
        setValidations(false);
        return true;
      
    }
   
   else if (selectedValues.altno != undefined) {
    if (selectedValues.altno.length != 10) {
      console.log(showValidations);
      setValidations(true);
      console.log(selectedValues.altno.length)
      return false;
    }
      else 
      setValidations(false);
      return true;
    
  }
  else if (selectedValues.aadhar != undefined) {
    if (selectedValues.aadhar.length != 12) {
      console.log(showValidations);
      setValidations(true);
      console.log(selectedValues.aadhar.length)
      return false;
    }
      else 
      setValidations(false);
      return true;
    
  }
  else {
    setValidations(true);
    return false;
  }
   
  }
  const onBranchChange = (value: any) => {
    setbranchid(value);
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.Branchid = value;
    setSelectedValues(copyselectedvalues);
  }


  const drivernamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.drivername = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const mobnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mobno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  
  const altnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.altno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const addresschange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.address = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const aadharchange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.aadhar = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const licensenochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.licenseno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const licexpdateChange = (value: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.licexpdate = value;
    setSelectedValues(selectedValuesCopy);
  };

  const badgenochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.badgeno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const badgeexpChange = (value: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.badgeexpdate = value;
    setSelectedValues(selectedValuesCopy);
  };
  const accountnochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.accountno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const ifscchange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.ifsc = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const banknamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const bankbranchnamechange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.bankbranchname = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const pannochange = (e: any) => {

    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.panno = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  
  const OndriveridChange = (e: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.driverid = e.target.value;;
    setSelectedValues(copyselectedvalues);
  };
  const addnewdriver = () => {
    if (!validateInput()) {
      return;
    }
    let licexpdate;
    let badgeexpdate;
    if(selectedValues.licexpdate)
    licexpdate = selectedValues.licexpdate
      ? selectedValues.licexpdate.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
      if(selectedValues.badgeexpdate)
     badgeexpdate = selectedValues.badgeexpdate
      ? selectedValues.badgeexpdate.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    const options: any = {
      data: {
        cab_id: selectedValues.mtruckid || selectedValues.cab_id,
        driver_id: selectedValues.driverid || selectedValues.driver_id,
        driver_name: selectedValues.drivername || selectedValues.driver_name,
        driver_mobno: selectedValues.mobno || selectedValues.driver_mobno,
        driver_almobno: selectedValues.altno || selectedValues.driver_almobno,
        driver_address: selectedValues.address || selectedValues.driver_address,
        driveraadhar: selectedValues.aadhar || selectedValues.driveraadhar,
        driver_licenceno: selectedValues.licenseno || selectedValues.driver_licenceno,
        driver_lic_val: licexpdate || selectedValues.driver_lic_val || "",
        driveraccno: selectedValues.accountno || selectedValues.driveraccno || "",
        driverifsc: selectedValues.ifsc || selectedValues.driverifsc || "",
        driverbankname: selectedValues.bankname || selectedValues.driverbankname || "",
        driverbranch: selectedValues.bankbranchname || selectedValues.driverbranch || "",
        driverpan: selectedValues.panno || selectedValues.driverpan || "",
        driverbadge: selectedValues.badgeno || selectedValues.driverbadge || "",
        driverbadgeexp: badgeexpdate || selectedValues.driverbadgeexp || "",
        isold: props.isold || oldChecked ||false,
      },
    };
    console.log(options);
    let url=UrlConstants.addnewdriver;
    if(props.isedit)
    url=UrlConstants.editdriver;
    axiosPost(url, options)
      .then((resp: any) => {
        if (resp.status != 401) {
        console.log(resp);
        handleCancel("", options.data);
        let notificationInfo: any = {
          header: "Driver Added Successfully",

        };
        if(props.isedit){
          notificationInfo = {
            header: "Driver Updated Successfully",
          };
        }
        openNotificationWithIcon("Success", notificationInfo);

       }
        else {
        let notificationInfo1: any;
        notificationInfo1 = {
          header: resp.message,
        };
        openNotificationWithIcon("Error", notificationInfo1);
      } })
      .catch(function (error: any) {

        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        let notificationInfo = {
          header: "Driver Adding Failed",
          description: "Error Occurred while Adding Driver",
        };
        if(props.isedit){
          notificationInfo = {
            header: "Driver Update Failed",
            description: "Error Occurred while Updating Driver",
          };
        }
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  



  return (

    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={700}
      visible={visible}

      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="nest-messages" {...formItemLayout}
        onFinish={onFinish}
       
      >
        <Row gutter={24}>
       
            <Col span={12}>
            <Form.Item name={["driverid", "driverid"]} label="Driver Id" rules={[]}>
            
              {
                  props.isold!==true?
                <Input type="text" value={selectedValues.driverid} defaultValue={selectedValues.driver_id || selectedValues.driverid} readOnly />
          
:
           
  <Input value={selectedValues.driverid }  onChange={OndriveridChange}  type="text"  />
              }
           
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["drivernamee", "drivername"]} label="Driver Name" rules={[]}>
              <Input onChange={drivernamechange} value={selectedValues.drivername} defaultValue={selectedValues.driver_name} type="text" />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              style={
                showValidations && !selectedValues.mobno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["mobile", "Mobileno"]} label="Mobile" rules={[]}>

              <Input
                value={selectedValues.mobno}
                defaultValue={selectedValues.driver_mobno}
                onChange={mobnochange}

                maxLength={10}

              ></Input>
              {showValidations  && (
                <span className="form-error-validation">
                  Please input Mobile Number!
                </span>
              )}

            </Form.Item>
          </Col>
          <Col span={12}>
           

            <Form.Item
              style={
                showValidations && !selectedValues.altno
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["alternate", "Alternate"]} label="Alternate No" rules={[]}>

              <Input
                value={selectedValues.altno}
                defaultValue={selectedValues.driver_almobno}
                onChange={altnochange}

                maxLength={10}

              ></Input>
              {showValidations  && (
                <span className="form-error-validation">
                  Please input Alternate Mobile Number!
                </span>
              )}

            

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["address", "Address"]} label="Adddress" rules={[]}>
              <Input onChange={addresschange} value={selectedValues.address} defaultValue={selectedValues.driver_address} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>

          <Form.Item
              style={
                showValidations && !selectedValues.aadhar
                  ? { marginBottom: 0 }
                  : {}
              }
              name={["Adhaar", "Aadhaar"]} label="Aadhaar" rules={[]}>

              <Input
                value={selectedValues.aadhar}
                defaultValue={selectedValues.driveraadhar}
                onChange={aadharchange}

                maxLength={12}

              ></Input>
              {showValidations  && (
                <span className="form-error-validation">
                  Please input Aadhar Number!
                </span>
              )}

            

            </Form.Item>
            {/* <Form.Item name={["Adhaar", "Aadhaar"]} label="Aadhaar" rules={[]}>
              <Input onChange={aadharchange} value={selectedValues.aadhar} type="text" /> */}

            {/* </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["license", "License"]} label="License" rules={[]}>
              <Input onChange={licensenochange} value={selectedValues.licenseno}   defaultValue={selectedValues.driver_licenceno} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["license", "LicenseExpiry Date"]} label="LicenseExpiry" rules={[]}>
              <DatePicker

                value={selectedValues.licexpdate}
                defaultValue={moment(selectedValues.driver_lic_val)}
                format={dateFormat}
                onChange={licexpdateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["badge", "Badge"]} label="Batch" rules={[]}>
              <Input onChange={badgenochange} value={selectedValues.badgeno}  defaultValue={selectedValues.driverbadge} type="text" />

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["badge", "BadgeExpiry Date"]} label="BatchExpiry" rules={[]}>
              <DatePicker

                value={selectedValues.badgeexpdate}
                defaultValue={moment(selectedValues.driverbadgeexp)}
                format={dateFormat}
                onChange={badgeexpChange}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["account", "Account No"]} label="A/C No" rules={[]}>
              <Input onChange={accountnochange} value={selectedValues.accountno} defaultValue={selectedValues.driveraccno}  type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["ifsc", "IFSC"]} label="IFSC" rules={[]}>
              <Input onChange={ifscchange} value={selectedValues.ifsc} defaultValue={selectedValues.driverifsc}  type="text" />

            </Form.Item>
          </Col>
        </Row >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["bank", "BankName"]} label="Bank Name" rules={[]}>
              <Input onChange={banknamechange} value={selectedValues.bankname} defaultValue={selectedValues.driverbankname} type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["branch", "Branch"]} label="Branch" rules={[]}>
              <Input onChange={bankbranchnamechange} value={selectedValues.bankbranchname} defaultValue={selectedValues.driverbranch} type="text" />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name={["PAN", "PAN No"]} label="PAN" rules={[]}>
              <Input onChange={pannochange} value={selectedValues.panno} defaultValue={selectedValues.driverpan} type="text" />
            </Form.Item>
          </Col>

        </Row>




      </Form>
      <Row gutter={24}>

        <Col offset={5}>
          <Button onClick={addnewdriver}>
            Save
      </Button>
        </Col>
        <Col offset={2}>
          <Button onClick={handleCancel}>
            Cancel
      </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Driverform;
