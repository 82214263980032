
import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, notification, Input, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";

import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AddCorporate from "../../components/AppModal/AddCorporate";
import DeleteConfirmModal from "../../components/AppModal/DeleteConfirmModal";

const { Search } = Input;

const CorporateMaster = () => {
  let history: any = useHistory();
  const [addcorporate, setaddcorporate] = useState(false);
  const [editcorporate, seteditcorporate] = useState(false);
  const [addcorporateTittle, setaddcorporateTittle] = useState("Add Company");
  const [status, setstatus] = useState("Block");
  const [corporatelist, setcorporateslist] = useState([]);

  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [selectedValues, setselectedValues]: any = useState([]);
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [deletecorporatetitle, setDeletecorporatetitle] = useState('Company');

  const columns = [
    {
      title: "Company Name",
      dataIndex: "CompanyName",
      key: "CompanyName",
      fixed: "left",
    
     
      
    },
    {
      title: "Company ID",
      dataIndex: "CompanyID",
      key: "CompanyID",
      fixed: "left",
    ellipsis: true,
      },
    {
      title: "GST No",
      dataIndex: "gst",
      
      key: "GSTNo",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.mobileno + " / " + record.alternativemobileno;
      // },
    },
    {
      title: "MD Name",
      dataIndex: "MD_Name",
      key: "MDName",
      ellipsis: true,
      // render: (text: any, record: any) => {
      //   return record.mobileno + " / " + record.alternativemobileno;
      // },
    },
    {
        title: "Mobile Number",
        dataIndex: "MD_Mobno",
        key: "Mobileno",
        
       
        ellipsis: true,
      },
      {
        title: "Auth Person",
        dataIndex: "Authorized_Person",
        key: "AuthPerson",
        
       
        ellipsis: true,
      },
      {
        title: "Auth Mobile",
        dataIndex: "Auth_Mobno",
        key: "AuthMobile",
        
       
        ellipsis: true,
      },
      {
        title: "Address",
        dataIndex: "Address",
        key: "Address",
        ellipsis: true,
      },
      {
        title: "Credit Status",
        dataIndex: "is_credit",
        key: "CreditStatus",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "isdeleted",
        key: "Status",
        ellipsis: true,
      },
      {
        title: "UserName",
        dataIndex: "username",
        key: "UserName",
        ellipsis: true,
      },
      {
        title: "Password",
        dataIndex: "Password",
        key: "Password",
        ellipsis: true,
      },


  ];
  
  useEffect(() => {
    getcorporatelist(pagination, SearchText);
    refresh(pagination, SearchText);
   
  }, []);

  const showaddcorporatemodal = () => {
    setaddcorporateTittle("Add Company")
    seteditcorporate(false);
    setaddcorporate(true);
   };
   const showeditcorporatemodal = () => {
     setaddcorporateTittle("Edit Company")
     seteditcorporate(true);
     let copyselectedvalues={... selectedRowData[0]}
     setselectedValues(copyselectedvalues);
    setaddcorporate(true);
   };
  const hideModal = (isVisible: any) => {
   setaddcorporate(isVisible);
   refresh(pagination, SearchText);
   setsearchText('');
  };
 


 

 
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
    if(rowInfo.length>0)
    {
      if(rowInfo[0].isdeleted=="Inactive")
      setstatus("Unblock");
      else
      setstatus("Block");
    }
  };

 
 
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };


  const globalSearch = (searchText:any) =>{ 
    let filteredData = corporatelist.filter((value: any) => {
        if(value.CompanyName===undefined || value.CompanyName===null)
        value.CompanyName='';
        if(value.CompanyID===undefined || value.CompanyID===null)
        value.CompanyID='';
        if(value.gst===undefined || value.gst===null)
        value.gst='';
        if(value.Authorized_Person===undefined || value.Authorized_Person===null)
        value.Authorized_Person='';
        if(value.MD_Name===undefined || value.MD_Name===null)
        value.MD_Name='';
        if(value.MD_Mobno===undefined || value.MD_Mobno===null)
        value.MD_Mobno='';
        if(value.Auth_Mobno===undefined || value.Auth_Mobno===null)
        value.Auth_Mobno='';
        if(value.username===undefined || value.username===null)
        value.username='';
        if(value.Password===undefined || value.Password===null)
        value.Password='';
        if(value.Address===undefined || value.Address===null)
        value.Address='';
        /* if(value.is_credit===undefined || value.is_credit===null)
        value.is_credit='';
        if(value.isdeleted===undefined || value.isdeleted===null)
        value.isdeleted=''; */

    return (
          value.CompanyName
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())||
          value.CompanyID
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.gst
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.MD_Name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.MD_Mobno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.Authorized_Person
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.Auth_Mobno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.username
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          value.Password
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
         /*  value.isdeleted
          .toLowerCase()
          .includes(searchText.toLowerCase()) || */
          value.Address
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) /* ||
          value.is_credit
          .toLowerCase()
          .includes(searchText.toLowerCase())  */  
      );
    });
      setcopyCurrentBookingGridData(filteredData);
};


const refresh = (pagination:any, SearchText:any) => {
  const options = {
    params: {
      branchid: branchid,
      limit: pagination.pageSize,
      skip: (pagination.current-1) * (pagination.pageSize),
      filtervalue: SearchText
    },
  };
  axiosGet(UrlConstants.getcompany, options).then(
    (resp: any) => {
      console.log(resp);
      resp.data?.forEach((e: any) => {
        //console.log(e.CompanyID);
        if(e.is_credit=="1")
          e.is_credit="True";
          else
          e.is_credit="False";
          if(e.isdeleted=="1")
          e.isdeleted="Inactive";
          else
          e.isdeleted="Active";
        e.key = e._id;
      });
     
      setcorporateslist(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    }).catch(function (error: any) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
    });
};
  const getcorporatelist = (pagination:any, SearchText:any) => {
    const options: any = {
      params: {
        limit: pagination.pageSize,
        skip: (pagination.current-1) * (pagination.pageSize),
        filtervalue: SearchText
      },
    };
    console.log(options);
    axiosGet(UrlConstants.getcompany, options).then((resp: any) => {
      console.log(resp);
      if(resp.data!=undefined){
      resp.data?.forEach((e: any) => {
        //console.log(e.CompanyID);
        if(e.is_credit=="1")
          e.is_credit="True";
          else
          e.is_credit="False";
          if(e.isdeleted=="1")
          e.isdeleted="Inactive";
          else
          e.isdeleted="Active";
        e.key = e._id;
      });
      setcorporateslist(resp.data);
      setcopyCurrentBookingGridData(resp.data);
      console.log(corporatelist.length);
      setPagination({
        ...pagination,
        total: resp.tablelength
      });
    }
    });
  };


  const Deletecompany = () => {
    const options: any = {
      data: {
        ID:selectedRowData[0].ID,
      },
    };
    console.log(options);
    
    /* axiosPost(UrlConstants.deletecompany, options)
    .then((resp: any) => {
      console.log(resp);
      refresh(pagination);
      const notificationInfo: any = {
        header: "Company Deleted Successfully",
        description: "Company Name: " + selectedRowData[0].CompanyName + " Deleted",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: "Delete Company Failed",
        description: "Error Occurred while Delete Company",
      };
      openNotificationWithIcon("Error", notificationInfo);
    }); */
    refresh(pagination, SearchText);
  }


  
  const Blockcompany = () => {
    let statusb;
    if(selectedRowData[0].isdeleted=="Inactive")
      statusb="0";
      else
      statusb="1";
    const options: any = {
      data: {
        ID:selectedRowData[0].ID,
        isdeleted:statusb
      },
    };
    axiosPost(UrlConstants.blockcompany, options)
    .then((resp: any) => {
      console.log(resp);
      refresh(pagination, SearchText);
      const notificationInfo: any = {
        header: "Company "+status+"ed Successfully",
        description: "Company Name: " + selectedRowData[0].CompanyName + " " +status+"ed",
      };
      openNotificationWithIcon("Success", notificationInfo);

    })
    .catch(function (error: any) {
      if (error.response) {
        console.log(error.response.data, error.response.status);
      }
      const notificationInfo = {
        header: status+" Company Failed",
        description: "Error Occurred while "+status+" Company",
      };
      openNotificationWithIcon("Error", notificationInfo);
    });

  }

 
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
        notification.success({
            message: info.header,
            description: info.description,
        });
    } else if (type === "Error") {
        notification.error({
            message: info.header,
            description: info.description,
        });
    }
    refresh(pagination, SearchText);
};
const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
  setPagination(pagination);
  getcorporatelist(pagination, SearchText);
  console.log(pagination); 
}
const showConfirmDeleteModal = (tariffType:any) => {
  setConfirmDeleteModalVisible(true);
};
const hideConfirmDeleteModal = (isVisible: any) => {
  setConfirmDeleteModalVisible(isVisible);
  refresh(pagination, SearchText);
};
const confirmDelete = (option:any) => {
  if(option == true){
    Deletecompany();
  }
}
const onSearch = (value:any) => {
  setsearchText(value);
  getcorporatelist(pagination, value);
}
  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          <Col span={2}>
          <Button onClick={showaddcorporatemodal}  >
           
             Add
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button
             
              disabled={!isRowSelected}
              onClick={showeditcorporatemodal}
            >
             Edit
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button onClick={showConfirmDeleteModal}
            disabled={!isRowSelected}
            >
         Delete
            </Button>
          </Col>
          <Col span={2} offset={1}>
            <Button onClick={Blockcompany}
            disabled={!isRowSelected}
            >
        {status}
            </Button>
          </Col>
          <Col offset={1}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Total : </span></Col>
          <Col style={{ paddingLeft: '10px' }}>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}> {corporatelist?.length}</span></Col>
          <Col span={4} offset={4} >
        {/* <Input  placeholder="Search Text" value={SearchText} onChange={onSearchchange}/> */}
        <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
        </Col>
        </Row>
      </div>
      <div>
          
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          enableRadioButton={true}
          onRadioButtonChange={onGridRowSelect}
          enablePagination={pagination}
          selectionType="radio"
          onchange={onPaginationchange}
        ></AppTable>
        </Spin>
        <AddCorporate
         title={addcorporateTittle}
         visible={addcorporate}
         isedit={editcorporate}
         selectedRowData={selectedValues}
         hideModal={hideModal}></AddCorporate>
         <DeleteConfirmModal
          title={deletecorporatetitle}
          visible={confirmDeleteModalVisible}
          hideModal={hideConfirmDeleteModal}
          selectedRowData={selectedRowData}
          confirmDelete={confirmDelete}
         />
      </div>
      
    </div>
  );
};

export default CorporateMaster;
