import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Select,
  notification,
  DatePicker,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
const { Option } = Select;

const AppModalDriverAttendanceLeave = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData]: any = useState({});
  const [selectedReason, setSelectedReason]: any = useState(0);
  const [reasonsList, setReasonsList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const RangePicker = DatePicker.RangePicker;
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (props.selectedRowData.cabid) {
      setModalData(props.selectedRowData);
      onReasonsList();
    }
  }, [props.selectedRowData]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const onReasonDropdownChange = (value: any) => {
    setSelectedReason(value);
  };
  const onReasonsList = () => {
    axiosGet(UrlConstants.reasonsforleave).then((resp: any) => {
      setReasonsList(resp);
    });
  };

  const ReasonChange = (value: any, fullInfo: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.Reasonselected = value;
    selectedValuesCopy.goodsTypeName = fullInfo.others.goodsname;
    setSelectedValues(selectedValuesCopy);
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const leaveApprove = () => {
    const options = {
      data: {
        cabid: props.selectedRowData.cabid,
        reason: selectedValues.Reasonselected,
        fromdate: selectedValues.fromdate,
        todate: selectedValues.todate,
      },
    };

    axiosPost(UrlConstants.approveleave, options)
      .then((resp: any) => {
        const notificationInfo = {
          header: "Leave Request for " + modalData.cabid + "has been Approved",
          description: "",
        };
        openNotificationWithIcon("Success", notificationInfo);

        handleCancel(true);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const fromdateChange = (dates: any, dateStrings: any) => {
    try {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.fromdate = dateStrings[0];
      copyselectedvalues.todate = dateStrings[1];
      setSelectedValues(copyselectedvalues);
      let startdate = dateStrings[1];
      var new_date = moment(startdate, "YYYY-MM-DD").add("days", 1);
      var day = new_date.format("DD");
      var month = new_date.format("MM");
      var year = new_date.format("YYYY");
      let todate = year + "-" + month + "-" + day;
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-approve-attendance"
      title={props.title}
      width={400}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Row gutter={[10, 10]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Cab Id:
        </Col>
        <Col span={12}>{modalData.cabid}</Col>
      </Row>
      <Row style={{ paddingBottom: 10 }}>
        <Col span={4} className="custom-modal-approve-attendance-left-column">
          Date:
        </Col>
        <Col span={18} offset={2}>
          {" "}
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              "This Month": [moment().startOf("month"), moment()],
            }}
            onChange={fromdateChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="custom-modal-approve-attendance-left-column">
          Reason:
        </Col>
        <Col offset={3}>
          <Select
            style={{ width: 150 }}
            defaultValue={modalData.leavereason}
            placeholder="Reason"
            value={selectedValues.Reasonselected}
            onChange={ReasonChange}
          >
            {reasonsList.map((reasons: any) => (
              <Option
                key={reasons.reason.toString()}
                value={reasons.reason}
                others={reasons}
              >
                {reasons.reason}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col
          span={12}
          className="custom-modal-approve-attendance-btn-left-column"
        >
          <Button
            className="custom-modal-approve-attendance-approve-btn"
            htmlType="submit"
            onClick={leaveApprove}
          >
            Submit
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="custom-modal-approve-attendance-cancel-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AppModalDriverAttendanceLeave;
