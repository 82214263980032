import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input } from "antd";
import AppTable from "../AppTable/AppTable";
import "./AppModal.scss";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";

const Vehicleaccountmoreinfo = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [vehiclepayemnthistory, setvehiclepaymenthistory] = useState([]);
  const [copyvehiclepaymenthistory, setcopyvehiclepaymenthistory] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
 
  const [isLoading, setIsLoading] = useState(false);
  const [SearchText, setsearchText]: any = useState("");

  
    useEffect(() => {
        console.log(props)
        setVisible(props.visible);
        if (props.selectedRowData ) {
            //console.log(props.selectedRowData.cabid);
            setSelectedRowInfo(props.selectedRowData);
            getvehiclepaymenthistory();
        }
    }, [props.visible]);

 

  
  const columns = [
       
   

    {
        title: "Booking ID",
        dataIndex: "id",
        key: "Booking ID",

    },
    {
        title: "Customer Name",
        dataIndex: "name",
        key: "Booking ID",

    },
    {
        title: "Fare",
        dataIndex: "fare",
        key: "Booking ID",

    },
    

    {
        title: "Credit",
        dataIndex: "iscredit",
        key: "Booking ID",
        render: (text: any, record: any) => {
          return (<span>{record.iscredit ==false ? 'No' : 'Yes'}</span>
          )
        },

    },



]

const handleCancel = () => {
  props.hideModal(false);
  //setSelectedRowInfo({});
};

const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText:any) =>{ 
    let filteredData = vehiclepayemnthistory.filter((value: any) => {
        setIsLoading(true);
    return (
     
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        // value.cabid.toLowerCase().includes(searchText.toLowerCase()) ||
        value.name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())||
        value.fare.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.id.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) 
         
         
       
      );
    });
      setcopyvehiclepaymenthistory(filteredData);
      setIsLoading(false);
};
const getvehiclepaymenthistory = ( ) => {
    setIsLoading(true);

    let fromdate = moment(props.selectedRowData.fromdate).format("YYYY-MM-DD");
    let todate = moment(props.selectedRowData.todate).format("YYYY-MM-DD");
   
    const options: any = {
        params: {
          cityid: branchid,
          fromdate: fromdate,
          todate: todate,
          cabid: selectedRowInfo.cabid || '',
        },
    };
    
    axiosGet(UrlConstants.getvehiclepaymentmoreinfo, options).then((resp: any) => {
        console.log(resp);
        
            setvehiclepaymenthistory(resp);
            setcopyvehiclepaymenthistory(resp);
            setIsLoading(false);
        
    })
        .catch(function (error: any) {
            if (error.response) {
                console.log(error.response.data, error.response.status);
                setvehiclepaymenthistory([]);
                setcopyvehiclepaymenthistory([]);
            }
        });
};


  return (
    <div>
     
      <Modal
        destroyOnClose={true}
        className="custom-modal-cancel-form"
        width={450}
        title="More Info"
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        
        <Row className="custom-modal-grid-booking-info">
          <Col span={8}>VehicleID: {selectedRowInfo.cabid}</Col>
        
                    <Col span={8} offset={8}>
                    <Input  placeholder="Search Text" value={SearchText} onChange={onSearchchange}/>
                  
              </Col>
        </Row>
        <Row >
          <AppTable
            columns={columns}
            data={copyvehiclepaymenthistory}
            
          ></AppTable>
        </Row>
      </Modal>
    </div>
  );
};

export default Vehicleaccountmoreinfo;
