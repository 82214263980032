import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Button, Checkbox, DatePicker, Input, Spin } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import "../../pages/Booking/Booking.scss";

const { Search } = Input;

const VehicleDeatils = () => {
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [SearchText, setsearchText]: any = useState("");
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonClicked, setIsButtonClicked] = useState("Today");
  const [pagination, setPagination]:any = useState({
    current: 1,
    pageSize: 10,
  });
 
  const RangePicker = DatePicker.RangePicker;

  const columns = [
    {
        title: "Vehicle Id",
        dataIndex: "vehicleid",
        key: "Booking ID",

    },
    // {
    //     title: "Owner Name",
    //     dataIndex: "owner_name",
    //     key: "Booking ID",

    // },
    {
        title: "Reg No",
        dataIndex: "cab_no",
        key: "Name",

    },
    {
        title: "Fc Date",
        dataIndex: "fc_val",
        key: "Name",
        render: (text: any) => {
          return ((text!==undefined && text!== "" && text!==null) ? moment(text).format("DD/MM/YYYY") : '');
        }
    },

    {
        title: "Insurance date",
        dataIndex: "inexpiry",
        key: "Name",
        render: (text: any) => {
          return ((text!=undefined && text!= "" && text!=null) ? moment(text).format("DD/MM/YYYY") : '');
          
        },

    },
    {
        title: "Pollution",
        dataIndex: "policyexpirt",
        key: "Name",
        render: (text: any) => {
          return ((text!=undefined && text!= "" && text!=null) ? moment(text).format("DD/MM/YYYY") : '');
          
        },

    },
    {
        title: "Tarpaulin",
        dataIndex: "tarpaulin",
        key: "Name",
        render: (text: any) => {
          return (text == 1) ? 'Yes' : ((text == 2) ? 'No' : '')
        }

    },
    {
        title: "Rope",
        dataIndex: "rope",
        key: "Name",
        render: (text: any) => {
          return (text == 1) ? 'Yes' : ((text == 2) ? 'No' : '')
        }

    },
    {
        title: "Truck Id",
        dataIndex: "cab_id",
        key: "Name",

    },
    // {
    //     title: "Capacity",
    //     dataIndex: "name",
    //     key: "Name",

    // },
    {
        title: "Vehicle Name",
        dataIndex: "vehiclename",
        key: "Name",

    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicletype",
      key: "Name",
      render: (text: any) => {
        return (text == 1) ? 'Self' : ((text == 2) ? 'Managed' : '')
      }

  },
    {
        title: "Date of Joining",
        dataIndex: "AttachDate",
        key: "Name",
        ellipsis: true,
        render: (text: any) => {
         return ((text!=undefined && text!= "" && text!=null) ? moment(text).format("DD/MM/YYYY") : '');
        },

    },
    {
        title: "Status",
        dataIndex: "status",
        key: "Name",

    },
    {
        title: "Date of Exit",
        dataIndex: "name",
        key: "Name",

    },
  
  




]  

  const onChange=(dates:any, dateStrings:any)=> {
    try{
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    let startdate = dateStrings[1];
    var new_date = moment(startdate, "YYYY-MM-DD").add('days', 1);
    var day = new_date.format('DD');
    var month = new_date.format('MM');
    var year = new_date.format('YYYY');
    let todate=year + '-' + month + '-' + day;
    DateSearch(/* dateStrings[0],todate, */pagination, SearchText);
    }
    catch(ex){
      console.log(ex);
    }
  };

  const adayclick =(event: any=null)=>{
    setIsButtonClicked('Today');
    var first = moment();
    var firstday = first.format('DD'); var firstmonth = first.format('MM'); var firstyear = first.format('YYYY');
    let fromdate=firstyear + '-' + firstmonth + '-' + firstday;
    var new_date = moment().add('days', 1);
    var day = new_date.format('DD'); var month = new_date.format('MM'); var year = new_date.format('YYYY');
    let todate=year + '-' + month + '-' + day;
    DateSearch(/* fromdate,todate, */pagination, SearchText);
  };

 



const DateSearch =(/* fromdate:any,todate:any, */pagination:any, SearchText:any)=>{
  setIsLoading(true);
  const options: any = {
    params: {
      branchid:branchid,
      limit: pagination.pageSize,
      skip: (pagination.current-1) * (pagination.pageSize),
      filtervalue: SearchText
    },
  };
  axiosGet(UrlConstants.getvehicledetails,options).then((resp: any) => {
    resp.data.forEach((e: any) => {
      e.key = e._id;
    });
    setIsLoading(false);
    setCurrentBookingGridData(resp.data);
    setcopyCurrentBookingGridData(resp.data);
    console.log(resp);
    setPagination({
      ...pagination,
      total: resp.tablelength
    });
    
  }).catch(function (error:any) {
    setIsLoading(false);
    if (error.response) {
      console.log(error.response.data,error.response.status);
      setCurrentBookingGridData([]);
      setcopyCurrentBookingGridData([]);
    }
  });
  };



  useEffect(() => {
    console.log(isRowSelected);
    adayclick();
  }, []);
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
    const globalSearch = (searchText:any) =>{ 
    let filteredData = currentBookingGridData.filter((value: any) => {
    
      if(value.vehicleid===undefined || value.vehicleid===null)
        value.vehicleid='';
      if(value.cab_no===undefined || value.cab_no===null)
        value.cab_no='';
     /*  if(value.fc_val===undefined || value.fc_val===null)
        value.fc_val='';
      if(value.inexpiry===undefined || value.inexpiry===null)
        value.inexpiry='';
      if(value.policyexpirt===undefined || value.policyexpirt===null)
        value.policyexpirt='';
      if(value.tarpaulin===undefined || value.tarpaulin===null)
        value.tarpaulin='';
      if(value.rope===undefined || value.rope===null)
        value.rope=''; */
      if(value.cab_id===undefined || value.cab_id===null)
        value.driver_mobno='';
      if(value.vehiclename===undefined || value.vehiclename===null)
        value.vehiclename='';
     /*   if(value.vehicletype===undefined || value.vehicletype===null)
        value.vehicletype='';
     if(value.AttachDate===undefined || value.AttachDate===null)
        value.AttachDate=''; 
         if(value.status===undefined || value.status===null)
        value.status='';*/
    //  console.log("attachdate"+value.AttachDate);
     
    return (
     
        value.vehicleid
        .toString()
        .toLowerCase().includes(searchText.toLowerCase()) ||
        value.cab_no
         .toString()
         .toLowerCase().includes(searchText.toLowerCase()) ||
        /* value.fc_val
         .toString()
         .toLowerCase().includes(searchText.toLowerCase()) ||
        value.inexpiry
         .toString()
         .toLowerCase().includes(searchText.toLowerCase()) ||
        value.policyexpirt
         .toString()
         .toLowerCase().includes(searchText.toLowerCase()) || 
        value.tarpaulin
         .toString()
         .toLowerCase().includes(searchText.toLowerCase()) ||
        value.rope
         .toString()
         .toLowerCase()
         .includes(searchText.toLowerCase())||*/
        value.cab_id
         .toString()
         .toLowerCase()
         .includes(searchText.toLowerCase())||
        value.vehiclename
         .toString()
         .toLowerCase()
         .includes(searchText.toLowerCase()) ||
        value.vehicletype
         .toString()
         .toLowerCase()
         .includes(searchText.toLowerCase())/*  ||
        value.AttachDate
         .toString()
         .replace('-','/')
         .toLowerCase()
         .includes(searchText.toLowerCase()) ||
         value.status
         .toString()
         .toLowerCase()
         .includes(searchText.toLowerCase())*/
      );
    });
      setcopyCurrentBookingGridData(filteredData);
};

function disabledDate(current:any) {
  // Can not select days before today and today
  return current > moment().endOf('day');
}
const onPaginationchange = (pagination:any, filters:any, sorter:any, extra:any) => {
  setPagination(pagination);
  DateSearch(/* fromdate, todate,  */pagination, SearchText);
  console.log(pagination); 
}
const onSearch = (value:any) => {
  console.log(value);
  setsearchText(value);
  DateSearch(/* fromdate, todate,  */pagination, value);
}

  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">
      <Row>
        
              <Col span={4} offset={20}>
        {/* <Input  placeholder="Search Text" value={SearchText} onChange={onSearchchange}/> */}
        <Search placeholder="Search Text" onSearch={onSearch} enterButton/>
        </Col>
        </Row>
      </div>
      
      <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <AppTable
          columns={columns}
          data={copycurrentBookingGridData}
          onchange={onPaginationchange}
          enablePagination={pagination}
          selectionType="radio"
          scroll={{ x:1800,y: 480 }}
        ></AppTable>
        </Spin>
      </div>
    </div>
  );
};

export default VehicleDeatils;
