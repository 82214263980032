import React, { useState, useEffect } from "react";
import { Tag, Space, Row, Col, Input, Select, Spin } from "antd";
import AppTable from "../../components/AppTable/AppTable";

import moment from "moment";
import "./Finance.scss";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import axiosGet from "../../helpers/AxiosRequest";

const { Search } = Input;

const Vehicleaccount = () => {
  const [vehiclepayemnthistory, setvehiclepaymenthistory] = useState([]);
  const [copyvehiclepaymenthistory, setcopyvehiclepaymenthistory] = useState(
    []
  );
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [isLoading, setIsLoading] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "Date",
      ellipsis: true,
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
    },

    {
      title: "Cab id",
      dataIndex: "cabid",
      key: "Booking ID",
    },
    {
      title: "Ref. No",
      dataIndex: "refference",
      key: "Booking ID",
    },
    {
      title: "Mode of Payment",
      dataIndex: "mop",
      key: "Booking ID",
      render: (text: any, record: any) => {
        return {
          children: (
            <div>
              {text == 1
                ? "NEFT"
                : text == 2
                ? "Prepaid"
                : text == 3
                ? "Cash"
                : ""}
            </div>
          ),
        };
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "Booking ID",
      render: (text: any) => {
        if (text != undefined) {
          if (text != null && text != undefined && text != "" && text != 0)
            return Math.round(text);
          else return text;
        }
      },
    },
  ];
  useEffect(() => {
    getvehiclepaymenthistory(pagination, SearchText);
  }, []);
  const onSearchchange = (e: any) => {
    console.log(e.target.value);
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = vehiclepayemnthistory.filter((value: any) => {
      return value.cabid
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
    setcopyvehiclepaymenthistory(filteredData);
  };

  const getvehiclepaymenthistory = (pagination: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        cityid: branchid,
        fromdate: new Date("2000-01-01"),
        todate: new Date().setDate(new Date().getDate() + 1),
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };

    axiosGet(UrlConstants.getvehiclepaymenthistory, options)
      .then((resp: any) => {
        console.log(resp);
        setIsLoading(false);
        resp.forEach((e: any) => {
          e.key = e._id;
        });
        setvehiclepaymenthistory(resp);
        setcopyvehiclepaymenthistory(resp);
        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    getvehiclepaymenthistory(pagination, SearchText);
    console.log(pagination);
  };

  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row style={{ paddingBottom: 10 }}>
          {" "}
          <Col>
            {" "}
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              {" "}
              VEHICLE PAYMENT HISTORY
            </span>
          </Col>
          <Col span={4} offset={10}>
            <Input
              placeholder="Search Cab"
              className="search"
              onChange={onSearchchange}
            />
          </Col>
        </Row>

        <div>
          <Spin tip="Loading..." spinning={isLoading}>
            <AppTable
              className="finance-table-row"
              columns={columns}
              data={copyvehiclepaymenthistory}
              enablePagination={true}
              onchange={onPaginationchange}
            ></AppTable>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Vehicleaccount;
