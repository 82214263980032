import React, { useState, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Form,
  Input,
  InputNumber,
  Button,
  notification,
  Select,
  Row,
  Col,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { convertToObject } from "typescript";

const AddAttachmentenquiry = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [branchList, setBranchList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const [copyinterCityList1, setcopyinterCityList]: any = useState({});
  const [selectedValues, setSelectedValues]: any = useState({});
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [assignedtime, setassignedtime]: any = useState(new Date());
  const [completetime, setcompletetime]: any = useState(new Date());
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const { Option } = Select;

  // useEffect(() => {
  //   setVisible(props.visible);
  // }, [props.visible]);

  useEffect(() => {
    setVisible(props.visible);
    getbrancheslist();
    getVechileTypeList(branchid);
    if (props.isedit) {
      let selectedVehicleType: any = vehicleTypeList.filter(
        (vehicle: any) => vehicle.id == props.selectedRowData.enquiry_type
      );
      let copyselectedvalues = { ...props.selectedRowData };
      copyselectedvalues.enquiry_type = selectedVehicleType[0].id;
      copyselectedvalues.vehicleType = selectedVehicleType[0].id;
      copyselectedvalues.vehiclename = selectedVehicleType[0].vehicletype;
      setSelectedValues(copyselectedvalues);
    } else {
      setSelectedValues({});
    }

    //onchangekm(props.selectedRowData[0].distance);
  }, [props.visible]);
  function getbrancheslist() {
    axiosGet(UrlConstants.getBranchList).then((resp: any) => {
      setBranchList(resp);
    });
  }

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values: any) => {};

  const getVechileTypeList = (cityId: any) => {
    const options = {
      params: {
        cityid: cityId,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + cityId;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
    });
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
  };
  const namechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.name = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const numberchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.number = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const onaddresschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.address = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const onareachange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.area = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const licensechange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.license = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const registrationchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.registration = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const modelchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.model = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const makeyearchange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.year = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const onActionChange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.action = e;
    setSelectedValues(selectedValuesCopy);
  };
  const remarkschange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.remarks = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const onVehicleTypeChange = (value: any, fullInfo: any) => {
    let copyselectedvalues = { ...selectedValues };
    copyselectedvalues.enquiry_type = value;
    copyselectedvalues.vehicleType = value;
    copyselectedvalues.capacity = fullInfo.others.loadcapacity;
    copyselectedvalues.vehiclename = fullInfo.others.vehicletype;
    setSelectedValues(copyselectedvalues);
  };

  const addattachenquiry = () => {
    const options: any = {
      data: {
        name: selectedValues.name,
        number: selectedValues.number,
        address: selectedValues.address,
        enquiry_type: selectedValues.enquiry_type,
        license: selectedValues.license,
        registration: selectedValues.registration,
        model: selectedValues.model,
        year: selectedValues.year,
        remarks: selectedValues.remarks,
        cityid: branchid,
        attended_by: username,
        area: selectedValues.area,
      },
    };

    axiosPost(UrlConstants.addattachment, options)
      .then((resp: any) => {
        handleCancel("");
        let notificationInfo: any;

        notificationInfo = {
          header: "Enquiry Added Successfully",
        };

        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Enquiry Adding Failed",
          description: "Error Occurred while Adding Enquiry",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const editattachenquiry = () => {
    const options: any = {
      data: {
        enquiry_id: selectedValues.enquiry_id,
        name: selectedValues.name,
        number: selectedValues.number,
        address: selectedValues.area,
        enquiry_type: selectedValues.enquiry_type,
        license: selectedValues.license,
        registration: selectedValues.registration,
        model: selectedValues.model,
        year: selectedValues.year,
        remarks: selectedValues.remarks,
        //cityid: branchid,
        action: selectedValues.action,
        attended_by: selectedValues.attended_by,
      },
    };
    axiosPost(UrlConstants.editattachment, options)
      .then((resp: any) => {
        handleCancel("");
        let notificationInfo: any;

        notificationInfo = {
          header: "Enquiry Edit Successfully",
        };

        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Enquiry Editing Failed",
          description: "Error Occurred while Editing Enquiry",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-form"
      title={props.title}
      width={450}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      {/* <>{JSON.stringify(selectedValues)}</> */}
      <Form {...layout} name="nest-messages" onFinish={onFinish}>
        <Form.Item name={["name", "name"]} label="Name" rules={[]}>
          <Input
            defaultValue={selectedValues.name}
            value={selectedValues.name}
            onChange={namechange}
          />
        </Form.Item>
        <Form.Item name={["number", "number"]} label="Number" rules={[]}>
          <Input
            defaultValue={selectedValues.number}
            value={selectedValues.number}
            onChange={numberchange}
          />
        </Form.Item>
        <Form.Item name={"Area"} label="Area" rules={[]}>
          <Input
            defaultValue={selectedValues.area}
            value={selectedValues.address}
            onChange={onaddresschange}
          />
        </Form.Item>
        <Form.Item name={"Address"} label="Address" rules={[]}>
          <Input
            defaultValue={selectedValues.area}
            value={selectedValues.area}
            onChange={onareachange}
          />
        </Form.Item>

        <Form.Item
          name={["enquiry_type", "enquiry_type"]}
          label="Enquiry Type"
          rules={[]}
        >
          <Select
            placeholder="Vehicle Name"
            value={selectedValues.vehicleType}
            defaultValue={selectedValues.vehicleType}
            onChange={onVehicleTypeChange}
          >
            {vehicleTypeList.map((vehicle: any) => (
              <Option key={vehicle.id} value={vehicle.id} others={vehicle}>
                {vehicle.vehicletype}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={["license", "license"]}
          label="License"
          // rules={[{ required: true }]}
        >
          <Input
            defaultValue={selectedValues.license}
            value={selectedValues.license}
            onChange={licensechange}
          />
        </Form.Item>
        <Form.Item
          name={["registration", "registration"]}
          label="Registration"
          rules={[]}
        >
          <Input
            defaultValue={selectedValues.registration}
            value={selectedValues.registration}
            onChange={registrationchange}
          />
        </Form.Item>
        <Form.Item label="Model">
          <Input
            defaultValue={selectedValues.model}
            value={selectedValues.model}
            onChange={modelchange}
          />
        </Form.Item>
        <Form.Item name={["make", "make"]} label="Make Year">
          <Input
            defaultValue={selectedValues.year}
            value={selectedValues.year}
            onChange={makeyearchange}
          />
        </Form.Item>
        {props.isedit && (
          <Form.Item name={["action", "action"]} label="Action" rules={[]}>
            <Select
              placeholder="Vehicle Name"
              value={selectedValues.action}
              defaultValue={selectedValues.action}
              onChange={onActionChange}
            >
              <Option key={1} value="NEW">
                New
              </Option>
              <Option key={2} value="PENDING">
                Pending
              </Option>
              <Option key={3} value="ATTACHED">
                Attached
              </Option>
              <Option key={4} value="REJECTED">
                Rejected
              </Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item name={["remarks", "remarks"]} label="Remarks">
          <Input
            defaultValue={selectedValues.remarks}
            value={selectedValues.remarks}
            onChange={remarkschange}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          {props.isedit ? (
            <Button
              className="custom-modal-form-submit-btn"
              htmlType="submit"
              onClick={editattachenquiry}
            >
              Update
            </Button>
          ) : (
            <Button
              className="custom-modal-form-submit-btn"
              htmlType="submit"
              onClick={addattachenquiry}
            >
              Add Attachment
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAttachmentenquiry;
