export const UrlConstants = {
  login: "/auth/login",
  verifyOtp: "/auth/verifyotp",
  addNewBooking: "/booking/addbooking",
  addEnquiry: "/booking/addenquiry",
  addToRac: "/booking/addtoRAC",
  assignBooking: "/booking/assigntrip",
  reassignBooking: "/booking/reassigntrip",
  getNewBooking: "/booking/getNewbooking",
  editBooking: "/booking/editbooking",
  getEditBookingDetails: "/booking/getNewbookingedit",
  getBranchList: "/booking/getbrancheslist",
  getCompanyList: "/booking/getcompanieslist",
  getGoodsList: "/booking/getgoodstypes",
  getVehicleTypeList: "/booking/getvehicletypes",
  getTripTypeList: "/booking/gettriptypes",
  getCurrentBooking: "/booking/getlivebooking",
  getCompletedbooking: "/booking/getcompletebooking",
  getonlinebooking: "/booking/getOnlinebooking",
  getCompletedbookingbydate: "/booking/getcompletebookingbydate",
  exportgetCompletedbookingbydate: "/booking/exportcompletebookingbydate",
  getenquirybydate: "/booking/getenquirybydate",
  getcancelbydate: "/booking/getcancelbydate",
  gettelecancelbydate: "/booking/gettelecancelbydate",
  getEnquiry: "/booking/getenquiry",
  getRAC: "/booking/getRAC",
  getCancelBooking: "/booking/cancelbooking",
  getPackagesList: "/booking/getpackages",
  getInterCityList: "/booking/getintercitylist",
  getInterCityReturnList: "/booking/getreturnintercitylist",
  getAvailableCabs: "/booking/getavailablecabs",
  getAvailableCabsNew: "/booking/getavailablecabsnew",
  getCustomerName: "/booking/getcustomer",
  getDriverAttendanceList: "/booking/getdriverattandance",
  getLocations: "/booking/getlocations",
  getDistance: "/booking/getdistance",
  getMeterFare: "/booking/getmeterfare",
  getcancelledbookinglist: "/booking/getcancelledbooking",
  newbookingtoRAC: "/booking/newbookingtoRAC",
  getTeleCancellist: "/booking/gettelecancelledlist",
  UpdatetoNewBooking: "/booking/updatetonewbooking",
  RACtoenquiry: "/booking/RACtoenquiry",
  approvelogin: "/booking/approveattandance",
  approvelogout: "/booking/approvelogout",
  approvecableave: "/booking/leaveapprove",
  reasonsforleave: "/booking/reasonsforleave",
  approveleave: "/booking/leaveapprove",
  tripComplete: "/booking/completetrip",
  getcancelreason: "/booking/getcancelreason",
  cancelbooking: "/booking/cancelbooking",
  getvehiclemasterlist: "/master/vehicleMasterList",
  getgoodslist: "/master/getListGoodsMaster",
  getuserslist: "/master/getUsersList",
  getmetrefarelist: "/master/getMeterFareList",
  addmetrefare: "/master/addMeterFare",
  editmetrefare: "/master/editMeterFare",
  deletemetrefare: "/master/deleteMeterFare",
  getpackagefarelist: "/master/getPakageFareList",
  addpackagefare: "/master/addPakageFare",
  editpackagefare: "/master/editPakageFare",
  deletepackagefare: "/master/deletePakageFare",
  getIntercityFareList: "/master/getIntercityFareList",
  addintercityfare: "/master/addIntercity",
  editintercityfare: "/master/editIntercity",
  deleteintercityfare: "/master/deleteIntercity",
  addgoods: "/master/createGoodsMaster",
  updategoods: "/master/editGoodsMaster",
  getlocations: "/master/getLocations",
  getroletypes: "/master/getListRoleTypes",
  getemployeeid: "/master/getUserID",
  addlocation: "/master/addLocation",
  editlocation: "/master/editLocation",
  deletelocation: "/master/deleteLocation",
  getcompany: "/master/getCompanyList",
  getcompanyid: "/master/getCompanyID",
  addcompany: "/master/addCompany",
  editcompany: "/master/editCompany",
  deletecompany: "/master/deleteCompany",
  blockcompany: "/master/block_or_unblockCompany",
  getmtruckid: "/master/getMtruckID",
  getvehicleid: "/master/getVechicleID",
  getownerid: "/master/getownerID",
  getdriverid: "/master/getDriverID",
  getavailablemtruckids: "/master/getmtrucklist",
  checkcabavaialbility: "/master/checkCabId",
  getownerslist: "/master/getOwnersList",
  adduser: "/master/addUser",
  edituser: "/master/editUser",
  deleteuser: "/master/deleteUser",
  blockuser: "/master/blockorunblockuser",
  addnewvehicle: "/master/addvehicle",
  editvehicle: "/master/editvehicle",
  addnewdriver: "/master/adddriver",
  editdriver: "/master/editdriver",
  addnewowner: "/master/addowner",
  editowner: "/master/editowner",
  addvehiclemaster: "/master/addvehiclemaster",
  editvehiclemaster: "/master/editvehiclemaster",
  getownerdetails: "/report/getownerdetails",
  getvehicledetails: "/report/getvehicledetails",
  getdriverdetails: "/report/getdriverdetails",
  gettelecallerreport: "/report/gettelecallerreport",
  getcabattendancereport: "/master/DayReport1",
  getwebreport: "/booking/getWebReport",
  getvehicleaccounts: "/finance/vehicleaccounts",
  getvehicleaccountnew: "/finance/vehicleaccountsnew",
  getcorporateaccounts: "/finance/corporateaccount",
  blockcab: "/booking/blockcab",
  unblockcab: "/booking/unblockcab",
  makevehiclepayment: "/finance/vehicleaddpayment",
  makecorporatepayment: "/finance/corporateaddpayment",
  getvehiclepaymenthistory: "/finance/vehiclepaymenthistory",
  getcorporatepaymenthistory: "/finance/corporatepaymenthistory",
  getvehiclepaymentmoreinfo: "/finance/vehicleaccountsmoreinfo",
  getcorporatemoreinfo: "/finance/corporateaccountsmoreinfo",
  getcabidlist: "/report/getcabidlist",
  getvehicleidslist: "/report/getvehicleidlist",
  getdayreport: "/report/getdayreport",
  getlogoutdetails: "/booking/getlogoutdetails",
  getattachment: "/enquiry/getAttachmentEnquiry",
  addattachment: "/enquiry/addAttachmentEnquiry",
  editattachment: "/enquiry/editAttachmentEnquiry",
  revertlogin: "/booking/revertlogin",
  revertlogout: "/booking/revertlogout",
  updateodometer: "/master/updateodometerKM",
  editcompletedtrip: '/booking/editcompletedtrip',
  enquiryAssignBooking: '/booking/enquiryassigntrip',
  enquirytonewbooking: "/booking/enquirytonewbooking",
  updateDriverStatus: "/booking/makeCabAvailable",
  continueTrip: "/booking/continueTrip",
  getLatLongbyName: "/master/getLatLongbyName",
  getCreditReport: "/credit/getcreditreportbydate",
  getCreditInvoice: "/credit/getcreditinvoicebydate",
  getStatisticsReportbydate: "report/getstaticsreport",
  getappusers:"/master/getCustomerList",
  userlogout:"/auth/logout",
  locationupdate:"/booking/editdriverlocation",
  getNightFareType:"/booking/getnightfare",
  getCustomerbyDate:"/master/getCustomerListbyDate"
};
